import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import ColorHelper from "../../Helpers/ColorHelper";
import ConstantHelper from "../../Helpers/ConstantHelper";
import FontHelper from "../../Helpers/FontHelper";
import IconHelper from "../../Helpers/IconHelper";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const SummaryStickySectionUnlabeled = () => {
  const navigate = useNavigate();
 
  const cartInfo = useSelector((state) => state.shop.cartInfo);
  const print = cartInfo.filter((items) => items.type == "print");
  const images = cartInfo.filter((items) => items.type == "download");
  const config = useSelector((state)=>state.config.masterConfig.result);
  const products = useSelector((state)=>state.shop.products);
  const collage = cartInfo.filter((items)=>items.type=="collage");
  
  return (
    <Box>
      <Box
        sx={{
          position: "fixed",
          px: { xs: 3, md: 5 },
          bottom: -20,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: ColorHelper.WhiteColor,
          width: "100%",
          minHeight: "auto",
          mt: 5,
          mb: 2,
          padding: "1.5rem 80px",
          borderTop: `1px solid ${ColorHelper.borderColor}`,
          borderTop: `1px solid ${ColorHelper.borderColor}`,
          zIndex: 5,
          boxShadow:
            "0px 20px 25px -5px rgba(16, 24, 40, 0.1), 0px 8px 10px -6px rgba(16, 24, 40, 0.1)",
        }}
      >
        <Typography
          sx={{
            fontFamily: FontHelper.fBold,
            overflow: "hidden",
            // whiteSpace: "nowrap",
            minWidth: "max-content",
            height: "33px",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            flexWrap: "wrap",
            color: ColorHelper.GreenColor,
          }}
        >
          <span style={{ color: ColorHelper.BlackColor, marginRight: 4 }}>
            {ConstantHelper.SelectedText}
          </span>
          {images.length !== 0 && (
            <>
              {images.length}{" "}
              <span
                style={{
                  color: ColorHelper.BlackColor,
                  marginLeft: 4,
                  marginRight: 4,
                }}
              >
                {ConstantHelper.products1}{" "}
              </span>{" "}
            </>
          )}
          {print.length !== 0 && (
            <>
              {print.length}{" "}
              <span
                style={{
                  color: ColorHelper.BlackColor,
                  marginLeft: 4,
                  marginRight: 4,
                }}
              >
                {" "}
                {ConstantHelper.products2}
              </span>{" "}
            </>
          )}
          {collage.length !== 0 && (
            <>
              {collage.length}{" "}
              <span
                style={{
                  color: ColorHelper.BlackColor,
                  marginLeft: 4,
                  marginRight: 4,
                }}
              >
                {ConstantHelper.products4}
              </span>{" "}
            </>
          )}
        </Typography>

        <Box
          sx={{
            border: `1px solid ${ColorHelper.LightPurple}`,
            width: "100%",
            mx: 5,
            display: { xs: "none", sm: "block" },
          }}
        ></Box>

        <Box
          disableripple={true}
          onClick={() => navigate("/unlabeled-cart")}
          sx={{
            fontFamily: FontHelper.fBold,
            fontSize: 16,
            color: ColorHelper.WhiteColor,
            backgroundColor: ColorHelper.GreenColor,
            whiteSpace: "noWrap",
            minWidth: "max-content",
            px: { xs: "15px", md: "30px" },
            py: "15px",
            borderRadius: "5px",
            marginLeft: "auto",
            mb: 0,
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            "&:hover": {
              backgroundColor: ColorHelper.PrimaryColor,
            },
            "#btnText": {
              display: { xs: "none", sm: "flex" },
            },
          }}
        >
          <IconHelper.CartFilledIcon />
          <span style={{ marginLeft: "0.3rem" }} id="btnText">
            {ConstantHelper.ProceedToCheckout}
          </span>
        </Box>
      </Box>
    </Box>
  );
};

export default SummaryStickySectionUnlabeled;
