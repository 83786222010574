import { Box } from "@mui/system";
import React, { useState, useEffect } from "react";
import FeaturedEvents from "../Components/FeaturedEvents/FeaturedEvents";
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import HeroSection from "../Components/HeroSection/HeroSection";
import Highlights from "../Components/Highlights/Highlights";
import axios from "axios";
import Cookies from "../Components/Cookies/Cookies";
import UpcomingAndPastEvents from "../Components/UpcomingAndPastEvents.js/UpcomingAndPastEvents";
import { Button, Container, Modal, Typography } from "@mui/material";
import eventData from "../Components/EventCard/EventData";
import EventCard from "../Components/EventCard/EventCard";
import FontHelper from "../Helpers/FontHelper";
import Preorder from "../Components/PreOrder/Preorder";
import Preorderfile from "../Components/PreOrder/Preorder";
import { useLocation } from "react-router-dom";
import { ApiManager } from "../ApiClient";
import { useNavigate } from "react-router-dom";
import LoaderComp from "../Components/Loader/LoaderComp";
import { useSnackbar } from "notistack";

const PreOrder = () => {
  const snack = useSnackbar();
  const navigate = useNavigate();

  const [errorData, setErrorData] = useState("");
  const [image, setImage] = useState(null);
  const [eventName, setEventName] = useState(null);
  const [date, setDate] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [price, setPrice] = useState(null);
  const [cutPrice, setCutPrice] = useState(null);

  const [selectedEvent, setSelectedEvent] = useState();
  const [events, setEvents] = useState();
  const [featuredEvents, setFeaturedEvents] = useState();
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [userName, setUserName] = useState("");
  const [cookiesModal, setCookies] = useState(true);
  const { state, search } = useLocation();
  const eventId = new URLSearchParams(search).get("eventId");

  const fetchPreOrder = async () => {
    const response = await axios
      .post(ApiManager.preOrder() + `?eventId=${eventId}`)
      .then((res) => {
        const response = res.data;
        if (response.StatusCode == 200) {
          setDate(response.result.eventslist.event_from_date);
          setImage(response.result.eventslist.event_logo);
          setEventName(response.result.eventslist.event_name);
          setFromDate(response.result.eventslist.event_from_date);
          setToDate(response.result.eventslist.event_to_date);
          setPrice(response.result.preorderprice.price);
          setCutPrice(response.result.preorderprice.cut_price);
        } else {
          snack.enqueueSnackbar({
            variant: "warning",
            message: response.Message,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        }
      })
      .catch((err) => {
        snack.enqueueSnackbar({
          variant: "error",
          message: "Something went wrong !",
        });
      });
  };

  const handleClose = () => {
    setCookies(!cookiesModal);
  };

  useEffect(() => {
    fetchPreOrder();
    // fetchEvents();
    // fetchEventsWithData();
  

    window.scrollTo(0, 100);
    window.scrollTo(0, 0);

    setIsSignedIn(localStorage.getItem("isSignedIn"));
    if (localStorage.getItem("Cookies") == "true") {
      setCookies(false);
    }
    if (localStorage.getItem("isSignedIn") == "yes") {
      setUserName(localStorage.getItem("Name"));
    } else {
      setUserName("Sign In");
    }
  }, []);
  useEffect(()=>{
    document.title = `Pre-order | ${eventName}`;
  },[eventName])

  return (
    <>
      <Box>
        {/* {cookiesModal && <Cookies open={cookiesModal} close={handleClose} />} */}
        {eventName ? (
          <>
            <Header isSignedIn={isSignedIn} userName={userName} />
            <Preorderfile
              image={ApiManager.url() + `${image}`}
              eventName={eventName}
              date={date}
              fromDate={fromDate}
              toDate={toDate}
              eventId={eventId}
              price={price}
              cutPrice={cutPrice}
            />
            <Footer />
          </>
        ) : (
          <LoaderComp />
        )}
      </Box>
    </>
  );
};
export default PreOrder;
