import { Box } from "@mui/system";
import React, { useState, useEffect } from "react";
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import axios from "axios";
import {
  Grid,
  CardContent,
  CardActions,
  Button,
  InputAdornment,
  Typography,
  Divider,
} from "@mui/material";

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "formik-material-ui";
import ConstantHelper from "../Helpers/ConstantHelper";
import FilledButton from "../Components/Ui-Components/Buttons/FilledButton/FilledButton";
import ColorHelper from "../Helpers/ColorHelper";
import { AccountCircle } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import FontHelper from "../Helpers/FontHelper";
import { ApiManager } from "../ApiClient";
import { useSnackbar } from "notistack";

const initialValues = {
  mail: "",
  passcode: "",
  password: "",
  confirmPassword: "",
};

const lengthRegEx = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
const MailRegEx = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

let validationSchema = Yup.object().shape({
  passcode: Yup.string()
    .matches(/^([0-9]).{5,}$/, "Enter the passcode")
    .required("Required!"),
  mail: Yup.string()
    .matches(MailRegEx, "Enter a Valid mail")
    .required("Required!"),
  password: Yup.string()
    .matches(
      lengthRegEx,
      "Password must be a min. eight characters long and should include one each of uppercase, lowercase, number, special character"
    )
    .required("Required!"),
  confirmPassword: Yup.string()
    .matches(
      lengthRegEx,
      "Password must be a min. eight characters long and should include one each of uppercase, lowercase, number, special character"
    )
    .required("Required!")
    .test("passwords-match", "Passwords must match", function (value) {
      return this.parent.password === value;
    }),
});

const ResetPassword = () => {
  const snack = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    document.title = "Reset Password";
  }, []);
  let navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 100);
    window.scrollTo(0, 0);
  }, []);

  const onSubmit = async (values, formicHelpers) => {
    if (localStorage.getItem("passcode") === values.passcode) {
      setIsLoading(true);

      axios
        .post(ApiManager.passwordReset(), {
          passCode: values.passcode,
          email: values.mail,
          newPassword: values.password,
          confirmPassword: values.confirmPassword,
        })

        .then((response) => {
          if (response.data.StatusCode == 200) {
            setIsLoading(false);

            snack.enqueueSnackbar({
              variant: "success",
              message: response.data.Message,
            });
            navigate("/");
          } else {
            setIsLoading(false);

            snack.enqueueSnackbar({
              variant: "warning",
              message: response.data.Message,
            });
          }
        })
        .catch((err) => {
          setIsLoading(false);

          snack.enqueueSnackbar({
            variant: "warning",
            message: err,
          });
        });
    } else {
      snack.enqueueSnackbar({
        variant: "warning",
        message: "please provide a valid passcode",
      });
    }

    formicHelpers.resetForm();
  };

  return (
    <Box>
      <div style={{ display: isLoading ? "flex" : "none" }} className="modal">
        <div className="modal-content">
          <div className="loader"></div>
          <div className="modal-text">Processing...</div>
        </div>
      </div>
      <>
        <Box>
          <Header />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItem: "center",
            }}
          >
            <Box
              sx={{
                py: 5,
                minHeight: "60vh",
                flexDirection: "column",
                width: { xs: "100%", sm: "70%", md: "60%", lg: "40%" },
              }}
            >
              <Typography
                sx={{
                  fontFamily: FontHelper.fBold,
                  fontSize: "30px",
                  fontWeight: 700,
                  color: ColorHelper.DarkGrey2,
                  px: 2,
                }}
              >
                {ConstantHelper.ResetPassword}
              </Typography>

              <Divider variant="middle" sx={{}} />
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({ dirty, isValid, values, handleChange, handleBlur }) => {
                  return (
                    <Form name="formName">
                      <CardContent>
                        <Typography
                          sx={{
                            fontFamily: FontHelper.fRegular,
                            fontSize: "16px",
                            color: ColorHelper.PrimaryColor,
                          }}
                        >
                          Passcode
                          {<span style={{ color: "red" }}>*</span>}
                        </Typography>

                        <Grid item xs={12} sm={6} md={12} sx={{ my: 2 }}>
                          <Field
                            placeholder="Enter your code sent to you"
                            variant="outlined"
                            fullWidth
                            type="passcode"
                            name="passcode"
                            value={values.passcode}
                            component={TextField}
                            startAdornment={
                              <InputAdornment position="start">
                                <AccountCircle />
                              </InputAdornment>
                            }
                          />
                        </Grid>
                        <Typography
                          sx={{
                            fontFamily: FontHelper.fRegular,
                            fontSize: "16px",
                            color: ColorHelper.PrimaryColor,
                          }}
                        >
                          E-mail
                          {<span style={{ color: "red" }}>*</span>}
                        </Typography>

                        <Grid item xs={12} sm={6} md={12} sx={{ my: 2 }}>
                          <Field
                            placeholder="Enter your E-mail id."
                            variant="outlined"
                            fullWidth
                            type="mail"
                            name="mail"
                            value={values.mail}
                            component={TextField}
                            startAdornment={
                              <InputAdornment position="start">
                                <AccountCircle />
                              </InputAdornment>
                            }
                          />
                        </Grid>
                        <Typography
                          sx={{
                            fontFamily: FontHelper.fRegular,
                            fontSize: "16px",
                            color: ColorHelper.PrimaryColor,
                          }}
                        >
                          {ConstantHelper.NewPassWord}
                          {<span style={{ color: "red" }}>*</span>}
                        </Typography>

                        <Grid item xs={12} sm={6} md={12} sx={{ my: 2 }}>
                          <Field
                            placeholder="Enter new password"
                            variant="outlined"
                            type="password"
                            fullWidth
                            name="password"
                            value={values.password}
                            component={TextField}
                            startAdornment={
                              <InputAdornment position="start">
                                <AccountCircle />
                              </InputAdornment>
                            }
                          />
                        </Grid>

                        <Typography
                          sx={{
                            fontFamily: FontHelper.fRegular,
                            fontSize: "16px",
                            color: ColorHelper.PrimaryColor,
                          }}
                        >
                          {ConstantHelper.ConfirmPassword}
                          {<span style={{ color: "red" }}>*</span>}
                        </Typography>

                        <Grid item xs={12} sm={6} md={12} sx={{ my: 2 }}>
                          <Field
                            placeholder="confirm your new password"
                            variant="outlined"
                            type="password"
                            fullWidth
                            name="confirmPassword"
                            value={values.confirmPassword}
                            component={TextField}
                            startAdornment={
                              <InputAdornment position="start">
                                <AccountCircle />
                              </InputAdornment>
                            }
                          />
                        </Grid>
                      </CardContent>
                      <CardActions
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <FilledButton
                          btnText={ConstantHelper.submitBtn}
                          color={ColorHelper.WhiteColor}
                          bgColor={ColorHelper.PrimaryColor}
                          fullWidth={true}
                          type="Submit"
                          disabled={!dirty || !isValid}
                        />
                      </CardActions>
                    </Form>
                  );
                }}
              </Formik>
            </Box>
          </Box>
          <Footer />
        </Box>
      </>
    </Box>
  );
};

export default ResetPassword;
