import React, { useState, useEffect } from "react";
import { Button, IconButton, InputAdornment, Typography } from "@mui/material";
import { Box } from "@mui/system";
// Helpers
import ImageHelper from "../../Helpers/ImageHelper";
import axios from "axios";
import {
  fetchProducts,
  fetchUnlabeledProducts,
  packageDetails,
  sponsorDiscount,
} from "../../redux/shoppingActions";
import * as actionTypes from "../../redux/shoppingTypes";
// Custoom Styles
import HeroSectionStyle from "./HeroSectionStyle";
import FilledButton from "../Ui-Components/Buttons/FilledButton/FilledButton";
import ConstantHelper from "../../Helpers/ConstantHelper";
import ColorHelper from "../../Helpers/ColorHelper";
import EventSelectBox from "../Ui-Components/EventSelectBox/EventSelectBox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useNavigate } from "react-router-dom";
import FontHelper from "../../Helpers/FontHelper";
import PreOrderDownloadForm from "../Modals/PreOrderDownloadForm/PreOrderDownloadForm";
import PreOrderDownloadModal from "../Modals/PreOrderDownloadForm/PreOrderDownloadModal";
import IconHelper from "../../Helpers/IconHelper";
import ImageUploadModal from "../Modals/ImageUploadModal/ImageUploadModal";
import AdvanceSearchComp from "../AdvanceSearchComp/AdvanceSearchComp";
import { getResults } from "../../redux/shoppingReducer";
import Cookies from "../Cookies/Cookies";
import { clearCart } from "../../redux/shoppingActions";
import { useDispatch, useSelector } from "react-redux";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import HeroBottomStripe from "../HeroBottomStripe/HeroBottomStripe";
import "./HeroSection.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useSnackbar } from "notistack";
import DateOfBirthSelectBox from "../Ui-Components/EventSelectBox/DateOfBirthSelectBox";
const HeroSection = (props) => {
  const snack = useSnackbar();
  const [openCFModal, setOpenCFModal] = useState(false); // PreOreder Download modal state

  const [openUploadImageModal, setOpenUploadImageModal] = useState(false); // Upload Image modal state

  const { selectedEvent, setSelectedEvent, events, HeroImage } = props;

  // console.log("selectedEvent----", selectedEvent);
  // console.log("setSelectedEvent----", setSelectedEvent);
  // console.log("Event---1-", events);
  // console.log("Event---2-", localStorage.getItem("Selected Event"));
  // console.log("Event----", localStorage.getItem("Recent Event"));

  const [advanceComp, setAdanceComp] = useState(false);

  const navigate = useNavigate();

  const [BIBValue, setBIBValue] = useState("");

  const dispatch = useDispatch();
  const productBiB = useSelector((state) => state.shop?.products?.bibNumber);
  const [isLoading, setIsLoading] = useState(false);
  const [eventDetails , setEventDetails] = useState({});

  const tabRef = React.useRef();

  const getSearchResults = async (event) => {
    dispatch(packageDetails(""));
    if (BIBValue !== productBiB) {
      dispatch({
        type: actionTypes.ADD_TO_CART,
        payload: {
          cartInfo: [],
        },
      });
      setIsLoading(false);
    }

    if (!BIBValue) {
      navigate("/");
      setIsLoading(false);
    } else {
      setIsLoading(true);
      localStorage.setItem("showModal", true)
      dispatch(sponsorDiscount({ apply: false, photoID: [] }));
      const ID = localStorage.getItem("ID");
      dispatch(fetchProducts(selectedEvent.event_name, BIBValue, selectedEvent.event_id, ID, ""))
        .then((res) => {
          if (res?.data?.StatusCode === 200) {
            setIsLoading(false);

            if (res?.data?.result?.photo.length > 0) {
              localStorage.setItem("bibId", res.data.result.bibNumber);
              navigate("/products");
            } else {
              snack.enqueueSnackbar({
                variant: "warning",
                message: res.data.Message,
              });
            }
          } else {
            setIsLoading(false);

            snack.enqueueSnackbar({
              variant: "warning",
              message: res.data.Message,
            });
          }
        })
        .catch((err) => {
          setIsLoading(false);
          snack.enqueueSnackbar({
            variant: "error",
            message: "Server Error",
          });
          console.log(err);
        });
    }
  };

  const getSearchValue = (val) => { };

  const handleAdvanceSearchClick = () => {
    setAdanceComp(!advanceComp);
  };

  const handlePreBookedBtn = (url) => {
    navigate(`${url}`);
  };

  const handleUnlabeledProducts = () => {
    dispatch(clearCart());
    dispatch(
      fetchUnlabeledProducts(selectedEvent.event_name, selectedEvent.event_id)
    )
      .then((res) => {
        localStorage.setItem("bibId", res.data.result.bibNumber);
        navigate("/unlabeled-events");
      })
      .catch((err) => console.log(err));
  };

  const SerachFire = () => { };

  const keyDownHandler = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();

      getSearchResults();
    }
    if (event.key === "Tab") {
      event.preventDefault();
      tabRef.current.focus();
    }
  };

  const keyEnterHandler = (event) => {
    if (event.key === "Enter") {
    }
  };

  const HeroDesign = () => (
    <>
      <Box sx={HeroSectionStyle.formContainerWrapper}>
        <Box sx={HeroSectionStyle.formContainer}></Box>
      </Box>
      <Box sx={{ position: "relative" }} className="heroImageAlignInput">
        <Box
          sx={{
            ...HeroSectionStyle.img_container,
            ...(advanceComp && {
              "#HeroImage": {
                minHeight: {
                  xs: "110vh",
                  sm: "92vh",
                  md: "95.2vh",
                  lg: "86.2vh",
                },
              },
            }),
          }}
        >
          <Box
            sx={{
              ...HeroSectionStyle.overlay,
              ...(props.selectedEvent != "" && {
                backgroundColor: "#000",
              }),
              ...(advanceComp && {
                minHeight: {
                  xs: "110vh",
                  sm: "92vh",
                  md: "95.2vh",
                  lg: "86.2vh",
                },
              }),
            }}
          ></Box>
          <LazyLoadImage
            src={HeroImage?.image}
            style={HeroSectionStyle.HeroImage}
            id="HeroImage"
            width={1350} height={360}
            loading="lazy"
            alt={HeroImage?.alt}
          />

          <Box sx={{ ...HeroSectionStyle.contents }}>
            <Typography
              sx={HeroSectionStyle.Herotitle}
              variant="h6"
              component="h2"
            >
              {ConstantHelper.Herotitle}
            </Typography>

            <Box sx={HeroSectionStyle.serachContainer}>
              <EventSelectBox
                selectedEvent={selectedEvent}
                setSelectedEvent={setSelectedEvent}
                events={events}
                setEventDetails={setEventDetails}
                setIsLoading={setIsLoading}
              />
              {/* <DateOfBirthSelectBox/> */}

              {selectedEvent && (
                <>
                  <Box
                    component="form"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      position: "relative",
                      width: { xs: "100%", md: "450px" },
                      px: { xs: 0, lg: 0 },
                      pr: { xs: 0, lg: 3 },
                      my: { xs: "8px", sm: 3 },
                      flexDirection: {
                        xs: "row",
                        xxs: "column",
                        lg: "unset",
                        md: "unset",
                      },
                      "& > :not(style)": { width: "100%" },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="outlined-basic"
                      sx={{
                        fontFamily: FontHelper.fBold,
                        
                        fontWeight: { xs: 300, md: 600 },
                        color: ColorHelper.WhiteColor,
                        background: "rgba(244, 248, 255, 0.2)",
                        borderRadius: { xs: "8px 0px 0px 8px", lg: "0px" },
                        backdropFilter: { xs: "unset", sm: "blur(8px)" },
                        border: "1px solid #FFFFFF",
                        input: {
                          fontSize: "15px",
                          height: { xs: "2.2rem", md: "2.5rem", lg: "2.5rem" },
                          color: ColorHelper.WhiteColor,
                          "&::placeholder": {
                            color: ColorHelper.WhiteColor,
                          },
                        },
                        "& fieldset": { border: "none" },
                      }}
                      size="small"
                      placeholder="Type your BIB number (ex: TT20608)"
                      onChange={(e) => {
                        setBIBValue(e.target.value);
                      }}
                      variant="outlined"
                      onKeyDown={keyDownHandler}
                      onKeyPress={keyEnterHandler}
                    />
                    {/* <button ref={tabRef}>something</button> */}
                    <Box
                      sx={{
                        px: 2,
                        py: 1.3,
                        backgroundColor: ColorHelper.WhiteColor,
                        maxWidth: "60px",
                        borderRadius: "0 5px 5px 0",
                      }}
                    >
                      {selectedEvent.selfiesearch == 1 &&  (
                        <Box
                          onClick={() => setOpenUploadImageModal(true)}
                          sx={{
                            ...HeroSectionStyle.CameraIconContainer,
                            height: {
                              xs: "2.2rem",
                              md: "1.8rem",
                              lg: "1.5rem",
                            },
                            "> svg": { width: "auto", height: "100%" },
                          }}
                        >
                          <IconHelper.CameraIcon />
                        </Box>
                      )}
                      <Box
                        ref={tabRef}
                        tabIndex="0"
                        sx={{
                          ...HeroSectionStyle.SearchIconContainer,
                          height: { xs: "2.2rem", md: "2.5rem", lg: "2.5rem" },
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          "> svg": { width: "auto", height: "80%" },
                        }}
                        onClick={getSearchResults}
                        onKeyDown={keyDownHandler}
                      >
                        <IconHelper.SearchIcon />
                      </Box>
                    </Box>
                  </Box>
                  {/* <Typography
                    sx={HeroSectionStyle.advanceSearchBtn}
                    variant="h6"
                    component="h2"
                    onClick={handleAdvanceSearchClick}
                  >
                    {ConstantHelper.AdvanceSearchTitle}
                  </Typography> */}
                </>
              )}
            </Box>  

            {selectedEvent && selectedEvent.preorder_allowed == 1 && (
              <Box sx={HeroSectionStyle.HeroCaption1Container}>
                <Typography
                  sx={HeroSectionStyle.HeroCaption1}
                  variant="h6"
                  component="h2"
                // onClick={() => handlePreBookedBtn(ConstantHelper.HeroCaptionURL1)}
                >
                  {ConstantHelper.HeroCaption1}
                </Typography>
                <Typography
                  sx={{
                    borderBottom: `1.5px solid ${ColorHelper.LightYellow2}`,
                    marginLeft: 1,
                    color: ColorHelper.WhiteColor,
                    fontFamily: FontHelper.fRegular,
                    fontSize: { xs: 14, md: 16 },
                    letterSpacing: "0px",
                    cursor: "pointer",
                    "&:hover": {
                      color: ColorHelper.LightYellow2,
                    },
                  }}
                  onClick={() => setOpenCFModal(true)}
                >
                  {ConstantHelper.HeroCaptionLink1}
                </Typography>
              </Box>
            )}
            {selectedEvent && advanceComp == false && selectedEvent.showunlabeled == 1 && (
              <Box
                sx={{
                  ...HeroSectionStyle.HeroCaption1Container,
                  "#UnlabledBtnContainer": {
                    marginLeft: 0.2,
                    "&:hover": {
                      color: ColorHelper.LightYellow2,
                    },
                  },
                }}
              >
                <Typography
                  sx={HeroSectionStyle.HeroCaption1}
                  variant="h6"
                  component="h2"
                >
                  {ConstantHelper.HeroCaption2}
                  <span
                    id="UnlabledBtnContainer"
                    onClick={() => {
                      handleUnlabeledProducts();
                    }}
                  >
                    <span style={{ marginLeft: "5px" }} id="iconContainer">
                      <IconHelper.UnlabledIcon />
                    </span>
                    <span
                      style={{
                        borderBottom: `1.5px solid ${ColorHelper.LightYellow2}`,
                        cursor: "pointer",
                      }}
                    >
                      {ConstantHelper.HeroCaptionLink2}
                    </span>
                  </span>
                </Typography>
              </Box>
            )}

            {
              advanceComp && (
                <Box
                  sx={{
                    width: { xs: "100%", sm: "auto", position: "relative" },
                    px: 3,
                  }}
                >
                  <Box sx={{ ...HeroSectionStyle.AdvanceSection }}>
                    <Typography sx={HeroSectionStyle.AdvanceSectionTitle}>
                      {ConstantHelper.AdvanceSectionTitle}
                    </Typography>
                    <Box
                      onClick={() => setAdanceComp(false)}
                      sx={{
                        position: "absolute",
                        top: "15px",
                        right: "15px",
                        cursor: "pointer",
                      }}
                    >
                      <IconHelper.CloseIcon2 />
                    </Box>
                    <AdvanceSearchComp
                      eventTheme={props.eventTheme}
                      eventYear={props.eventYear}
                    />
                  </Box>
                </Box>
              )
              /* } */
            }
          </Box>
        </Box>
      </Box>
    </>
  );

  useEffect(() => {
    HeroDesign();
    // },[selectedEvent])
  }, [selectedEvent]);

  return (
    <Box>
      <div style={{ display: isLoading ? "flex" : "none", zIndex:"9" }} className="modal">
        <div className="modal-content">
          <div className="loader"></div>
          <div className="modal-text">Processing...</div>
        </div>
      </div>
      <>
        <Box sx={HeroSectionStyle.sectionWrapper}>
          {HeroDesign()}
          <PreOrderDownloadModal
            open={openCFModal}
            selectedEvent={selectedEvent}
            onClose={() => setOpenCFModal(false)}
            setOpenCFModal={setOpenCFModal}
          />

          <ImageUploadModal
            open={openUploadImageModal}
            onClose={() => setOpenUploadImageModal(false)}
          />
        </Box>
      </>
    </Box>
  );
};

export default HeroSection;
