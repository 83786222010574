import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import ColorHelper from "../Helpers/ColorHelper";
import ConstantHelper from "../Helpers/ConstantHelper";
import FontHelper from "../Helpers/FontHelper";
import IconHelper from "../Helpers/IconHelper";
import ImageHelper from "../Helpers/ImageHelper";
import { useNavigate, Link } from "react-router-dom";
import Header from "../Components/Header/Header";
import { useDispatch } from "react-redux";
import { resetData } from "../redux/shoppingActions";

const UnAuthorizedUser = (props) => {
  const { title, description, status } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logout = () => {
    localStorage.removeItem("Name");
    localStorage.removeItem("isSignedIn");
    localStorage.removeItem("ID");
    localStorage.removeItem("gst");
    localStorage.removeItem("isSignedIn");
    localStorage.removeItem("eventID");
    localStorage.removeItem("cartPrice");
    localStorage.removeItem("Selected Event");
    localStorage.removeItem("Print");
    localStorage.removeItem("Photo Print");
    localStorage.removeItem("Recent Event");
    localStorage.removeItem("currPage");
    dispatch(resetData());
    navigate("/");
    window.location.reload();
  };
  return (
    <>
      <Header />

      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            py: { xs: 2, sm: 5 },
            px: { xs: 2, sm: 15 },
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: 26, md: 36 },
              color: ColorHelper.RedColor,
              fontFamily: FontHelper.fSemiBold,
              lineHeight: "44px",
              marginTop: "140px",
            }}
          >
            {"You are not authorized to access this page."}
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: 14, md: 16 },
              color: ColorHelper.DarkGrey2,
              fontFamily: FontHelper.fRegular,
              pt: { xs: "8px", md: "16px" },
              lineHeight: "19.2px",
            }}
          >
            <span>Please </span>

            <span
              onClick={() => {
                logout();
              }}
              style={{
                cursor: "pointer",
                textdecoration: "underline",
                color: "blue",
                fontFamily: FontHelper.fSemiBold,
              }}
            >
              log out
            </span>
            <span>
              {" "}
              and sign in using correct credentials to view this page.
            </span>
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default UnAuthorizedUser;
