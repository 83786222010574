import { Box } from "@mui/system";
import React, { useState, useEffect } from "react";
import FAQS from "../Components/Faqs/Faqs";
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import IconHelper from "../Helpers/IconHelper";
import ColorHelper from "../Helpers/ColorHelper";

const Faqs = () => {
  // const [selectedEvent, setSelectedEvent] = useState();
  // const [events, setEvents] = useState();
  // const [featuredEvents, setFeaturedEvents] = useState();
  // const [isSignedIn, setIsSignedIn] = useState(false);
  // const [userName, setUserName] = useState("");

  useEffect(() => {
    document.title = "FAQ";
    window.scrollTo(0, 100);
    window.scrollTo(0, 0);
  }, []);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Box id="MainPage">
      <Header />
      <FAQS />
      <Footer />
      <Box
        onClick={() => goToTop()}
        sx={{
          backgroundColor: ColorHelper.WhiteColor,
          padding: { xs: 1.5, md: 2 },
          borderRadius: "50%",
          position: "fixed",
          bottom: { xs: "7%", md: "9%" },
          right: "3%",
          aspectRatio: "1/1",
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          svg: {
            transform: "rotate(90deg)",
          },
        }}
      >
        <IconHelper.LeftArrow />
      </Box>
    </Box>
  );
};

export default Faqs;
