// Helper

import { EventAvailable } from "@mui/icons-material";
import ColorHelper from "../../Helpers/ColorHelper";
import FontHelper from "../../Helpers/FontHelper";

const HeroSectionStyle = {
  sectionWrapper: {
    m: 0,
    p: 0,
    position: "relative",
    
  },
  container: {
    minHeight: { xs: "70vh", md: "60vh", lg: "80vh" },
    minWidth: "100%",
    backgroundColor: ColorHelper.grey,
    // px: {xs : 10, md : 5},
    px: { xs: 3, md: 10 },
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    flexDirection: { xs: "column", md: "row" },
  },
  HeroImage: {
    maxWidth: "100%",
    minWidth: "-webkit-fill-available",
    height: "60vh",
    maxHeight: "100vh",
    objectFit: "cover",
    objectPosition: "top left",
  },
  contents: {
    order: { xs: 2, md: 1 },
    display: "flex",
    justifyContent: { xs: "center", md: "center" },
    alignItems: "center",
    flexDirection: "column",
    position: { xs: "absolute", md: "absolute" },
    maxWidth: "100%",
    width: "100%",
    zIndex: 1,
    height: { xs: "auto", md: "100vh", lg: "60vh" },
    top: "50%",
    left: "0%",
    transform: "translateY(-50%)",
  },
  Herotitle: {
    fontFamily: FontHelper.fBold,
    fontSize: { xs: 24, sm: 24, md: 30, lg: 30 },
    fontWeight: 600,
    color: ColorHelper.WhiteColor,
    textAlign: { xs: "center", md: "center" },
    // textTransform: "capitalize !important",
    zIndex: 1,
    lineHeight: 1.2,
    // maxWidth: '50%',
    mb: 5,
    mx: { xs: "24px", md: "unset", lg: "unset" },
    width: { xs: "unset", sm: "70%", md: "80%", lg: "45%" },
    lineHeight: "1.3",
    borderBottom: "none",
  },
  advanceSearchBtn: {
    fontFamily: FontHelper.fRegular,
    fontSize: { xs: 14, md: 16 },
    fontWeight: 300,
    color: ColorHelper.WhiteColor,
    borderBottom: `1.5px solid ${ColorHelper.LightYellow2}`,
    lineHeight: 0,
    textAlign: { xs: "center", md: "left" },
    textTransform: "capitalize !important",
    zIndex: 2,
    ml: 2,
    cursor: "pointer",
    width: "max-content",
    height: { xs: "30px", md: "30px" },
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "auto",
    cursor: "pointer",
    pt: { xs: "8px", sm: "0px" },
    "&:hover": {
      color: ColorHelper.LightYellow2,
    },
  },
  HeroCaption1Container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
    rowGap: { xs: 1, md: 0 },
    mb: { xs: 1, lg: 3 },
    mt: { xs: 2, lg: 0 },
    px: { xs: 2, md: 0 },
  },
  HeroCaption1: {
    fontFamily: FontHelper.fRegular,
    fontSize: { xs: 14, md: 16 },
    fontWeight: 300,
    color: ColorHelper.WhiteColor,
    lineHeight: "auto",
    letterSpacing: "0px",
    textAlign: { xs: "center", md: "left" },
    textTransform: "capitalize !important",
    zIndex: 2,
    "#iconContainer svg": {
      transform: "translateY(3px)",
      mr: 0.5,
    },
  },
  btnContainer: {
    mt: 2,
    ml: -1,
    textAlign: { xs: "center", md: "left" },
    display: "flex",
    justifyContent: { xs: "center", md: "flex-start" },
    alignItems: "center",
  },
  HeroNavbtns: {
    display: "flex",
    justifyContent: { xs: "center", md: "flex-start" },
    alignItems: "center",
  },
  img_container: {
    minWidth: "100%",
    minHeight: "100%",
    order: { xs: 1, md: 2 },
    mt: { xs: 0, md: 0 },
    "#HeroImage": {
      // minHeight: {  xs: "62vh", sm: "92vh", md: "62.2vh",lg:"89vh" },
      minHeight: { xs: "89vh", sm: "92vh", md: "86.2vh" },
    },
  },
  overlay: {
    display: "block",
    backgroundColor: ColorHelper.DarkGrey2,
    opacity: 0.8,
    width: "100%",
    //height: { xs: "21vh", md: "100%" },
    // height: { xs: "100vh", md: "85vh" },
    // minHeight: { xs: "62vh", sm: "92vh", md: "62.2vh",lg:"89vh" },
    minHeight: { xs: "89vh", sm: "92vh", md: "86.2vh" },
    position: "absolute",
    zIndex: 1,
  },
  formContainerWrapper: {
    display: { xs: "none", md: "block" },
    position: { xs: "absolute", md: "absolute" },
    top: "50%",
    transform: "translateY(-50%)",
    right: 0,
    zIndex: 3,
    maxWidth: { xs: "90%", md: "30%" },
    mr: { xs: 5, md: 20 },
  },
  formContainer: {
    maxWidth: "100%",
    backgroundColor: ColorHelper.white,
    pr: 2,
    ml: 2,
    mt: { xs: 80, md: 5 },
    boxShadow: "8px -5px 10px rgba(0, 0, 0, 0.25)",
    position: "relative",
    "&:before": {
      position: "absolute",
      content: '""',
      top: -20,
      left: 20,
      right: 0,
      width: "100%",
      height: "100%",
      backgroundColor: ColorHelper.PrimaryColor,
      zIndex: -1,
    },
  },
  HeroContents: {
    // margin: 'auto',
    mt: { xs: 2, md: 0 },
    mb: { xs: 5, md: 0 },
    order: { xs: 2, md: 1 },
    display: "flex",
    justifyContent: { xs: "center", md: "space-around" },
    alignItems: "flex-start",
    flexDirection: "column",
    // flexWrap: 'wrap',
    width: "100%",
    // height: {xs: 'fit-content', md: '100vh'},
    // minHeight: { xs: "fit-content", md: "60vh", lg: "85vh" },
    height: { xs: "70vh", md: "auto" },
    // position: "absolute",
    // top: {xs: '-130px', md: '-400%'},
    // left: {xs: '-185%', md : '-120%'},
    py: { xs: 1, md: 10 },
    px: 0,
  },
  HeroTitle: {
    fontFamily: FontHelper.fBold,
    fontSize: { xs: "1.5rem", md: 60 },
    fontWeight: 300,
    color: ColorHelper.white,
    textTransform: "Uppercase !important",
    zIndex: 2,
    maxWidth: { xs: "85%", md: "70%" },
    textAlign: { xs: "center", md: "left" },
    lineHeight: "0.9",
    mb: { xs: 0, md: 4 },
  },
  HeroDescription: {
    fontFamily: FontHelper.fRegular,
    fontSize: { xs: "0.7rem", md: 18 },
    fontWeight: 600,
    color: ColorHelper.white,
    lineHeight: 1.8,
    mx: 2,
    textTransform: "capitalize !important",
    zIndex: 2,
    maxWidth: { xs: "85%", md: "100%" },
    textAlign: { xs: "center", md: "left" },
    mb: { xs: 2, md: 4 },
    ml: 0,
  },
  serachContainer: {
    display: { xs: "block", sm: "flex" },
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
    flexDirection: "row",
    width: { xs: "100%", sm: "70%", lg: "unset" },
    px: { xs: 2, sm: 5, lg: "unset" },
  },
  CameraIconContainer: {
    position: "absolute",
    top: "25%",
    right: { xs: "25%", sm: "14%", md: "21%", lg: "22%" },
    cursor: "pointer",
  },
  SearchIconContainer: {
    cursor: "pointer",
    my: -0.1,
  },
  AdvanceSection: {
    mt: "10px",
    background: "rgba(255, 255, 255, 0.05)",
    border: "1px solid #9B9DFD",
    backdropFilter: "blur(12px)",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "20px 30px",
    gap: "20px",
    //ml: -17,
    width: "auto",
    position: "relative",
  },
  AdvanceSectionTitle: {
    fontFamily: FontHelper.fRegular,
    fontSize: { xs: 14, md: 16 },
    color: ColorHelper.WhiteColor,
    mb: -2,
  },
};

export default HeroSectionStyle;
