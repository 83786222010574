import { Box } from "@mui/material";
import React from "react";

const ArrowIcon = ({ sx1 }) => {
  return (
    <Box sx={{ ...sx1 }}>
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.75 7H12.25M12.25 7L7 1.75M12.25 7L7 12.25"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </Box>
  );
};

export default ArrowIcon;
