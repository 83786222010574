const FontHelper = {
  fBold: "Urbanist-Bold",
  foutfit: "Outfit",
  fExtraBold: "Urbanist-ExtraBold",
  fExtraLight: "Urbanist-ExtraLight",
  fLight: "Urbanist-Light",
  fMedium: "Urbanist-Medium",
  fRegular: "Urbanist-Regular",
  fSemiBold: "Urbanist-SemiBold",
};

export default FontHelper;
