import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import ColorHelper from "../../Helpers/ColorHelper";
import FontHelper from "../../Helpers/FontHelper";

const LoaderComp = () => {
  return (
    // <div style={{ marginLeft: "100" }}>
    //   <div class="loader"></div>
    // </div>

    <Box>
      <div class="loader"></div>

      <Typography
        sx={{
          fontSize: { xs: 14, md: 16 },
          color: ColorHelper.BlackColor,
          fontFamily: FontHelper.fRegular,
          pt: { xs: "8px", md: "16px" },
          textAlign: "center",
        }}
      >
        Processing ...
      </Typography>
    </Box>
  );
};
export default LoaderComp;
