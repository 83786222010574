import ColorHelper from "../../../Helpers/ColorHelper";

const ImageUploadFormStyle = {
    sectionWrapper: {
        background: ColorHelper.WhiteColor,
        border: `1px dashed ${ColorHelper.LightGrey4}`,
        borderRadius: '5px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
        p: 4
    },
    imguploadIconContainer: {
        maxWidth: '32px',
    }
}

export default ImageUploadFormStyle;