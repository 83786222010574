import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ListOfItemsInBillStyle from "./ListOfItemsInBillStyle";
import ColorHelper from "../../../Helpers/ColorHelper";
import { ApiManager } from "../../../ApiClient";
const ListOfItemsInBill = ({
  cartPrice,
  photoPrintPrice,
  videoCart,
  selectedValue,
  digitalDownloadCount,
  setPrintInstruction,
  printInstruction,
}) => {
  const [gstInfo, setGstInfo] = React.useState();

  const userIsdCode = useSelector((state) => state.user.isdCode);
  React.useEffect(() => {
    setGstInfo(cartPrice.gstInfo);
  }, [cartPrice]);


  const { discountedPhoto } = useSelector(
    (state) => state.shop.sponsorDiscount
  );

  return (
    <Box sx={ListOfItemsInBillStyle.SectionWrapper}>
      <Box sx={ListOfItemsInBillStyle.ListItem}>
        {
          cartPrice?.getSponsorOffersDetails && discountedPhoto.length != 0 &&
          <Box sx={ListOfItemsInBillStyle.List}>
            <Box sx={ListOfItemsInBillStyle.NameQtyWrapper}>
              <Typography sx={{ fontWeight: "bold", fontSize: "16px", color: "#09a542", marginRight: "4px" }}>
                Special Offer
              </Typography>
              <Typography sx={{ color: ColorHelper.Voiletblue }}>
                ( {cartPrice?.getSponsorOffersDetails?.coupon_name} )
              </Typography>
            </Box>
            <Typography sx={{ fontSize: "15px", fontWeight: "bold", color: "#09a542" }}>
              (-{cartPrice?.getSponsorOffersDetails?.discount_amount}.00)
            </Typography>
          </Box>
        }
        {
          cartPrice?.offerInfo && cartPrice?.offerInfo[0].discount_price != 0 &&
          <Box sx={ListOfItemsInBillStyle.List}>
            <Box sx={ListOfItemsInBillStyle.NameQtyWrapper}>
              <Typography sx={{ fontWeight: "bold", fontSize: "16px", color: "#09a542", marginRight: "4px" }}>
                {cartPrice?.offerInfo[0]?.offerName}
              </Typography>
              {/* <Typography sx={{color:ColorHelper.Voiletblue}}>
                ( {cartPrice?.getSponsorOffersDetails?.coupon_name} )
              </Typography> */}
            </Box>
            <Typography sx={{ fontSize: "15px", fontWeight: "bold", color: "#09a542" }}>
              (-{cartPrice?.offerInfo[0]?.discount_price}.00)
            </Typography>
          </Box>
        }


        <Box sx={ListOfItemsInBillStyle.List}>
          <Box sx={ListOfItemsInBillStyle.NameQtyWrapper}>
            <Typography sx={ListOfItemsInBillStyle.name}>
              Digital Downloads
            </Typography>
            <Typography sx={ListOfItemsInBillStyle.qty}>
              ({digitalDownloadCount})
            </Typography>
          </Box>
          <Typography sx={ListOfItemsInBillStyle.amount}>
            {cartPrice && digitalDownloadCount === 0
              ? 0
              : cartPrice.digital_download}
            .00
          </Typography>
        </Box>

        {cartPrice.collage_journey_map !==
          "Ignoring collage selection as minimum number of pics NOT selected" &&
          cartPrice.collage_journey_map !== 0 && (
            <Box sx={ListOfItemsInBillStyle.List}>
              <Box sx={ListOfItemsInBillStyle.NameQtyWrapper}>
                <Typography sx={ListOfItemsInBillStyle.name}>
                  Collage Count
                </Typography>
                <Typography sx={ListOfItemsInBillStyle.qty}>
                  ({cartPrice.collage_count})
                </Typography>
              </Box>
              <Typography sx={ListOfItemsInBillStyle.amount}>
                {cartPrice.collage_journey_map ==
                  "Ignoring collage selection as minimum number of pics NOT selected"
                  ? 0
                  : cartPrice.collage_journey_map + 212}
                .00
              </Typography>
            </Box>
          )}
        {cartPrice.print_count !== 0 && userIsdCode === '+91' && (
          <Box sx={ListOfItemsInBillStyle.List}>
            <Box sx={ListOfItemsInBillStyle.NameQtyWrapper}>
              <Typography sx={ListOfItemsInBillStyle.name}>
                Print Count
              </Typography>
              <Typography sx={ListOfItemsInBillStyle.qty}>
                ({cartPrice.print_count})
              </Typography>
            </Box>
            <Typography sx={ListOfItemsInBillStyle.amount}>
              {cartPrice.print_count == 0 ? 0 : photoPrintPrice}.00
            </Typography>
          </Box>
        )}
        {videoCart?.length != 0 && (
          <Box sx={ListOfItemsInBillStyle.List}>
            <Box sx={ListOfItemsInBillStyle.NameQtyWrapper}>
              <Typography sx={ListOfItemsInBillStyle.name}>
                Video Count
              </Typography>
              <Typography sx={ListOfItemsInBillStyle.qty}>
                ({videoCart?.length})
              </Typography>
            </Box>
            <Typography sx={ListOfItemsInBillStyle.amount}>
              {cartPrice?.digital_video_download}.00
            </Typography>
          </Box>
        )}
        {(cartPrice.print_count !== 0 || cartPrice.collage_count !== 0) && userIsdCode === '+91' && (
          <Box sx={{ width: "100%" }}>
            <Typography sx={ListOfItemsInBillStyle.name}>
              Print/ Collage Instructions
            </Typography>

            <textarea
              placeholder="Enter instructions such as T-shirt size, cropping the pic, gift packing or similar (if any)"
              value={printInstruction}
              onChange={(e) => {
                setPrintInstruction(e.target.value);
              }}
              rows="4"
              cols="40"
              style={{
                padding: "5px",
                marginTop: "5px",
                maxWidth: "100%",
                boxSizing: "border-box",
              }}
            />
            <Typography sx={{ color: 'red', fontSize: "13px" }}>* Prints can not be delivered outside India</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};
export default ListOfItemsInBill;
