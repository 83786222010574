import React from 'react'
import ColorHelper from '../../Helpers/ColorHelper'

const UserPlus = () => {
    return (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_300_5814)">
                <path fillRule="evenodd" clipRule="evenodd" d="M9 2C6.23858 2 4 4.23858 4 7C4 9.76142 6.23858 12 9 12C11.7614 12 14 9.76142 14 7C14 4.23858 11.7614 2 9 2ZM6 7C6 5.34315 7.34315 4 9 4C10.6569 4 12 5.34315 12 7C12 8.65685 10.6569 10 9 10C7.34315 10 6 8.65685 6 7Z" fill={ColorHelper.PrimaryColor} />
                <path d="M5.5 14C4.17392 14 2.90215 14.5268 1.96447 15.4645C1.02678 16.4021 0.5 17.6739 0.5 19V21C0.5 21.5523 0.947715 22 1.5 22C2.05228 22 2.5 21.5523 2.5 21V19C2.5 18.2044 2.81607 17.4413 3.37868 16.8787C3.94129 16.3161 4.70435 16 5.5 16H12.5C13.2956 16 14.0587 16.3161 14.6213 16.8787C15.1839 17.4413 15.5 18.2043 15.5 19V21C15.5 21.5523 15.9477 22 16.5 22C17.0523 22 17.5 21.5523 17.5 21V19C17.5 17.6739 16.9732 16.4021 16.0355 15.4645C15.0979 14.5268 13.8261 14 12.5 14H5.5Z" fill={ColorHelper.PrimaryColor} />
                <path d="M20.5 7C21.0523 7 21.5 7.44772 21.5 8V10H23.5C24.0523 10 24.5 10.4477 24.5 11C24.5 11.5523 24.0523 12 23.5 12H21.5V14C21.5 14.5523 21.0523 15 20.5 15C19.9477 15 19.5 14.5523 19.5 14V12H17.5C16.9477 12 16.5 11.5523 16.5 11C16.5 10.4477 16.9477 10 17.5 10H19.5V8C19.5 7.44772 19.9477 7 20.5 7Z" fill={ColorHelper.PrimaryColor} />
            </g>
            <defs>
                <clipPath id="clip0_300_5814">
                    <rect width="24" height="24" fill="white" transform="translate(0.5)" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default UserPlus