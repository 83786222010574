import ColorHelper from "../../Helpers/ColorHelper";
import FontHelper from "../../Helpers/FontHelper";

const PrivacyOverviewStyle = {
  SectionWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    gap: "8px",
    pt: "20px",
  },
  Title: {
    fontFamily: FontHelper.fSemiBold,
    fontStyle: "normal",
    fontSize: "20px",
    fontWeight: "600",
    lineHeight: "30px",
    color: ColorHelper.LightGrey6,
    flex: "none",
    order: "0",
    alignSelf: "stretch",
    flexGrow: "0",
  },
  Description: {
    fontFamily: FontHelper.fRegular,
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "19px",
    color: ColorHelper.LightGrey7,
  },
  InnerTitle: {
    fontFamily: FontHelper.fBold,
    fontStyle: "normal",
    fontSize: { xs: 14, md: 16 },
    fontWeight: "600",
    lineHeight: "30px",
    color: ColorHelper.LightGrey7,
    flex: "none",
    order: "0",
    alignSelf: "stretch",
    flexGrow: "0",
  },
  InnnerDescription: {
    fontFamily: FontHelper.fRegular,
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "19px",
    color: ColorHelper.LightGrey7,
  },
};

export default PrivacyOverviewStyle;
