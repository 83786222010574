// Helper
import ColorHelper from "../../Helpers/ColorHelper";

const FeatureEventsStyle = {
  container: {
    backgroundColor: ColorHelper.WhiteColor2,
    py: 4,
    px: { xs: 2, sm: 8, md: 10, lg: 15 },
    ul: {
      paddingTop: 2,
      paddingBottom: 4,
    },
    ".react-multiple-carousel__arrow--left": {
      transform: "translateX(-100%)",
      backgroundColor: ColorHelper.PrimaryColor,
    },
    ".react-multiple-carousel__arrow--right": {
      transform: "translateX(80%)",
      backgroundColor: ColorHelper.PrimaryColor,
    },
  },
  Carouselcontainer: {
    maxWidth: 345,
    //  mx: 4,
  },
  CorouselInner: {
    mx: 5,
    disply: { xs: "flex", lg: "block" },
    justifyContent: "center",
    // backgroundColor: ColorHelper.BlueColor,
    // mx: 4,
  },
  FeaturedIcons: {
    width: "60%",
    backgroundColor: ColorHelper.WhiteColor,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    mt: { xs: 5, md: 0 },
    width: "200px",
    height: "9em",
    // boxShadow: '0px 20px 25px -5px rgba(16, 24, 40, 0.1), 0px 8px 10px -6px rgba(16, 24, 40, 0.1)',
    // boxShadow:
    //   "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    border: `1px solid ${ColorHelper.LightGrey10}`,
    borderRadius: "5px",
    gap: { xs: 0, sm: "20px" },
  },
};

export default FeatureEventsStyle;
