import React from "react";

const OrderForm = ({ orderState }) => {
  return (
    <form action="https://test.payu.in/_payment" method="post">
      Key
      <input type="text" name="key" value={orderState.key} /> <br />
      Txnid
      <input type="text" name="txnid" value={orderState.txnid} />
      <br />
      productinfo
      <input type="text" name="productinfo" value={orderState.productinfo} />
      <br />
      amount
      <input type="text" name="amount" value={orderState.amount} />
      <br />
      email
      <input type="text" name="email" value={orderState.email} />
      <br />
      firstname
      <input type="text" name="firstname" value={orderState.firstname} />
      <br />
      {/* <input type="hidden" name="lastname" value="Kumar" /> */}
      success url
      <input
        type="text"
        name="surl"
        value="https://apiplayground-response.herokuapp.com/"
      />
      <br />
      failure url
      <input
        type="text"
        name="furl"
        value="https://apiplayground-response.herokuapp.com/"
      />
      <br />
      phone
      <input type="text" name="phone" value={orderState.phone} />
      <br />
      hash
      <input type="text" name="hash" value={orderState.hash} />
      <br />
      <input type="submit" value="submit" />
    </form>
  );
};

export default OrderForm;
