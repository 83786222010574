import ColorHelper from "../../Helpers/ColorHelper";

const flexIt = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
}

const flexItColumn = {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
}

const BillViewStyle = {
    TotalOrdeContainer: {
        ...flexIt,
        px: 5,
        borderBottom: `1px solid ${ColorHelper.LightGrey4}`,
        pt: 4,
        pb: 1,
    },
    TotalOrderInnerLeft: {
        ...flexItColumn,
    }

}

export default BillViewStyle;