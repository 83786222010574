import ImageHelper from "../../Helpers/ImageHelper";

const FeatureEventData = [ 
    {
        id: 1,
        FEimg: ImageHelper.FeaturedEventsIcon1,
    },
    {
        id: 2,
        FEimg: ImageHelper.FeaturedEventsIcon2,
    },
    {
        id: 3,
        FEimg: ImageHelper.FeaturedEventsIcon3,
    },
    ,{
        id: 4,
        FEimg: ImageHelper.FeaturedEventsIcon4,
    },
    {
        id: 5,
        FEimg: ImageHelper.FeaturedEventsIcon5,
    }
]

export default FeatureEventData;