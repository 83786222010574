import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import { Box, Button, Grid, Typography } from "@mui/material";
import ImageHelper from "../../../Helpers/ImageHelper";
import FontHelper from "../../../Helpers/FontHelper";
import ColorHelper from "../../../Helpers/ColorHelper";
import IconHelper from "../../../Helpers/IconHelper";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "80%", lg: "825px" },
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  padding: { xs: 2, md: "24px" },
  gap: "24px",
  borderRadius: "5px",
  flex: "none",
  order: "0",
  alignSelf: "stretch",
  flexGrow: "0",
};

const JourneyModal = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button onClick={handleOpen}>Click Here</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            padding: "24px",
            gap: "8px",
            borderRadius: "10px",
            flex: "none",
            order: "0",
            flexGrow: "0",
          }}
        >
          <Box sx={style}>
            <Box
              style={{
                boxSizing: "border-box",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "flex-start",
                padding: { xs: 0, md: "0px 0px 8px 16px" },
                gap: { xs: "6px", md: "282px" },
                borderBottom: "1px solid #E4E7EC",
                flex: "none",
                order: "0",
                alignSelf: "stretch",
                flexGrow: "0",
              }}
            >
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                sx={{
                  fontFamily: FontHelper.fRegular,
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: { xs: 14, md: "20px" },
                  lineHeight: "24px",
                  color: ColorHelper.LightGrey6,
                  flex: "none",
                  order: "0",
                  flexGrow: "0",
                }}
              >
                Journey Map
              </Typography>
              <Box
                onClick={handleClose}
                style={{
                  cursor: "pointer",
                }}
              >
                <IconHelper.CloseIcon
                  color={ColorHelper.RedColor}
                  bgcolor={ColorHelper.RedColor}
                />
              </Box>
            </Box>

            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "0px 18px",
                gap: "30px",
                flex: "none",
                order: "1",
                alignSelf: "stretch",
                flexGrow: "0",
              }}
            >
              <Grid
                container
                id="ModalContainer"
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: "0px",
                  gap: { xs: "20px", md: "60px" },
                  flex: "none",
                  order: "0",
                  flexGrow: "1",
                  flexWrap: { xs: "wrap", md: "nowrap" },
                }}
              >
                <Grid
                  item
                  xs={12}
                  md={8}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    padding: "0px",
                    gap: "8px",
                    flex: "none",
                    order: { xs: 2, md: 0 },
                    flexGrow: "1",
                  }}
                >
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    sx={{
                      fontFamily: FontHelper.fRegular,
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "17px",
                      color: ColorHelper.LightGrey7,
                      flex: "none",
                      order: "1",
                      alignSelf: "stretch",
                      flexGrow: "0",
                    }}
                  >
                    It'd be amazing to see how far we came as a Runner in a
                    single edition or across editions of a run or even in a
                    given year.
                  </Typography>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    sx={{
                      fontFamily: FontHelper.fRegular,
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "17px",
                      color: ColorHelper.LightGrey7,
                      flex: "none",
                      order: "1",
                      alignSelf: "stretch",
                      flexGrow: "0",
                    }}
                  >
                    The Journey Map/ Collage can be created across Run editions
                    or from single edition or for a given year and the sample is
                    available here both in Portrait (2ft x 3ft) & Landscape (3ft
                    x 2ft).
                  </Typography>

                  <Typography
                    sx={{
                      fontFamily: FontHelper.fRegular,
                      fontStyle: "normal",
                      fontWeight: "700",
                      fontSize: "14px",
                      lineHeight: "17px",
                      color: "#1D2939",
                      flex: "none",
                      order: "1",
                      alignSelf: "stretch",
                      flexGrow: "0",
                    }}
                  >
                    This is available ONLY in print.
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={ImageHelper.JourneyMapPortrait}
                    alt="pacakgeModal"
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default JourneyModal;
