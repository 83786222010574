import { Box } from "@mui/system";
import React, { useEffect } from "react";
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import "./Signin.css";
import { Typography, Divider, unstable_useEnhancedEffect } from "@mui/material";
import ConstantHelper from "../Helpers/ConstantHelper";
import ColorHelper from "../Helpers/ColorHelper";
import ImageHelper from "../Helpers/ImageHelper";
import FontHelper from "../Helpers/FontHelper";
import { useLocation } from "react-router-dom";

import { useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import jwt_decode from "jwt-decode";
import SigninForm from "../Components/SigninSignUpForms/SigninForm";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SignIn = () => {
  useEffect(() => {
    document.title = "Sign In";
  }, []);
  const { state } = useLocation();

  const redirected = state?.redirected;
  // console.log("Signin jsx redirected -----", state);

  const orderId = state?.orderId;

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [openErrorSnackBar, setOpenErrorSnackbar] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 100);
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box id="signinPage">
      <Header />
      <Box sx={{ minHeight: "60vh" }}>
        <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
          <Box gridColumn="span 6">
            <img
              src={ImageHelper.ProductPageImage13}
              style={{ width: "85%", height: "100%", objectFit: "cover" }}
            />
          </Box>
          <Box gridColumn="span 6" marginTop="68px" marginRight="25%">
            <Typography
              sx={{
                fontFamily: FontHelper.fBold,
                fontSize: "30px",
                fontWeight: 700,
                color: ColorHelper.DarkGrey2,
                px: 2,
              }}
            >
              {ConstantHelper.signInBtn}
            </Typography>

            <Divider variant="middle" sx={{}} />
            <SigninForm
              redirected={redirected}
              orderId={orderId}
              itsInTabs={false}
              openSnackBar={openSnackBar}
              setOpenSnackBar={setOpenSnackBar}
              openErrorSnackBar={openErrorSnackBar}
              setOpenErrorSnackbar={setOpenErrorSnackbar}
            />
          </Box>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};

export default SignIn;
