import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
// Custom Style
import FeatureEventsStyle from "./FeatureEventsStyle";

// Helper
import ImageHelper from "../../Helpers/ImageHelper";
import ConstantHelper from "../../Helpers/ConstantHelper";
import SectionTitle from "../Ui-Components/SectionTitle/SectionTitle";
import FeatureEventData from "./FeatureData";
import ColorHelper from "../../Helpers/ColorHelper";
import {
  Button,
  Container,
  Modal,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
} from "@mui/material";
import FontHelper from "../../Helpers/FontHelper";
import styled from "styled-components";
import { reverse } from "lodash";
import EventCard from "../EventCard/EventCard";
import { ApiManager } from "../../ApiClient";

const responsive = {
  bigDesktop: {
    breakpoint: { max: 3000, min: 1400 },
    items: 6,
    slidesToSlide: 1,
  },
  desktop: {
    breakpoint: { max: 1400, min: 1200 },
    items: 5,
    slidesToSlide: 1,
  },
  laptop: {
    breakpoint: { max: 1200, min: 1024 },
    items: 5,
    slidesToSlide: 1,
  },
  tablet2: {
    breakpoint: { max: 1024, min: 920 },
    items: 4,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 920, min: 640 },
    items: 3,
    slidesToSlide: 1,
  },
  mobile2: {
    breakpoint: { max: 639, min: 300 },
    items: 2,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 420, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

const carouselParams = {
  additionalTransfrom: -39,
  arrows: false,
  autoPlay: true,
  autoPlaySpeed: 1500,
  centerMode: false,
  className: "",
  containerClass: "carousel-container",
  dotListClass: "",
  draggable: true,
  focusOnSelect: false,
  infinite: true,
  itemClass: "",
  keyBoardControl: true,
  minimumTouchDrag: 80,
  renderButtonGroupOutside: true,
  renderDotsOutside: false,
  responsive: responsive,
  showDots: false,
  sliderClass: "",
  slidesToSlide: 3,
};

const FeaturedEvents = ({ featuredEvents }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const [yeardata, setYeardata] = useState([]);

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  useEffect(() => {
    if (featuredEvents) {
      const arraydaya = reverse(Object.keys(featuredEvents));
      setYeardata(arraydaya);
      setSelectedOption(arraydaya[0]);
    }
  }, []);

  return (
    <Box sx={{ backgroundColor: "#F2F4F7" }}>
      <Box
        sx={{
          marginLeft: { xs: "1.5rem", md: "5.5rem", lg: "5.5rem" },
          marginRight: { xs: "1.5rem", md: "5.5rem", lg: "5.5rem" },
          fontFamily: FontHelper.fBold,
          display: "flex",
        }}
      >
        <Typography
          variant="h6"
          sx={{ fontFamily: FontHelper.fBold, paddingTop: "12px" }}
        >
          Feature Events
        </Typography>
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <Select value={selectedOption} onChange={handleChange}>
            <MenuItem disabled value="Year">
              Year
            </MenuItem>
            {yeardata &&
              yeardata.map((year) => {
                return <MenuItem value={year} key={year}>{year}</MenuItem>;
              })}
          </Select>
        </FormControl>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          flexWrap: "wrap",
          gap: { xs: 2, md: 3 },
          marginTop: "4px",
          mx: { xs: 2, md: 3, lg: 10 },
          borderTop: `1px solid ${ColorHelper.DarkGrey6}`,
          borderBottom: `1px solid ${ColorHelper.DarkGrey6}`,
          py: 5,
        }}
      >
        {/* {
featuredEvents &&
featuredEvents[selectedOption] && featuredEvents[selectedOption].map((item) => (
{
item.event_logo && item.event_name && item.event_to_date &&
<EventCard
image={`https://test.photos.oneglint.com/${item.event_logo}`}
eventName={item.event_name}
date={item.event_to_date}
/>
}

))} */}

        {featuredEvents &&
          featuredEvents[selectedOption] &&
          featuredEvents[selectedOption].map((item) => {
            if (item.event_logo && item.event_name && item.event_to_date) {
              return (
                <EventCard
                  key={item.event_id}
                  image={ApiManager.url() + `${item.event_logo}`}
                  //image={`https://test.photos.oneglint.com/${item.event_logo}`}

                  eventName={item.event_name}
                  date={item.event_to_date}
                />
              );
            }
            return null;
          })}

        {featuredEvents && featuredEvents.length === 0 && (
          <Grid
            container
            sx={[
              FeatureEventsStyle.CorouselInner,
              {
                width: "auto",
                marginLeft: "0px",
                marginRight: "0px",
                textAlign: "center",
                margin: "0 auto",
              },
            ]}
          >
            No Feature Events Found
          </Grid>
        )}
      </Box>
    </Box>
  );
};

export default FeaturedEvents;
