import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import {
  Grid,
  Input,
  List,
  Link,
  ListItem,
  TextField,
  CircularProgress,
} from "@mui/material";
import { ApiManager } from "../../ApiClient";
import PreorderStyle from "../PreOrder/Preorderstyle";
import FontHelper from "../../Helpers/FontHelper";
import ColorHelper from "../../Helpers/ColorHelper";
import orderStyle from "./orderdetailsstyle";
import { Form } from "react-router-dom";
import FilledButton from "../Ui-Components/Buttons/FilledButton/FilledButton";
import ConstantHelper from "../../Helpers/ConstantHelper";
import { useRef } from "react";

import Lottie from "react-lottie";
import PaymentGateway from "../../Assets/Animation/PaymentGateway.json";
import { Spinner } from "react-bootstrap";

const Orderdetails = ({ orderState }) => {
  // console.log("----orderState----",orderState);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    p: 4,
  };

  const clickButton = useRef();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: PaymentGateway,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  React.useEffect(() => {
    document.getElementById("finalSubmit").click();
  }, []);

  return (
    <div>
      <Header />
      <Box sx={PreorderStyle.Sectionmain}>
        <Typography sx={PreorderStyle.SectionHead}>Order Checkouts</Typography>
        <hr />
      </Box>
      <form action={orderState.paymentUrl} method="post">
      {/* <form> */}

        <Box sx={orderStyle.mainbx}>
          <Box sx={{ display: "none" }}>
            Key
            <TextField type="text" name="key" value={orderState.key} /> <br />
            Txnid
            <TextField type="text" name="txnid" value={orderState.txnid} />
            <br />
            productinfo
            <TextField
              type="text"
              name="productinfo"
              value={orderState.productinfo}
            />
            <br />
            amount
            <TextField type="text" name="amount" value={orderState.amount} />
            <br />
            email
            <TextField type="text" name="email" value={orderState.email} />
            <br />
            firstname
            <TextField
              type="text"
              name="firstname"
              value={orderState.firstname}
            />
            <br />
            <TextField type="hidden" name="lastname" value="Kumar" />
            success url
            <TextField
              type="text"
              name="surl"
              value={ApiManager.orderSuccess()}
            />
            <br />
            failure url
            <TextField
              type="text"
              name="furl"
              value={
                localStorage.getItem("isUnlabled") === "true"
                  ? ApiManager.unlabledCart()
                  : ApiManager.cart()
              }
            />
            <br />
            phone
            <TextField type="text" name="phone" value={orderState.phone} />
            <br />
            hash
            <TextField type="text" name="hash" value={orderState.hash} />
            <br />
          </Box>

          <Box>
            <Lottie options={defaultOptions} height={300} width={300} />
          </Box>
          <div>
            <FilledButton
              // ref={clickButton}
              id={"finalSubmit"}
              type="submit"
              value="submit"
              btnText=" In Progress"
              color={ColorHelper.WhiteColor}
              bgColor={ColorHelper.PrimaryColor}
              startIcon={
                <CircularProgress color="success" size={"18px"} thickness={6} />
              }
            />
          </div>
        </Box>
      </form>
      <Footer />
    </div>
  );
};

export default Orderdetails;
