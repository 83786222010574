import React from "react";
import ColorHelper from "../../Helpers/ColorHelper";

const CloseIcon = (props) => {
  const { color, bgcolor } = props;
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.25 2.25C10.2722 2.25 8.33879 2.83649 6.6943 3.9353C5.04981 5.03412 3.76809 6.5959 3.01121 8.42316C2.25433 10.2504 2.0563 12.2611 2.44215 14.2009C2.828 16.1407 3.78041 17.9225 5.17894 19.3211C6.57746 20.7196 8.35929 21.672 10.2991 22.0578C12.2389 22.4437 14.2496 22.2457 16.0768 21.4888C17.9041 20.7319 19.4659 19.4502 20.5647 17.8057C21.6635 16.1612 22.25 14.2278 22.25 12.25C22.2449 9.59939 21.1897 7.05881 19.3155 5.18455C17.4412 3.31028 14.9006 2.25508 12.25 2.25ZM15.875 14.7788C16.0194 14.9247 16.1005 15.1217 16.1005 15.3269C16.1005 15.5322 16.0194 15.7292 15.875 15.875C15.728 16.0172 15.5314 16.0966 15.3269 16.0966C15.1224 16.0966 14.9259 16.0172 14.7788 15.875L12.25 13.3365L9.72116 15.875C9.57413 16.0172 9.3776 16.0966 9.17308 16.0966C8.96856 16.0966 8.77203 16.0172 8.625 15.875C8.48056 15.7292 8.39952 15.5322 8.39952 15.3269C8.39952 15.1217 8.48056 14.9247 8.625 14.7788L11.1635 12.25L8.625 9.72115C8.50234 9.57169 8.43965 9.38195 8.44914 9.18884C8.45862 8.99572 8.5396 8.81303 8.67632 8.67631C8.81304 8.5396 8.99572 8.45862 9.18884 8.44913C9.38196 8.43965 9.5717 8.50234 9.72116 8.625L12.25 11.1635L14.7788 8.625C14.9283 8.50234 15.118 8.43965 15.3112 8.44913C15.5043 8.45862 15.687 8.5396 15.8237 8.67631C15.9604 8.81303 16.0414 8.99572 16.0509 9.18884C16.0604 9.38195 15.9977 9.57169 15.875 9.72115L13.3365 12.25L15.875 14.7788Z"
        fill={bgcolor}
      />
    </svg>
  );
};

export default CloseIcon;
