import Logo from "../Assets/Logos/Logo.svg";
import HeroImage from "../Assets/Images/HeroImage.webp";
import FeaturedEventsIcon1 from "../Assets/Images/FeaturedEventsIcon/FeaturedEventsIcon1.png";
import FeaturedEventsIcon2 from "../Assets/Images/FeaturedEventsIcon/FeaturedEventsIcon2.png";
import FeaturedEventsIcon3 from "../Assets/Images/FeaturedEventsIcon/FeaturedEventsIcon3.png";
import FeaturedEventsIcon4 from "../Assets/Images/FeaturedEventsIcon/FeaturedEventsIcon4.png";
import FeaturedEventsIcon5 from "../Assets/Images/FeaturedEventsIcon/FeaturedEventsIcon5.png";
import HighlightImage from "../Assets/Images/Highlights/HighlightImage.jpg";
import GoogleIcon from "../Assets/Logos/GoogleIcon.svg";
import FBIcon from "../Assets/Logos/FBIcon.svg";
import EventLogo1 from "../Assets/Logos/EventLogos/EventLogo1.png";
import ProductPageImage01 from "../Assets/Images/ProductPageImages/ProductPageImage01.jpg";
import ProductPageImage02 from "../Assets/Images/ProductPageImages/ProductPageImage02.jpg";
import ProductPageImage03 from "../Assets/Images/ProductPageImages/ProductPageImage03.jpg";
import ProductPageImage04 from "../Assets/Images/ProductPageImages/ProductPageImage04.jpg";
import ProductPageImage05 from "../Assets/Images/ProductPageImages/ProductPageImage05.jpg";
import ProductPageImage06 from "../Assets/Images/ProductPageImages/ProductPageImage06.jpg";
import ProductPageImage07 from "../Assets/Images/ProductPageImages/ProductPageImage07.jpg";
import ProductPageImage08 from "../Assets/Images/ProductPageImages/ProductPageImage08.jpg";
import ProductPageImage09 from "../Assets/Images/ProductPageImages/ProductPageImage09.jpg";
import ProductPageImage10 from "../Assets/Images/ProductPageImages/ProductPageImage10.jpg";
import ProductPageImage11 from "../Assets/Images/ProductPageImages/ProductPageImage11.jpg";
import ProductPageImage12 from "../Assets/Images/ProductPageImages/ProductPageImage12.jpg";
import ProductPageImage13 from "../Assets/Images/ProductPageImages/productimage21.png";
import ProductTotal from "../Assets/Images/ProductPageImages/ProductTotal.png";

import PrintsImage01 from "../Assets/Images/PrintsImages/PrintsImage01.jpg";
import PrintsImage02 from "../Assets/Images/PrintsImages/PrintsImage02.jpg";
import PrintsImage03 from "../Assets/Images/PrintsImages/PrintsImage03.jpg";
import PrintsImage04 from "../Assets/Images/PrintsImages/PrintsImage04.jpg";
import PrintsImage05 from "../Assets/Images/PrintsImages/PrintsImage05.jpg";

import add from "../Assets/Images/FeaturedEventsIcon/Icon.png";
import sub from "../Assets/Images/FeaturedEventsIcon/Icon1.png";

//faq icons
import faq1 from "../Assets/Images/FeaturedEventsIcon/faq1.png";
import faq2 from "../Assets/Images/FeaturedEventsIcon/faq2.png";
import faq3 from "../Assets/Images/FeaturedEventsIcon/faq3.png";
import erimage from "../Assets/Images/Errorimg/err.png";
//contact page icon
import contact from "../Assets/Images/StaticPageIcons/contact.jpg";

import EmptyCartBox from "../Assets/Images/EmptyCartBox.svg";


import ErrorStatus from "../Assets/Images/OrderStatus/Error.png";
import SucessStatus from "../Assets/Images/OrderStatus/Success.png";

import VerticalCollage from "../Assets/Images/CollageLayouts/verticalCollage.png";
import HorizontalCollage from "../Assets/Images/CollageLayouts/horizontalCollage.png";

import pacakgeModal1 from "../Assets/Images/PacakgesModal/pacakgeModal1.png";
import pacakgeModal2 from "../Assets/Images/PacakgesModal/pacakgeModal2.png";
import pacakgeModal3 from "../Assets/Images/PacakgesModal/pacakgeModal3.png";

import TimedImage from "../Assets/Images/PacakgesModal/TimedImage.png";
import CertificateImage from "../Assets/Images/PacakgesModal/CertificateImage.png";

import JourneyMapPortrait from "../Assets/Images/PacakgesModal/JourneyMapPortrait.png";
import JourneyMapLandscap from "../Assets/Images/PacakgesModal/JourneyMapLandscap.png";

import Cart1 from '../Assets/Images/cart/cart1.jpg'
import Cart2 from '../Assets/Images/cart/cart2.jpg'
import Cart3 from '../Assets/Images/cart/cart3.jpg'

import Video from '../Assets/Images/cart/video.png';

const ImageHelper = {
  Logo,
  add,
  sub,
  faq1,
  faq2,
  faq3,
  erimage,

  contact,
  // Hero Images
  HeroImage,

  HighlightImage,

  FeaturedEventsIcon1,
  FeaturedEventsIcon2,
  FeaturedEventsIcon3,
  FeaturedEventsIcon4,
  FeaturedEventsIcon5,

  GoogleIcon,
  FBIcon,

  // Product Page
  EventLogo1,

  ProductPageImage01,
  ProductPageImage02,
  ProductPageImage03,
  ProductPageImage04,
  ProductPageImage05,
  ProductPageImage06,
  ProductPageImage07,
  ProductPageImage08,
  ProductPageImage09,
  ProductPageImage10,
  ProductPageImage11,
  ProductPageImage12,
  ProductPageImage13,
  ProductTotal,

  PrintsImage01,
  PrintsImage02,
  PrintsImage03,
  PrintsImage04,
  PrintsImage05,
  // EventLogo,

  EmptyCartBox,

  SucessStatus,
  ErrorStatus,

  VerticalCollage,
  HorizontalCollage,

  pacakgeModal1,
  pacakgeModal2,
  pacakgeModal3,

  TimedImage,
  CertificateImage,

  JourneyMapPortrait,
  JourneyMapLandscap,

  Cart1,
  Cart2,
  Cart3,

  Video
};

export default ImageHelper;
