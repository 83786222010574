import React, { useState, useEffect, useRef } from "react";
import { modalSliderData } from "./UltraPremiumData";
import { Box, Button, Grid, Typography } from "@mui/material";
import ImageHelper from "../../../../Helpers/ImageHelper";
import FontHelper from "../../../../Helpers/FontHelper";
import ColorHelper from "../../../../Helpers/ColorHelper";
import IconHelper from "../../../../Helpers/IconHelper";

const colors = ["#0088FE", "#00C49F", "#FFBB28"];
const delay = 2500;

function UltraPremiumContent() {
  const [index, setIndex] = useState(0);
  const timeoutRef = useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === modalSliderData.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );

    return () => {
      resetTimeout();
    };
  }, [index]);

  return (
    <div className="slideshow">
      <div
        className="slideshowSlider"
        style={{
          transform: `translate3d(${-index * 100}%, 0, 0)`,
          width: "48rem",
        }}
      >
        {modalSliderData.map((data, index) => (
          <div
            className="slide"
            key={index}
            //   style={{ display:"flex" }}
          >
            <div style={{ display: "flex" }}>
              <img
                className="sliderImage2"
                src={data.image}
                height="300px"
                width="100vw !important"
              />
            </div>
          </div>
        ))}
      </div>

      {/* <div className="slideshowDots">
        {colors.map((_, idx) => (
          <div
            key={idx}
            className={`slideshowDot${index === idx ? " active" : ""}`}
            onClick={() => {
              setIndex(idx);
            }}
          ></div>
        ))}
      </div> */}
    </div>
  );
}

export default UltraPremiumContent;
