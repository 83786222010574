import { Box } from "@mui/system";
import React, { useState, useEffect } from "react";
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import axios from "axios";
import {
  Grid,
  CardContent,
  CardActions,
  Button,
  InputAdornment,
  Typography,
  Divider,
} from "@mui/material";

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "formik-material-ui";
import ConstantHelper from "../Helpers/ConstantHelper";
import FilledButton from "../Components/Ui-Components/Buttons/FilledButton/FilledButton";
import ColorHelper from "../Helpers/ColorHelper";
import { AccountCircle } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import FontHelper from "../Helpers/FontHelper";
import { ApiManager } from "../ApiClient";
import { useSnackbar } from "notistack";

const initialValues = {
  oldpassword: "",
  password: "",
  confirmPassword: "",
};

const lengthRegEx = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;

let validationSchema = Yup.object().shape({
  oldpassword: Yup.string()
    .matches(
      lengthRegEx,
      "Password must be at least eight characters long and should include one each of uppercase, lowercase, number, special character"
    )
    .required("Required!"),
  password: Yup.string()
    .matches(
      lengthRegEx,
      "Password must be at least eight characters long and should include one each of uppercase, lowercase, number, special character"
    )
    .required("Required!"),
  confirmPassword: Yup.string()
    .matches(
      lengthRegEx,
      "Password must be at least eight characters long and should include one each of uppercase, lowercase, number, special character"
    )
    .required("Required!")
    .test("passwords-match", "Passwords must match", function (value) {
      return this.parent.password === value;
    }),
});

const ChangePassword = () => {
  const snack = useSnackbar();
  useEffect(() => {
    document.title = "Change Password";
  }, []);
  let navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 100);
    window.scrollTo(0, 0);
  }, []);

  const onSubmit = async (values, formicHelpers) => {
    axios
      .post(ApiManager.changePassword(), {
        userID: localStorage.getItem("ID"),
        currentPassword: values.oldpassword,
        newPassword: values.password,
        confirmPassword: values.confirmPassword,
      })
      .then((response) => {
        if (response.data.StatusCode === 200) {
          snack.enqueueSnackbar({
            variant: "success",
            message: response.data.Message,
          });
          setTimeout(() => {
            navigate("/");
          }, 2000);
        } else {
          snack.enqueueSnackbar({
            variant: "warning",
            message: response.data.Message,
          });
        }
      })
      .catch((err) => {
        snack.enqueueSnackbar({
          variant: "error",
          message: "Something Went Wrong",
        });
      });
    formicHelpers.resetForm();
  };
  return (
    <>
      <Box>
        <Header />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItem: "center",
          }}
        >
          <Box
            sx={{
              py: 5,
              minHeight: "60vh",
              flexDirection: "column",
              width: { xs: "100%", sm: "70%", md: "60%", lg: "40%" },
            }}
          >
            <Typography
              sx={{
                fontFamily: FontHelper.fBold,
                fontSize: "30px",
                fontWeight: 700,
                color: ColorHelper.DarkGrey2,
                px: 2,
              }}
            >
              {ConstantHelper.ChangePassword}
            </Typography>

            <Typography
              sx={{
                fontFamily: FontHelper.fRegular,
                fontSize: "16px",
                color: ColorHelper.PrimaryColor,
                marginLeft: "20px",
                marginTop: "10px",
              }}
            >
              Password requirements:
            </Typography>
            <ul
              style={{
                marginLeft: "36px",
                marginTop: "10px",
                color: "red",
                fontFamily: "sans-serif",
              }}
            >
              <li>At least eight characters long</li>
              <li>At least one lowercase letter</li>
              <li>At least one uppercase letter</li>
              <li>At least one number</li>
              <li>At least one special character</li>
            </ul>

            <Divider
              variant="middle"
              sx={{ marginBottom: "10px", marginTop: "8px" }}
            />
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ dirty, isValid, values, handleChange, handleBlur }) => {
                return (
                  <Form name="formName">
                    <CardContent>
                      <Typography
                        sx={{
                          fontFamily: FontHelper.fRegular,
                          fontSize: "16px",
                          color: ColorHelper.PrimaryColor,
                        }}
                      >
                        {ConstantHelper.OldPassword}
                        {<span style={{ color: "red" }}>*</span>}
                      </Typography>

                      <Grid item xs={12} sm={6} md={12} sx={{ my: 2 }}>
                        <Field
                          placeholder="Enter your old password"
                          variant="outlined"
                          fullWidth
                          type="password"
                          name="oldpassword"
                          value={values.oldpassword}
                          component={TextField}
                          startAdornment={
                            <InputAdornment position="start">
                              <AccountCircle />
                            </InputAdornment>
                          }
                        />
                      </Grid>

                      <Typography
                        sx={{
                          fontFamily: FontHelper.fRegular,
                          fontSize: "16px",
                          color: ColorHelper.PrimaryColor,
                        }}
                      >
                        {ConstantHelper.NewPassWord}
                        {<span style={{ color: "red" }}>*</span>}
                      </Typography>

                      <Grid item xs={12} sm={6} md={12} sx={{ my: 2 }}>
                        <Field
                          placeholder="Enter new password"
                          variant="outlined"
                          type="password"
                          fullWidth
                          name="password"
                          value={values.password}
                          component={TextField}
                          startAdornment={
                            <InputAdornment position="start">
                              <AccountCircle />
                            </InputAdornment>
                          }
                        />
                      </Grid>

                      <Typography
                        sx={{
                          fontFamily: FontHelper.fRegular,
                          fontSize: "16px",
                          color: ColorHelper.PrimaryColor,
                        }}
                      >
                        {ConstantHelper.ConfirmPassword}
                        {<span style={{ color: "red" }}>*</span>}
                      </Typography>
                      <Grid item xs={12} sm={6} md={12} sx={{ my: 2 }}>
                        <Field
                          placeholder="confirm your new password"
                          variant="outlined"
                          type="password"
                          fullWidth
                          name="confirmPassword"
                          value={values.confirmPassword}
                          component={TextField}
                          startAdornment={
                            <InputAdornment position="start">
                              <AccountCircle />
                            </InputAdornment>
                          }
                        />
                      </Grid>
                    </CardContent>
                    <CardActions
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <FilledButton
                        btnText={ConstantHelper.submitBtn}
                        color={ColorHelper.WhiteColor}
                        bgColor={ColorHelper.PrimaryColor}
                        fullWidth={true}
                        type="Submit"
                        disabled={!dirty || !isValid}
                      />
                    </CardActions>
                  </Form>
                );
              }}
            </Formik>
          </Box>
        </Box>
        <Footer />
      </Box>
    </>
  );
};

export default ChangePassword;
