import ColorHelper from "../../../Helpers/ColorHelper";
import FontHelper from "../../../Helpers/FontHelper";

const SectionTitleStyle2 = {
  sectionHeading: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",
    px: { xs: 1.5, sm: 5, lg: 10 },
    pt: { xs: 4, md: 4 },
    pb: { xs: 2, md: 4 },
    width: { xs: "100%", md: "auto" },
  },
  SectionHeadingLeftPart: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: { xs: "150px", md: "auto" },
  },
  title: {
    color: ColorHelper.PrimaryColor,
    fontFamily: FontHelper.fBold,
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: { xs: "14px", md: "22px" },
    lineHeight: "24px",
    // width: "max-content",
    ml: { xs: 1, lg: 2 },
    width: { xs: "max-content", md: "max-content" },
  },
  BackBtn: {
    cursor: "pointer",
  },
};

export default SectionTitleStyle2;
