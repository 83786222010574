import React from 'react'
import ColorHelper from '../../Helpers/ColorHelper'

const CartPlusIcon = () => {
    return (
        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M9.75 7.25H8.25V5H6V3.5H8.25V1.25H9.75V3.5H12V5H9.75V7.25ZM3.7575 15.5C3.7575 14.675 4.425 14 5.25 14C6.075 14 6.75 14.675 6.75 15.5C6.75 16.325 6.075 17 5.25 17C4.425 17 3.7575 16.325 3.7575 15.5ZM12.75 14C11.925 14 11.2575 14.675 11.2575 15.5C11.2575 16.325 11.925 17 12.75 17C13.575 17 14.25 16.325 14.25 15.5C14.25 14.675 13.575 14 12.75 14ZM11.6625 10.25H6.075L5.25 11.75H14.25V13.25H5.25C4.11 13.25 3.39 12.0275 3.9375 11.0225L4.95 9.1925L2.25 3.5H0.75V2H3.2025L6.3975 8.75H11.6625L14.5575 3.5L15.87 4.22L12.975 9.4775C12.72 9.9425 12.225 10.25 11.6625 10.25Z" fill={ColorHelper.WhiteColor} />
        </svg>

    )
}

export default CartPlusIcon