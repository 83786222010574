import * as actionTypes from './shoppingTypes';
const INITIAL_STATE = {
    user: [],
    isdCode: ""
}

const userReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case actionTypes.SAVE_USER_ISD_CODE:
            return {
                ...state,
                isdCode: payload
            };
        case actionTypes.SAVE_USER:
            return {
                ...state, user: { ...payload }
            };
        case actionTypes.RESET:
            return {
                user: []
            };
        default:
            return state;
    }
}

export default userReducer