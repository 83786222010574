import { combineReducers } from "redux";
import shopReducer from "./shoppingReducer";
import userReducer from "./userReducer";
import storage from "redux-persist/lib/storage";
import configReducer from "./ConfigReducer";
import { persistReducer } from "redux-persist";
const persistConfig = {
    key:'oneGlint',
    version: 1,
    storage
}

const rootReducer = combineReducers({
    shop: shopReducer,
    config: configReducer,
    user: userReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default persistedReducer;