import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect } from "react";
import BillView from "../Components/BillView/BillView";
import CollageJourneyMapSection from "../Components/CartSections/CollageJourneyMapSection/CollageJourneyMapSection";
import DigitalDownloadsSection from "../Components/CartSections/DigitalDownloadsSection/DigitalDownloadsSection";
import PrintsSection from "../Components/CartSections/PrintsSection/PrintsSection";
import FooterNote from "../Components/FoooterNote/FooterNote";
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import HeaderNote from "../Components/HeaderNote/HeaderNote";
import SummarySection from "../Components/SummarySection/SummarySection";
import SectionTitle2 from "../Components/Ui-Components/SectionTitle2/SectionTitle2";
import ColorHelper from "../Helpers/ColorHelper";
import { useSelector } from "react-redux";
import ConstantHelper from "../Helpers/ConstantHelper";
import axios from "axios";
import { useState } from "react";
import {
  getCartDetails,
  getUnlabeledCartDetails,
} from "../redux/shoppingActions";
import { useDispatch } from "react-redux";
import EmptyCart from "../Components/CartSections/EmptyCart/EmptyCart";
import { useLocation } from "react-router-dom";

const UnlabeledCartPage = () => {
  const dispatch = useDispatch();
  const productInfo = useSelector((state) => state.shop.products);
  const cartInfo = useSelector((state) => state.shop.cartInfo);
  const downloadsCart = cartInfo?.filter((item) => item.type == "download");
  const printsCart = cartInfo?.filter((item) => item.type == "print");
  const collageCart = cartInfo?.filter((item) => item.type.includes("collage"));
  const cartPrice = useSelector((state) => state.shop.cartCheckout);
  const [checkoutInfo, setCheckoutInfo] = useState({});
  const [checkPrint, setCheckPrint] = useState([]);
  const userIsdCode = useSelector((state)=> state.user.isdCode);

  const location = useLocation();
  const findPath = location.pathname;

  // console.log(
  //   "collageCart------",
  //   JSON.parse(localStorage.getItem("Config")).DISABLEPRINTS.includes(
  //     cartPrice.eventId
  //   )
  // );

  const updateBill = (updatedCart) => {};

  useEffect(() => {
    window.scrollTo(0, 100);
    window.scrollTo(0, 0);
    findPath === "/unlabeled-cart" &&
      localStorage.setItem("currPage", "unlabeled-cart");
  }, []);

  useEffect(() => {
    const payload = { eventID: localStorage.getItem("eventID"), cartInfo };
    const disPrints = JSON.parse(localStorage.getItem("Config"));
    setCheckPrint(disPrints.DISABLEPRINTS);
    dispatch(getUnlabeledCartDetails(payload));
  }, [cartInfo]);

  return (
    <Box>
      <Header />
      {cartInfo?.length > 0 ? (
        <>
          <SectionTitle2
            type={"unlabeled"}
            title={productInfo.eventName}
            eventLogo={productInfo.eventLogo}
            eventLogoShow={true}
            bgColor={ColorHelper.WhiteColo4}
          />
          <HeaderNote priceInfo={cartPrice.priceInfo} />
          <SummarySection bibNumber="unlabeled" reportPics={false} />
          <Grid
            container
            sx={{
              backgroundColor: ColorHelper.WhiteColor2,
              pb: 12,
              px: { xs: 4, md: 5, lg: 10 },
            }}
          >
            <Grid item xs={12} md={8}>
              <DigitalDownloadsSection
                data={downloadsCart}
                updateBill={updateBill}
                type={"unlabeled"}
              />
              {JSON.parse(
                localStorage.getItem("Config")
              ).DISABLEPRINTS.includes(cartPrice.eventId)  && userIsdCode === '+91' && (
                <PrintsSection
                  data={printsCart}
                  type={"unlabeled"}
                  print={"print"}
                />
              )}
              {collageCart.length !== 0 && (
                <CollageJourneyMapSection
                  collageCart={collageCart}
                  type={"unlabeled"}
                />
              )}
            </Grid>
            <Grid item xs={12} md={4} sx={{ pt: 3 }}>
              <BillView cartPrice={cartPrice} cartInfo={cartInfo} />
            </Grid>
          </Grid>
        </>
      ) : (
        <EmptyCart />
      )}
      <FooterNote />
      <Footer />
    </Box>
  );
};
export default UnlabeledCartPage;
