import * as actionTypes from "../../../redux/shoppingTypes";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";

import { saveAs } from "file-saver";
import React, { useEffect, useState } from "react";
import ColorHelper from "../../../Helpers/ColorHelper";
import ConstantHelper from "../../../Helpers/ConstantHelper";
import FontHelper from "../../../Helpers/FontHelper";
import IconHelper from "../../../Helpers/IconHelper";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import DigitalDownloadsSection from "../DigitalDownloadsSection/DigitalDownloadsSection";
import { useDispatch } from "react-redux";
import CardCart from "./Card";
import ImageHelper from "../../../Helpers/ImageHelper";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";

import {
  removeFromCart,
  clearCart,
  clearDigitalPhoto,
  addAllToCart,
  fetchProducts,
  removePrint,
  removeFromCartCollage,
  removeFromCartDigital,
  orderInfo
} from "../../../redux/shoppingActions";

const CartCardComp = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const {
    CartCardData,
    actionStyle,
    CartCardDataRemoveCard,
    CartCardDataAddCard,
    CartCardDataFullPackage,
    collageCart,
    digital,
    collage,
    type,
    video,
    print,
    print_instructions,
    sponsorLogo
  } = props;
  const cartInfo = useSelector((state) => state.shop.cartInfo);
  const [isLoading, setIsLoading] = useState(false);

  const filteredCart = cartInfo.filter((item) => {
    if (item.type === "download") {
      return item.category !== "image";
    }
    return item;
  });
  
 
  const products = useSelector((state) => state.shop.products.photo);
  const { bibNumber, orderId } = useSelector((state) => state.shop.cartDetails);
  const cartPrice = useSelector((state) => state.shop.cartCheckout);
  const findpath = location.pathname;
  const [printItems, setPrintItems] = useState(CartCardData);
  const userID = localStorage.getItem("ID");
  useEffect(() => {
    setPrintItems(CartCardData);
  }, [CartCardData]);

  const handleAddFullPackage = (url) => {
    if (orderId) {
      dispatch(orderInfo(bibNumber, orderId, cartPrice.eventId));
      dispatch(fetchProducts(cartPrice.eventName, bibNumber, cartPrice.eventId,userID,orderId))
      .then((res)=>{
        if(res.data.StatusCode == 200){       
            dispatch(addAllToCart(res.data.result.photo, bibNumber, filteredCart));
        }
      })

    }else{
     dispatch(addAllToCart(products, bibNumber, filteredCart));
    }
  };
  const HandleAddMorePhoto = (url) => {
    if (orderId) {
      dispatch(orderInfo(bibNumber, orderId, cartPrice.eventId));
      dispatch(fetchProducts(cartPrice.eventName, bibNumber, cartPrice.eventId, userID , orderId))
      .then((res)=>{
        if(res.data.StatusCode == 200){  
          navigate("/products");
        }
      })
    }else{
          navigate("/products");
    }
  };

  const HandleRemovePhoto = (url) => {
    dispatch(clearDigitalPhoto(cartInfo));
  };

  const handleDelete = (id, product, sequence) => {
    print == "print"
      ? dispatch(removePrint(id, cartInfo, product, sequence))
      : digital == "digital"
        ? dispatch(removeFromCartDigital(id, cartInfo, sequence))
        : dispatch(removeFromCartCollage(id, cartInfo, sequence));
    props.updateBill(cartInfo);
  };

  const downloadFile = (url) => {
    saveAs(url.s3, url.imageid, { autoBom: true });
  };
  const test = printItems.map((items) => {
    return <img src={items.image} />;
  });

  return (
    // <Box>
    //   <div style={{ display: isLoading ? "flex" : "none" }} className="modal">
    //     <div className="modal-content">
    //       <div className="loader"></div>
    //       <div className="modal-text">Processing...</div>
    //     </div>
    //   </div>
    //   <>
    <Box>
      {props.print !== "print" &&
        cartPrice?.collage_count < 3 &&
        !cartPrice?.collage_count > 5 &&
        digital !== "digital" &&
        video !== "video" &&
        type !== "orderDetails" &&
        findpath !== "/orderDetails" &&
        findpath !== "/preauthdownload" &&
        findpath !== "/myorders" && (
          <div className="collageWarning">
            <div style={{ marginTop: "5px" }}>
              <IconHelper.RedWarningIcon />
            </div>
            <Typography
              sx={{
                fontFamily: FontHelper.fRegular,
                fontSize: "0.9rem",
                color: ColorHelper.YellowColor2,
              }}
            >
              *Minimum 3 photos are mandatory to make collage
            </Typography>
          </div>
        )}
      <Box
        sx={{
          // display: "flex",
          // justifyContent: "flex-start",
          // alignItems: "center",
          // flexWrap: "wrap",
          pr: { xs: 0, sm: 0, md: 5, lg: 10 },
          columnGap: { xs: 0, sm: 3 },
          rowGap: { xs: 3, sm: 3 },
          display: "grid",
          gridTemplateColumns: {
            xs: "1fr",
            sm: "1fr 1fr",
            md: "1fr 1fr 1fr",
            lg: "1fr 1fr 1fr 1fr",
          },
        }}
      >
        {/* {test} */}
        {(printItems !== undefined || printItems !== null) &&
          printItems.map((item) => {
            return (
              <CardCart
                moveFrom={type}
                digital={digital}
                video={video}
                actionStyle={actionStyle}
                print={print}
                item={item}
                handleDelete={handleDelete}
                type={item?.category === "video" ? "video" : type}
                downloadFile={downloadFile}
                bibNumber={bibNumber}
                orderId={orderId}
                sponsorLogo={sponsorLogo}
                key={item.id}
              />
            );
          })}
      </Box>
      <img src={CartCardData.imageA} alt="" />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          flexDirection: { xs: "column", sm: "row" },
          gap: 3,
          my: 3,
          pr: { xs: 0, sm: 10 },
        }}
      >
        {CartCardDataAddCard &&
          type !== "orderDetails" &&
          video !== "video" &&
          // products?.length !== cartInfo?.length &&
          // bibNumber !== "selfiesearch" &&
          props.print !== "print" && (
            <Card
              sx={
                // products?.length ===
                // cartInfo
                //   ?.map((item) => item.type === "download")
                //   .filter((value) => value == true)?.length
                //   ? {}
                //   :
                {
                  width: "100%",
                  maxHeight: "100%",
                  p: "20px",
                  borderRadius: "5px 5px 0 0",
                  textAlign: "center",
                }
              }
              onClick={() => HandleAddMorePhoto(CartCardDataAddCard.addMore)}
            >
              {/* {products?.length ===
              cartInfo
                .map((item) => item.type === "download")
                .filter((value) => value == true).length ? (
                " "
              ) : ( */}
              <Box
                sx={{
                  borderRadius: "5px",
                  maxWidth: "100%",
                  aspectRatio: "1/1",
                  maxHeight: "100%",
                  objectFit: "cover",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  backgroundColor: ColorHelper.LightGreen,
                }}
              >
                <Box
                  onClick={() =>
                    HandleAddMorePhoto(CartCardDataAddCard.addMore)
                  }
                  sx={{
                    cursor: "pointer",
                  }}
                >
                  <IconHelper.PlusCirclular color={ColorHelper.GreenColor} />
                </Box>
                <Typography
                  sx={{
                    fontFamily: FontHelper.fBold,
                    fontSize: 18,
                    color: ColorHelper.BlackColor,
                    mt: 4,
                  }}
                >
                  {CartCardDataAddCard.title}
                </Typography>
              </Box>
              {/* )}
              {products?.length ===
              cartInfo
                .map((item) => item.type === "download")
                .filter((value) => value == true).length ? (
                " "
              ) : ( */}
              <CardActions sx={{ px: 0, py: 0 }}>
                <Box
                  disableripple={true}
                  onClick={() => {
                    HandleAddMorePhoto(CartCardDataAddCard.addMoreItems);
                  }}
                  sx={{
                    fontFamily: FontHelper.fBold,
                    fontSize: { xs: 14, lg: 18 },
                    textAlign: "center",
                    color: ColorHelper.WhiteColor,
                    backgroundColor: ColorHelper.GreenColor,
                    width: "100%",
                    px: "15px",
                    py: "10px",
                    borderRadius: "5px",
                    marginLeft: "auto",
                    mt: 2,
                    mb: 0,
                    cursor: "pointer",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    "&:hover": {
                      backgroundColor: ColorHelper.GreenColor,
                    },
                  }}
                >
                  {ConstantHelper.AddMorePhotos}
                </Box>
              </CardActions>
              {/* )} */}
            </Card>
          )}

        {/* CartCardDataAddCard &&  */}

        {CartCardDataAddCard &&
          type !== "orderDetails" &&
          video !== "video" &&
          // products?.length !== cartInfo.length &&
          // bibNumber !== "selfiesearch" &&
          props.print !== "print" && (
            <Card
              sx={{
                width: { xs: "100%", sm: "200%" },
                maxHeight: "100%",
                p: "20px",
                borderRadius: "5px 5px 0 0",
              }}
              onClick={() =>
                handleAddFullPackage(CartCardDataAddCard.addMoreItems)
              }
            >
              <Box
                sx={{
                  borderRadius: "5px",
                  maxWidth: "100%",
                  aspectRatio: { xs: "1/1", sm: "2/1" },
                  maxHeight: "100%",
                  objectFit: "cover",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  backgroundColor: ColorHelper.LightGreen,
                }}
              >
                <Box
                  onClick={() =>
                    handleAddFullPackage(CartCardDataAddCard.addMore)
                  }
                  sx={{
                    cursor: "pointer",
                  }}
                >
                  {/* {products?.length ===
                  cartInfo
                    .map((item) => item.type === "download")
                    .filter((value) => value == true).length ? (
                    <>
                      <IconHelper.Aatc color={ColorHelper.BlueColor} />
                    </>
                  ) : ( */}
                  <IconHelper.PlusCirclular color={ColorHelper.BlueColor} />
                  {/* )} */}
                </Box>

                <Typography
                  sx={{
                    fontFamily: FontHelper.fBold,
                    fontSize: 18,
                    color: ColorHelper.BlackColor,
                    mt: 4,
                    textAlign: "center",
                  }}
                >
                  {products?.length ===
                    cartInfo
                      .map((item) => item.type === "download")
                      .filter((value) => value == true).length
                    ? "Added all to cart"
                    : ConstantHelper.AddFullPackageAtDiscountedRatesTitle}
                </Typography>
              </Box>

              {/* {products?.length ===
              cartInfo
                .map((item) => item.type === "download")
                .filter((value) => value == true).length ? (
                " "
              ) : ( */}
              <CardActions sx={{ px: 0, py: 0 }}>
                <Box
                  disableripple={true}
                  onClick={() =>
                    handleAddFullPackage(CartCardDataAddCard.addMoreItems)
                  }
                  sx={{
                    fontFamily: FontHelper.fBold,
                    fontSize: { xs: 14, lg: 18 },
                    textAlign: "center",
                    color: ColorHelper.WhiteColor,
                    backgroundColor: ColorHelper.BlueColor,
                    width: "100%",
                    px: "15px",
                    py: "10px",
                    borderRadius: "5px",
                    marginLeft: "auto",
                    mt: 2,
                    mb: 0,
                    cursor: "pointer",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    "&:hover": {
                      backgroundColor: ColorHelper.BlueColor,
                    },
                  }}
                >
                  {ConstantHelper.AddFullPackageBtn}
                </Box>
              </CardActions>
              {/* )} */}
            </Card>
          )}

        {cartInfo
          ?.map((item) => item.type === "download")
          .filter((value) => value == true)?.length !== 0 &&
          CartCardDataRemoveCard &&
          type !== "orderDetails" &&
          video !== "video" &&
          // products?.length !== cartInfo?.length &&
          // bibNumber !== "selfiesearch" &&
          props.print !== "print" && (
            <Card
              sx={
                // products?.length ===
                // cartInfo
                //   ?.map((item) => item.type === "download")
                //   .filter((value) => value == true)?.length
                //   ? {}
                //   :
                {
                  width: "100%",
                  maxHeight: "100%",
                  p: "20px",
                  borderRadius: "5px 5px 0 0",
                  textAlign: "center",
                }
              }
              onClick={() => HandleRemovePhoto()}
            >
              {/* {products?.length ===
              cartInfo
                .map((item) => item.type === "download")
                .filter((value) => value == true).length ? (
                " "
              ) : ( */}
              <Box
                sx={{
                  borderRadius: "5px",
                  maxWidth: "100%",
                  aspectRatio: "1/1",
                  maxHeight: "100%",
                  objectFit: "cover",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  backgroundColor: ColorHelper.LightRedColor,
                }}
              >
                <Box
                  onClick={() => HandleRemovePhoto()}
                  sx={{
                    cursor: "pointer",
                  }}
                >
                  <IconHelper.RemoveFromCart color={ColorHelper.RedColor} />
                </Box>
                <Typography
                  sx={{
                    fontFamily: FontHelper.fBold,
                    fontSize: 18,
                    color: ColorHelper.BlackColor,
                    mt: 4,
                  }}
                >
                  Clear All Photos
                </Typography>
              </Box>
              {/* )}
              {products?.length ===
              cartInfo
                .map((item) => item.type === "download")
                .filter((value) => value == true).length ? (
                " "
              ) : ( */}
              <CardActions sx={{ px: 0, py: 0 }}>
                <Box
                  disableripple={true}
                  onClick={() => {
                    HandleRemovePhoto();
                  }}
                  sx={{
                    fontFamily: FontHelper.fBold,
                    fontSize: { xs: 14, lg: 18 },
                    textAlign: "center",
                    color: ColorHelper.WhiteColor,
                    backgroundColor: ColorHelper.RedColor,
                    width: "100%",
                    px: "15px",
                    py: "10px",
                    borderRadius: "5px",
                    marginLeft: "auto",
                    mt: 2,
                    mb: 0,
                    cursor: "pointer",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    "&:hover": {
                      backgroundColor: ColorHelper.RedColor,
                    },
                  }}
                >
                  {ConstantHelper.removeBtn}
                </Box>
              </CardActions>
              {/* )} */}
            </Card>
          )}
      </Box>

      {collageCart?.length > 0 && (
        <Box>
          <Grid
            sx={{
              pr: { xs: 0, sm: 0, md: 5, lg: 10 },
              columnGap: { xs: 0, sm: 3 },
              rowGap: { xs: 3, sm: 3 },
              display: "grid",
              gridTemplateColumns: {
                xs: "1fr",
                sm: "1fr 1fr",
                md: "1fr 1fr 1fr",
                lg: "1fr 1fr 1fr 1fr",
              },
            }}
          >
            {collageCart.map((item) => (
              <Card
                sx={{
                  padding: "10px",
                  width: "100%",
                  "@media screen and (min-width: 320px) and (max-width: 430px)":
                  {
                    width: "100%",
                  },
                  "@media screen and (width: 820px)": {
                    width: "80%",
                  },
                  "@media screen and (width: 768px)": {
                    width: "87%",
                  },
                }}
              >
                <Grid key={item.img} item>
                  <img
                    style={{ aspectRatio: 1 / 1, objectFit: "contain" }}
                    src={item.s3}
                    alt={item.title}
                    loading="lazy"
                  />
                  {item.product !== "" && item.product !== undefined && (
                    <img
                      style={{
                        marginTop: "5px",
                        width: "20%",
                      }}
                      src={item.product}
                    />
                  )}
                  {findpath !== "/orderDetails" &&
                    findpath !== "/myorders" &&
                    findpath !== "/preauthdownload" && (
                      <Button
                        onClick={() => {
                          dispatch(
                            removeFromCartCollage(item, cartInfo, item.seq)
                          );
                        }}
                        disableripple={true}
                        sx={{
                          fontFamily: FontHelper.fMedium,
                          fontSize: 12,
                          color: "#FFFFFF",
                          backgroundColor: "#B91880",
                          width: "100%",
                          px: "10px",
                          py: "10px",
                          borderRadius: "3px",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          height: 50,
                          mt: { xs: "0px", md: "10px" },
                          "&:hover": {
                            backgroundColor: "#3f0e77",
                          },
                        }}
                      >
                        Remove
                      </Button>
                    )}
                </Grid>
              </Card>
            ))}
          </Grid>
        </Box>
      )}
    </Box>
    //   </>
    // </Box>
  );
};

export default CartCardComp;
