import ColorHelper from "../../Helpers/ColorHelper";
import FontHelper from "../../Helpers/FontHelper";

const FooterStyle = {
  SectionWrapper1: {
    backgroundColor: ColorHelper.PrimaryColor,
    color: ColorHelper.WhiteColor,
    display: "flex",
    justifyContent: "space-between",
    flexDirection: { xs: "column", sm: "row" },
    alignItems: { xs: "center", md: "flex-start" },
    px: { xs: 2, sm: 7, lg: 15 },
    py: { xs: 1, sm: 5 },
  },
  SectionWrapper: {
    backgroundColor: ColorHelper.PrimaryColor,
    color: ColorHelper.WhiteColor,
    display: "flex",
    justifyContent: {
      xs: "center",
      sm: "space-between",
      md: "space-between",
      lg: "space-between",
    },
    rowGap: 1,
    alignItems: "center",
    flexWrap: "wrap",
    px: { xs: 2, sm: 7, lg: 15 },
    py: { xs: 2, md: 5 },
  },

  creditsContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: { xs: "column", md: "row" },
    py: 3,
    mx: { xs: 1, md: 19 },
    mb: { xs: 0, md: 3 },
  },
  copyrightWrapper: {
    my: { xs: 2, md: 1 },
  },
  Copyright: {
    textAlign: { xs: "center", md: "left" },
    color: ColorHelper.LightGrey9,
    fontSize: "1rem",
  },
  Copyright1: {
    fontFamily: FontHelper.fSemiBold,
    textAlign: { xs: "center", sm: "left" },
    color: ColorHelper.grey3,
    fontWeight: "700",
    fontSize: 16,
    textAlign: { xs: "center", md: "left" },
    margin: { xs: "auto", sm: "unset" },
    px: { xs: 2, md: 0 },
    mt: { xs: 1, sm: 3 },
    lineHeight: { xs: "24px", md: "24px" },
  },
  Copyright2: {
    fontFamily: FontHelper.fRegular,
    textAlign: { xs: "center", sm: "left" },
    color: ColorHelper.LightGrey9,
    fontSize: "1rem",
    fontWeight: 400,
    fontSize: 16,
    px: { xs: 2, md: 0 },
    mt: { xs: 1, md: 1 },
    lineHeight: { xs: "24px", md: "24px" },
  },
  Copyright3: {
    fontFamily: FontHelper.fRegular,
    textAlign: { xs: "center", md: "left" },
    color: ColorHelper.YellowColor3,
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 16,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    margin: "auto",
    mt: { xs: 4, md: 4 },
  },
  socialIconsContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    mt: { xs: 3, sm: 3, md: 0 },
  },
  imagefooter: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    mt: { xs: 2, md: 10 },
    mx: { xs: 10, md: 0 },
  },
  Footerupperpart: {
    display: "flex",
    flexDirection: "Column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    px: { xs: 0, md: 0 },
  },

  footerLinks: {
    cursor:'pointer',
    mr: { xs: "none", md: 4 },
    ml: { xs: "none", md: 0 },
    px: { xs: 1, md: 0 },
  },

  FooterIcons: {
    mx: { xs: 2, md: 4 },
    cursor: "pointer",
    mt: { xs: 0, md: 0 },
  },

  Link: {
    display: "flex",
    // justifyContent: {xs: 'center', md: 'flex-start'},
    alignItems: "center",
    minWidth: "max-content",
    textDecoration: "none",
    
    margin: { xs: "auto", md: "unset" },
    color: ColorHelper.WhiteColor,
    "&hover": {
      color: ColorHelper.SecondaryColor,
    },
  },
};

export default FooterStyle;
