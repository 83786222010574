import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import ImageHelper from "../../../Helpers/ImageHelper";
import SectionTitleStyle from "./SectionTitleStyle";

const SectionTitle = (props) => {
  const { title, sx1 } = props;
  return (
    <Box sx={SectionTitleStyle.sectionHeading}>
      <Typography sx={{...SectionTitleStyle.title, ...sx1}}>{title}</Typography>
    </Box>
  );
};

export default SectionTitle;
