import { Pagination, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect } from "react";
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import SectionTitle3 from "../Components/Ui-Components/SectionTitle3/SectionTitle3";
import ConstantHelper from "../Helpers/ConstantHelper";
import "./table.css";
import FontHelper from "../Helpers/FontHelper";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";

import {
  addAllToCart,
  addAllPrintToCart,
  orderInfo,
  packageDetails,
  fetchProducts,
} from "../redux/shoppingActions";
//table from mui
import {
  Paper,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import img from "../Assets/orderpage/avatar.jpg";
import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ColorHelper from "../Helpers/ColorHelper";
import EmptyOrder from "../Components/EmptyOrderHistory/EmptyOrder";
import { ApiManager } from "../ApiClient";
import { OrderHistoryList } from "../Components/OrderHistory/OrderHistoryList";
import LoaderComp from "../Components/Loader/LoaderComp";
export const getArray = (length) => {
  let traversy = [];
  for (let i = 0; i < length; i++) {
    traversy.push(i);
  }
  return traversy;
};

const OrderHistory = () => {
  const [orders, setOrders] = useState([]);
  const [updatedData, setUpdatedData] = useState(null);
  const [hideLoader, setHideLoader] = useState(false)
  const [txnId, setTxnId] = useState();
  const [amount, setAmount] = useState();
  const products = useSelector((state) => state.shop.products);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fetchOrderHistory = async () => {
    const response = await axios.post(ApiManager.orderHistory(), {
      userID: localStorage.getItem("ID"),
      searchtext: "",
    });

    if (response.data.StatusCode === 200) {
      setOrders(response.data.result.order_history);
      setHideLoader(true)
    } else {
      setHideLoader(true)

      console.log("err: ", response.data.Message);
    }
    // console.log("ordesss",response.data)
  };

  const goToDetails = async (
    price,
    payment_id,
    bibId,
    orderId,
    isPending,
    status,
    name
  ) => {

    if (status !== "Cancelled") {
      if (isPending === false) {
        const userId = localStorage.getItem("ID");
        const eventName = name.split("-")[1].trim(" ");
        const eventId = name.split("-")[0].trim(" ");

        localStorage?.setItem("isPending", JSON?.stringify(!isPending));
        const response = await axios.post(ApiManager.orderDetail(), {
          orderID: orderId,
          bibNumber: bibId,
        });

        if (response.data.StatusCode === 200) {
          const responseData = response.data.result;
          const video = responseData.video || [];
          if (responseData?.bundle[0]?.id === "b_ultraprem") {
            dispatch(fetchProducts(eventName, bibId, eventId, userId,""));
            dispatch(packageDetails("b"));
          }
          if (responseData?.prints?.length > 0) {
            let prints = responseData.prints;
            let collage = responseData.prints
              .filter((ele) => {
                return ele.type.includes("collage");
              })
              .map((item) => {
                return {
                  id: item.imageid,
                  s3: item.s3,
                  category: item.category,
                  type: "collage",
                  price: item.price,
                };
              });
            let print = responseData.prints
              .filter((ele) => {
                return ele.type === "print";
              })
              .map((item) => {
                let printId = null;
                for (let i = 0; i < prints.length; i++) {
                  if (prints[i].name === item.productName) {
                    printId = prints[i].prodid;
                    break;
                  }
                }
                return {
                  id: item.imageid,
                  image: item.product,
                  imageAthlete: item.s3,
                  s3: undefined,
                  category: item.category,
                  type: item.type,
                  price: item.price,
                  videopath: "",
                  product: item.productName,
                  dataimg: printId,
                };
              });

            let cartProduct = [...responseData.photo, ...print, ...collage];

            dispatch(addAllPrintToCart(cartProduct, bibId, orderId));
            dispatch(orderInfo(bibId, orderId, responseData.eventID));
            if (bibId === "unlabeled") {
              navigate("/unlabeled-cart");
            } else {
              navigate("/cart");
            }
          } else {
            dispatch(
              addAllPrintToCart(
                [...responseData.photo, ...video],
                bibId,
                orderId
              )
            );
            dispatch(orderInfo(bibId, orderId, responseData.eventID));
            if (bibId === "unlabeled") {
              navigate("/unlabeled-cart");
            } else {
              navigate("/cart");
            }
          }
        } else {
          console.log("err: ", response.data.Message);
        }
      } else {


        localStorage?.setItem("isPending", JSON?.stringify(!isPending));
        dispatch(orderInfo(bibId, orderId, ""));

        navigate("/orderDetails", {
          state: {
            bibId: bibId,
            orderId: orderId,
            payment_id: orderId,
            // payment_id: price === 0 ? orderId : payment_id,
            moveFrom: "OrderHistory",
          },
        });
      }
    }
  };

  const filteredOrder = (search) => {
    // console.log("----orders--1--", orders);
    // console.log("----orders--11--", search);

    const results = orders.filter((item) => {
      return (
        item.name.toLowerCase().includes(search.toLowerCase()) ||
        item.bib_id.toLowerCase().includes(search.toLowerCase()) ||
        item.order_ref.toLowerCase().includes(search.toLowerCase())
      );
    });

    setUpdatedData(results);
  };

  useEffect(() => {
    // window.scrollTo(0, 100);
    window.scrollTo(0, 0);
    fetchOrderHistory();
  }, []);

  return (
    <Box>
      {hideLoader ? (
        <>
          <Header />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              flexWrap: "wrap",
              px: { xs: 4, md: 10 },
              py: 0,
            }}
          >
            <Box className="bgbox">
              <Grid
                container
                spacing={2}
                columns={12}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Grid item xs={12} sm={5} className="leftgrid">
                  <img
                    src={img}
                    className="imagecontent"
                    style={{ height: "36px", borderRadius: "50%" }}
                  />
                  <Box
                    className="imagecontent"
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      className="mainname"
                      style={{ fontSize: "18px" }}
                    >
                      {localStorage.getItem("Name")}
                    </Typography>
                    <Typography
                      className="submail"
                      style={{ fontSize: "12px" }}
                    >
                      {localStorage.getItem("Email")}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={7}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-evenly",
                      pb: 2
                    }}
                  >
                    <Paper
                      component="form"
                      sx={{
                        p: "2px 4px",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        flexWrap: "wrap",
                        width: { md: "360px" },
                        height: 50,
                        mt: { xs: "0px", md: "10px" },
                      }}
                    >
                      <IconButton sx={{ p: { xs: "4px", sm: "10px" } }} aria-label="menu">
                        <SearchIcon />
                      </IconButton>
                      <InputBase
                        sx={{ ml: 1, flex: 1, fontSize: "12px" }}
                        onChange={(e) => {
                          filteredOrder(e.target?.value);
                        }}
                        placeholder="Search by event name"
                        inputProps={{ "aria-label": "search google maps" }}
                      />
                    </Paper>
                    <Button
                      disableripple={true}
                      // onClick={() => filterState()}
                      sx={{
                        fontFamily: FontHelper.fMedium,
                        fontSize: 12,
                        color: "#FFFFFF",
                        backgroundColor: "#B91880",
                        width: { sx: "auto", sm: "100px" },
                        px: "15px",
                        py: "10px",
                        borderRadius: "3px",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        height: 50,
                        mt: { xs: "0px", md: "10px" },
                        "&:hover": {
                          backgroundColor: "#3f0e77",
                        },
                      }}
                    >
                      Search
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <SectionTitle3 title={ConstantHelper.OrderHistoryTitle} />
          {orders.length > 0 ? (
            <OrderHistoryList
              goToDetails={goToDetails}
              orders={updatedData ? updatedData : orders}
            />
          ) : (
            <EmptyOrder />
          )}
          <Footer />{" "}
        </>
      ) : (
        <LoaderComp />
      )}
    </Box>
  );
};

export default OrderHistory;
