import React from "react";
import ConstantHelper from "../../../Helpers/ConstantHelper";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import IconHelper from "../../../Helpers/IconHelper";
import { Button, Typography } from "@mui/material";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import FontHelper from "../../../Helpers/FontHelper";
import ColorHelper from "../../../Helpers/ColorHelper";

const AddToCartAddedBtn = (props) => {
  const { AddToCartText, CardBtnStyle, handleCartBtn } = props;
  return (
    <Button
      disableripple={false}
      btnText={ConstantHelper.addToCartBtn}
      onClick={() => {
        handleCartBtn();
      }}
      sx={CardBtnStyle}
      startIcon={
        AddToCartText == "Added" ? <CheckCircleIcon /> : <AddShoppingCartIcon />
      }
      // clickEvent={addToCartDetails(item.id,item.image,stateDetails.bibNumber)}
    >
      <Typography
        sx={{
          display: { xs: "none", sm: "block" },
          fontFamily: FontHelper.fBold,
          fontSize: 10,
          color: ColorHelper.WhiteColor,
          display: { xs: "none", lg: "block" },
        }}
      >
        {AddToCartText}
      </Typography>

      <span style={{ marginLeft: "0.3rem" }}></span>
    </Button>
  );
};

export default AddToCartAddedBtn;
