import * as actionTypes from "./shoppingTypes";

const INITIAL_STATE = {
  products: [],
  cartDetails: { eventID: "", bibNumber: "", orderId: "" },
  packageDetails: "",
  order_info: [],
  cartInfo: [],
  cartCheckout: [],
  typeOfPage: "",
  sponsorDiscount: { appliedDiscount: false, discountedPhoto: [] },
};
const shopReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case actionTypes.UNLABELED_PRODUCTS:
      return {
        ...state,
        products: payload,
        cartDetails: { eventID: payload.eventId },
      };
    case actionTypes.TYPE_OF_PAGE:
      return {
        ...state,
        typeOfPage: payload,
      };
    case actionTypes.MULTIPLE_BIB_PRODUCT:
      return {
        ...state,
        products: payload,
      };
    case actionTypes.FETCH_PRODUCTS:
      return {
        ...state,
        products: payload,
        cartDetails: { eventID: payload.eventId, bibNumber: payload.bibNumber },
      };
    case actionTypes.ADD_TO_CART:
      return {
        ...state,
        cartInfo: payload.cartInfo,
      };
    case actionTypes.REMOVE_FROM_CART:
      return {
        ...state,
        cartInfo: payload.cartInfo,
      };
    case actionTypes.REMOVE_PRINT:
      return {
        ...state,
        cartInfo: payload.cartInfo,
      };
    case actionTypes.CHECKOUT:
      return {
        ...state,
        cartCheckout: payload,
      };
    case actionTypes.UPDATE_CART:
      return {};
    case actionTypes.VIEW_CART:
      return {};
    case actionTypes.CLEAR_CART:
      return {
        ...state,
        cartInfo: [],
      };
    case actionTypes.RESET:
      return { ...INITIAL_STATE };
    case actionTypes.ORDER_INFO:
      return {
        ...state,
        cartDetails: {
          eventID: payload.eventID,
          bibNumber: payload.bibNumber,
          orderId: payload.orderId,
        },
      };
    case actionTypes.PACKAGE_INFO:
      return {
        ...state,
        packageDetails: payload,
      };
    case actionTypes.CLEAR_ALL_DIGITAL_PHOTO:
      return {
        ...state,
        cartInfo: payload,
      };
    case actionTypes.SPONSOR_DISCOUNT:
      
      return {
        ...state,
        sponsorDiscount: {
          appliedDiscount: payload.apply,
          discountedPhoto: payload.photoID,
        },
      };
    default:
      return state;
  }
};
export default shopReducer;
