import React, { useState, useEffect } from "react";
import { Box } from "@mui/system";
import { Typography, Pagination, MenuItem, Select } from "@mui/material";
import {
  Paper,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Divider,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import ConstantHelper from "../../Helpers/ConstantHelper";
import Autocomplete from "@mui/material/Autocomplete";
import { useSnackbar } from "notistack";
import axios from "axios";
import ColorHelper from "../../Helpers/ColorHelper";
import Button from "@mui/material/Button";
import FontHelper from "../../Helpers/FontHelper";
import "../../Pages/table.css";
import { useSelector } from "react-redux";
import { ApiManager } from "../../ApiClient";
import { nodeName } from "rsuite/esm/DOMHelper";
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import LabelIcon from '@mui/icons-material/Label';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import SaveAltIcon from '@mui/icons-material/SaveAlt';

const OrganizerDashboardList = ({ events }) => {
  const snack = useSnackbar();
  const [orders, setOrders] = useState([]);
  const [updateOrders, setUpdateOrders] = useState([]);
  const [finalOrders, setFinalOrders] = useState([]);
  const [bibLessImage, setBibLessImage] = useState("");
  const [bibGreaterImage, setBibGreaterImage] = useState("");
  const [maxImage, setMaxImage] = useState("");
  const [orderMaxImages, setOrderMaxImages] = useState("");
  const [bibsGreaterOrder, setBibsGreaterOrder] = useState("");
  const [maxImages, setMaxImages] = useState("");
  const [orderDropped, setOrderDropped] = useState("");
  const [orderPending, setOrderPending] = useState("");
  const [unlabeledImages, setUnlabeledImages] = useState("");
  const [ordersUnderAndMaxImages, setordersUnderAndMaxImages] = useState("");
  const [photographer, setPhotographer] = useState("");
  const [allCategories, setAllCategories] = useState("");

  const [style, setStyle] = useState("combo-box-demo");
  const [inputValue, setInputValue] = useState("");

  const [eventsTotalBib, setEventsTotalBib] = useState("");

  const [eventsTotalImage, setEventsTotalImage] = useState("");
  const [eventsTotalUnImage, setEventsTotalUnImage] = useState("");

  const [eventsToDate, setEventsToDate] = useState("");
  const [selectedEvent, setSelectedEvent] = useState();

  const [filterData, setFilterData] = useState([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const state = useSelector((state) => state.shop);

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (eventId) => {
    setIsLoading(true);

    await axios
      .post(ApiManager.orgnDashboard(), {
        event_id: eventId,
        email: localStorage.getItem("email"),
      })
      .then((res) => {
        if (res.data.StatusCode === 200) {
          setIsLoading(false);

          if (res.data.HasError === true) {
            snack.enqueueSnackbar({
              variant: "warning",
              message: res.data.Message,
            });
          } else {
            setOrders(res.data.result.event_revenue);
            setUpdateOrders(res.data.result.event_revenue);
            setEventsTotalBib(res.data.result.totalbibs.totalbibs);
            setEventsTotalImage(res.data.result.totalimgs.totalimgs);
            setEventsTotalUnImage(res.data.result.totalimgs.totunqimgs);

            setBibLessImage(res.data.result.analytics[1].bibslteq2);
            setBibGreaterImage(res.data.result.analytics[2].bibsgteq10);
            setMaxImage(res.data.result.analytics[0].hbibrecs);
            setOrderMaxImages(res.data.result.analytics[3].omaxordrecs);
            setBibsGreaterOrder(res.data.result.analytics[6].bibrepeatords);
            // console.log("setBibsGreaterOrder", res.data.result.analytics);
            setMaxImages(res.data.result.analytics[1].bibslteq2);
            setOrderDropped(res.data.result.analytics[5].orderspymtpend);
            setOrderPending(res.data.result.analytics[4].bibordspend);
            setUnlabeledImages(res.data.result.analytics[7].unlblimgs);
            setordersUnderAndMaxImages(res.data.result.analytics[8].unlblordrs);
            setPhotographer(res.data.result.analytics[1].bibslteq2);
            setAllCategories(
              res.data.result.analytics[9].catwiseordrs[0].actvtyorders
            );

            snack.enqueueSnackbar({
              variant: "success",
              message: res.data.Message,
            });
          }
        } else {
          setIsLoading(false);
          snack.enqueueSnackbar({
            variant: "warning",
            message: res.data.Message,
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        // snack.enqueueSnackbar({
        //   variant: "warning",
        //   message: "Error",
        // });
      });
  };

  const filteredOrder = (search) => {
    const results = orders.filter((item) => {
      return (
        item.price == search ||
        item.totalords == search ||
        item.ordval == search ||
        item.tax == search ||
        item.printval == search ||
        item.videoval == search ||
        item.discountamount == search ||
        item.totalrev == search ||
        item.photos_count == search
      );
    });

    if (results.length > 0) {
      setUpdateOrders(results);
    } else {
      setUpdateOrders(orders);
    }
  };

  return (
    <Box>
      <div style={{ display: isLoading ? "flex" : "none" }} className="modal">
        <div className="modal-content">
          <div className="loader"></div>
          <div className="modal-text">Processing...</div>
        </div>
      </div>

      <div className="fullWidthContainer"
        style={{ maxWidth: "1200px", width: "100%", margin: "0px auto", padding: "0 20px" }} >
        <Box>
        <Grid container columnSpacing={{sm:1,lg:4}}>
            <Grid
              item
              sm={6}
              xs={12}
              sx={{
                fontFamily: FontHelper.fBold,
                fontSize: "30px",
                fontWeight: 700,
                color: "#3F0E77",
                marginBottom: "10px",
                // px: 2,
                // mx: 3,
                // paddingTop: "20px",
                // marginLeft: "80px",
                // marginRight: "80px",
                // marginTop: "12px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Autocomplete
                sx={{
                  width: "100%",
                  height:"38px"
                }}
                onChange={(events, newValue) => {

                  
                  setSelectedEvent(newValue.event_id);
                  {
                    newValue == null ? setStyle("") : setStyle("afterSubmit");
                  }
                  localStorage.setItem(
                    "EventName",
                    newValue.event_id + " - " + newValue.event_name
                  );
                  localStorage.setItem("EventId", newValue.event_id);
                  onSubmit(newValue.event_id);
                }}
                onInputChange={(events, newInputValue) => {

                  setInputValue(newInputValue);
                }}
                options={events}
                getOptionLabel={(option) =>
                  option.event_name
                    ? option.event_id + " - " + option.event_name
                    : ""
                }
                size="small"
                renderInput={(params) => (
                  <TextField
                    sx={{ fontFamily: FontHelper.fRegular }}
                    {...params}
                    placeholder={ConstantHelper.SerachFieldTitle}
                  />
                )}
              />
            </Grid>
            {/* <Grid item lg={2} md={2} sm={3} xs={3} >
              <Button
                sx={{
                  height: "38px",
                  height: { lg: "38px", md: "38px", sm: "35px", xs: "32px" },
                  width: "100%",
                  alignItems: "center",
                  flexDirection: "column",
                  backgroundColor: "#3f0e77",
                  color: "#FFFFFF",
                  "&:hover": { backgroundColor: "#fff", border: "1px solid #3f0e77", color: "#3f0e77" }
                }}
                onClick={onSubmit}
              >
                GET DATA
              </Button>
            </Grid> */}
            {updateOrders?.length > 0 && <Grid item sm={6} xs={12}>
              <Paper
                component="form"
                sx={{
                  width: "100%",
                  // width: { lg: "30%", md: "30%", sm: "100%", xs: "100%" },
                  height: "36px",
                  mb: 1,
                  border: "1px solid #ccc",
                  boxShadow: "none"
                }}
              >
                <IconButton sx={{ p: "0 10px" }} aria-label="menu">
                  <SearchIcon />
                </IconButton>
                <InputBase
                  sx={{ ml: 1, flex: 1, fontSize: "12px", py: 1 }}
                  onChange={(e) => {
                    filteredOrder(e.target?.value);
                  }}
                  placeholder="Search here"
                  inputProps={{ "aria-label": "search google maps" }}
                />
              </Paper>
            </Grid>}
          </Grid>
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  // px: { xs: 4, md: 10 },
                  // pb: 2,
                }}
              >
                <TableContainer component={Paper}>
                  <Table sx={{}} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="right">
                          Order Value{" "}
                          <SwapVertIcon
                            sx={{
                              fontSize: "12px",
                              ":hover": { cursor: "pointer" },
                              color: "#3F0E77",
                            }}
                          />
                        </TableCell>

                        <TableCell align="right">
                          Total Order{" "}
                          <SwapVertIcon
                            sx={{
                              fontSize: "12px",
                              color: "#3F0E77",
                              ":hover": { cursor: "pointer" },
                            }}
                          />
                        </TableCell>

                        <TableCell align="right">
                          Video Amount{" "}
                          <SwapVertIcon
                            sx={{
                              fontSize: "12px",
                              color: "#3F0E77",
                              ":hover": { cursor: "pointer" },
                            }}
                          />
                        </TableCell>

                        <TableCell align="right">
                          Print Amount{" "}
                          <SwapVertIcon
                            sx={{
                              fontSize: "12px",
                              color: "#3F0E77",
                              ":hover": { cursor: "pointer" },
                            }}
                          />
                        </TableCell>

                        <TableCell align="right">
                          Discount{" "}
                          <SwapVertIcon
                            sx={{
                              fontSize: "12px",
                              color: "#3F0E77",
                              ":hover": { cursor: "pointer" },
                            }}
                          />
                        </TableCell>

                        <TableCell align="right">
                          Total Revenue{" "}
                          <SwapVertIcon
                            sx={{
                              fontSize: "12px",
                              color: "#3F0E77",
                              ":hover": { cursor: "pointer" },
                            }}
                          />
                        </TableCell>

                        <TableCell align="right">
                          Photo Count{" "}
                          <SwapVertIcon
                            sx={{
                              fontSize: "12px",
                              color: "#3F0E77",
                              ":hover": { cursor: "pointer" },
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    {updateOrders && updateOrders?.length > 0 ? (
                      <TableBody>
                        {updateOrders
                          ?.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((row, index) => (
                            <TableRow
                              key={row.index}
                              sx={{
                                "&:last-child td, &:last-child th": { border: 0 },
                              }}
                            >
                              <TableCell component="th" scope="row" align="right">
                                {row.price}
                              </TableCell>

                              <TableCell sx={{ cursor: "pointer" }} align="right">
                                {row.totalords}
                              </TableCell>

                              <TableCell
                                sx={{
                                  cursor: "pointer",
                                  // textDecoration: "underline",
                                  "&:hover": {
                                    color: ColorHelper.PinkColor,
                                  },
                                }}
                                align="right"
                              >
                                {row.videoval}
                              </TableCell>

                              <TableCell sx={{ cursor: "pointer" }} align="right">
                                {row.printval}
                              </TableCell>

                              <TableCell align="right">
                                {row.discountamount}
                              </TableCell>

                              <TableCell align="right">{row.ordval}</TableCell>

                              <TableCell align="right">{row.photos_count}</TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    ) : (
                      <TableBody>
                        <TableCell colSpan={10} align="center">
                          No matching records found
                        </TableCell>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "flex-start",
                  px: { xs: 4, md: 10 },
                  py: 2,
                }}
              >
                {updateOrders.length > rowsPerPage && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      ml: "60px",
                      mb: "15px",
                    }}
                  >
                    <Pagination
                      count={Math.ceil(updateOrders?.length / rowsPerPage)}
                      page={page + 1}
                      color={"primary"}
                      onChange={(event, value) => {
                        setPage(value - 1);
                      }}
                    />
                  </Box>
                )}
              </Box>
            </Grid>

            <Grid item lg={12} md={12} sm={12} style={{ paddingTop: "0", width: "100%", paddingBottom: "20px" }}>
              {updateOrders && updateOrders.length > 0 ? (
                <Box>
                  <Typography
                    sx={{
                      fontFamily: FontHelper.fRegular,
                      fontSize: "20px",
                      fontWeight: 700,
                      color: "#3F0E77",
                      mb: 1,
                      // marginLeft: "80px",
                    }}
                  >
                    Event Analytics
                  </Typography>

                  {/* <Box
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "flex-start",
                      // px: { xs: 4, md: 10 },
                      pb: 4,
                    }}
                  > */}
                  <TableContainer component={Paper} className="w-100">
                    <Table aria-label="simple table" className="w-100">
                      <TableHead>
                        <Tooltip title="Total number of records available for this Event. Same photo having multiple bibs will have multiple records"
                          TransitionComponent={Zoom} followCursor>
                          <TableRow>
                            <TableCell align="start">Total # </TableCell>
                            <TableCell component="th" scope="row" align="right">
                              {eventsTotalImage}
                            </TableCell>
                          </TableRow>
                        </Tooltip>
                        <Tooltip title="Number of unique photos available for this Event" TransitionComponent={Zoom} followCursor>
                          <TableRow>
                            <TableCell align="start">Unique #images </TableCell>
                            <TableCell component="th" scope="row" align="right">
                              {eventsTotalUnImage}
                            </TableCell>
                          </TableRow>
                        </Tooltip>
                        <Tooltip title="Number of unique bibs recognised for this Event" TransitionComponent={Zoom} followCursor>
                          <TableRow>
                            <TableCell align="start">Unique #BIBs </TableCell>
                            <TableCell component="th" scope="row" align="right">
                              {eventsTotalBib}
                            </TableCell>
                          </TableRow>
                        </Tooltip>
                        <Tooltip title="Number of bibs with <= 2 photos mapped" TransitionComponent={Zoom} followCursor>
                          <TableRow>
                            <TableCell align="start">BIBs({"<"}=2 images) </TableCell>
                            <TableCell component="th" scope="row" align="right">
                              {bibLessImage}
                            </TableCell>
                          </TableRow>
                        </Tooltip>
                        <Tooltip title="Number of bibs with >= 10 photos mapped" TransitionComponent={Zoom} followCursor>
                          <TableRow>
                            <TableCell align="start">BIBs({">"}=10 images) </TableCell>
                            <TableCell component="th" scope="row" align="right">
                              {bibGreaterImage}
                            </TableCell>
                          </TableRow>
                        </Tooltip>
                        <Tooltip title="Maximum number of photos clicked for a bib" TransitionComponent={Zoom} followCursor>
                          <TableRow>
                            <TableCell align="start">Max #Images </TableCell>
                            <TableCell component="th" scope="row" align="right">
                              {maxImage}
                            </TableCell>
                          </TableRow>
                        </Tooltip>
                        <Tooltip title="Maximum photos bought by a bib" TransitionComponent={Zoom} followCursor>
                          <TableRow>
                            <TableCell align="start">Order(w/ Max images)</TableCell>
                            <TableCell component="th" scope="row" align="right">
                              {orderMaxImages}
                            </TableCell>
                          </TableRow>
                        </Tooltip>
                        <Tooltip title="Number of repeat orders by a bib" TransitionComponent={Zoom} followCursor>
                          <TableRow>
                            <TableCell align="start">BIBs({">"}1 Order)</TableCell>
                            <TableCell component="th" scope="row" align="right">
                              {bibsGreaterOrder}
                            </TableCell>
                          </TableRow>
                        </Tooltip>
                        <Tooltip title="Orders pending payment" TransitionComponent={Zoom} followCursor>
                          <TableRow>
                            <TableCell align="start" sx={{ display: "flex", alignItems: "center" }}>Orders(<ShoppingCartIcon sx={{ fontSize: "14px"}} /> dropped)</TableCell>
                            <TableCell component="th" scope="row" align="right">
                              {orderDropped}
                            </TableCell>
                          </TableRow>
                        </Tooltip>
                        <Tooltip title="Orders booked; download pending" TransitionComponent={Zoom} followCursor>
                          <TableRow>
                            <TableCell align="start" sx={{ display: "flex", alignItems: "center" }}>Orders(<SaveAltIcon sx={{ fontSize: "14px"}} /> pending)</TableCell>
                            <TableCell component="th" scope="row" align="right">
                              {orderPending}
                            </TableCell>
                          </TableRow>
                        </Tooltip>
                        <Tooltip title="Number of photos for which bib is not recognised" TransitionComponent={Zoom} followCursor>
                          <TableRow>
                            <TableCell align="start">Unlabeled Images</TableCell>
                            <TableCell component="th" scope="row" align="right">
                              {unlabeledImages}
                            </TableCell>
                          </TableRow>
                        </Tooltip>
                        <Tooltip title="Order with maximum unlabeled photos" TransitionComponent={Zoom} followCursor>
                          <TableRow>
                            <TableCell align="start" sx={{ display: "flex", alignItems: "center" }}>
                              Orders(w/ un<LabelIcon sx={{ fontSize: "14px" }} />d & Max images)
                            </TableCell>
                            <TableCell component="th" scope="row" align="right" >
                              <div style={{ display: "flex", alignItems: "center" , justifyContent:"end" }}>

                                {ordersUnderAndMaxImages[0]}{"("}<ShoppingCartIcon sx={{ fontSize: "14px"}} />{":"}{ordersUnderAndMaxImages[2]}{")"}
                              </div>


                            </TableCell>
                          </TableRow>
                        </Tooltip>

                        <TableRow>
                          <TableCell align="start">Photographers</TableCell>
                          <TableCell component="th" scope="row" align="right">
                            {photographer}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell align="start">All category Orders</TableCell>
                          <TableCell component="th" scope="row" align="right">
                            {allCategories}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                    </Table>
                  </TableContainer>
                  {/* </Box> */}
                </Box>
              ) : (
                ""
              )}
            </Grid>
          </Grid>



        </Box>



      </div>
      {/* <Footer /> */}
    </Box>
  );
};
export default OrganizerDashboardList;
