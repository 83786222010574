import React from "react";

const CloseIcon2 = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99999 7.05531L11.3 3.75531L12.2427 4.69798L8.94266 7.99798L12.2427 11.298L11.3 12.2406L7.99999 8.93998L4.69999 12.24L3.75732 11.2973L7.05732 7.99731L3.75732 4.69731L4.69999 3.75531L7.99999 7.05531Z"
        fill="white"
      />
    </svg>
  );
};

export default CloseIcon2;
