import { Grid, Box, Modal } from "@mui/material";
import Button from "@mui/material/Button";
import ProductCardStyle from "./ProductCardStyle";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import IconHelper from "../../Helpers/IconHelper";
import Typography from "@mui/material/Typography";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ColorHelper from "../../Helpers/ColorHelper";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import { useDispatch } from "react-redux";
import {
  addToCart,
  removeFromCart,
  removeFromCartCollage,
  removeCartCollage,
  removeCartDownload,
  removeFromCartDigital,
} from "../../redux/shoppingActions";
import { useState } from "react";
import { useSelector } from "react-redux";
import FontHelper from "../../Helpers/FontHelper";
import "./ProductStyles.css";
import { useEffect } from "react";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import CloseIcon from "@mui/icons-material/Close";

const SingleCarousalProduct = ({
  config,
  products,
  photo,
  valueimg,
  stateDetails,
  cartDetails,
  handleChildOpen,
  handleOpen,
  handleAddtoCart1,
}) => {
  const dispatch = useDispatch();
  const [AddToCartText, setAddtoCartText] = useState("Add To Cart");
  const [AddToCollageText, setAddtoCollageText] = useState("Collage");
  const cartInfo = useSelector((state) => state.shop.cartInfo);
  const userIsdCode = useSelector((state) => state.user.isdCode);
  const handleAddtoCart = (id, s3, image, product, type, price) => {

    const cartItems = {
      id: id,
      s3: s3,
      image: image,
      category: "image",
      bibNumber: stateDetails.bibNumber,
      type: type,
      videopath: "",
      // loc:0
    };
    const collageItems = {
      id: id,
      s3: s3,
      image: image,
      category: "image",
      bibNumber: stateDetails.bibNumber,
      type: type,
      price: 1179,
    };
    if (AddToCartText == "Add To Cart") {
      setAddtoCartText("Added");
      product == undefined && price == undefined
        ? dispatch(addToCart(cartDetails, collageItems))
        : dispatch(
          addToCart(cartDetails, {
            ...cartItems,
            product: product,
            price: price,
          })
        );
    } else {
      setAddtoCartText("Add To Cart");
      dispatch(removeCartDownload(id, cartInfo, "digital"));
    }
  };
  const handleAddCollage = (id, s3, image, type) => {
    const collageItems = {
      id: id,
      s3: s3,
      image: image,
      category: "image",
      bibNumber: stateDetails.bibNumber,
      type: type,
      price: 1179,
    };
    if (AddToCollageText == "Collage") {
      setAddtoCollageText("Added");
      dispatch(addToCart(cartDetails, collageItems));
    } else {
      setAddtoCollageText("Collage");
      dispatch(removeCartCollage(id, cartInfo, "collage"));
    }
  };

  useEffect(() => { }, [AddToCartText]);

  const checkInCart = (dataimg) => {
    const ids = cartInfo.filter(
      (items) => items.id && items.type == "download"
    );
    const downloadIDs = ids.map((items) => items.id);
    const collages = cartInfo.filter(
      (items) => items.id && items.type == "collage"
    );
    const collagesID = collages.map((items) => items.id);
    if (downloadIDs.includes(dataimg.id)) {
      setAddtoCartText("Added");
    }
    if (collagesID.includes(dataimg.id)) {
      setAddtoCollageText("Added");
    }
  };

  useEffect(() => {
    checkInCart(valueimg);
  }, []);

  const styles = (theme) => ({
    modalStyle1: {
      position: "absolute",
      top: "20%",
      left: "20%",
      right: "20%",
      overflow: "scroll",
      height: "100%",
      width: "80%",
      display: "block",
    },
  });

  const [showComponent, setShowComponent] = useState(false);

  function setShowComponentData() {
    setShowComponent(true);
  }

  function closemodel() {
    setShowComponent(false);
  }

  return (
    <>
      <div className="imageContainer">
        {
          <button onClick={setShowComponentData}>
            <ZoomOutMapIcon />
          </button>
        }

        <>
          {showComponent ? (
            <Modal className={styles.modalStyle1} open>
              <>
                <button
                  onClick={closemodel}
                  style={{ position: "absolute", top: 0, right: 0, zIndex: 1 }}
                >
                  <CloseIcon />
                </button>
                <img
                  style={{ height: "53rem", marginTop: "-3rem" }}
                  className="imgboxpic"
                  src={valueimg.s3}
                  id={valueimg.id}
                  alt="empty"
                />
              </>
            </Modal>
          ) : (
            ""
          )}
        </>
        <img
          className="imgboxpic"
          src={valueimg.s3}
          id={valueimg.id}
          alt="empty"
        />
      </div>
      <hr />

      <Grid
        container
        columns={{ xs: 12, md: 12 }}
        sx={{ py: "16px", gap: 1 }}
        className="buttonContainer"
      >
        {!config.DISABLEPRINTS.includes(products.eventId) && userIsdCode === '+91' && (
          <Grid
            xs={12}
            md={12}
            lg={12}
            sx={{ px: 2, py: "auto", textAlign: "center" }}
          >
            <Button
              // disableripple={false}
              onClick={() => {
                handleOpen(valueimg.s3, valueimg.id);
              }}
              btnText="PRINT"
              sx={ProductCardStyle.ModalBtn2}
              startIcon={<IconHelper.PrintIcon />}
              className="carouselButton1 w-auto"
            >
              Order print
              <span style={{ marginLeft: "0.3rem" }}></span>
            </Button>
          </Grid>
        )}

        {/* {!config.DISABLEPRINTS.includes(products.eventId) && (
          <Grid xs={12} md={6} sx={{ px: 2 }}>
            <Button
              // disableripple={false}
              onClick={() => {
                handleAddCollage(
                  valueimg.id,
                  valueimg.s3,
                  valueimg.image,
                  "collage"
                );
              }}
              btnText="PRINT"
              sx={{ ...ProductCardStyle.ModalBtn2 }}
              startIcon={<PhotoLibraryIcon />}
              className="carouselButton1"
            >
              {AddToCollageText}
              <span style={{ marginLeft: "0.3rem" }}></span>
            </Button>
          </Grid>
        )} */}
      </Grid>
      <hr style={{ borderTop: "1px solid #E5E7EB" }} />

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: "wrap",
          padding: "5px",
          fontSize: "15px",
          "& > *": {
            flex: "1 1 auto",
            minWidth: 0,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            "@media (max-width: 600px)": {
              fontSize: "10px",
              padding: "0 2px",
            },
          },
        }}
      >
        <Typography
          sx={{
            m: 0,
            fontFamily: FontHelper.fRegular,
            lineHeight: "1.2",
            display: "flex",
            alignItems: "center",
          }}
        >
          Photo ID: <strong style={{ color: "blue" }}>{valueimg.id}</strong>
        </Typography>
        <Typography
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            minWidth: "max-content",
            fontFamily: FontHelper.fRegular,
            lineHeight: "1.2",
            mt: "8px",
          }}
        >
          <img src={IconHelper.InstantDownloadCheck} alt="Check Icon" />
          &nbsp; Instant Download Available
        </Typography>
      </Box>

      <hr style={{ borderTop: "1px solid #E5E7EB" }} />
      <Grid
        container
        sx={{ backgroundColor: ColorHelper.LightGreen }}
        columns={{ xs: 12, md: 12 }}
      >
        <Grid xs={12} md={7}>
          <Typography
            className="leftpickpara"
            sx={{
              fontFamily: FontHelper.fRegular,
              lineHeight: "1.2 !important",
              fontSize: "12px !important",
              letterSpacing: 0,
              m: 0,
            }}
          >
            {products.priceInfo}
          </Typography>
        </Grid>
        <Grid xs={12} md={5} sx={{ p: "10px" }}>
          <Button
            // disableripple={false}
            onClick={() => {
              handleAddtoCart(
                valueimg.id,
                valueimg.s3,
                valueimg.image,
                undefined,
                "download",
                undefined
              );
            }}
            btnText="PRINT"
            sx={ProductCardStyle.ModalBtn3}
            startIcon={<AddShoppingCartIcon sx={{ color: "#fff" }} />}
          >
            {AddToCartText}
            <span style={{ marginLeft: "0.3rem" }}></span>
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
export default SingleCarousalProduct;
