import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState, useEffect } from "react";
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import TNCHeader from "../Components/TermsAndConditionsComps/TNCHeader";
import TNCOverview from "../Components/TermsAndConditionsComps/TNCOverview";
import ColorHelper from "../Helpers/ColorHelper";
import FontHelper from "../Helpers/FontHelper";

const TermsAndConditions = () => {
  useEffect(() => {
    document.title = "Terms and Conditions";
    window.scrollTo(0, 100);
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box>
      <Header />
      <Box sx={{ px: { xs: 2, md: 15 }, py: 5 }}>
        <TNCHeader />
        <TNCOverview />
      </Box>
      <Footer />
    </Box>
  );
};

export default TermsAndConditions;
