import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import CountryCodeData from "../../Helpers/CountryCodeData";

import {
  Grid,
  CardContent,
  CardActions,
  Button,
  FormControl,
  InputAdornment,
  Typography,
  Divider,
  Select,
  MenuItem,
} from "@mui/material";

import axios from "axios";

import { Formik, Form, Field } from "formik";
import { useLoginFormValidator } from "../Orderdetails/UserOrderDetails/hooks/useRegisterForm";
import * as Yup from "yup";
import { TextField } from "formik-material-ui";
import ConstantHelper from "../../Helpers/ConstantHelper";
import FilledButton from "../../Components/Ui-Components/Buttons/FilledButton/FilledButton";
import ColorHelper from "../../Helpers/ColorHelper";
import { AccountCircle } from "@mui/icons-material";
import ImageHelper from "../../Helpers/ImageHelper";
import { useNavigate } from "react-router-dom";
import FontHelper from "../../Helpers/FontHelper";
import IconHelper from "../../Helpers/IconHelper";
import BorderedButton from "../../Components/Ui-Components/Buttons/BorderedButton/BorderedButton";
import ReCAPTCHA from "react-google-recaptcha";
import RecaptchaConfigs from "../../Helpers/RecaptchaConfigs";
// import Snackbar from "@mui/material/Snackbar";
// import MuiAlert from "@mui/material/Alert";
// import FacebookLogin from 'react-facebook-login';
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";

import GoogleLogin from "react-google-login";
import { LoginSocialFacebook } from "reactjs-social-login";
import { FacebookLoginButton } from "react-social-login-buttons";
import { useGoogleLogin } from "@react-oauth/google";
import { ApiManager } from "../../ApiClient";
//Data
const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  primaryPhone: "",
  password: "",
  confirmPassword: "",
};



//Phone No. validation
const numericRegEx = /^\+?(91)?[789]\d{9}$|^\+?\d{2,3}\d{8,12}$/;
const lengthRegEx = /(?=.{8,})/;

//validation schema
let validationSchema = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  primaryPhone: Yup.string().matches(numericRegEx, 'Invalid phone number!').required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string()
    .matches(lengthRegEx, "Must be atleast 8 letters long!")
    .required("Required!"),
  confirmPassword: Yup.string()
    .matches(lengthRegEx, "Must be atleast 8 letters long!")
    .required("Required!")
    .test("passwords-match", "Passwords must match", function (value) {
      return this.parent.password === value;
    }),
});

const SignUpForm = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isdCode, setIsdCode] = useState("+91");
  const [filteredCountryCode, setFilteredCountrycode] = useState([]);
  const [openDropDown, setOpenDropDown] = useState(false);

  const userIsdCode = useSelector((state) => state.user.isdCode);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 80,
      },
    },
  };


  const { itsInTabs } = props;
  let navigate = useNavigate();
  const snack = useSnackbar();

  const [isVerified, setVerified] = useState(false);
  const handleOnChangeRecaptcha = () => {
    setVerified(true);
  };

  const headers = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(() => {
    setIsdCode(userIsdCode)
    document.title = "Sign Up";
    window.scrollTo(0, 100);
    window.scrollTo(0, 0);
  }, []);

  const toggleDropDown = () => {
    setOpenDropDown(true);
  }
  const handleFilter = (e) => {
    setIsdCode(e.target.value)
    const result = CountryCodeData.filter((ele) => (ele.code.includes(e.target.value) || ele.name.toLowerCase().includes(e.target.value.toLowerCase())));
    setFilteredCountrycode(result);
  }
  const handleClickOutside = (event) => {
    if (!event.target.closest(".dropdown")) {
      setOpenDropDown(false);
    }
  };
  const handleSelectIsdCode = (code) => {
    setIsdCode(code);
    setOpenDropDown(false);
  }

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // const Alert = React.forwardRef(function Alert(props, ref) {
  //   return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  // });

  const [userDetails, setUserDetails] = useState(null);
  const { errors, validateForm, onBlurField } = useLoginFormValidator(userDetails);

  const onSubmit = async (values, formicHelpers) => {
    setIsLoading(true);

    formicHelpers.resetForm();
    setUserDetails({
      firstName: values.firstName,
      lastName: values.lastName,
      primaryPhone: values.primaryPhone,
      email: values.email,
      password: values.password,
      isdCode: isdCode
    });
    await axios
      .post(
        ApiManager.userRegistration(),
        {
          newUserFlg: "1",
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          primaryPhone: values.primaryPhone,
          password: values.password,
          isdCode: isdCode
        },
        headers
      )
      .then((res) => {
        if (res.data.StatusCode === 200) {
          setIsLoading(false);

          if (res.data.HasError === true) {
            snack.enqueueSnackbar({
              variant: "warning",
              message: res.data.Message,
            });
          } else {
            snack.enqueueSnackbar({
              variant: "success",
              message: res.data.Message,
            });
            setTimeout(() => {
              localStorage.getItem("currPage") == "NotLoggedIn"
                ? window.location.reload()
                : navigate("/sign-in");
            }, 1000);
          }
        } else {
          setIsLoading(false);

          snack.enqueueSnackbar({
            variant: "warning",
            message: res.data.Message,
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);

        snack.enqueueSnackbar({
          variant: "warning",
          message: "Error",
        });
        // setOpenErrorSnackbar(true)
      });
  };

  // const [openSnackBar, setOpenSnackBar] = useState(false);
  // const [openErrorSnackBar, setOpenErrorSnackbar] = useState(false);

  const [user, setUser] = useState([]);
  const [profile, setProfile] = useState([]);
  const [googlelogindata, setGooglelogindata] = useState();
  const [cookies, setCookie] = useCookies(["user"]);

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => console.log("Login Failed:", error),
  });

  const Googlelogin = () => {
    setIsLoading(true);

    axios
      .get(
        `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
        {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        setProfile(res.data);
        if (res.status === 200) {
          setIsLoading(false);

          const formData = new FormData();
          formData.append("email", res.data.email);
          formData.append("login_type", 2);
          formData.append("tokenkey", res.data.id);
          formData.append("firstName", res.data.given_name);
          formData.append("lastName", res.data.family_name);

          axios
            .post(ApiManager.socialLogin(), formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((dataa) => {
              if (dataa.data.StatusCode === 200) {
                snack.enqueueSnackbar({
                  variant: "success",
                  message: "Registered Successfully !",
                });
                // alert("Registered Successfully !");
                // setOpenSnackBar(true);

                if (dataa.data.result) {
                  setGooglelogindata(dataa.data.result);
                }
                navigate("/");
                localStorage.setItem("ID", dataa.data.result.id);
                localStorage.setItem("Name", dataa.data.result.firstName);
                localStorage.setItem("isSignedIn", true);
                setCookie("Name", dataa.data.result.firstName, { path: "/" });
                setCookie("Email", dataa.data.result.email, { path: "/" });
                setCookie("ID", dataa.data.result.id, { path: "/" });
                setCookie("Cookies Consent", true, { path: "/" });
              }
            });
        } else {
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (user) {
      Googlelogin();
    } else {
      // alert("Login failed");

      snack.enqueueSnackbar({
        variant: "warning",
        message: "Login failed",
      });
    }
  }, [user]);
  const responseFacebook = (response) => {
    // console.log(response);
  };

  const responseGoogle = (response) => {
    // console.log(response);
  };

  return (
    <Box>
      <div style={{ display: isLoading ? "flex" : "none" }} className="modal">
        <div className="modal-content">
          <div className="loader"></div>
          <div className="modal-text">Processing...</div>
        </div>
      </div>

      <>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ dirty, isValid, values, handleChange, handleBlur }) => {
            return (
              <Form name="formName">
                <CardContent sx={{ pr: { xs: 0, md: "auto" } }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      // flexDirection: { xs: "column", lg: "row" },
                      my: 5,
                      width: "100%",
                      gap: 2,
                    }}
                  >
                    <h3
                      style={{
                        textAlign: "center",
                        fontSize: "24px",
                        fontFamily: "inherit",
                        fontWeight: "500",
                        lineHeight: "1:1",
                        color: "inherit",
                      }}
                    >
                      Signup with
                    </h3>

                    {/* <LoginSocialFacebook
                    
                    appId="176377896303154"
                    onResolve={(response) => {
                      console.log(response);
                    }}
                    onReject={(error) => {
                      console.log(error);
                    }}
                  >
                    <FacebookLoginButton text="Facebook" />
                  </LoginSocialFacebook> */}

                    <BorderedButton
                      handleClick={() => login()}
                      btnText={ConstantHelper.SignUp_Google}
                      color={ColorHelper.PrimaryColor}
                      bgColor={ColorHelper.WhiteColor}
                      bordercolor={ColorHelper.LightGrey3}
                      startIcon={
                        <img src={ImageHelper.GoogleIcon} alt="Google Icon" />
                      }
                      sx={{
                        width: "200px",
                        padding: "20px",
                        mx: 0,
                        px: 0,
                        height: "53px",
                        marginLeft: "0px",
                      }}
                    />
                  </Box>

                  <Typography
                    sx={{
                      fontFamily: FontHelper.fRegular,
                      fontSize: "16px",
                      color: ColorHelper.PrimaryColor,
                    }}
                  >
                    {ConstantHelper.useEmailToSignUpTitle}
                  </Typography>

                  <Grid item xs={12} sm={6} md={12} sx={{ my: 2 }}>
                    <Field
                      label="First Name"
                      variant="outlined"
                      fullWidth
                      name="firstName"
                      value={values.firstName}
                      component={TextField}
                      startAdornment={
                        <InputAdornment position="start">
                          <AccountCircle />
                        </InputAdornment>
                      }
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={12} sx={{ my: 2 }}>
                    <Field
                      label="Last Name"
                      variant="outlined"
                      fullWidth
                      name="lastName"
                      value={values.lastName}
                      component={TextField}
                      startAdornment={
                        <InputAdornment position="start">
                          <AccountCircle />
                        </InputAdornment>
                      }
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={12} sx={{ my: 2 }}>
                    <Field
                      label="Email"
                      variant="outlined"
                      fullWidth
                      name="email"
                      component={TextField}
                      value={values.email}
                      startAdornment={
                        <InputAdornment position="start">
                          <AccountCircle />
                        </InputAdornment>
                      }
                    />
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={2} sm={2} md={2} >
                      <div class="dropdown">
                        <Field
                          label="ISD code"
                          variant="outlined"
                          fullWidth
                          name="ISD"
                          value={isdCode}
                          onClick={() => toggleDropDown()}
                          onChange={(e) => handleFilter(e)}
                       
                          component={TextField}
                          startAdornment={
                            <InputAdornment position="start">
                              <AccountCircle />
                            </InputAdornment>
                          }
                        
                        />
                        {openDropDown &&
                          <div class="dropdown-content">
                            {
                              filteredCountryCode.length === 0 ?
                                CountryCodeData.map((ele) => {
                                  return (
                                    <div class="card" onClick={() => handleSelectIsdCode(ele.code)}>{ele.code} - {ele.name}</div>
                                  )
                                })
                                :
                                filteredCountryCode.map((ele) => {
                                  return (
                                    <div class="card" onClick={() => handleSelectIsdCode(ele.code)}>{ele.code} - {ele.name}</div>
                                  )
                                })
                            }
                          </div>}
                      </div>
                    </Grid>

                    <Grid item xs={10} sm={10} md={10}>
                      <Field
                        label="Phone Number"
                        variant="outlined"
                        fullWidth
                        name="primaryPhone"
                        value={values.primaryPhone}
                        component={TextField}
                        startAdornment={
                          <InputAdornment position="start">
                            <AccountCircle />
                          </InputAdornment>
                        }
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={6} md={12} sx={{ my: 2 }}>
                    <Field
                      label="Password"
                      variant="outlined"
                      fullWidth
                      name="password"
                      type="password"
                      value={values.password}
                      component={TextField}
                      startAdornment={
                        <InputAdornment position="start">
                          <AccountCircle />
                        </InputAdornment>
                      }
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={12} sx={{ my: 2 }}>
                    <Field
                      label="Confirm Password"
                      variant="outlined"
                      fullWidth
                      type="password"
                      name="confirmPassword"
                      value={values.confirmPassword}
                      component={TextField}
                      startAdornment={
                        <InputAdornment position="start">
                          <AccountCircle />
                        </InputAdornment>
                      }
                    />
                  </Grid>
                  <ReCAPTCHA
                    sitekey={RecaptchaConfigs.Sitekey}
                    onChange={handleOnChangeRecaptcha}
                  />
                </CardContent>
                <CardActions
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Button
                    disableripple={true}
                    // disabled={!dirty || !isValid}
                    sx={{ width: "100%" }}
                  >
                    <FilledButton
                      disabled={!isVerified}
                      type="Submit"
                      btnText={ConstantHelper.signUpBtn}
                      color={ColorHelper.WhiteColor}
                      bgColor={ColorHelper.PrimaryColor}
                      fullWidth={true}
                      sx={{ width: "100%", ml: 0 }}
                    />
                  </Button>

                  {itsInTabs == false ? (
                    <Box
                      onClick={() => navigate("/sign-in")}
                      sx={{
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        my: 3,
                      }}
                    >
                      <IconHelper.UserPlus />
                      <Typography
                        sx={{
                          fontFamily: FontHelper.fBold,
                          fontSize: "16px",
                          color: ColorHelper.PrimaryColor,
                          pl: 1,
                        }}
                      >
                        {ConstantHelper.alreadyHaveAccount}
                      </Typography>
                    </Box>
                  ) : null}
                </CardActions>
              </Form>
            );
          }}
        </Formik>
      </>
    </Box>
  );
};

export default SignUpForm;
