import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect } from "react";
import BillView from "../Components/BillView/BillView";
import CollageJourneyMapSection from "../Components/CartSections/CollageJourneyMapSection/CollageJourneyMapSection";
import DigitalDownloadsSection from "../Components/CartSections/DigitalDownloadsSection/DigitalDownloadsSection";
import PrintsSection from "../Components/CartSections/PrintsSection/PrintsSection";
import FooterNote from "../Components/FoooterNote/FooterNote";
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import HeaderNote from "../Components/HeaderNote/HeaderNote";
import SummarySection from "../Components/SummarySection/SummarySection";
import SectionTitle2 from "../Components/Ui-Components/SectionTitle2/SectionTitle2";
import ColorHelper from "../Helpers/ColorHelper";
import { useSelector } from "react-redux";
import ConstantHelper from "../Helpers/ConstantHelper";
import axios from "axios";
import { useState } from "react";
import { useDispatch } from "react-redux";
import OrderForm from "../Components/OrderForm/orderForm";
import { useLocation } from "react-router-dom";
import Orderdetails from "../Components/Orderdetails/orderdetails";

const OrderConfirmation = () => {
  const orderState = useLocation();
  useEffect(() => {
    window.scrollTo(0, 100);
    window.scrollTo(0, 0);
    localStorage.setItem("currPage", "Order Confirmation");
    localStorage.setItem("txnid", orderState.state.txnid);
    localStorage.setItem("email",orderState.state.email);
  }, []);
  return <Orderdetails orderState={orderState.state} />;
};

export default OrderConfirmation;
