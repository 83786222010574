import React from 'react'
import ColorHelper from '../../Helpers/ColorHelper'

const CameraIcon = () => {
    return (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_311_4230)">
                <path fillRule="evenodd" clipRule="evenodd" d="M12.0264 8C9.26494 8 7.02637 10.2386 7.02637 13C7.02637 15.7614 9.26494 18 12.0264 18C14.7878 18 17.0264 15.7614 17.0264 13C17.0264 10.2386 14.7878 8 12.0264 8ZM9.02637 13C9.02637 11.3431 10.3695 10 12.0264 10C13.6832 10 15.0264 11.3431 15.0264 13C15.0264 14.6569 13.6832 16 12.0264 16C10.3695 16 9.02637 14.6569 9.02637 13Z" fill={ColorHelper.WhiteColor} />
                <path fillRule="evenodd" clipRule="evenodd" d="M9.02637 2C8.69201 2 8.37978 2.1671 8.19432 2.4453L6.49118 5H3.02637C2.23072 5 1.46766 5.31607 0.905047 5.87868C0.342438 6.44129 0.0263672 7.20435 0.0263672 8V19C0.0263672 19.7957 0.342438 20.5587 0.905047 21.1213C1.46766 21.6839 2.23072 22 3.02637 22H21.0264C21.822 22 22.5851 21.6839 23.1477 21.1213C23.7103 20.5587 24.0264 19.7957 24.0264 19V8C24.0264 7.20435 23.7103 6.44129 23.1477 5.87868C22.5851 5.31607 21.822 5 21.0264 5H17.5616L15.8584 2.4453C15.673 2.1671 15.3607 2 15.0264 2H9.02637ZM7.85842 6.5547L9.56155 4H14.4912L16.1943 6.5547C16.3798 6.8329 16.692 7 17.0264 7H21.0264C21.2916 7 21.5459 7.10536 21.7335 7.29289C21.921 7.48043 22.0264 7.73478 22.0264 8V19C22.0264 19.2652 21.921 19.5196 21.7335 19.7071C21.5459 19.8946 21.2916 20 21.0264 20H3.02637C2.76115 20 2.5068 19.8946 2.31926 19.7071C2.13172 19.5196 2.02637 19.2652 2.02637 19V8C2.02637 7.73478 2.13172 7.48043 2.31926 7.29289C2.5068 7.10536 2.76115 7 3.02637 7H7.02637C7.36072 7 7.67295 6.8329 7.85842 6.5547Z" fill={ColorHelper.WhiteColor} />
            </g>
            <defs>
                <clipPath id="clip0_311_4230">
                    <rect width="24" height="24" fill="white" transform="translate(0.0263672)" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default CameraIcon