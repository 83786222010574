import { Box, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import FontHelper from "../../Helpers/FontHelper";
import ColorHelper from "../../Helpers/ColorHelper";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { addToCart, removeFromCart } from "../../redux/shoppingActions";
import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import ProductCardStyle from "./ProductCardStyle";

const PopUpPrint = ({ dataimg }) => {
  return (
    <Grid item xs={6} md={6} key={dataimg.id}>
      <Box className="cardmodalnew">
        <img
          className="cardimgboxonenew"
          src={dataimg.image}
          alt={dataimg.prod_id}
        />
        <Typography className="smallname" sx={{ marginLeft: "10px" }}>
          {dataimg.name}
        </Typography>
        <Box sx={{ display: "inline-flex", marginLeft: "10px" }}>
          <Typography className="pricename">
            Price:{dataimg.price}{" "}
            <span style={{ color: "#b91880" }}>{dataimg.pricewthgst}</span>
          </Typography>
          <Typography className="smallname">inc.GST</Typography>
        </Box>

        <Button
          // disableripple={false}
          onClick=""
          btnText="PRINT"
          sx={ProductCardStyle.ModalBtn1}
          startIcon={
            <i>
              <AddCircleIcon sx={{ mt: "10px " }} />{" "}
            </i>
          }
        >
          Add to Cart
        </Button>
      </Box>
    </Grid>
  );
};

export default PopUpPrint;
