import { Route, Routes } from "react-router";
import "./App.css";
import React,{Suspense} from "react";

import ForgotPassword from "./Pages/ForgotPassword";
import HomePage from "./Pages/HomePage";
import OrderHistory from "./Pages/OrderHistory";
import ProductPage from "./Pages/ProductPage";
import SignIn from "./Pages/SignIn";
import SignUp from "./Pages/SignUp";
import Faqs from "./Pages/Faqs";
import Contact from "./Pages/Contact";
import OrderDetailsMyOrder from "./Pages/OrderDetailsMyOrder";
import OrderDetailsPreAuth from "./Pages/OrderDetailsPreAuth";
import OrderConfirmation from "./Pages/OrderConfirmation";
import PreOrder from "./Pages/PreOrder";
import Error404 from "./Components/Errors/Error404";
import Error500 from "./Components/Errors/Error500";
import UnlabeledProductPage from "./Pages/UnlabeledProduct";
import UnlabeledCartPage from "./Pages/UnlabeledCartPage";
import EmptyCart from "./Components/CartSections/EmptyCart/EmptyCart";
import UserOrderdetails from "./Components/Orderdetails/UserOrderDetails/UserOrderdetails";
import TermsAndConditions from "./Pages/TermsAndConditions";
import OrderDetails from "./Pages/OrderDetails";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import ChangePassword from "./Pages/ChangePassword";
import OrderSucess from "./Pages/OrderSuccess";
import NotLoggedInCheckoutPage from "./Pages/NotLoggedInCheckoutPage";
import UltraPremiumPackageModal from "./Components/Modals/PackagesModal/UltraPremiumPackageModal";
import PremiumPackageModal from "./Components/Modals/PackagesModal/PremiumPackageModal";
import JourneyModal from "./Components/Modals/PackagesModal/JourneyModal";
import { SnackbarProvider } from "notistack";
import ResetPassword from "./Pages/ResetPassword";
import UnAuthorizedUser from "./Pages/UnAuthorizedUser";
import OrganizationDashboard from "../src/Pages/OrganizerDashboard";
import EventRegister from "./Components/SigninSignUpForms/EventRegistration";
import ShowRevenue from "./Pages/ShowRevenue";
import CartPage from "./Pages/CartPage";
// const CartPage = React.lazy(() => import("./Pages/CartPage"));

function App() {
  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      autoHideDuration={3000}
    >
      {/* <Provider store={store}> */}
      {/* <Suspense fallback={()=>{}}> */}
      <Routes>
        <Route
          path="/organization_dashboard"
          element={<OrganizationDashboard />}
        ></Route>
        <Route path="/registration" element={<EventRegister />}></Route>
        <Route path="/show_revenue" element={<ShowRevenue />}></Route>

        <Route path="/" element={<HomePage />}></Route>
        <Route path="/sign-in" element={<SignIn />}></Route>
        <Route path="/sign-up" element={<SignUp />}></Route>
        <Route path="/products" element={<ProductPage />}></Route>
        
        <Route path="/cart" element={<CartPage />}></Route>
        
        <Route path="/order-history" element={<OrderHistory />}></Route>
        <Route path="/forgot-password" element={<ForgotPassword />}></Route>
        <Route path="/reset-password" element={<ResetPassword />}></Route>
        <Route path="/change-password" element={<ChangePassword />}></Route>
        <Route path="*" element={<HomePage />}></Route>
        <Route path="/test" element={<UserOrderdetails />}></Route>
        <Route path="/faqs" element={<Faqs />}></Route>
        <Route path="/contact-us" element={<Contact />}></Route>
        <Route path="/order-confirm" element={<OrderConfirmation />}></Route>
        <Route path="/preorder" element={<PreOrder />}></Route>
        <Route path="/errorone" element={<Error404 />}></Route>
        <Route path="/errortwo" element={<Error500 />}></Route>
        <Route path="/searchbyselfie" element={<ProductPage />}></Route>
        <Route
          path="/order-confirmation"
          element={<OrderConfirmation />}
        ></Route>
        <Route path="/UserOrderdetails" element={<UserOrderdetails />}></Route>
        <Route path="/ec" element={<EmptyCart />}></Route>
        <Route
          path="/checkoutPage-authentication"
          element={<NotLoggedInCheckoutPage />}
        ></Route>
        <Route
          path="/terms-and-conditions"
          element={<TermsAndConditions />}
        ></Route>
        <Route
          path="/unlabeled-events"
          element={<UnlabeledProductPage />}
        ></Route>
        <Route path="/unlabeled-cart" element={<UnlabeledCartPage />}></Route>

        <Route path="/orderDetails" element={<OrderDetails />}></Route>
        <Route path="/myorders" element={<OrderDetailsMyOrder />}></Route>
        <Route
          path="/preauthdownload"
          element={<OrderDetailsPreAuth />}
        ></Route>

        <Route path="/privacypolicy" element={<PrivacyPolicy />}></Route>
        <Route path="/ordersuccess" element={<OrderSucess />}></Route>
        <Route
          path="/PremiumPackageModal"
          element={<PremiumPackageModal />}
        ></Route>
        <Route
          path="/UltraPremiumPackageModal"
          element={<UltraPremiumPackageModal />}
        ></Route>
        <Route path="/unauthorizeduser" element={<UnAuthorizedUser />}></Route>
        <Route path="/JourneyModal" element={<JourneyModal />}></Route>
      </Routes>
      {/* </Provider> */}
      {/* </Suspense> */}

    </SnackbarProvider>
  );
}

export default App;
