import {
  Box,
  Grid,
  CardContent,
  CardActions,
  Typography,
  Divider,
  InputAdornment,
} from "@mui/material";

import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "formik-material-ui";
import { AccountCircle } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import FilledButton from "../Components/Ui-Components/Buttons/FilledButton/FilledButton";
import ConstantHelper from "../Helpers/ConstantHelper";
import ColorHelper from "../Helpers/ColorHelper";
import FontHelper from "../Helpers/FontHelper";
import { ApiManager } from "../ApiClient";
import { useSnackbar } from "notistack";

const initialValues = {
  email: "",
};

const validationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
});

const ForgotPassword = () => {
  const snack = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    document.title = "Forgot Password";
  }, []);

  const navigate = useNavigate();

  const onSubmit = async (values, formikHelpers) => {
    try {
      setIsLoading(true);

      const response = await axios.post(ApiManager.getPasswordCode(), {
        email: values.email,
      });
      if (response.data.StatusCode === 200) {
        setIsLoading(false);

        snack.enqueueSnackbar({
          variant: "success",
          message: response.data.Message,
        });

        localStorage.setItem("passcode", response.data.result.passcode);
        navigate("/reset-password", { state: { email: values.email } });
      } else {
        setIsLoading(false);

        snack.enqueueSnackbar({
          variant: "warning",
          message: response.data.Message,
        });
      }
      formikHelpers.resetForm();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box>
      <div style={{ display: isLoading ? "flex" : "none" }} className="modal">
        <div className="modal-content">
          <div className="loader"></div>
          <div className="modal-text">Processing...</div>
        </div>
      </div>
      <>
        <Box>
          <Header />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                py: 5,
                minHeight: "60vh",
                flexDirection: "column",
                width: { xs: "100%", sm: "70%", md: "60%", lg: "40%" },
              }}
            >
              <Typography
                sx={{
                  fontFamily: FontHelper.fBold,
                  fontSize: "30px",
                  fontWeight: 700,
                  color: ColorHelper.DarkGrey2,
                  px: 2,
                }}
              >
                {ConstantHelper.ForgotPassword}
              </Typography>

              <Divider variant="middle" sx={{}} />
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({ dirty, isValid, values }) => (
                  <Form name="formName">
                    <CardContent>
                      <Typography
                        sx={{
                          fontFamily: FontHelper.fRegular,
                          fontSize: "16px",
                          color: ColorHelper.PrimaryColor,
                        }}
                      >
                        {ConstantHelper.ForgotPasswordTitle}
                      </Typography>

                      <Grid item xs={12} sm={6} md={12} sx={{ my: 2 }}>
                        <Field
                          label="Email"
                          variant="outlined"
                          fullWidth
                          name="email"
                          component={TextField}
                          startAdornment={
                            <InputAdornment position="start">
                              <AccountCircle />
                            </InputAdornment>
                          }
                        />
                      </Grid>
                    </CardContent>
                    <CardActions
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <FilledButton
                        btnText={ConstantHelper.submitBtn}
                        color={ColorHelper.WhiteColor}
                        bgColor={ColorHelper.PrimaryColor}
                        fullWidth={true}
                        type="submit"
                        disabled={!dirty || !isValid}
                      />
                    </CardActions>
                  </Form>
                )}
              </Formik>
            </Box>
          </Box>
          <Footer />
        </Box>
      </>
    </Box>
  );
};

export default ForgotPassword;
