import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import ColorHelper from "../../Helpers/ColorHelper";
import FontHelper from "../../Helpers/FontHelper";
import TNCHeaderStyle from "./TNCHeaderStyle";

const TNCHeader = () => {
  return (
    <Box sx={TNCHeaderStyle.SectionWrapper}>
      <Typography sx={TNCHeaderStyle.Title}>Terms & Conditions</Typography>
      <Typography sx={TNCHeaderStyle.Caption}>
        Welcome to oneglint.com, your partner for all your photography needs.
      </Typography>
    </Box>
  );
};

export default TNCHeader;
