import ImageHelper from "../../../../Helpers/ImageHelper";

export const modalSliderData = [
    {
      title: "Timed Image",
      discription1__1:
        "How about having the run time printed on the image? Your Image with race time may appear like this.",
      discription2:
        "These images will be available Organiser has signed an agreement time printed on the image available with Oneglint at the time Digital downloads.",
      image: ImageHelper.TimedImage,
    },
    {
      title: "Certificate Image",
      discription1:
        "Isn't it always exciting to have your photo on Participation Certificate? The participation certification with your photo (in action) will look like this and you get all selected photos in this format.",
      discription2:
        "The availability of Certificate images dependent on the run Organiser and Oneglint's agreement.",
      image: ImageHelper.CertificateImage,
    },
  ];