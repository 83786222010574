import React from "react";

const ReturnArrow = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.83398 4.66669V7.33335L1.83398 4.00002L5.83398 0.666687V3.33335H9.16732C10.5818 3.33335 11.9384 3.89526 12.9386 4.89545C13.9387 5.89565 14.5007 7.2522 14.5007 8.66669C14.5007 10.0812 13.9387 11.4377 12.9386 12.4379C11.9384 13.4381 10.5818 14 9.16732 14H3.16732V12.6667H9.16732C10.2282 12.6667 11.2456 12.2453 11.9957 11.4951C12.7459 10.745 13.1673 9.72755 13.1673 8.66669C13.1673 7.60582 12.7459 6.58841 11.9957 5.83826C11.2456 5.08811 10.2282 4.66669 9.16732 4.66669H5.83398Z"
        fill="white"
      />
    </svg>
  );
};

export default ReturnArrow;
