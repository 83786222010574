import {
  Box,
  Button,
  Card,
  CardActions,
  CardMedia,
  Typography,
} from "@mui/material";
import React from "react";
import ColorHelper from "../../../Helpers/ColorHelper";
import ConstantHelper from "../../../Helpers/ConstantHelper";
import FontHelper from "../../../Helpers/FontHelper";
import IconHelper from "../../../Helpers/IconHelper";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  removeFromCart,
  clearDigitalPhoto,
  removePrint,
  removeFromCartCollage,
  removeFromCartDigital,
} from "../../../redux/shoppingActions";
import CardCart from "./Card";
import { Padding } from "@mui/icons-material";

const CartCardUnlabeledComp = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    CartCardData,
    actionStyle,
    CartCardDataAddCard,
    CartCardDataFullPackage,
    digital,
    collage,
    collageCart,
    type,
    print,
    orderId,
    bibNumber,
  } = props;

  const cartInfo = useSelector((state) => state.shop.cartInfo);
  const products = useSelector((state) => state.shop.products.photo);

  const imageLength = cartInfo
    .map((data) => data.type === "download")
    .filter((value) => value === true).length;

  const HandleAddItemBtn = (url) => {
    type !== "unlabeled"
      ? navigate("/products")
      : navigate("/unlabeled-events");
  };

  const handlePlusBtn = (url) => {
    type !== "unlabeled"
      ? navigate("/products")
      : navigate("/unlabeled-events");
  };

  const HandleRemovePhoto = (url) => {
    dispatch(clearDigitalPhoto(cartInfo));
  };

  const handleDelete = (id, product, sequence) => {
    print == "print"
      ? dispatch(removePrint(id, cartInfo, product, sequence))
      : digital == "digital"
      ? dispatch(removeFromCartDigital(id, cartInfo, sequence))
      : dispatch(removeFromCartCollage(id, cartInfo, sequence));
    props.updateBill(cartInfo);
  };

  const downloadFile = (url) => {
    // var a = document.createElement("a");
    // window.location.href = url;
  };
  return (
    <Box
      sx={{
        pr: { xs: 0, sm: 0, md: 5, lg: 10 },
        columnGap: { xs: 0, sm: 3 },
        rowGap: { xs: 3, sm: 3 },
        display: "grid",
        gridTemplateColumns: {
          xs: "1fr",
          sm: "1fr 1fr",
          md: "1fr 1fr 1fr",
          lg: "1fr 1fr 1fr 1fr",
        },
      }}
    >
      {(CartCardData !== undefined || CartCardData !== null) &&
        CartCardData.map((item) => (
          <CardCart
            actionStyle={actionStyle}
            print={print}
            item={item}
            handleDelete={handleDelete}
            type={type}
            downloadFile={downloadFile}
            digitl={"digital"}
            orderId={orderId}
            bibNumber={bibNumber}
          />
        ))}

      {CartCardDataAddCard &&
        imageLength !== 0 &&
        type !== "orderDetails" &&
        props.print !== "print" && (
          <Card
            sx={{
              width: "100%",
              maxHeight: "100%",
              p: "20px",
              borderRadius: "5px 5px 0 0",
            }}
            onClick={() => HandleRemovePhoto()}
          >
            <Box
              sx={{
                borderRadius: "5px",
                maxWidth: "100%",
                aspectRatio: "1/1",
                maxHeight: "100%",
                objectFit: "cover",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                backgroundColor: ColorHelper.LightRedColor,
              }}
            >
              <Box
                onClick={() => HandleRemovePhoto()}
                sx={{
                  cursor: "pointer",
                  paddingTop: "25px",
                }}
              >
                <IconHelper.RemoveFromCart color={ColorHelper.RedColor} />
              </Box>
              <Typography
                sx={{
                  fontFamily: FontHelper.fBold,
                  fontSize: 18,
                  color: ColorHelper.BlackColor,
                  mt: 4,
                  textAlign: "center",
                }}
              >
                Clear All Photos
              </Typography>
            </Box>
            <CardActions sx={{ px: 0, py: 0 }}>
              <Box
                disableripple={true}
                onClick={() => HandleRemovePhoto()}
                sx={{
                  fontFamily: FontHelper.fBold,
                  fontSize: { xs: 14, lg: 18 },
                  textAlign: "center",
                  color: ColorHelper.WhiteColor,
                  backgroundColor: ColorHelper.RedColor,
                  width: "100%",
                  px: "15px",
                  py: "10px",
                  borderRadius: "5px",
                  marginLeft: "auto",
                  mt: 2,
                  mb: 0,
                  cursor: "pointer",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  "&:hover": {
                    backgroundColor: ColorHelper.RedColor,
                  },
                }}
              >
                {ConstantHelper.removeBtn}
              </Box>
            </CardActions>
          </Card>
        )}
      {CartCardDataAddCard && type !== "orderDetails" && (
        <Card
          sx={{
            width: "100%",
            maxHeight: "100%",
            p: "20px",
            borderRadius: "5px 5px 0 0",
          }}
          onClick={() => HandleAddItemBtn(CartCardDataAddCard.addMoreItems)}
        >
          <Box
            sx={{
              borderRadius: "5px",
              maxWidth: "100%",
              aspectRatio: "1/1",
              maxHeight: "100%",
              objectFit: "cover",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              backgroundColor: ColorHelper.LightGreen,
            }}
          >
            <Box
              onClick={() => handlePlusBtn(CartCardDataAddCard.addMore)}
              sx={{
                cursor: "pointer",
              }}
            >
              <IconHelper.PlusCirclular color={ColorHelper.GreenColor} />
            </Box>
            <Typography
              sx={{
                fontFamily: FontHelper.fBold,
                fontSize: 18,
                color: ColorHelper.BlackColor,
                mt: 4,
                textAlign: "center",
              }}
            >
              {CartCardDataAddCard.title}
            </Typography>
          </Box>
          <CardActions sx={{ px: 0, py: 0 }}>
            <Box
              disableripple={true}
              onClick={() => HandleAddItemBtn(CartCardDataAddCard.addMoreItems)}
              sx={{
                fontFamily: FontHelper.fBold,
                fontSize: { xs: 14, lg: 18 },
                textAlign: "center",
                color: ColorHelper.WhiteColor,
                backgroundColor: ColorHelper.GreenColor,
                width: "100%",
                px: "15px",
                py: "10px",
                borderRadius: "5px",
                marginLeft: "auto",
                mt: 2,
                mb: 0,
                cursor: "pointer",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                "&:hover": {
                  backgroundColor: ColorHelper.GreenColor,
                },
              }}
            >
              {ConstantHelper.AddMorePhotos}
            </Box>
          </CardActions>
        </Card>
      )}

      {CartCardDataAddCard &&
        type !== "orderDetails" &&
        type !== "unlabeled" &&
        props.print !== "print" && (
          <Card
            sx={{
              width: "100%",
              maxHeight: "100%",
              p: "20px",
              borderRadius: "5px 5px 0 0",
            }}
            onClick={() => HandleAddItemBtn(CartCardDataAddCard.addMoreItems)}
          >
            <Box
              sx={{
                borderRadius: "5px",
                maxWidth: "100%",
                aspectRatio: "1/1",
                maxHeight: "100%",
                objectFit: "cover",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                backgroundColor: ColorHelper.LightGreen,
              }}
            >
              <Box
                onClick={() => handlePlusBtn(CartCardDataAddCard.addMore)}
                sx={{
                  cursor: "pointer",
                }}
              >
                <IconHelper.PlusCirclular color={ColorHelper.WhiteColor} />
              </Box>
              <Typography
                sx={{
                  fontFamily: FontHelper.fBold,
                  fontSize: 18,
                  color: ColorHelper.BlackColor,
                  mt: 4,
                  textAlign: "center",
                }}
              >
                {ConstantHelper.AddFullPackageAtDiscountedRatesTitle}
              </Typography>
            </Box>
            <CardActions sx={{ px: 0, py: 0 }}>
              <Box
                disableripple={true}
                onClick={() =>
                  HandleAddItemBtn(CartCardDataAddCard.addMoreItems)
                }
                sx={{
                  fontFamily: FontHelper.fBold,
                  fontSize: { xs: 14, lg: 18 },
                  textAlign: "center",
                  color: ColorHelper.WhiteColor,
                  backgroundColor: ColorHelper.WhiteColor,
                  width: "100%",
                  px: "15px",
                  py: "10px",
                  borderRadius: "5px",
                  marginLeft: "auto",
                  mt: 2,
                  mb: 0,
                  cursor: "pointer",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  "&:hover": {
                    backgroundColor: ColorHelper.WhiteColor,
                  },
                }}
              >
                {ConstantHelper.AddFullPackageBtn}
              </Box>
            </CardActions>
          </Card>
        )}
    </Box>
  );
};

export default CartCardUnlabeledComp;
