// Helpers
import ColorHelper from "../../../Helpers/ColorHelper";

const modalBGcolor = {
  backgroundColor: ColorHelper.LightBlue,
};

const PreOrderDownloadModalStyle = {
  modalWrapper: {
    minHeight: "max-content",
    ...modalBGcolor,
  },
  modalHeader: {
    ...modalBGcolor,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    pl: 2,
    pt: 3,
    pb: 1,
    borderBottom: `1px solid ${ColorHelper.WhiteColor}`,
  },
  ModalTitle: {
    fontSize: "1.2rem",
    fontWeight: 600,
    color: ColorHelper.PrimaryColor,
    ml: "24px",
  },
  modalContent: {
    ...modalBGcolor,
    p: 2,
    pt: 0,
  },
  main: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minWidth: "fit-content",
    maxWidth: "20vw",
  },
  videoContainer: {
    width: { xs: "100%", md: "100%" },
  },
  moalFooter: {
    ...modalBGcolor,
    borderTop: `2px solid ${ColorHelper.lightGrey}`,
    py: 2,
  },
};

export default PreOrderDownloadModalStyle;
