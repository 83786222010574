import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ProductCardData from "./ProductCardData";
import {
  addToCart,
  getCartDetails,
  removeFromCart,
} from "../../redux/shoppingActions";
import { useDispatch } from "react-redux";
import ColorHelper from "../../Helpers/ColorHelper";
import FontHelper from "../../Helpers/FontHelper";
import IconHelper from "../../Helpers/IconHelper";
import ConstantHelper from "../../Helpers/ConstantHelper";
import { Box, Grid } from "@mui/material";
import FilledButton from "../Ui-Components/Buttons/FilledButton/FilledButton";
import { useSelector } from "react-redux";
import Modal from "@mui/material/Modal";
import { useState } from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import Masonry from "react-masonry-css";
import "./productstyle.css";
import ImageHelper from "../../Helpers/ImageHelper";
import { Pause, PlayCircle } from "@mui/icons-material";

export default function ProductCardVideo({ photo, stateDetails, cartDetails }) {
  const dispatch = useDispatch();
  const [AddToCartText, setAddtoCartText] = useState("Add To Cart");
  const [orderDetailsID, setorderDetailsID] = useState();
  const [imageAthlete, setImageAthlete] = useState();

  const cartInfo = useSelector((state) => state.shop.cartInfo);

  const videos = cartInfo?.filter((cart) => cart.category == "video");

  React.useEffect(() => {
    if (videos.length > 0) {
      setAddtoCartText("Added");
    } else {
      setAddtoCartText("Add To Cart");
    }
  }, []);

  const handleAddtoCart = (id, image, product, type, price) => {
    const cartItems = {
      id: id,
      image: image,
      category: "video", //video
      bibNumber: stateDetails.bibNumber,
      type: "download", //download
      videopath: image,
    };

    product == undefined &&
      price == undefined &&
      dispatch(addToCart(cartDetails, cartItems));
    setAddtoCartText("Added");

    if (AddToCartText == "Added") {
      dispatch(removeFromCart(id, cartDetails));
      setAddtoCartText("Add To Cart");
    }
  };
  const images = [
    {
      id: 0,
      download_url: ImageHelper.PrintsImage01,
      name: "Fridge Magnet(4x6 inches)",
      type: "Magnet",
      price: 739,
      gstPrice: "₹872",
    },
    {
      id: 1,
      download_url: ImageHelper.PrintsImage05,
      name: "Frame(10x10 inches)",
      type: "Frame",
      price: 319,
      gstPrice: "₹376",
    },
    {
      id: 2,
      download_url: ImageHelper.PrintsImage03,
      name: "Coffee Mugs",
      type: "Coffee Mug",
      price: 259,
      gstPrice: "₹306",
    },
    {
      id: 3,
      download_url: ImageHelper.PrintsImage04,
      name: "Wall Poster(13x10 inches)",
      type: "Marathon Poster",
      price: 350,
      gstPrice: "₹413",
    },
  ];

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "500px", md: "1000px" },
    marginTop: { xs: "200px", md: 0 },
    bgcolor: "background.paper",

    // border: "2px solid #000",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    borderRadius: "5px",
  };

  //order print modal
  const [open, setOpen] = useState(false);

  const handleOpen = (e) => {
    e.preventDefault();
    setOpen(true);
  };
  const handleClose = (e) => {
    e.preventDefault();
    setOpen(false);
  };

  const handleModalOpen = (id, image) => {
    setImageAthlete(image);
    setorderDetailsID(id);
    setOpen(true);
  };

  //on click picture modal
  const [click, setClick] = useState(false);
  const [play, setPlay] = useState(false);
  const vidRef = React.useRef(null);
  const handlePlayVideo = () => {
    vidRef.current?.play();
  };
  const handlePuaseVideo = () => {
    vidRef.current?.pause();
  };
  const handlePicOpen = (e) => {
    e.preventDefault();
    setClick(true);
  };
  const handlePicClose = (e) => {
    e.preventDefault();
    setClick(false);
  };

  return (
    <>
      {/* <Sample />

            <Masonry
                breakpointCols={breakpointColumnsObj}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column"
            >
                {items}
            </Masonry>

            <Masonry
                breakpointCols={breakpointColumnsObj}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column"
            >
                {photo.map((item) => (
                    <div key={item.id}>
                        <img src={item.s3} style={{ width: "100%", }} />
                    </div>
                ))}

            </Masonry> */}

      {/* order print modal  below*/}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        stateDetails={stateDetails}
      >
        <Box sx={{ ...style }}>
          <Box className="muimodalfirst">
            <Typography>
              BIB No:{" "}
              <span style={{ color: "blue" }}>{stateDetails.bibNumber}</span>
            </Typography>
            <Typography>{stateDetails.eventName}</Typography>
            <Button onClick={handleClose}>
              <i className="closeround">
                {" "}
                <CloseRoundedIcon fontSize="25px" />
              </i>
            </Button>
          </Box>
          <Grid container columns={{ xs: 12, md: 12 }}>
            {images.map((dataimg) => {
              return (
                <Grid item xs={6} md={3} key={dataimg.id}>
                  <Box className="cardmodal">
                    <img
                      className="cardimgboxone"
                      src={dataimg.download_url}
                      alt={dataimg.url}
                    />
                    <Typography
                      className="smallname"
                      sx={{ marginLeft: "10px", fontSize: "0.9rem" }}
                    >
                      {dataimg.name}
                    </Typography>
                    <Box sx={{ display: "inline-flex", marginLeft: "10px" }}>
                      <Typography className="pricename">
                        Price:{dataimg.price}{" "}
                        <span style={{ color: "#b91880" }}>
                          {dataimg.gstPrice}
                        </span>
                      </Typography>
                      <Typography className="smallname">inc.GST</Typography>
                    </Box>

                    <Button
                      // disableripple={false}
                      onClick={() => {
                        handleAddtoCart(
                          orderDetailsID,
                          imageAthlete,
                          dataimg.type,
                          "print",
                          dataimg.price
                        );
                      }}
                      btnText="PRINT"
                      sx={{
                        fontFamily: FontHelper.fBold,
                        fontSize: 12,
                        color: ColorHelper.WhiteColor,
                        backgroundColor: "#b91880",
                        px: "15px",
                        py: "10px",
                        height: 40,
                        borderRadius: 0,
                        textTransform: "capitalize",
                        width: "199px",
                        borderRadius: "3px",
                        marginRight: "5px",
                        mb: 0,
                        "&:hover": {
                          backgroundColor: ColorHelper.NavyBlue,
                        },
                      }}
                      startIcon={
                        <i>
                          <AddCircleIcon sx={{ mt: "10px " }} />{" "}
                        </i>
                      }
                    >
                      {AddToCartText}
                    </Button>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
          <Grid container spacing={2} className="buttonsmodal">
            <Grid xs={6}>
              <Button
                // disableripple={false}
                onClick={handleOpen}
                btnText="PRINT"
                sx={{
                  fontFamily: FontHelper.fBold,
                  fontSize: 12,
                  border: "1px solid black",
                  color: ColorHelper.BlackColor,
                  backgroundColor: ColorHelper.WhiteColor,
                  px: "20px",
                  py: "10px",
                  height: 40,
                  borderRadius: "3px",
                  textTransform: "capitalize",
                  width: "100%",
                  marginRight: "5px",

                  mb: 0,
                  "&:hover": {
                    backgroundColor: ColorHelper.NavyBlue,
                    color: ColorHelper.WhiteColor,
                  },
                }}
                startIcon={<AddShoppingCartIcon />}
              >
                Add all to cart
                <span style={{ marginLeft: "0.3rem" }}></span>
              </Button>
            </Grid>
            <Grid xs={6}>
              <Button
                // disableripple={false}
                // onClick={handleOpen}
                btnText="PRINT"
                sx={{
                  fontFamily: FontHelper.fBold,
                  fontSize: 12,
                  border: "1px solid black",
                  color: ColorHelper.WhiteColor,
                  backgroundColor: "#3F0E77",
                  px: "20px",
                  py: "10px",
                  height: 40,
                  borderRadius: "3px",
                  textTransform: "capitalize",
                  width: "100%",
                  mx: "10px",
                  marginRight: "30px",
                  mb: 0,
                  "&:hover": {
                    backgroundColor: ColorHelper.NavyBlue,
                  },
                }}
              >
                Proceed
                <span style={{ marginLeft: "0.3rem" }}></span>
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      {/* onpickclickmodal */}

      {/* <Modal
                open={click}
                onClose={handlePicClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={{ ...style, }}>
                    <Box className='muimodalfirst'>

                        <Typography>BIB No: <span style={{ color: 'blue' }}>{}</span></Typography>
                        <Typography>NMDC Hyderabad Marathan 2022</Typography>
                        <Button onClick={handleClose}><i className='closeround'>  <CloseRoundedIcon fontSize='25px' /></i></Button>
                    </Box>
                    <Grid container columns={{ xs: 12, md: 12 }} >
                    </Grid>
                </Box>

            </Modal> */}

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
          px: 2,
          pt: 2,
          pb: 10,
        }}
      >
        {photo.map((item) => (
          <Card sx={{ maxWidth: 412, minWidth: 412, p: 2, m: 2 }} key={item.id}>
            <CardMedia
              component="video"
              alt="oneglint"
              height="auto"
              src={item.s3}
              ref={vidRef}
              // pl
              sx={{ borderRadius: "3px", pointerEvents: "none" }}
            />
            <Box
              sx={{
                // backgroundColor: 'red',
                width: "full",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                py: "3px",
                pt: "4px",
              }}
            >
              {play ? (
                <div
                  onClick={() => {
                    setPlay(!play);
                    handlePuaseVideo();
                  }}
                >
                  <Pause htmlColor="orange" />
                </div>
              ) : (
                <div
                  onClick={() => {
                    setPlay(!play);
                    handlePlayVideo();
                  }}
                >
                  <PlayCircle htmlColor="green" />
                </div>
              )}
            </Box>
            <CardActions sx={{ px: 0 }}>
              <Box
                sx={{ width: "100%" }}
                onClick={() => {
                  handleAddtoCart(
                    item.id,
                    item.s3,
                    undefined,
                    "download",
                    undefined
                  );
                }}
              >
                <FilledButton
                  disableripple={true}
                  btnText={AddToCartText}
                  sx={{
                    fontFamily: FontHelper.fBold,
                    fontSize: 12,
                    color: ColorHelper.WhiteColor,
                    backgroundColor: ColorHelper.GreenColor,
                    px: "15px",
                    py: "10px",
                    height: 40,
                    width: "100%",
                    borderRadius: 0,
                    marginLeft: "auto",
                    mb: 0,
                    "&:hover": {
                      backgroundColor: ColorHelper.GreenColor,
                    },
                  }}
                  startIcon={<IconHelper.CartPlusIcon />}

                  // clickEvent={addToCartDetails(item.id,item.image,stateDetails.bibNumber)}
                >
                  <span style={{ marginLeft: "0.3rem" }}></span>
                </FilledButton>
              </Box>
              {/* <button onClick={()=>{handleAddtoCart(item.id,item.image)}}>
                            Add to Cart
                        </button> */}
              {/* <Button
                // disableripple={false}
                onClick={() => {
                  handleModalOpen(item.id, item.s3);
                }}
                btnText="PRINT"
                sx={{
                  fontFamily: FontHelper.fBold,
                  fontSize: 12,
                  color: ColorHelper.BlueColor,
                  backgroundColor: ColorHelper.LightPurple,
                  px: "15px",
                  py: "10px",
                  height: 40,
                  borderRadius: 0,
                  textTransform: "capitalize",
                  width: "100%",
                  marginLeft: "auto",
                  mb: 0,
                  "&:hover": {
                    backgroundColor: ColorHelper.LightPurple,
                  },
                }}
                endIcon={<IconHelper.PrintIcon />}
              >
                Order print
                <span style={{ marginLeft: "0.3rem" }}></span>
              </Button>
              <FilledButton
                disableripple={true}
                btnText={ConstantHelper.CollageBtn}
                sx={{
                  fontFamily: FontHelper.fBold,
                  fontSize: 12,
                  color: ColorHelper.BlueColor,
                  backgroundColor: ColorHelper.LightPurple,
                  px: "15px",
                  py: "10px",
                  width: "26%",
                  height: 40,
                  borderRadius: 0,
                  marginLeft: "auto",
                  mb: 0,
                  "&:hover": {
                    backgroundColor: ColorHelper.LightPurple,
                  },
                }}
              ></FilledButton> */}
            </CardActions>
          </Card>
        ))}
      </Box>
    </>
  );
}
