import * as React from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { ApiManager } from "../../ApiClient";
import Box from "@mui/material/Box";
import { Button, Typography } from "@mui/material";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import { Container } from "@mui/material";
import ImageHelper from "../../Helpers/ImageHelper";
import ColorHelper from "../../Helpers/ColorHelper";
import FontHelper from "../../Helpers/FontHelper";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import ConstantHelper from "../../Helpers/ConstantHelper";
import { useSnackbar } from "notistack";

const EventCard = ({
  image,
  eventName,
  date,
  type,
  eventId,
  // price,
  // cutPrice,
  preAllowed,
}) => {
  const snack = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const [openErrorSnackBar, setOpenErrorSnackbar] = useState(false);
  const [errorData, setErrorData] = useState("");
  const [show, setShow] = useState(false);

  useEffect(() => {
    const currentDate = new Date();

    // if (type == "upcoming") {
    let eventTodate = new Date(date);
    preAllowed == 1
      ? currentDate < eventTodate
        ? setShow(true)
        : setShow(false)
      : setShow(false);
    // }
  }, []);
  const handlePreorderCheck = async () => {
    setIsLoading(true);

    await axios
      .post(ApiManager.preOrder(), {
        eventId: eventId,
      })
      .then((res) => {
        const response = res.data;
        if (response.StatusCode == 200) {
          setIsLoading(false);

          let fromDate = response.result.eventslist.event_from_date;
          let toDate = response.result.eventslist.event_to_date;
          let price = response.result.preorderprice.price;
          let cutPrice = response.result.preorderprice.cut_price;

          navigate(`/preorder?eventId=${eventId}`, {
            state: {
              image,
              eventName,
              date,
              fromDate,
              toDate,
              eventId,
              price,
              cutPrice,
            },
          });
        } else {
          setIsLoading(false);

          snack.enqueueSnackbar({
            variant: "error",
            message: response.data.Message,
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);

        console.log(err);
      });
  };

  return (
    // <Box>
    //   <div style={{ display: isLoading ? "flex" : "none" }} className="modal">
    //     <div className="modal-content">
    //       <div className="loader"></div>
    //       <div className="modal-text">Processing...</div>
    //     </div>
    //   </div>
    <>
      <Box
        sx={{
          p: "24px",
          display: "flex",
          flexWrap: "wrap",
          backgroundColor: "#FFFFFF",
          justifyItems: "center",
          borderRadius: "5px",
          width: { xs: "100%", sm: "48%", md: "30%", lg: "22.5%" },
          maxWidth: "100%",
          border: "1px solid #E4E7EC",
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: "row",
            mb: 1,
          }}
        >
          <Grid
            item
            xs={3}
            sm={3}
            md={3}
            lg={3.5}
            style={{ paddingTop: "35px" }}
          >
            <Box sx={{ width: "100%", height: "auto" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "22px",
                }}
              >
                <img
                  src={image}
                  style={{ width: "100%" }}
                  alt={eventName + ConstantHelper.ImageALT}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={9} sm={9} md={9} lg={8.5}>
            <Typography
              sx={{
                fontFamily: FontHelper.fRegular,
                color: ColorHelper.BlackColor,
                fontSize: 16,
                lineHeight: "19px",
              }}
            >
              <span style={{ fontWeight: 400, fontFamily: FontHelper.fBold }}>
                {eventName}
              </span>
              <br />
              <span
                style={{
                  fontSize: 16,
                  fontWeight: 400,
                  fontFamily: FontHelper.fRegular,
                  marginBottom: "10px",
                }}
              >
                {date}
              </span>
            </Typography>
          </Grid>
        </Grid>
        {type == "upcoming" && show ? (
          <Box
            sx={{
              display: "flex",
              borderRadius: "6px",
              alignItems: "center",
              justifyContent: "center",
              margin: "auto",
              backgroundColor: ColorHelper.PrimaryColor,
              width: "-webkit-fill-available",
            }}
          >
            <Button
              variant="outlined"
              style={{
                color: "white",
                border: ColorHelper.PrimaryColor,
                fontSize: 12,
                fontWeight: 400,
                fontFamily: FontHelper.fRegular,
              }}
              onClick={() => {
                //navigate("/preorder", { state: { image, eventName, date , eventId} });
                handlePreorderCheck();
              }}
            >
              {/* <AddIcon /> */}
              Pre Order
            </Button>
          </Box>
        ) : null}
      </Box>
    </>
    // </Box>
  );
};

export default EventCard;
