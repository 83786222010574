// Helper

import ColorHelper from "../../Helpers/ColorHelper";

const CustomNavbarStyle = {
  AppBarStyle: {
    bgcolor: ColorHelper.PrimaryColor,
    px: { xs: 3, md: 0, lg: 10 },
    color: ColorHelper.WhiteColor,
  },
  MainMenu: {
    liOfMenu: {
      width: { xs: "100vw", md: "unset" },
      justifyContent: "center",
      mr: 1,
      backgroundColor: "#dc0053",
      "&: hover": {
        backgroundColor: "#dc0053",
        color: "#fff",
      },
      "&: focusVisible": {
        backgroundColor: "#dc0053",
        color: "#fff",
      },
    },
  },
  liOfMenu: {
    fill: "white",
    "&: hover": {
      backgroundColor: "transparent",
    },
  },
  MainMenuText: {
    color: ColorHelper.WhiteColor,
    mr: 0.8,
  },
  MenuItem: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    my: 1,
    p: 3,
  },
  SubMenuText: {
    ml: 1,
  },
  subMenuTitle: {
    fontSize: 14,
    fontWeight: 800,
    color: ColorHelper.WhiteColor,
    mb: 0.5,
  },
  subMenuCaption: {
    fontSize: 13,
    fontWeight: 500,
    color: ColorHelper.WhiteColor,
  },
  logoContainer: {
    minWidth: { xs: "0.5rem", md: "6rem" },
    cursor: "pointer",
    my: { xs: 2, md: "19.5px" },
    img: {
      maxWidth: { xs: "100px", md: "250px" },
      transform: "translateY(5px)",
    },
  },
};

export default CustomNavbarStyle;
