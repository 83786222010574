import ColorHelper from "../../../Helpers/ColorHelper";
import FontHelper from "../../../Helpers/FontHelper";

const ListOfItemsInBillStyle = {
    SectionWrapper: {
        py: 2,
        px: 5,
        width: "100%",
    },
    ListItem: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'column',
    },
    List: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: "100%",
        py: 1,
    },
    NameQtyWrapper: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    name: {
        fontFamily: FontHelper.fMedium,
        fontSize: 16,
        color: ColorHelper.DarkGrey4,
    },
    qty: {
        fontFamily: FontHelper.fBold,
        fontSize: 16,
        color: ColorHelper.BlueColor,
        pl: 0.5,
    },
    amount: {
        fontFamily: FontHelper.fBold,
        fontSize: 16,
        color: ColorHelper.DarkGrey4,
    }
}

export default ListOfItemsInBillStyle;