import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import PreorderStyle from "../../PreOrder/Preorderstyle";
import orderStyle from "../orderdetailsstyle";
import { useLocation } from "react-router-dom";
import Lottie from "react-lottie";
import PaymentGateway from "../../../Assets/Animation/PaymentGateway.json";
import { Grid } from "@mui/material";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import UserOrderForm from "./UserOrderForm";
import IconHelper from "../../../Helpers/IconHelper";
import { useNavigate } from "react-router-dom";
import { packageDetails } from "../../../redux/shoppingActions";
import { useSelector } from "react-redux";
const UserOrderdetails = ({ orderState }) => {
  const { state } = useLocation();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: PaymentGateway,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const theme = useTheme();
  const onMobile = useMediaQuery(theme.breakpoints.up("sm"));
  const navigate = useNavigate();

  return (
    <div>
      <Header />
      <Box sx={PreorderStyle.Sectionmain}>
        <Box
          onClick={() => navigate(-1)}
          style={{ display: "flex", alignItems: "center" }}
        >
          <IconHelper.LeftArrow />{" "}
          <Typography
            sx={PreorderStyle.SectionHead}
            style={{ marginLeft: "10px" }}
          >
            Order Checkout
          </Typography>
        </Box>

        <hr />
      </Box>
      <Grid sx={orderStyle.mainbx} container>
        <Grid sx={12} md={6}>
          <Lottie
            options={defaultOptions}
            height={onMobile == true ? 500 : 200}
            width={onMobile == true ? 500 : 200}
          />
        </Grid>
        <Grid sx={12} md={6}>
          <UserOrderForm
            selectedValue={state.selectionType}
            price={state.price}
            printInstruction={state.printInstruction}
          />
        </Grid>
      </Grid>
      <Footer />
    </div>
  );
};

export default UserOrderdetails;
