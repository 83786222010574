const NotesData = [
    {
        id: 0,
        note: "Digital copies can be downloaded immediately after order booking",
    },
    {
        id: 1,
        note: "Photo paper prints will NOT be delivered with this order",
    },
    {
        id: 2,
        note: "Article Prints (if opted) will be delivered with in 2 weeks from the order date.",
    },
    {
        id: 3,
        note: "Article Prices are inclusive of shipping charges.",
    },
];

export default NotesData;