import ImageHelper from "../../../../Helpers/ImageHelper";

export const SliderData = [
    {
      image:ImageHelper.Cart1
    },
    {
      image:ImageHelper.Cart2
    },
    {
      image:ImageHelper.Cart3
    },
  ];
  