const EVENT = {
  HOME_PAGE: "homePage",
  PRE_ORDER: "preorder",
  GENERATE_PREORDER_LINK: "generatePreorderLink",
  GET_EVENT_NAME: "getEventName",
  GET_MULTI_EVENT_LIST: "getMultiEventList",
  CART_DETAILS: "cartDetails",
  CREATE_ORDER: "createOrder",
  ORDER_SUCCESS: "orderSuccess",
  ORDER_DETAIL: "orderDetail",
  ORDER_HISTORY: "orderHistory",
  DOWNLOAD_ORDER_ZIP: "downloadOrderZip",
  RECORD_FEEDBACK_ON_IMAGE: "recordFeedbackOnImage",
  GET_EVENT_THEME_DETAILS: "getEventThemeDetails",
  SEARCH_BY_SELFIE: "searchBySelfie",
  SEARCH_UNLABELED_EVENT: "searchUnlabeledEvent",
  SEARCH_EVENT_BY_BIB: "searchEventByBIB",
  UNLABELED_CART_DETAILS: "unlabledCartDetails",
  MASTER_CONFIGURATION: "masterConfiguration",
  MY_ORDER: "myorders",
  PRE_AUTH_DOWNLOAD: "preauthdownload",
  DOWNLOAD_IMAGE: "downloadImage",
  ORGN_DASHBOARD: "orgnDashboard",
  UNLABELED: "unlabeled",
  UNLABELED_CART: "unlabeled-cart",
  CART: "cart",
  REGISTER_PARTNER: "registerPartner",
  PARTNER_SHARE: "partnerShare",
  VERIFY_OTP:"verifyOTP",
  GENERATE_OTP:"generateOTP",
  GET_EVENT_DETAILS:"getEventDetails"
};

const USER = {
  LOGIN: "login",
  SOCIAL_LOGIN: "socialLogin",
  USER_REGISTRATION: "userRegistration",
  CHANGE_PASSW0RD: "changePassword",
  GET_PASSWORD_CODE: "getPasswordCode",
  PASSWORD_RESET: "passwordReset",
  CHECKOUT_USER_INFO: "checkoutUserInfo",
  CONTACT_FORM_SUBMIT: "contactFormSubmit",
};

module.exports = {
  EVENT,
  USER,
};
