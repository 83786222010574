import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CookieData from "./CookieData";
import { Modal } from "@mui/material";
import { useState } from "react";
import FontHelper from "../../Helpers/FontHelper";
import { useCookies } from "react-cookie";
import ColorHelper from "../../Helpers/ColorHelper";

const Cookies = ({ open, close }) => {
  const [cookies, setCookie] = useState(["user"]);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    width: "80%",
    backgroundColor: "rgb(255, 255, 255)",
    padding: "6%",
    margin: "0%",
    borderRadius: "0.5rem",
  };
  const handleCookies = () => {
    localStorage.setItem("Cookies", true);

    setCookie("Cookies Consent", "true", { path: "/" });
    close();
  };

  return (
    <div>
      <Modal open={open} onClose={close}>
        <Box sx={style}>
          <Typography
            variant="h5"
            component="h2"
            sx={{ fontFamily: FontHelper.fBold }}
          >
            {CookieData.heading}
          </Typography>
          <Typography variant="body" sx={{ fontFamily: FontHelper.fRegular }}>
            {CookieData.title}
            <span style={{ cursor: "pointer", color: "#3F0E77" }}>
              {CookieData.privacyPolicy}
            </span>
          </Typography>

          <Box sx={{ py: 2, textAlign: "center" }}>
            <Button
              sx={{
                fontSize: { xs: 12, md: 14 },
                width: { xs: "auto", sm: "50%" },
                borderRadius: "0px",
                mr: { xs: 2, sm: 0 },
                backgroundColor: ColorHelper.LightGrey3,
                color: ColorHelper.BlackColor,
                fontFamily: FontHelper.fRegular,
                "&:hover": {
                  backgroundColor: ColorHelper.LightGrey1,
                },
              }}
              onClick={handleCookies}
              variant="contained"
            >
              Cancel
            </Button>
            <Button
              sx={{
                fontSize: { xs: 12, md: 14 },
                width: { xs: "max-width", sm: "50%" },
                borderRadius: "0px",
                backgroundColor: "#3F0E77",
                fontFamily: FontHelper.fRegular,
                "&:hover": {
                  backgroundColor: ColorHelper.LightPurple,
                },
              }}
              onClick={handleCookies}
              variant="contained"
            >
              Accept Cookies
            </Button>
          </Box>
          <Typography variant="h6" sx={{ fontFamily: FontHelper.fRegular }}>
            {CookieData.functionalCookiesTitle}
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2, fontFamily: FontHelper.fRegular }}
            variant="body"
          >
            {CookieData.functionalCookies}
          </Typography>

          <Typography
            variant="h6"
            sx={{ fontFamily: FontHelper.fRegular, pt: 2 }}
          >
            {CookieData.analyticalCookiesTitle}
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2, fontFamily: FontHelper.fRegular }}
            variant="body"
          >
            {CookieData.analyticalCookies}
          </Typography>

          <Typography
            variant="h6"
            sx={{ fontFamily: FontHelper.fRegular, pt: 2 }}
          >
            {CookieData.marketingCookiesTitle}
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2, fontFamily: FontHelper.fRegular }}
            variant="body"
          >
            {CookieData.marketingCookies}
          </Typography>
          <br />
        </Box>
      </Modal>
    </div>
  );
};

export default Cookies;
