import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, TextField, Button } from "@mui/material";
import ColorHelper from "../../../Helpers/ColorHelper";
import { Modal } from "@mui/material";
import Drawer from 'react-bottom-drawer'
import { ApiManager } from "../../../ApiClient";
import axios from "axios";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import * as actionTypes from "../../../redux/shoppingTypes"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from "react-router-dom";
function PrivacyPolicyModal({ privacyPolicy, productInfo }) {
    const snack = useSnackbar();
    const navigate = useNavigate();
    const dispatch = useDispatch();;
    const session_token = localStorage.getItem("session_token");

    const style = {
        // position: "absolute",
        // top: { xs: "70%", xl: "30%" },
        // left: "50%",
        // transform: "translate(-50%, -50%)",
        // width: { xs: "95%", sm: "75%", lg: "725px" },
        // height: { xs: "450px", lg: "300px" },
        bgcolor: "background.paper",
        // boxShadow: 24,
        // p: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        px: { xs: 2, md: "24px" },
        py: "4px",
        gap: "5px",
        borderRadius: "5px",
        flex: "none",
        order: "0",
        alignSelf: "stretch",
        flexGrow: "0",
    };
    const [demo, setDemo] = useState(true);
    const [second, setSecond] = useState(30)
    const [isVisible, setIsVisible] = useState(false)
    const [otp, setOtp] = useState("")
    const [openSponorModal, setOpenSponsorModal] = useState(false);
    const userInfo = useSelector((state) => state.user.user)
    // console.log("userInfo", userInfo.id)
    const handleVerifyOTP = async () => {
        const response = await axios.post(ApiManager.verifyOtp(), {
            eventName: productInfo.eventName,
            bibNumber: productInfo.bibNumber,
            eventID: productInfo.eventId,
            userID: userInfo.id,
            otp: otp,
            sessionToken: session_token || ""

        })

        if (response.data.StatusCode === 200) {
            snack.enqueueSnackbar({
                variant: "success",
                message: "OTP Verified Successfully",
            });
            dispatch({
                type: actionTypes.FETCH_PRODUCTS,
                payload: response.data.result,
            });
        } else if (response.data.StatusCode === 400) {
            snack.enqueueSnackbar({
                variant: "warning",
                message: response.data.Message,
            });
        }
    }
    const handleResetOTP = async () => {

        const response = await axios.post(ApiManager.generateOtp(), {
            eventName: productInfo.eventName,
            bibNumber: productInfo.bibNumber,
            eventID: productInfo.eventId,
            userID: userInfo.id,
            sessionToken: session_token || ""
        })


        if (response.data.StatusCode === 200) {
            setSecond(30);
            setOtp("")
            snack.enqueueSnackbar({
                variant: "success",
                message: response.data.Message,
            });
        } else if (response.data.StatusCode === 400) {
            snack.enqueueSnackbar({
                variant: "warning",
                message: response.data.Message,
            });
        }



    }
    const handleClose = () => {
        if (demo == false) {
            setOpenSponsorModal(false);
        }
    };
    const handleOtpInput = (value) => {
        if (/^\d+$/.test(value) && value.length < 7) {
            setOtp(value)
        }
    }
    useEffect(() => {
        // console.log("isVerified", productInfo?.isVerified)
        if (productInfo?.isVerified !== true && productInfo?.isPrivacyCheckEnabled === true) {
            setIsVisible(true);
        } else {
            setIsVisible(false)
        }
    }, [productInfo])
    useEffect(() => {
        if (second > 0) {
            setTimeout(() => {
                setSecond(second - 1);
            }, 1000);
        }

    }, [second])
    const closeDrawer = () => {
        setIsVisible(true)
    }
    return (
        // <Modal
        //     open={openSponorModal}
        //     onClose={() => { return true }}

        //     aria-labelledby="modal-modal-title"
        //     aria-describedby="modal-modal-description"
        // >
        //     <Box >
        //         <Grid sx={style} container spacing={1} >
        //             <Grid item xs={12} sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        //                 <Typography sx={{ my: "5px" }}>We ask for this additional step of OTP verification to protect your data.</Typography>
        //                 <Typography>An otp has been sent to {privacyPolicy?.email1}</Typography>
        //             </Grid>
        //             <Grid item xs={12} >

        //                 <TextField id="outlined-basic" label="Enter otp" variant="outlined" type="text"
        //                     onChange={(e) => handleOtpInput(e.target.value)} />
        //                 <Box sx={{ display: "flex", justifyContent: "end" }}>
        //                     <Button sx={{
        //                         border: "none",
        //                         textUnderlinePosition: "under",
        //                         "&:hover": { 
        //                            bgcolor:"none",

        //                         }
        //                     }} 
        //                     disabled={second !== 0}
        //                     onClick={handleVerifyOTP}
        //                     >Resend OTP</Button>
        //                     <Typography sx={{ marginTop: "6px", color: "red" }}>00:{second > 9 ? second : "0" + second}</Typography>
        //                 </Box>
        //             </Grid><Grid item xs={12} >

        //                 <Button variant="contained" color="success">
        //                     Verify
        //                 </Button>
        //             </Grid>

        //         </Grid>
        //     </Box>
        // </Modal>
        <Drawer
            duration={350}
            hideScrollbars={true}
            onClose={closeDrawer}
            isVisible={isVisible}
            className="drawer"
        >{
                productInfo?.isVerified !== true &&
                <Box>
                    <Button sx={{ 
                        minWidth: "20px",
                        ":hover":{color:"rgb(63, 14, 119)"}
                        }} onClick={() => navigate("/")}>
                        <ArrowBackIcon />
                        <span style={{ marginLeft: "5px" }}>Back to Home</span>
                    </Button>
                    <Grid sx={style} container >
                        <Grid item xs={12} sx={{ display: "flex", flexDirection: { lg: "column" }, alignItems: "center" }}>
                            <Typography sx={{ mb: "15px", fontSize: { xs: "14px" } }} >We ask for this additional step of OTP verification to protect your data. An otp has been sent to {privacyPolicy?.email1}</Typography>
                            {/* <Typography></Typography> */}
                        </Grid>
                        <Grid item xs={12} >

                            <TextField id="outlined-basic" label="Enter Otp" variant="outlined" type="text"
                                onChange={(e) => handleOtpInput(e.target.value)}
                                value={otp}
                            />
                            <Box sx={{ display: "flex", justifyContent: "end" }}>
                                <Button sx={{
                                    border: "none",
                                    textUnderlinePosition: "under",
                                    "&:hover": {
                                        bgcolor: "none",

                                    }
                                }}
                                    disabled={second !== 0}
                                    onClick={handleResetOTP}
                                >Resend OTP</Button>
                                <Typography sx={{ marginTop: "6px", color: "red" }}>00:{second > 9 ? second : "0" + second}</Typography>
                            </Box>
                        </Grid><Grid item xs={12} >
                            <Button variant="contained" color="success" sx={{ mb: "10px" }} onClick={handleVerifyOTP}>
                                Verify
                            </Button>
                        </Grid>

                    </Grid>
                </Box>
            }

        </Drawer>
    )
}

export default PrivacyPolicyModal