import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import "./signup.css";

import {
  Grid,
  CardContent,
  CardActions,
  Button,
  FormControl,
  InputAdornment,
  Typography,
  Divider,
} from "@mui/material";

import axios from "axios";
import * as Yup from "yup";
import ConstantHelper from "../Helpers/ConstantHelper";
import ColorHelper from "../Helpers/ColorHelper";
import ImageHelper from "../Helpers/ImageHelper";
import { useNavigate } from "react-router-dom";
import FontHelper from "../Helpers/FontHelper";
import { useSnackbar } from "notistack";

import SignUpForm from "../Components/SigninSignUpForms/SignUpForm";
import { ApiManager } from "../ApiClient";

//Data
const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  primaryPhone: "",
  password: "",
  confirmPassword: "",
};

//Phone No. validation
const numericRegEx = /^[+?(91)?[789]\d{9}$|^\+?\d{2,3}\d{8,12}$/;
const lengthRegEx = /(?=.{8,})/;

//validation schema
let validationSchema = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  primaryPhone: Yup.number().required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string()
    .matches(lengthRegEx, "Must be atleast 8 letters long!")
    .required("Required!"),
  confirmPassword: Yup.string()
    .matches(lengthRegEx, "Must be atleast 8 letters long!")
    .required("Required!")
    .test("passwords-match", "Passwords must match", function (value) {
      return this.parent.password === value;
    }),
});

const SignUp = () => {
  let navigate = useNavigate();
  const snack = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const headers = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(() => {
    document.title = "Sign Up";
    window.scrollTo(0, 100);
    window.scrollTo(0, 0);
  }, []);

  const onSubmit = async (values, formicHelpers) => {
    formicHelpers.resetForm();
    setIsLoading(true);

    await axios
      .post(
        ApiManager.userRegistration(),
        {
          newUserFlg: "1",
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          primaryPhone: values.primaryPhone,
          password: values.password,
        },
        headers
      )
      .then((res) => {
        if (res.status === 200) {
          setIsLoading(false);

          if (res.HasError === true) {
            snack.enqueueSnackbar({
              variant: "success",
              message: res.Message,
            });
          } else {
            snack.enqueueSnackbar({
              variant: "warning",
              message: res.Message,
            });
            setTimeout(() => {
              navigate("/sign-in");
            }, 1000);
          }
        } else {
          setIsLoading(false);
        }
      })
      .catch((err) => console.log("Error", err));
  };

  return (
    <Box>
      <div style={{ display: isLoading ? "flex" : "none" }} className="modal">
        <div className="modal-content">
          <div className="loader"></div>
          <div className="modal-text">Processing...</div>
        </div>
      </div>
      <>
        <Box id="signupPage">
          <Header />

          <Box sx={{ minHeight: "60vh" }}>
            <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
              <Box gridColumn="span 6">
                <img
                  src={ImageHelper.ProductPageImage13}
                  style={{ width: "85%", height: "100%", objectFit: "cover" }}
                />
              </Box>
              <Box gridColumn="span 6" marginTop="68px" marginRight="25%">
                <Typography
                  sx={{
                    fontFamily: FontHelper.fBold,
                    fontSize: "30px",
                    fontWeight: 700,
                    color: ColorHelper.DarkGrey2,
                    px: 2,
                  }}
                >
                  {ConstantHelper.signUpBtn}
                </Typography>

                <Divider variant="middle" sx={{}} />
                <SignUpForm itsInTabs={false} />
              </Box>
            </Box>
          </Box>

          <Footer />
        </Box>
      </>
    </Box>
  );
};

export default SignUp;
