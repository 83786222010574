const CountryCodeData = [
  {
    code: "+1",
    name: "Canada"
  },
  {
    code: "+1",
    name: "United States"
  },
  {
    code: "+7",
    name: "Russia"
  },
  {
    code: "+20",
    name: "Egypt"
  },
  {
    code: "+27",
    name: "South Africa"
  },
  {
    code: "+30",
    name: "Greece"
  },
  {
    code: "+31",
    name: "Netherlands"
  },
  {
    code: "+32",
    name: "Belgium"
  },
  {
    code: "+33",
    name: "France"
  },
  {
    code: "+34",
    name: "Spain"
  },
  {
    code: "+36",
    name: "Hungary"
  },
  {
    code: "+39",
    name: "Italy"
  },
  {
    code: "+40",
    name: "Romania"
  },
  {
    code: "+41",
    name: "Switzerland"
  },
  {
    code: "+43",
    name: "Austria"
  },
  {
    code: "+44",
    name: "United Kingdom"
  },
  {
    code: "+45",
    name: "Denmark"
  },
  {
    code: "+46",
    name: "Sweden"
  },
  {
    code: "+47",
    name: "Norway"
  },
  {
    code: "+48",
    name: "Poland"
  },
  {
    code: "+49",
    name: "Germany"
  },
  {
    code: "+51",
    name: "Peru"
  },
  {
    code: "+52",
    name: "Mexico"
  },
  {
    code: "+53",
    name: "Cuba"
  },
  {
    code: "+54",
    name: "Argentina"
  },
  {
    code: "+55",
    name: "Brazil"
  },
  {
    code: "+56",
    name: "Chile"
  },
  {
    code: "+56",
    name: "Easter Island"
  },
  {
    code: "+57",
    name: "Colombia"
  },
  {
    code: "+58",
    name: "Venezuela"
  },
  {
    code: "+60",
    name: "Malaysia"
  },
  {
    code: "+61",
    name: "Australia"
  },
  {
    code: "+62",
    name: "Indonesia"
  },
  {
    code: "+63",
    name: "Philippines"
  },
  {
    code: "+64",
    name: "New Zealand"
  },
  {
    code: "+65",
    name: "Singapore"
  },
  {
    code: "+66",
    name: "Thailand"
  },
  {
    code: "+77",
    name: "Kazakhstan"
  },
  {
    code: "+81",
    name: "Japan"
  },
  {
    code: "+82",
    name: "South Korea"
  },
  {
    code: "+84",
    name: "Vietnam"
  },
  {
    code: "+86",
    name: "China"
  },
  {
    code: "+90",
    name: "Turkey"
  },
  {
    code: "+91",
    name: "India"
  },
  {
    code: "+92",
    name: "Pakistan"
  },
  {
    code: "+93",
    name: "Afghanistan"
  },
  {
    code: "+94",
    name: "Sri Lanka"
  },
  {
    code: "+95",
    name: "Myanmar"
  },
  {
    code: "+98",
    name: "Iran"
  },
  {
    code: "+212",
    name: "Morocco"
  },
  {
    code: "+213",
    name: "Algeria"
  },
  {
    code: "+216",
    name: "Tunisia"
  },
  {
    code: "+218",
    name: "Libya"
  },
  {
    code: "+220",
    name: "Gambia"
  },
  {
    code: "+221",
    name: "Senegal"
  },
  {
    code: "+222",
    name: "Mauritania"
  },
  {
    code: "+223",
    name: "Mali"
  },
  {
    code: "+224",
    name: "Guinea"
  },
  {
    code: "+225",
    name: "Ivory Coast"
  },
  {
    code: "+226",
    name: "Burkina Faso"
  },
  {
    code: "+227",
    name: "Niger"
  },
  {
    code: "+228",
    name: "Togo"
  },
  {
    code: "+229",
    name: "Benin"
  },
  {
    code: "+230",
    name: "Mauritius"
  },
  {
    code: "+231",
    name: "Liberia"
  },
  {
    code: "+232",
    name: "Sierra Leone"
  },
  {
    code: "+233",
    name: "Ghana"
  },
  {
    code: "+234",
    name: "Nigeria"
  },
  {
    code: "+235",
    name: "Chad"
  },
  {
    code: "+236",
    name: "Central African Republic"
  },
  {
    code: "+237",
    name: "Cameroon"
  },
  {
    code: "+238",
    name: "Cape Verde"
  },
  {
    code: "+240",
    name: "Equatorial Guinea"
  },
  {
    code: "+241",
    name: "Gabon"
  },
  {
    code: "+242",
    name: "Congo"
  },
  {
    code: "+243",
    name: "Congo, Dem. Rep. of (Zaire)"
  },
  {
    code: "+244",
    name: "Angola"
  },
  {
    code: "+245",
    name: "Guinea-Bissau"
  },
  {
    code: "+246",
    name: "British Indian Ocean Territory"
  },
  {
    code: "+246",
    name: "Diego Garcia"
  },
  {
    code: "+247",
    name: "Ascension"
  },
  {
    code: "+248",
    name: "Seychelles"
  },
  {
    code: "+249",
    name: "Sudan"
  },
  {
    code: "+250",
    name: "Rwanda"
  },
  {
    code: "+251",
    name: "Ethiopia"
  },
  {
    code: "+253",
    name: "Djibouti"
  },
  {
    code: "+254",
    name: "Kenya"
  },
  {
    code: "+255",
    name: "Tanzania"
  },
  {
    code: "+256",
    name: "Uganda"
  },
  {
    code: "+257",
    name: "Burundi"
  },
  {
    code: "+260",
    name: "Zambia"
  },
  {
    code: "+261",
    name: "Madagascar"
  },
  {
    code: "+262",
    name: "Mayotte"
  },
  {
    code: "+262",
    name: "Reunion"
  },
  {
    code: "+263",
    name: "Zimbabwe"
  },
  {
    code: "+264",
    name: "Namibia"
  },
  {
    code: "+265",
    name: "Malawi"
  },
  {
    code: "+266",
    name: "Lesotho"
  },
  {
    code: "+267",
    name: "Botswana"
  },
  {
    code: "+268",
    name: "Swaziland"
  },
  {
    code: "+269",
    name: "Comoros"
  },
  {
    code: "+291",
    name: "Eritrea"
  },
  {
    code: "+297",
    name: "Aruba"
  },
  {
    code: "+298",
    name: "Faroe Islands"
  },
  {
    code: "+299",
    name: "Greenland"
  },
  {
    code: "+345",
    name: "Cayman Islands"
  },
  {
    code: "+350",
    name: "Gibraltar"
  },
  {
    code: "+351",
    name: "Portugal"
  },
  {
    code: "+352",
    name: "Luxembourg"
  },
  {
    code: "+353",
    name: "Ireland"
  },
  {
    code: "+354",
    name: "Iceland"
  },
  {
    code: "+355",
    name: "Albania"
  },
  {
    code: "+356",
    name: "Malta"
  },
  {
    code: "+358",
    name: "Finland"
  },
  {
    code: "+359",
    name: "Bulgaria"
  },
  {
    code: "+370",
    name: "Lithuania"
  },
  {
    code: "+371",
    name: "Latvia"
  },
  {
    code: "+372",
    name: "Estonia"
  },
  {
    code: "+373",
    name: "Moldova"
  },
  {
    code: "+374",
    name: "Armenia"
  },
  {
    code: "+375",
    name: "Belarus"
  },
  {
    code: "+376",
    name: "Andorra"
  },
  {
    code: "+377",
    name: "Monaco"
  },
  {
    code: "+378",
    name: "San Marino"
  },
  {
    code: "+380",
    name: "Ukraine"
  },
  {
    code: "+381",
    name: "Serbia"
  },
  {
    code: "+382",
    name: "Montenegro"
  },
  {
    code: "+385",
    name: "Croatia"
  },
  {
    code: "+386",
    name: "Slovenia"
  },
  {
    code: "+387",
    name: "Bosnia and Herzegovina"
  },
  {
    code: "+389",
    name: "Macedonia"
  },
  {
    code: "+420",
    name: "Czech Republic"
  },
  {
    code: "+421",
    name: "Slovakia"
  },
  {
    code: "+423",
    name: "Liechtenstein"
  },
  {
    code: "+500",
    name: "Falkland Islands"
  },
  {
    code: "+500",
    name: "South Georgia and the South Sandwich Islands"
  },
  {
    code: "+501",
    name: "Belize"
  },
  {
    code: "+502",
    name: "Guatemala"
  },
  {
    code: "+503",
    name: "El Salvador"
  },
  {
    code: "+504",
    name: "Honduras"
  },
  {
    code: "+505",
    name: "Nicaragua"
  },
  {
    code: "+506",
    name: "Costa Rica"
  },
  {
    code: "+507",
    name: "Panama"
  },
  {
    code: "+509",
    name: "Haiti"
  },
  {
    code: "+537",
    name: "Cyprus"
  },
  {
    code: "+590",
    name: "Guadeloupe"
  },
  {
    code: "+591",
    name: "Bolivia"
  },
  {
    code: "+593",
    name: "Ecuador"
  },
  {
    code: "+594",
    name: "French Guiana"
  },
  {
    code: "+595",
    name: "Guyana"
  },
  {
    code: "+595",
    name: "Paraguay"
  },
  {
    code: "+596",
    name: "French Antilles"
  },
  {
    code: "+596",
    name: "Martinique"
  },
  {
    code: "+597",
    name: "Suriname"
  },
  {
    code: "+598",
    name: "Uruguay"
  },
  {
    code: "+599",
    name: "Curacao"
  },
  {
    code: "+599",
    name: "Netherlands Antilles"
  },
  {
    code: "+670",
    name: "East Timor"
  },
  {
    code: "+670",
    name: "Timor Leste"
  },
  {
    code: "+672",
    name: "Australian External Territories"
  },
  {
    code: "+672",
    name: "Norfolk Island"
  },
  {
    code: "+673",
    name: "Brunei"
  },
  {
    code: "+674",
    name: "Nauru"
  },
  {
    code: "+675",
    name: "Papua New Guinea"
  },
  {
    code: "+676",
    name: "Tonga"
  },
  {
    code: "+677",
    name: "Solomon Islands"
  },
  {
    code: "+678",
    name: "Vanuatu"
  },
  {
    code: "+679",
    name: "Fiji"
  },
  {
    code: "+680",
    name: "Palau"
  },
  {
    code: "+681",
    name: "Wallis and Futuna"
  },
  {
    code: "+682",
    name: "Cook Islands"
  },
  {
    code: "+683",
    name: "Niue"
  },
  {
    code: "+685",
    name: "Samoa"
  },
  {
    code: "+686",
    name: "Kiribati"
  },
  {
    code: "+687",
    name: "New Caledonia"
  },
  {
    code: "+688",
    name: "Tuvalu"
  },
  {
    code: "+689",
    name: "French Polynesia"
  },
  {
    code: "+690",
    name: "Tokelau"
  },
  {
    code: "+691",
    name: "Micronesia"
  },
  {
    code: "+692",
    name: "Marshall Islands"
  },
  {
    code: "+850",
    name: "North Korea"
  },
  {
    code: "+852",
    name: "Hong Kong SAR China"
  },
  {
    code: "+853",
    name: "Macau SAR China"
  },
  {
    code: "+855",
    name: "Cambodia"
  },
  {
    code: "+856",
    name: "Laos"
  },
  {
    code: "+880",
    name: "Bangladesh"
  },
  {
    code: "+886",
    name: "Taiwan"
  },
  {
    code: "+960",
    name: "Maldives"
  },
  {
    code: "+961",
    name: "Lebanon"
  },
  {
    code: "+962",
    name: "Jordan"
  },
  {
    code: "+963",
    name: "Syria"
  },
  {
    code: "+964",
    name: "Iraq"
  },
  {
    code: "+965",
    name: "Kuwait"
  },
  {
    code: "+966",
    name: "Saudi Arabia"
  },
  {
    code: "+967",
    name: "Yemen"
  },
  {
    code: "+968",
    name: "Oman"
  },
  {
    code: "+970",
    name: "Palestinian Territory"
  },
  {
    code: "+971",
    name: "United Arab Emirates"
  },
  {
    code: "+972",
    name: "Israel"
  },
  {
    code: "+973",
    name: "Bahrain"
  },
  {
    code: "+974",
    name: "Qatar"
  },
  {
    code: "+975",
    name: "Bhutan"
  },
  {
    code: "+976",
    name: "Mongolia"
  },
  {
    code: "+977",
    name: "Nepal"
  },
  {
    code: "+992",
    name: "Tajikistan"
  },
  {
    code: "+993",
    name: "Turkmenistan"
  },
  {
    code: "+994",
    name: "Azerbaijan"
  },
  {
    code: "+995",
    name: "Georgia"
  },
  {
    code: "+996",
    name: "Kyrgyzstan"
  },
  {
    code: "+998",
    name: "Uzbekistan"
  },
  {
    code: "+1242",
    name: "Bahamas"
  },
  {
    code: "+1246",
    name: "Barbados"
  },
  {
    code: "+1264",
    name: "Anguilla"
  },
  {
    code: "+1268",
    name: "Antigua and Barbuda"
  },
  {
    code: "+1268",
    name: "Barbuda"
  },
  {
    code: "+1284",
    name: "British Virgin Islands"
  },
  {
    code: "+1340",
    name: "U.S. Virgin Islands"
  },
  {
    code: "+1441",
    name: "Bermuda"
  },
  {
    code: "+1473",
    name: "Grenada"
  },
  {
    code: "+1649",
    name: "Turks and Caicos Islands"
  },
  {
    code: "+1664",
    name: "Montserrat"
  },
  {
    code: "+1670",
    name: "Northern Mariana Islands"
  },
  {
    code: "+1671",
    name: "Guam"
  },
  {
    code: "+1684",
    name: "American Samoa"
  },
  {
    code: "+1767",
    name: "Dominica"
  },
  {
    code: "+1787",
    name: "Puerto Rico"
  },
  {
    code: "+1808",
    name: "Midway Island"
  },
  {
    code: "+1808",
    name: "Wake Island"
  },
  {
    code: "+1809",
    name: "Dominican Republic"
  },
  {
    code: "+1868",
    name: "Trinidad and Tobago"
  },
  {
    code: "+1869",
    name: "Nevis"
  },
  {
    code: "+1876",
    name: "Jamaica"
  },
  {
    code: "+7840",
    name: "Abkhazia"
  }
]

export default CountryCodeData;
