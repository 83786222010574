import { Box, Typography, Grid, Button } from "@mui/material";
import React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import EventListData from "../Ui-Components/EventSelectBox/EventListData";
import ColorHelper from "../../Helpers/ColorHelper";
import FilledButton from "../Ui-Components/Buttons/FilledButton/FilledButton";
import ConstantHelper from "../../Helpers/ConstantHelper";
import FontHelper from "../../Helpers/FontHelper";
import IconHelper from "../../Helpers/IconHelper";
import Radio from "@mui/material/Radio";
import { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import SearchIcon from "@mui/icons-material/Search";

//table
import {
  TableRow,
  Table,
  Paper,
  TableHead,
  TableBody,
  TableContainer,
  TableCell,
} from "@mui/material";

//customcss
import "./Advancesearch.css";
import axios from "axios";
import { ApiManager } from "../../ApiClient";

let widthOfSelect = 300;
let widthOfSelectYear = 200;

// const styles = (theme) => ({
//   radio: {
//     "&$checked": {
//       color: "#4B8DF8",
//     },
//   },
//   checked: {},
// });

const modalstyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "450px", md: "1000px" },
  marginTop: { xs: "100px", md: 0 },
  bgcolor: "background.paper",
  flexWrap: "wrap",
  boxShadow: 24,

  borderRadius: "5px",
};

const AdvanceSearchComp = (props) => {
  const [isLoading, setIsLoading] = useState(false);

  const [events2, setEvents2] = useState();
  const [evtCode, setEvtCode] = useState();
  const [year, setYear] = useState("");
  const [modalData, setModalData] = useState([]);
  const [selectedValue, setSelectedValue] = useState("a");

  const handleChange = (event) => {
    setEvents2(event.target.value);
  };

  const handleChange2 = (event) => {
    setYear(event.target.value);
  };

  const handleChangeforSelectList = (event) => {
    setSelectedValue(event.target.value);
  };

  useEffect(() => {}, []);

  //event modal
  const [mopen, setMopen] = useState(false);

  const getMultiEventList = async (evtcode) => {
    setIsLoading(true);

    const response = await axios.post(ApiManager.getMultiEventList, {
      searchtxt: evtcode,
    });
    if (response.data.StatusCode == 200) {
      setIsLoading(false);

      setModalData(response.data.result);
    } else {
      setIsLoading(false);
    }
  };

  const handlemOpen = (e) => {
    e.preventDefault();

    setMopen(true);
  };
  const handlemClose = (e) => {
    e.preventDefault();
    setMopen(false);
  };
  //year modal
  const [yopen, setYopen] = useState(false);

  const handleyOpen = (e) => {
    e.preventDefault();
    setYopen(true);
  };
  const handleYClose = (e) => {
    e.preventDefault();
    setYopen(false);
  };
  //table props for event- belowpart
  function createData(eveid, evebib) {
    return { eveid, evebib };
  }
  // const rows = [
  //   createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  //   createData("Ice cream sandwich ", 237, 9.0, 37, 4.3),
  //   createData("Eclair", 262, 16.0, 24, 6.0),
  //   createData("Cupcake", 305, 3.7, 67, 4.3),
  //   createData("Gingerbread", 356, 16.0, 49, 3.9),
  // ];

  //table props for year -below part
  function yearData(yearid, yearbib) {
    return { yearid, yearbib };
  }
  const newrows = [
    yearData("NMDC Hyderabad Marathon 2022", 159, 6.0, 24, 4.0),
    yearData("TCS World 10K Bengaluru 2022", 237, 9.0, 37, 4.3),
    yearData("Eclair", 262, 16.0, 24, 6.0),
    yearData("Cupcake", 305, 3.7, 67, 4.3),
    yearData("Gingerbread", 356, 16.0, 49, 3.9),
  ];
  return (
    <Box>
      <div style={{ display: isLoading ? "flex" : "none" }} className="modal">
        <div className="modal-content">
          <div className="loader"></div>
          <div className="modal-text">Processing...</div>
        </div>
      </div>
      <>
        {modalData && (
          <Modal
            open={mopen}
            onClose={handlemClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
          >
            <Box
              sx={{
                ...modalstyle,
                flexWrap: "wrap",
                width: { xs: "33rem", md: "476px" },
                mt: { xs: "5px" },
              }}
            >
              <Box sx={{ px: "24px" }} className="newbox">
                <Typography className="lefttext">
                  Enter BIB numbers (Event-wise){" "}
                </Typography>
                <CloseRoundedIcon
                  onClick={handlemClose}
                  sx={{
                    bgcolor: "white",
                    fontSize: "20px",
                    borderRadius: "50%",
                    cursor: "pointer",
                  }}
                />
              </Box>
              <TableContainer component={Paper}>
                <Table
                  sx={{
                    minWidth: { xs: "250px", md: "476px" },
                    border: "1px solid black",
                  }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow className="tablerow">
                      <TableCell className="thead">Event</TableCell>
                      <TableCell className="thead" align="left">
                        BIB ID
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {modalData.map((row, index) => (
                      <TableRow
                        key={row.event_id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          style={{
                            borderRight: "1px solid lightGray",
                            fontSize: "14px !important",
                            fontFamily: FontHelper.fRegular,
                          }}
                        >
                          {row.event_name}
                        </TableCell>
                        <TableCell align="left">
                          <input
                            style={{ border: "none", padding: "10px" }}
                            className="advancedSearchField"
                            type="text"
                            placeholder="BIB Number"
                            value=""
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box sx={{ px: "24px" }} className="newboxab">
                {Object.values(modalData).length > 4 ? (
                  <Button
                    btnText="PRINT"
                    sx={{
                      fontFamily: FontHelper.fBold,
                      fontSize: 12,
                      color: " #6941C6",
                      backgroundColor: "#F4EBFF;",
                      height: 40,
                      borderRadius: 0,
                      textTransform: "capitalize",
                      width: { xs: "60px", md: "87px" },
                      borderRadius: "3px",
                      marginRight: "5px",
                      mb: 0,
                      "&:hover": {
                        backgroundColor: ColorHelper.NavyBlue,
                        color: "#ffffff",
                      },
                    }}
                  >
                    Previous
                  </Button>
                ) : (
                  <Button
                    btnText="PRINT"
                    disabled
                    sx={{
                      fontFamily: FontHelper.fBold,
                      fontSize: 12,
                      color: " #6941C6",
                      backgroundColor: "#F4EBFF;",
                      height: 40,
                      borderRadius: 0,
                      textTransform: "capitalize",
                      width: { xs: "60px", md: "87px" },
                      borderRadius: "3px",
                      marginRight: "5px",
                      mb: 0,
                      "&.Mui-disabled": {
                        background: "#eaeaea",
                        color: "#c0c0c0",
                      },
                    }}
                  >
                    Previous
                  </Button>
                )}
                <Typography sx={{ color: "#667085" }}>Page 1 of 3</Typography>
                {Object.values(modalData).length > 4 ? (
                  <Button
                    btnText="PRINT"
                    sx={{
                      fontFamily: FontHelper.fBold,
                      fontSize: 12,
                      color: " #ffffff",
                      backgroundColor: "#7F56D9",
                      height: 40,
                      borderRadius: 0,
                      textTransform: "capitalize",
                      width: { xs: "50px", md: "64px" },
                      borderRadius: "19px",
                      marginRight: "5px",
                      mb: 0,
                      "&:hover": {
                        backgroundColor: ColorHelper.NavyBlue,
                      },
                    }}
                  >
                    Next
                  </Button>
                ) : (
                  <Button
                    btnText="PRINT"
                    disabled
                    sx={{
                      fontFamily: FontHelper.fBold,
                      fontSize: 12,
                      color: " #6941C6",
                      backgroundColor: "#F4EBFF;",
                      height: 40,
                      borderRadius: 0,
                      textTransform: "capitalize",
                      width: { xs: "60px", md: "87px" },
                      borderRadius: "3px",
                      marginRight: "5px",
                      mb: 0,
                      "&.Mui-disabled": {
                        background: "#eaeaea",
                        color: "#c0c0c0",
                      },
                    }}
                  >
                    Next
                  </Button>
                )}
              </Box>
              <Box className="newboxc">
                <Button
                  btnText="PRINT"
                  sx={{
                    fontFamily: FontHelper.fBold,
                    fontSize: 12,
                    color: ColorHelper.WhiteColor,
                    backgroundColor: "#b91880",
                    height: 40,
                    borderRadius: 0,
                    textTransform: "capitalize",
                    width: { xs: "100px", md: "123px" },
                    borderRadius: "6px",
                    marginRight: "5px",
                    mb: 0,
                    "&:hover": {
                      backgroundColor: ColorHelper.NavyBlue,
                    },
                  }}
                  startIcon={
                    <i>
                      <SearchIcon sx={{ mt: "10px " }} />
                    </i>
                  }
                >
                  Find all
                </Button>
              </Box>
            </Box>
          </Modal>
        )}

        <Modal
          open={yopen}
          onClose={handleYClose}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <Box
            sx={{
              ...modalstyle,
              flexWrap: "wrap",
              width: { xs: "33rem", md: "476px" },
              mt: { xs: "5px" },
            }}
          >
            <Box sx={{ px: "24px" }} className="newbox">
              <Typography className="lefttext">
                Enter BIB numbers (Year-wise){" "}
              </Typography>
              <CloseRoundedIcon
                onClick={handleYClose}
                sx={{
                  bgcolor: "white",
                  fontSize: "20px",
                  borderRadius: "50%",
                  cursor: "pointer",
                }}
              />
            </Box>
            <TableContainer component={Paper}>
              <Table
                sx={{
                  minWidth: { xs: "250px", md: "476px" },
                  border: "1px solid black",
                }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow className="tablerow">
                    <TableCell className="thead">Event</TableCell>
                    <TableCell className="thead" align="left">
                      BIB ID
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {newrows.map((rown) => (
                    <TableRow
                      key={rown.yearbib}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell style={{ borderRight: "1px solid lightGray" }}>
                        {rown.yearid}
                      </TableCell>
                      <TableCell align="left">
                        <input
                          style={{ border: "none", padding: "10px" }}
                          className="advancedSearchField"
                          type="text"
                          placeholder="Type your BIB Number"
                          value=""
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box sx={{ px: "24px" }} className="newboxab">
              {Object.values(newrows).length > 5 ? (
                <Button
                  btnText="PRINT"
                  sx={{
                    fontFamily: FontHelper.fBold,
                    fontSize: 12,
                    color: " #6941C6",
                    backgroundColor: "#F4EBFF;",
                    height: 40,
                    borderRadius: 0,
                    textTransform: "capitalize",
                    width: { xs: "60px", md: "87px" },
                    borderRadius: "3px",
                    marginRight: "5px",
                    mb: 0,
                    "&:hover": {
                      backgroundColor: ColorHelper.NavyBlue,
                      color: "#ffffff",
                    },
                  }}
                >
                  Previous
                </Button>
              ) : (
                <Button
                  btnText="PRINT"
                  disabled
                  sx={{
                    fontFamily: FontHelper.fBold,
                    fontSize: 12,
                    color: " #6941C6",
                    backgroundColor: "#F4EBFF;",
                    height: 40,
                    borderRadius: 0,
                    textTransform: "capitalize",
                    width: { xs: "60px", md: "87px" },
                    borderRadius: "3px",
                    marginRight: "5px",
                    mb: 0,
                    "&.Mui-disabled": {
                      background: "#eaeaea",
                      color: "#c0c0c0",
                    },
                  }}
                >
                  Previous
                </Button>
              )}
              <Typography sx={{ color: "#667085" }}>Page 1 of 3</Typography>
              {Object.values(newrows).length > 5 ? (
                <Button
                  btnText="PRINT"
                  sx={{
                    fontFamily: FontHelper.fBold,
                    fontSize: 12,
                    color: " #ffffff",
                    backgroundColor: "#7F56D9",
                    height: 40,
                    borderRadius: 0,
                    textTransform: "capitalize",
                    width: { xs: "50px", md: "64px" },
                    borderRadius: "19px",
                    marginRight: "5px",
                    mb: 0,
                    "&:hover": {
                      backgroundColor: ColorHelper.NavyBlue,
                    },
                  }}
                >
                  Next
                </Button>
              ) : (
                <Button
                  btnText="PRINT"
                  disabled
                  sx={{
                    fontFamily: FontHelper.fBold,
                    fontSize: 12,
                    color: " #6941C6",
                    backgroundColor: "#F4EBFF;",
                    height: 40,
                    borderRadius: 0,
                    textTransform: "capitalize",
                    width: { xs: "60px", md: "87px" },
                    borderRadius: "3px",
                    marginRight: "5px",
                    mb: 0,
                    "&.Mui-disabled": {
                      background: "#eaeaea",
                      color: "#c0c0c0",
                    },
                  }}
                >
                  Next
                </Button>
              )}
            </Box>
            <Box className="newboxc">
              <Button
                btnText="PRINT"
                sx={{
                  fontFamily: FontHelper.fBold,
                  fontSize: 12,
                  color: ColorHelper.WhiteColor,
                  backgroundColor: "#b91880",
                  height: 40,
                  borderRadius: 0,
                  textTransform: "capitalize",
                  width: { xs: "100px", md: "123px" },
                  borderRadius: "6px",
                  marginRight: "5px",
                  mb: 0,
                  "&:hover": {
                    backgroundColor: ColorHelper.NavyBlue,
                  },
                }}
                startIcon={
                  <i>
                    <SearchIcon sx={{ mt: "10px " }} />
                  </i>
                }
              >
                Find all
              </Button>
            </Box>
          </Box>
        </Modal>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: { xs: "flex-start", md: "center" },
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Radio
              checked={selectedValue === "a"}
              onChange={handleChangeforSelectList}
              value="a"
              name="radio-buttons-select-list"
              inputProps={{ "aria-label": "A" }}
              sx={{
                color: ColorHelper.PinkColor,
                "&.Mui-checked": {
                  color: ColorHelper.PinkColor,
                },
              }}
            />

            <FormControl
              variant="filled"
              sx={{ m: 1, minWidth: { xs: "60vw", md: widthOfSelect } }}
            >
              <InputLabel
                id="demo-simple-select-filled-label"
                sx={{
                  color: ColorHelper.WhiteColor,
                }}
              >
                Select Event Theme
              </InputLabel>
              {selectedValue === "a" ? (
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  value={events2}
                  onChange={handleChange}
                  sx={{ color: "white" }}
                  IconComponent={IconHelper.chevronDownIcon}
                >
                  {props.eventTheme.map((item, index) => (
                    <MenuItem
                      value={item.evtname}
                      key={index}
                      onClick={() => {
                        getMultiEventList(item.evtcode);
                      }}
                      sx={{
                        minWidth: { xs: "60vw", md: widthOfSelect },
                      }}
                    >
                      {item.evtname}
                    </MenuItem>
                  ))}
                </Select>
              ) : (
                <Select
                  disabled
                  IconComponent={IconHelper.chevronDownIcon}
                ></Select>
              )}
            </FormControl>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Radio
              checked={selectedValue === "b"}
              onChange={handleChangeforSelectList}
              value="b"
              name="radio-buttons-select-list"
              inputProps={{ "aria-label": "B" }}
              sx={{
                color: ColorHelper.PinkColor,
                "&.Mui-checked": {
                  color: ColorHelper.PinkColor,
                },
              }}
            />
            <FormControl
              variant="filled"
              sx={{ m: 1, minWidth: { xs: "60vw", md: widthOfSelectYear } }}
            >
              <InputLabel
                id="demo-simple-select-filled-label"
                sx={{
                  color: ColorHelper.WhiteColor,
                }}
              >
                Select Year
              </InputLabel>
              {selectedValue === "b" ? (
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  value={year}
                  onChange={handleChange2}
                  IconComponent={IconHelper.chevronDownIcon}
                >
                  <>
                    {props.eventYear.map((item, index) => (
                      <MenuItem
                        value={item.evtyear}
                        key={index}
                        onClick={handleyOpen}
                        sx={{
                          minWidth: { xs: "60vw", md: widthOfSelectYear },
                        }}
                      >
                        {item.evtyear}
                      </MenuItem>
                    ))}
                  </>
                </Select>
              ) : (
                <Select
                  disabled
                  IconComponent={IconHelper.chevronDownIcon}
                ></Select>
              )}
            </FormControl>
          </Box>

          <Box
            disableripple="true"
            type="Submit"
            sx={{
              width: { xs: "100%", sm: "fit-contnet" },
              textAlign: "center",
              px: 0,
              ml: { xs: "auto", lg: 2 },
              margin: "auto",
              pt: { xs: 2, lg: 0 },
            }}
          >
            <FilledButton
              btnText={ConstantHelper.submitBtn}
              color={ColorHelper.WhiteColor}
              bgColor={ColorHelper.PinkColor}
              fullWidth={true}
              onClick={handlemOpen}
              endIcon={<IconHelper.ArrowIcon />}
              sx={{
                width: "100%",
                p: 0,
                m: 0,
                fontFamily: FontHelper.fBold,
                svg: {
                  marginBottom: "0.2rem",
                  ml: "0.2rem",
                },
              }}
            />
          </Box>
        </Box>
      </>
    </Box>
  );
};

export default AdvanceSearchComp;
