const TNCData = [
  {
    title: "Ownership of our Web site",
    description: [
      {
        para: "This Web site is owned and operated by ONEGLINT MEDIA SOLUTIONS PVT LTD. All images and video clips are protected by International copyright law.",
      },
    ],
  },
  {
    title: "Your use of our Photographs",
    description: [
      {
        para: "You can purchase the photographs & video clips featured on our Web site in the form of photographic print, CD or DVD or downloadable images or as maybe offered from time to time. You may not reproduce, display, transmit, distribute or otherwise exploit any of the prints, clips, images, DVDs or CDs, or any portion thereof, in any manner, including, without limitation, print or electronic reproduction, publication or any display of photographs or video clips, without the prior written consent of oneglint. Prints or video clips are intended for your personal use only. You may not use or reproduce any product purchased from this website in such a manner as to suggest an affiliation with or endorsement by any product, person or entity that is not affiliated with you. You also agree not to modify, alter or otherwise manipulate any product, including without limitation, adding other material to any product without oneglint.com prior written consent. In addition, you may not reverse engineer, de-compile, or otherwise disassemble software included on this Web site.",
      },
    ],
  },
  {
    title: "Disclaimers",
    description: [
      {
        para: 'NOTWITHSTANDING THE ONEGLINT MEDIA SOLUTIONS PVT LTD. CUSTOMER SATISFACTION GUARANTEE, THIS WEB SITE AND ITS CONTENT ARE PROVIDED "AS IS" AND ONEGLINT MAKE NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, ABOUT ITS IMAGES OR WEB SITE INCLUDING, WITHOUT LIMITATION, WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT, TO THE FULLEST EXTENT PERMISSIBLE UNDER APPLICABLE LAW.',
      },
      {
        para: "oneglint and its officers, directors, employees, subsidiaries, affiliates, licensors and representatives, will not be responsible or liable for any damages of any kind including, without limitation, lost business or profits, direct, indirect, incidental, consequential, compensatory, exemplary, special or punitive damages that may result from your access to or use of either this Web site or the photographs DVDs, CDs or video clips.",
      },
    ],
  },
  {
    title: "Indemnification",
    description: [
      {
        para: `In the event that you reproduce, display, transmit, distribute or otherwise exploit the photographs or Prints, CDs, DVDs or video clips, or any portion thereof, in any manner not authorized by oneglint, or if you otherwise infringe any intellectual property rights relating to any of the images of clips on this Web site, you agree to indemnify and hold oneglint Media Solutions Pvt Ltd, its subsidiaries, affiliates, licensors and representatives, harmless against any losses, expenses, costs or damages, including reasonable attorneys' fees, incurred by them as a result of unauthorized use of the Prints, CDs, DVDs or video clips and/or your breach of this terms of this Agreement.`,
      },
    ],
  },
  {
    title: "Refund and Replacement Policy",
    description: [
      {
        para: "Digital downloads are non-refundable, and any payments made for them are non-recoverable. Should a refund be warranted for reasons other than order cancellation, it will be processed and issued within 12 to 15 business days.",
      },
      {
        para: "In case if you completed your ordering process & couldn’t download your pictures/video clips or for any other reasons, oneglint is happy to replace the product.",
      },
      {
        para: "- email us @ support@oneglint.com within 7 days after you have placed the order & we would be happy to resend access link your pictures/video clips to the email ID used while ordering."
      }
    ],
  },
  {
    title: "Colours and Pictures",
    description: [
      {
        para: "We have made every effort to display the colours and descriptions of our products that appear on oneglint.com as accurate as possible. However, as the actual colours you see may depend on your monitor quality and settings and that we cannot guarantee your monitors display of any colours, the variation may occur.",
      },
    ],
  },
  {
    title: "Other Terms",
    description: [
      {
        para: "oneglint reserve the right to make changes to this Web site, this Agreement, and the other information contained in this Web site at any time and without notice. Please refer to these User Terms when you visit the site as they may change from time to time.",
      },
    ],
  },
];

export default TNCData;
