import React from 'react'
import ColorHelper from '../../Helpers/ColorHelper'

const CheckMarkIcon = () => {
    return (
        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.2 5.49375L6.51875 8.18125L5.4875 7.15C5.43147 7.08457 5.36252 7.03144 5.28498 6.99392C5.20744 6.95641 5.12299 6.93533 5.03691 6.932C4.95084 6.92868 4.865 6.94318 4.7848 6.9746C4.7046 7.00602 4.63176 7.05368 4.57085 7.11459C4.50994 7.1755 4.46228 7.24835 4.43085 7.32855C4.39943 7.40875 4.38493 7.49458 4.38825 7.58066C4.39158 7.66673 4.41266 7.75119 4.45017 7.82873C4.48769 7.90627 4.54083 7.97522 4.60625 8.03125L6.075 9.50625C6.1334 9.56418 6.20266 9.61 6.27881 9.64111C6.35496 9.67221 6.4365 9.68798 6.51875 9.6875C6.68272 9.68681 6.83984 9.62172 6.95625 9.50625L10.0813 6.38125C10.1398 6.32315 10.1863 6.25402 10.2181 6.17786C10.2498 6.1017 10.2661 6.02001 10.2661 5.9375C10.2661 5.85499 10.2498 5.7733 10.2181 5.69714C10.1863 5.62098 10.1398 5.55185 10.0813 5.49375C9.96415 5.37734 9.80574 5.312 9.64063 5.312C9.47551 5.312 9.3171 5.37734 9.2 5.49375ZM7.5 1.25C6.26387 1.25 5.0555 1.61656 4.02769 2.30331C2.99988 2.99007 2.1988 3.96619 1.72576 5.10823C1.25271 6.25027 1.12894 7.50693 1.37009 8.71931C1.61125 9.9317 2.20651 11.0453 3.08059 11.9194C3.95466 12.7935 5.06831 13.3888 6.28069 13.6299C7.49307 13.8711 8.74974 13.7473 9.89178 13.2742C11.0338 12.8012 12.0099 12.0001 12.6967 10.9723C13.3834 9.94451 13.75 8.73613 13.75 7.5C13.75 6.67924 13.5883 5.86651 13.2743 5.10823C12.9602 4.34994 12.4998 3.66095 11.9194 3.08058C11.3391 2.50022 10.6501 2.03984 9.89178 1.72575C9.13349 1.41166 8.32076 1.25 7.5 1.25ZM7.5 12.5C6.5111 12.5 5.5444 12.2068 4.72215 11.6573C3.89991 11.1079 3.25904 10.327 2.88061 9.41342C2.50217 8.49979 2.40315 7.49445 2.59608 6.52455C2.789 5.55464 3.26521 4.66373 3.96447 3.96447C4.66373 3.2652 5.55465 2.789 6.52455 2.59607C7.49446 2.40315 8.49979 2.50216 9.41342 2.8806C10.3271 3.25904 11.1079 3.8999 11.6574 4.72215C12.2068 5.54439 12.5 6.51109 12.5 7.5C12.5 8.82608 11.9732 10.0979 11.0355 11.0355C10.0979 11.9732 8.82609 12.5 7.5 12.5Z" fill={ColorHelper.GreenColor} />
        </svg>
    )
}

export default CheckMarkIcon