import React from "react";

const PhotoIcon = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.21883 14.5L3.2055 14.5133L3.1915 14.5H1.99483C1.81937 14.4998 1.65116 14.43 1.52716 14.3059C1.40315 14.1817 1.3335 14.0135 1.3335 13.838V3.162C1.33472 2.98692 1.40476 2.81934 1.5285 2.69548C1.65225 2.57161 1.81975 2.5014 1.99483 2.5H14.0055C14.3708 2.5 14.6668 2.79667 14.6668 3.162V13.838C14.6656 14.0131 14.5956 14.1807 14.4718 14.3045C14.3481 14.4284 14.1806 14.4986 14.0055 14.5H3.21883V14.5ZM13.3335 10.5V3.83333H2.66683V13.1667L9.3335 6.5L13.3335 10.5ZM13.3335 12.3853L9.3335 8.38533L4.55216 13.1667H13.3335V12.3853ZM5.3335 7.83333C4.97987 7.83333 4.64074 7.69286 4.39069 7.44281C4.14064 7.19276 4.00016 6.85362 4.00016 6.5C4.00016 6.14638 4.14064 5.80724 4.39069 5.55719C4.64074 5.30714 4.97987 5.16667 5.3335 5.16667C5.68712 5.16667 6.02626 5.30714 6.27631 5.55719C6.52635 5.80724 6.66683 6.14638 6.66683 6.5C6.66683 6.85362 6.52635 7.19276 6.27631 7.44281C6.02626 7.69286 5.68712 7.83333 5.3335 7.83333Z"
        fill="#344054"
      />
    </svg>
  );
};

export default PhotoIcon;
