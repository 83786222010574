import { Box } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import NotLoggedInCheckout from "../Components/NotLoggedInCheckout/NotLoggedInCheckout";

import { setConfig } from "../redux/shoppingActions";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { ApiManager } from "../ApiClient";

const NotLoggedInCheckoutPage = () => {
  const [featuredEvents, setFeaturedEvents] = useState();
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState();
  const [pastEvents, setPastEvents] = useState();
  const [upcomingEvents, setUpcomingEvents] = useState();
  const [highlights, setHighlights] = useState();
  const [events, setEvents] = useState([]);
  const [eventTheme, setEventTheme] = useState();
  const [eventYear, setEventYear] = useState();
  const [cookiesModal, setCookies] = useState(true);

  const [userName, setUserName] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const { state } = useLocation();
  const fetchEventThemeDetails = async () => {
    const response = await axios.get(ApiManager.getEventThemeDetails());
    setEventTheme(response.data.result.evtthemes);
    setEventYear(response.data.result.evtyears);
  };

  const fetchEventsWithData = async () => {
    const response = await axios.get(ApiManager.homePage());
    setFeaturedEvents(response.data.result.feature_event);
    setPastEvents(response.data.result.past_event["2022"]);
    setUpcomingEvents(response.data.result.upcoming_event["Dec-2022"]);
    setHighlights(response.data.result.highlight_season);
  };

  useEffect(() => {
    localStorage.setItem("currPage", "NotLoggedIn");
    document.title = "Checkout";
  }, []);

  useEffect(() => {
    window.scrollTo(0, 100);
    window.scrollTo(0, 0);
  }, []);

  return (
    // <Box>
    // <div style={{ display: isLoading ? "flex" : "none" }} className="modal">
    //   <div className="modal-content">
    //     <div className="loader"></div>
    //     <div className="modal-text">Processing...</div>
    //   </div>
    // </div>
    // <>
    <Box>
      <Header isSignedIn={isSignedIn} userName={userName} />
      <NotLoggedInCheckout
        selectedValue={state.selectionType}
        price={state.price}
        printInstruction={state.printInstruction}
      />
      <Footer />
    </Box>
    // </>
    // </Box>
  );
};

export default NotLoggedInCheckoutPage;
