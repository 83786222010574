const RecaptchaConfigs = {
  // Testing Credentials for localhost
  Sitekey: "6LeToLMUAAAAANRCqmYzyPDdMCXakMCteJzXQLn_",
  Secretkey: "6LeIxAcTAAAAAGG-vFI1TnRWxMZNFuojJ4WifJWe",

  // Sitekey: "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI",

  // Actual Credentials
  // Sitekey: "6Le6GXIjAAAAAJQ72lWLLLpAEM3DWdWvp0PGA0Vt",
  // Secretkey: "6Le6GXIjAAAAAF-sZzF7vccXYwJwJjfGeBk7LkRx",
};

export default RecaptchaConfigs;
