import { Box, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ColorHelper from "../../Helpers/ColorHelper";
import ConstantHelper from "../../Helpers/ConstantHelper";
import FontHelper from "../../Helpers/FontHelper";
import IconHelper from "../../Helpers/IconHelper";
import ImageHelper from "../../Helpers/ImageHelper";
import FilledButton from "../Ui-Components/Buttons/FilledButton/FilledButton";
import SliderCustomBtns from "../Ui-Components/Buttons/SliderCustomBtns/SliderCustomBtns";
import SectionTitle from "../Ui-Components/SectionTitle/SectionTitle";
import HighlightsData from "./HighlightsData";
import HighlightsStyle from "./HighlightsStyle";
import "./highlight.css";

const ImageItem = ({ image }) => {
  const [isHovering, setIsHovering] = useState(false);
  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };
  return (
    <figure
      className="container1"
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      <img
        className="show"
        src={image}
        alt="Candid moments of Race participants"
      />
      {/* {isHovering && <figcaption>
        {" "}
        <Box sx={{ textAlign: "center", minWidth: "100%" }}>
          <FilledButton
            btnText={ConstantHelper.ExploreMore}
            color={ColorHelper.WhiteColor}
            bgColor={ColorHelper.VioletClr1}
            bordercolor={ColorHelper.WhiteColor}
            sx={{
              fontFamily: FontHelper.fBold,

              fontWeight: 500,
              fontSize: 14,
              height: "40px",
              px: 3,
              "&:hover": {
                backgroundColor: ColorHelper.VioletClr2,
                color: ColorHelper.WhiteColor,
              },
            }}
          />
        </Box>
      </figcaption>} */}
    </figure>
  );
};

const Highlights = ({ highlights }) => {
  // const responsive = {
  //     desktop: {
  //         breakpoint: { max: 3000, min: 1619 },
  //         items: 4,
  //         slidesToSlide: 1,
  //     },
  //     laptop: {
  //         breakpoint: { max: 1619, min: 1024 },
  //         items: 4,
  //         slidesToSlide: 1,
  //     },
  //     tablet: {
  //         breakpoint: { max: 1024, min: 640 },
  //         items: 3,
  //         slidesToSlide: 1,
  //     },
  //     mobile: {
  //         breakpoint: { max: 639, min: 0 },
  //         items: 1,
  //         slidesToSlide: 1,
  //     },
  // };

  // const carouselParams = {
  //     additionalTransfrom: 0,
  //     arrows: true,
  //     autoPlay: true,
  //     autoPlaySpeed: 3000,
  //     centerMode: false,
  //     className: "",
  //     containerClass: "carousel-container",
  //     // customButtonGroup: <SliderCustomBtns />,
  //     dotListClass: "",
  //     draggable: true,
  //     focusOnSelect: false,
  //     infinite: true,
  //     itemClass: "",
  //     keyBoardControl: true,
  //     minimumTouchDrag: 80,
  //     renderButtonGroupOutside: true,
  //     renderDotsOutside: false,
  //     responsive: responsive,
  //     showDots: false,
  //     sliderClass: "",
  //     slidesToSlide: 1,
  // };
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1619 },
      items: 5,
      slidesToSlide: 1,
    },
    laptop: {
      breakpoint: { max: 1619, min: 1024 },
      items: 4,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 640 },
      items: 3,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 639, min: 0 },
      items: 1,
      slidesToSlide: 1,
      arrows: false,
    },
  };

  const carouselParams = {
    additionalTransfrom: 0,
    arrows: false,
    autoPlay: false,
    autoPlaySpeed: 2000,
    centerMode: false,
    className: "",
    containerClass: "carousel-container",
    customButtonGroup: <SliderCustomBtns />,
    dotListClass: "",
    draggable: true,
    focusOnSelect: false,
    infinite: true,
    itemClass: "",
    keyBoardControl: true,
    minimumTouchDrag: 80,
    renderButtonGroupOutside: true,
    renderDotsOutside: false,
    responsive: responsive,
    showDots: false,
    sliderClass: "",
    slidesToSlide: 1,
  };

  return (
    // <Box sx={HighlightsStyle.SectionWraper}>
    //   <Box sx={HighlightsStyle.innerContainer}>
    //     <SectionTitle title={ConstantHelper.HightlightSectionTitle} />
    //     <Carousel {...carouselParams} sx={{ position: "absolute" }}>
    //       {highlights.map((item) => (
    //         <Grid
    //           key={item.id}
    //           container
    //           sx={HighlightsStyle.HighlightContainer}
    //         >

    //           <Box
    //             sx={{
    //               backgroundImage: `url(${item.s3})`,
    //               ...HighlightsStyle.categoryBlock,
    //             }}
    //             key={item.id}
    //             id="category"
    //           >
    //             <Box id="overlay" sx={HighlightsStyle.overlay}></Box>
    //             <Box sx={HighlightsStyle.categorytexts} id="categorytexts">
    //               <Typography
    //                 sx={HighlightsStyle.categorytTitle}
    //                 variant="h6"
    //                 component="h2"
    //                 id="categoryTitle"
    //               >
    //                 {item.title}
    //               </Typography>

    //               <Box sx={{ textAlign: "center", minWidth: "100%" }}>
    //                 <FilledButton
    //                   btnText={ConstantHelper.ExploreMore}
    //                   color={ColorHelper.WhiteColor}
    //                   bgColor={ColorHelper.VioletClr1}
    //                   bordercolor={ColorHelper.WhiteColor}
    //                   sx={{
    //                     fontFamily: FontHelper.fBold,
    //                     fontWeight: 500,
    //                     fontSize: 14,
    //                     height: "40px",
    //                     px: 3,
    //                     "&:hover": {
    //                       backgroundColor: ColorHelper.VioletClr2,
    //                       color: ColorHelper.WhiteColor,
    //                     },
    //                   }}
    //                 />
    //               </Box>
    //             </Box>
    //           </Box>
    //         </Grid>
    //       ))}
    //     </Carousel>
    //   </Box>
    // </Box>
    <Box sx={HighlightsStyle.SectionWraper}>
      <Box sx={HighlightsStyle.innerContainer}>
        <SectionTitle title={ConstantHelper.HightlightSectionTitle} />
        <Box className="container" sx={{maxWidth: "95%", width: "100%", margin: "0px auto", boxShadow: "none", borderRadius: "0"}}>
          {highlights.map((item) => (
            <ImageItem id={item.id} image={item.s3} />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default Highlights;
