import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { Formik, Form, Field } from "formik";
import { GoogleOAuthProvider } from "@react-oauth/google";

import {
  Grid,
  CardContent,
  CardActions,
  Button,
  InputAdornment,
  Typography,
  unstable_useEnhancedEffect,
} from "@mui/material";
import { TextField } from "formik-material-ui";
import FilledButton from "../Ui-Components/Buttons/FilledButton/FilledButton";

import { AccountCircle } from "@mui/icons-material";
import IconHelper from "../../Helpers/IconHelper";
import BorderedButton from "../Ui-Components/Buttons/BorderedButton/BorderedButton";

import ReCAPTCHA from "react-google-recaptcha";
import * as Yup from "yup";

import axios from "axios";
import RecaptchaConfigs from "../../Helpers/RecaptchaConfigs";
import ImageHelper from "../../Helpers/ImageHelper";
import ColorHelper from "../../Helpers/ColorHelper";
import ConstantHelper from "../../Helpers/ConstantHelper";
import FontHelper from "../../Helpers/FontHelper";

import { useCookies } from "react-cookie";

import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { saveUser } from "../../redux/shoppingActions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { LoginSocialFacebook } from "reactjs-social-login";
import { FacebookLoginButton } from "react-social-login-buttons";
import { useGoogleLogin } from "@react-oauth/google";
import { ApiManager } from "../../ApiClient";
import { useSnackbar } from "notistack";

//Data
const initialValues = {
  email: "",
  password: "",
};

//Phone No. validation
const numericRegEx = /(?=.[0-9])/;
const lengthRegEx = /(?=.{8,})/;

//validation schema
let validationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string()
    .matches(lengthRegEx, "Must be atleast 8 letters long!")
    .required("Required!"),
});

const SigninForm = (props) => {
  const [isLoading, setIsLoading] = useState(false);

  const { setSigninFormProps, itsInTabs, redirected, orderId } = props;
  const [isVerified, setVerified] = useState(false);
  const snack = useSnackbar();
  // console.log("SigninForm------", redirected);
  const recaptchaRef = React.useRef();

  const handleOnChangeRecaptcha = () => {
    setVerified(true);
  };
  const redoCaptcha = () => {
    this.reset();
    this.execute();
  };

  useEffect(() => {
    window.scrollTo(0, 100);
    window.scrollTo(0, 0);
  }, []);

  const showToastMessage = () => {
    toast.success("Success Notification !", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  };

  // const showWrongMessage = () => {
  //   toast.error("Wrong Credentials", {
  //     position: toast.POSITION.BOTTOM_RIGHT,
  //   });
  // };

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();

  const [cookies, setCookie] = useCookies(["user"]);

  let navigate = useNavigate();

  const onSubmit = (values, formicHelpers) => {
    const recaptchaValue = recaptchaRef.current.getValue();
    formicHelpers.resetForm();
    setEmail(values.email);
    setPassword(values.password);
    setIsLoading(true);

    axios
      .post(
        ApiManager.login(),
        {
          newUserFlg: "0",
          email: values.email,
          password: values.password,
          gCaptchToken: recaptchaValue,
          secretKey: RecaptchaConfigs.Secretkey, // "6LeIxAcTAAAAAGG-vFI1TnRWxMZNFuojJ4WifJWe",
        },
        headers
      )
      .then((res) => {
        if (res.data.StatusCode === 200) {
          setIsLoading(false);

          setName(res.data.result.firstName);
          showToastMessage();
          dispatch(saveUser(res.data.result));
          localStorage.setItem("ID", res.data.result.id);
          localStorage.setItem("Name", res.data.result.firstName);
          localStorage.setItem("isSignedIn", true);
          localStorage.setItem("email", res.data.result.email);
          localStorage.setItem("isOrganiser", res.data.result.isOrganiser);
          localStorage.setItem("isPartner", res.data.result.isPartner);
          setCookie("Name", res.data.result.firstName, { path: "/" });
          setCookie("Email", res.data.result.email, { path: "/" });
          setCookie("ID", res.data.result.id, { path: "/" });
          setCookie("Cookies Consent", true, { path: "/" });
          snack.enqueueSnackbar({
            variant: "success",
            message: res.data.Message,
          });
          setTimeout(() => {
            if (redirected === "MyOrder") {
              navigate("/myorders", { state: { orderId: orderId } });
            } else {
              localStorage.getItem("currPage") == "Cart"
                ? navigate("/cart")
                : localStorage.getItem("currPage") == "NotLoggedIn"
                ? navigate("/cart")
                : navigate("/");
            }
          }, 1000);
        } else {
          setIsLoading(false);

          snack.enqueueSnackbar({
            variant: "warning",
            message: res.data.Message,
          });
        }
      })
      .catch(() => {
        setIsLoading(false);
        console.log("Error");
      });
  };

  const headers = {
    accept: "application/x-www-form-urlencoded",
    "accept-language": "en_US",
    "content-type": "application/json",
  };

  const [user, setUser] = useState([]);
  const [profile, setProfile] = useState([]);
  const [googlelogindata, setGooglelogindata] = useState();

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => console.log("Login Failed:", error),
  });

  const Googlelogin = () => {
    setIsLoading(true);

    axios
      .get(
        `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
        {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        setProfile(res.data);
        if (res.status === 200) {
          setIsLoading(false);

          const formData = new FormData();
          formData.append("email", res.data.email);
          formData.append("login_type", 2);
          formData.append("tokenkey", res.data.id);
          formData.append("firstName", res.data.given_name);
          formData.append("lastName", res.data.family_name);

          axios
            .post(ApiManager.socialLogin(), formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((info) => {
              if (info.data.StatusCode === 200) {
                snack.enqueueSnackbar({
                  variant: "success",
                  message: info.data.Message,
                });
                // if (info.data.result) {
                //   setGooglelogindata(info.data.result);
                // }
                dispatch(saveUser(info.data.result));
                navigate("/");
                localStorage.setItem("email", info.data.result.email);

                localStorage.setItem("ID", info.data.result.id);
                localStorage.setItem("Name", info.data.result.firstName);
                localStorage.setItem("isSignedIn", true);
                setCookie("Name", info.data.result.firstName, { path: "/" });
                setCookie("Email", info.data.result.email, { path: "/" });
                setCookie("ID", info.data.result.id, { path: "/" });
                setCookie("Cookies Consent", true, { path: "/" });
              }
              else{
                snack.enqueueSnackbar({
                  variant: "error",
                  message: info.data.Message,
                });
              }
            });
        } else {
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    if (user) {
      Googlelogin();
    } else {
      snack.enqueueSnackbar({
        variant: "warning",
        message: "Login failed",
      });
    }
  }, [user]);

  return (
    <Box>
      <div style={{ display: isLoading ? "flex" : "none" }} className="modal">
        <div className="modal-content">
          <div className="loader"></div>
          <div className="modal-text">Processing...</div>
        </div>
      </div>
      <>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ dirty, isValid, values, handleChange, handleBlur }) => {
            return (
              <Form name="formName">
                <CardContent sx={{ pr: { xs: 0, md: "auto" } }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      my: 5,
                      width: "100%",
                      gap: 2,
                    }}
                  >
                    <h3
                      style={{
                        textAlign: "center",

                        fontSize: "24px",
                        fontFamily: "inherit",
                        fontWeight: "500",
                        lineHeight: "1:1",
                        color: "inherit",
                      }}
                    >
                      Login with
                    </h3>

                    {/* <LoginSocialFacebook
                    appId="176377896303154"
                    onResolve={(response) => {
                      console.log(response);
                    }}
                    onReject={(error) => {
                      console.log(error);
                    }}
                  >
                    <FacebookLoginButton text="Facebook" />
                  </LoginSocialFacebook> */}

                    <BorderedButton
                      handleClick={() => login()}
                      btnText={ConstantHelper.SignUp_Google}
                      color={ColorHelper.PrimaryColor}
                      bgColor={ColorHelper.WhiteColor}
                      bordercolor={ColorHelper.LightGrey3}
                      startIcon={
                        <img src={ImageHelper.GoogleIcon} alt="Google Icon" />
                      }
                      sx={{
                        width: "200px",
                        padding: "20px",
                        mx: 0,
                        px: 0,
                        height: "53px",
                        marginLeft: "0px",
                      }}
                    />
                  </Box>

                  <Typography
                    sx={{
                      fontFamily: FontHelper.fRegular,
                      fontSize: "16px",
                      color: ColorHelper.PrimaryColor,
                    }}
                  >
                    {ConstantHelper.useEmailToSignInTitle}
                  </Typography>

                  <Grid item xs={12} sm={6} md={12} sx={{ my: 2 }}>
                    <Field
                      label="Email"
                      variant="outlined"
                      fullWidth
                      name="email"
                      value={values.email}
                      component={TextField}
                      startAdornment={
                        <InputAdornment position="start">
                          <AccountCircle />
                        </InputAdornment>
                      }
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={12} sx={{ my: 2 }}>
                    <Field
                      label="Password"
                      variant="outlined"
                      fullWidth
                      type="password"
                      name="password"
                      value={values.password}
                      component={TextField}
                      startAdornment={
                        <InputAdornment position="start">
                          <AccountCircle />
                        </InputAdornment>
                      }
                    />
                  </Grid>
                  <Box
                    onClick={() => navigate("/forgot-password")}
                    sx={{
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      my: 3,
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: FontHelper.fRegular,
                        fontSize: "14px",
                        color: ColorHelper.PrimaryColor,
                        borderBottom: "1px solid",
                        borderColor: ColorHelper.DarkGrey3,
                      }}
                    >
                      {ConstantHelper.forgotPassword}
                    </Typography>
                  </Box>
                  <ReCAPTCHA
                    sitekey={RecaptchaConfigs.Sitekey}
                    onExpired={redoCaptcha}
                    onChange={handleOnChangeRecaptcha}
                    ref={recaptchaRef}
                  />
                </CardContent>
                <CardActions
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    marginLeft: "10px",
                  }}
                >
                  <Box
                    disableripple={true}
                    type="Submit"
                    sx={{ width: "100%" }}
                  >
                    <FilledButton
                      disabled={!isVerified}
                      type="Submit"
                      btnText={ConstantHelper.signInBtn}
                      color={ColorHelper.WhiteColor}
                      bgColor={ColorHelper.PrimaryColor}
                      fullWidth={true}
                      sx={{ ml: 0 }}
                    />
                  </Box>

                  {itsInTabs == false ? (
                    <Box
                      onClick={() => navigate("/sign-up")}
                      sx={{
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        my: 3,
                      }}
                    >
                      <IconHelper.UserPlus />
                      <Typography
                        sx={{
                          fontFamily: FontHelper.fBold,
                          fontSize: "16px",
                          color: ColorHelper.PrimaryColor,
                          pl: 1,
                        }}
                      >
                        {ConstantHelper.createAnAccount}
                      </Typography>
                    </Box>
                  ) : null}
                </CardActions>
              </Form>
            );
          }}
        </Formik>
      </>
    </Box>
  );
};

export default SigninForm;
