
const CookieData = {
    heading:'Cookie Settings',
    title:'Our website keeps three levels of cookies. You can adjust your preferences at any time. If you want more information about what cookies are and which cookies we collect, please read our',
    privacyPolicy:' Privacy Policy',
    functionalCookiesTitle: 'Functional Cookies',
    functionalCookies:'are essential cookies that ensure that the website functions properly and that your preferences (e.g. language, region) are saved.',
    analyticalCookiesTitle:'Analytical Cookies',
    analyticalCookies:"allow us to analyse website use and to improve the visitor's shopping experience.",
    marketingCookiesTitle:'Something Cookies',
    marketingCookies:"allow us to personalise your experience and to send you relevant content and offers, on this website and other websites."
}
export default CookieData;