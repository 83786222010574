import { Card, CardActions, Typography } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import ColorHelper from "../../Helpers/ColorHelper";
import ConstantHelper from "../../Helpers/ConstantHelper";
import FontHelper from "../../Helpers/FontHelper";
import IconHelper from "../../Helpers/IconHelper";
import BillViewStyle from "./BillViewStyle";
import ListOfItemsInBill from "./ListOfItemsInBill/ListOfItemsInBill";
import Notes from "./Notes/Notes";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import "./BillView.css";
import PremiumPackageModal from "../Modals/PackagesModal/PremiumPackageModal";
import UltraPremiumPackageModal from "../Modals/PackagesModal/UltraPremiumPackageModal";
import Radio from "@mui/material/Radio";

import {
  addAllToCart,
  getCartDetails,
  packageDetails,
} from "../../redux/shoppingActions";
import { ApiManager } from "../../ApiClient";

const BillView = ({ cartInfo, orderDetails, type, price, bibnumber }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const { bibNumber } = useSelector((state) => state.shop.cartDetails);
  const { eventId, eventName } = useSelector((state) => state.shop.products);
  const { cartPrice, ultraPackagePrice = 0 } = useSelector((state) => ({
    cartPrice: state.shop.cartCheckout,
    ultraPackagePrice: state.shop?.products?.ultra_premium_pack,
  }));
  const packageSelected = useSelector((state) => state.shop.packageDetails);
  const filteredCart = cartInfo.filter((item) => {
    if (item.type === "download") {
      return item.category !== "image";
    }
    return item;
  });
  const [collageInfo, setCollageInfo] = useState("");
  const [checkoutInfo, setCheckoutInfo] = useState({});
  const [photoPrintPrice, setPhotoPrintPrice] = useState();
  const config = useSelector((state) => state.config.masterConfig.result);
  const products = useSelector((state) => state.shop.products);
  const digitalDownload = cartInfo.filter((item) => {
    if (item.type === "download") {
      return item.category === "image";
    }
  });
  const [isLoading, setIsLoading] = useState(false)
  const digitalDownloadCount = digitalDownload.length;
  const videoCart = cartInfo.filter((item) => item.category == "video");
  const [selectedValue, setSelectedValue] = React.useState("a");
  const [printInstruction, setPrintInstruction] = useState("");


  const handleChange = (event) => {
    dispatch(packageDetails(event.target.value));
    dispatch(addAllToCart(products.photo, products.bibNumber, filteredCart));

    setSelectedValue(event.target.value);
  };
  const controlProps = (item) => ({
    checked:
      digitalDownloadCount >= products.threshold_photo_count &&
      (packageSelected || selectedValue) === item,
    onChange: handleChange,
    value: item,
    name: "size-radio-button-demo",
    inputProps: { "aria-label": item },
  });

  const data = cartInfo
    .map((data) => data.type === "download")
    .filter((value) => value === true).length;
  const data1 = cartInfo
    .map((data) => data.type === "collage")
    .filter((value) => value === true).length;
  const data2 = cartInfo
    .map((data) => data.type === "print")
    .filter((value) => value === true).length;
  const totalCart = data + data1 + data2;

  useEffect(() => {
    if (digitalDownloadCount >= products.threshold_photo_count) {
      setSelectedValue(packageSelected || "a");
    }
    if (
      !packageSelected &&
      digitalDownloadCount >= products.threshold_photo_count && cartPrice.bibNumber != "selfiesearch"
    ) {
      dispatch(packageDetails("a"));
    }
  }, [cartInfo]);

  useEffect(() => {
    setCollageInfo("");
    var sum = 0;
    if (cartPrice.print_count !== 0) {
      const photo_print = cartPrice.photo_print;

      const totalPrice = photo_print?.map((item) => item.print_photo_price);
      for (var values in totalPrice) {
        sum += totalPrice[values];
      }

      localStorage.setItem("Print", sum);
      setPhotoPrintPrice(sum);
    }
    // setIsLoading(true);
    // const userId = localStorage.getItem("ID");
    // if (userId) {
    //   axios
    //     .post(ApiManager.checkoutUserInfo(), {
    //       userID: localStorage.getItem("ID"),
    //     })
    //     .then((response) => {
    //       setIsLoading(false);

    //       setCheckoutInfo(response.data.result);
    //     })
    //     .catch((err) => { });
    // }
  }, [cartPrice]);

  const proceedToPay = async () => {
    if (
      !config.DISABLEPRINTS.includes(cartPrice.eventId) &&
      cartPrice.collage_count > 0 &&
      cartPrice.collage_count < 3 &&
      cartPrice.collage_count > 5
    ) {
      setCollageInfo(
        "Minimum 3 photos and maximum 5 are mandatory to make collage"
      );
    } else {
      localStorage.getItem("isSignedIn") == "true"
        ? navigate("/UserOrderdetails", {
          state: {
            selectionType:
              cartPrice.photo_count < products.threshold_photo_count
                ? null
                : selectedValue,
            price: cartPrice.total_amount,
            printInstruction: printInstruction,
          },
        })
        : navigate("/checkoutPage-authentication", {
          state: {
            selectionType:
              cartPrice.photo_count < products.threshold_photo_count
                ? null
                : selectedValue,
            price: cartPrice.total_amount,
            printInstruction: printInstruction,
          },
        });
    }
  };


  return (
    <Box>
      {/* <div style={{ display: isLoading ? "flex" : "none" }} className="modal">
        <div className="modal-content">
          <div className="loader"></div>
          <div className="modal-text">Processing...</div>
        </div>
      </div> */}
      <>

        <Card
          sx={{
            maxWidth: "100%",
            minWidth: "100%",
            height: "auto",
            pt: "40px",
            borderRadius: "10px",
            backgroundColor: ColorHelper.WhiteColor,
            boxShadow:
              "0px 20px 25px -5px rgba(16, 24, 40, 0.1), 0px 8px 10px -6px rgba(16, 24, 40, 0.1)",
            border: `1px solid ${ColorHelper.LightGrey4}`,
          }}
        >
          <Typography
            sx={{
              textTransform: "Uppercase",
              fontFamily: FontHelper.fBold,
              fontSize: 18,
              color: ColorHelper.DarkGrey4,
              borderBottom: `1px solid ${ColorHelper.LightGrey4}`,
              px: 5,
              pb: "7px",
            }}
          >
            Price Details
            <Typography
              sx={{
                fontSize: 12,
                color: ColorHelper.RedColor,
              }}
            >
              {collageInfo}
            </Typography>
          </Typography>
          <Box sx={BillViewStyle.TotalOrdeContainer}>
            <Box sx={BillViewStyle.TotalOrderInnerLeft}>
              <Typography
                sx={{
                  fontFamily: FontHelper.fMedium,
                  fontSize: 16,
                  color: ColorHelper.DarkGrey4,
                }}
              >
                Order Total
                <span
                  style={{
                    fontFamily: FontHelper.fMedium,
                    fontSize: "14px",
                    color: ColorHelper.PinkColor,
                    marginLeft: "8px",
                  }}
                >
                  (₹{localStorage.getItem("gst")}.00 &nbsp;inc.GST)
                </span>
              </Typography>

              <Typography
                sx={{
                  fontFamily: FontHelper.fMedium,
                  fontSize: 13,
                  color: ColorHelper.DarkGrey4,
                }}
              >
                Amount is rounded to nearest rupee
              </Typography>

            </Box>
            {type !== "orderDetails" ? (
              <Typography
                sx={{
                  fontFamily: FontHelper.fBold,
                  fontSize: 22,
                  color: ColorHelper.DarkGrey4,
                }}
              >
                {cartPrice.total_amount}.00
              </Typography>
            ) : (
              <Typography
                sx={{
                  fontFamily: FontHelper.fBold,
                  fontSize: 22,
                  color: ColorHelper.DarkGrey4,
                }}
              >
                {price}
              </Typography>
            )}
          </Box>

          {type !== "orderDetails" && (
            <ListOfItemsInBill
              cartPrice={cartPrice}
              photoPrintPrice={photoPrintPrice}
              videoCart={videoCart}
              selectedValue={selectedValue}
              digitalDownloadCount={digitalDownloadCount}
              setPrintInstruction={setPrintInstruction}
              printInstruction={printInstruction}
            />
          )}
          {bibNumber !== "selfiesearch" ? (
            <>
              {products?.show_prempack == 1 && (
                <Box sx={BillViewStyle.TotalOrderInnerLeft}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: FontHelper.fMedium,
                        fontSize: 16,
                        padding: "20px 20px 0 40px",
                        color: ColorHelper.DarkGrey4,
                        borderTop: `1px solid ${ColorHelper.LightGrey4}`,
                      }}
                    >
                      Premium Package
                    </Typography>
                    <div style={{ margin: "20px 30px 0 0 " }}>
                      <Radio
                        {...controlProps("a")}
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 28,
                          },
                        }}
                      />
                    </div>
                  </div>

                  <Typography
                    sx={{
                      fontFamily: FontHelper.fMedium,
                      fontSize: 13,
                      color: ColorHelper.DarkGrey4,
                      borderBottom: `1px solid ${ColorHelper.LightGrey4}`,
                      padding: "0 20px 20px 40px",
                    }}
                  >
                    <PremiumPackageModal />
                  </Typography>
                </Box>
              )}
              {products?.show_prempack == 1 &&
                products?.show_ultrapack == 1 && (
                  <Box sx={BillViewStyle.TotalOrderInnerLeft}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: FontHelper.fMedium,
                          fontSize: 16,
                          padding: "20px 20px 0 40px",
                          color: ColorHelper.DarkGrey4,
                          borderTop: `1px solid ${ColorHelper.LightGrey4}`,
                        }}
                      >
                        Ultra Premium Package
                      </Typography>
                      <div style={{ margin: "20px 30px 0 0 " }}>
                        <Radio
                          {...controlProps("b")}
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: 28,
                            },
                          }}
                        />
                      </div>
                    </div>
                    <Typography
                      sx={{
                        fontFamily: FontHelper.fMedium,
                        fontSize: 13,
                        color: ColorHelper.DarkGrey4,
                        padding: "0 20px 0px 40px",
                      }}
                    >
                      ( Premium Pack + Timed & Certificate Photos )
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: FontHelper.fMedium,
                        fontSize: 13,
                        color: ColorHelper.DarkGrey4,
                        borderBottom: `1px solid ${ColorHelper.LightGrey4}`,
                        padding: "0 20px 20px 40px",
                      }}
                    >
                      <UltraPremiumPackageModal />
                    </Typography>
                  </Box>
                )}
            </>
          ) : (
            ""
          )}

          <CardActions sx={{ px: 5, flexDirection: "column" }}>
            {type !== "orderDetails" && (
              <Box
                disableripple={true}
                onClick={proceedToPay}
                sx={{
                  fontFamily: FontHelper.fBold,
                  fontSize: 18,
                  textAlign: "center",
                  color: ColorHelper.WhiteColor,
                  backgroundColor: ColorHelper.GreenColor,
                  width: "100%",
                  px: "15px",
                  py: "20px",
                  borderRadius: "5px",
                  marginLeft: "auto",
                  mt: 2,
                  mb: 0,
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: ColorHelper.PrimaryColor,
                  },
                  "#btnText": {
                    display: { xs: "none", sm: "flex" },
                  },
                }}
              >
                <IconHelper.PaymentCard />
                <span style={{ marginLeft: "0.3rem" }}>
                  {ConstantHelper.ProceedToPay}
                </span>
              </Box>
            )}
          </CardActions>
          {type !== "orderDetails" && <Notes />}
        </Card>
      </>
    </Box>
  );
};
export default BillView;
