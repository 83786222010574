import React, { useState, useEffect } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import ConstantHelper from "../../../Helpers/ConstantHelper";
import EventListData from "./EventListData";
import ColorHelper from "../../../Helpers/ColorHelper";
import { Box, IconButton, InputAdornment, Typography } from "@mui/material";
import FontHelper from "../../../Helpers/FontHelper";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import "./EventSelectBox.css";
import { VisibilityOff } from "@mui/icons-material";
import { Modal } from "@mui/material";
import IconHelper from "../../../Helpers/IconHelper";

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateField } from '@mui/x-date-pickers/DateField';
import axios from "axios";
import { ApiManager } from "../../../ApiClient";

export default function EventSelectBox(props) {

  const { selectedEvent, setSelectedEvent, events, setEventDetails, setIsLoading } = props;
  const [inputValue, setInputValue] = useState("");
  const session_token = localStorage.getItem("session_token");
  // if(session_token == null || session_token == undefined){
  //   localStorage.setItem("session_token","");
  //   console.log("session_token","working")
  // }

  const [style, setStyle] = useState("combo-box-demo");

  const [selectValue, setSelectValue] = useState("");
  const [open, setOpen] = React.useState(false);
  const [openModal, setOpenModal] = useState(false);


  const handleChange = (event) => {
    setSelectValue(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleModalClose = () => {
    setOpenModal(false)
  };

  const handleDateFormat = (newDate) => {
    // console.log("datecheck", newDate)
  };

  useEffect(() => {
    setSelectedEvent(selectValue);
    // console.log("selectValue", selectValue)

  }, [selectValue]);

  const handleEventSelect = async (value) => {
    setIsLoading(true)
    // console.log("1223", value)
    const response = await axios.post(ApiManager.getEventDetails(), {
      eventID: value?.event_id,
      eventName: value?.event_name,
      sessionToken: session_token || ""
    })
    if (response.data.StatusCode == "200") {
      setIsLoading(false)
      setSelectedEvent(response.data.result[0]);
      localStorage.setItem("session_token", response.data.sessionDetails[0].session_token)
    }
  }

  let widthOfSelect = 350;

  //const EventListData = ['NMDC Hyderabad Marathon 2022',"TCS World 10K Bengaluru 2022"]
  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    borderRadius: "10px",
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    // boxShadow: 24,
    p: 4,
  };

  return (

    <FormControl
      sx={{
        border: "4px",
        borderRadius: "4px",
        color: "white",
        minWidth: { xs: "100%", md: "80%", lg: "350px" },
        marginTop: { xs: "-20px", md: "unset", lg: "unset" },
        // height: { sm: "unset", md: "60px", lg: "60px" },
        px: { xs: "0px", md: "unset", lg: "unset" },
      }}
    >
      <Autocomplete
        disablePortal
        id={style}
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        onChange={(event, newValue) => {
          // console.log("selectedEvent111",newValue)

          // newValue?.privacycheck_by === "dob" && setOpenModal(true)
          {
            newValue == null ? setStyle("") : setStyle("afterSubmit");
          }

          localStorage.setItem("Selected Event", newValue?.event_name);
          localStorage.setItem("Selected Event Id", newValue?.event_id);

          newValue ? handleEventSelect(newValue) : setSelectedEvent(newValue)
        }}
        size="small"

        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);

        }}
        // options={EventListData}
        options={events}
        getOptionLabel={(option) =>
          option.event_name ? option.event_name : ""
        }
        renderOption={(props, option) => (
          <MenuItem {...props} style={{ fontSize: '14px' }}>
            {option.event_name}
          </MenuItem>
        )}
        freeSolo
        sx={{
          width: { xs: "100%", md: "100%", lg: "100%" },
          color: ColorHelper.WhiteColor,
          backdropFilter: "blur(8px)",
          borderRadius: "8px",
          border: `1px solid ${ColorHelper.WhiteColor}`,
          background: "rgba(244, 248, 255, 0.2)",
          //px: 2,
          ...(selectedEvent && {
            background: ColorHelper.WhiteColor,
            borderRadius: { xs: "8px", lg: "8px 0px 0px 8px" },
            fontFamily: FontHelper.fRegular,
            height: "auto",
          }),
          input: {
            height: { xs: "2.2rem", md: "2.5rem", lg: "2.5rem" },
            color: ColorHelper.WhiteColor,
            outline: "none",
            "&::placeholder": {
              fontSize: 16,
              color: ColorHelper.WhiteColor,
            },
          },
          "& fieldset": { border: "none" },
          "#combo-box-demo + div > button > svg > path": {
            fill: ColorHelper.WhiteColor,
          },
        }}
        renderInput={(params) => (
          <TextField
            sx={{ fontFamily: FontHelper.fRegular }}
            {...params}
            placeholder={ConstantHelper.SerachFieldTitle}
          />
        )}
      />

    </FormControl>

  );
}
