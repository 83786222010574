import { Box, Typography, Button } from "@mui/material";
import React, { useState, useEffect } from "react";
import ColorHelper from "../../../Helpers/ColorHelper";
import ConstantHelper from "../../../Helpers/ConstantHelper";
import FontHelper from "../../../Helpers/FontHelper";
import IconHelper from "../../../Helpers/IconHelper";
import ImageUploadFormStyle from "./ImageUploadFormStyle";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ApiManager } from "../../../ApiClient";
import { useSnackbar } from "notistack";
import Avatar from "react-avatar-edit";
import "react-image-crop/dist/ReactCrop.css";
import UploadIcon from "@mui/icons-material/Upload";
import CameraOutlinedIcon from "@mui/icons-material/CameraOutlined";
import {
  fetchSelfieImageProduct,
  clearCart,
  packageDetails,
  sponsorDiscount
} from "../../../redux/shoppingActions";
import { useSelector, useDispatch } from "react-redux";
import Camera, { FACING_MODES, IMAGE_TYPES } from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";
import Webcam from "react-webcam";
const ImageUploadForm = ({ closeDialog }) => {
  const snack = useSnackbar();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  // const [postImage, setPostImage] = useState({
  //   myFile: "",
  // });
  // const [src, setSrc] = useState(null);
  // const [preview, setPreview] = useState(null);
  const [picture, setPicture] = useState("");
  const webcamRef = React.useRef(null);
  const capture = React.useCallback(() => {
    const pictureSrc = webcamRef.current.getScreenshot();
    setPicture(pictureSrc);
  });

  const handleImageUpload = async () => {
    setIsLoading(true);

    try {
      const response = await axios.post(ApiManager.searchBySelfie(), {
        eventID: localStorage.getItem("Selected Event Id"),
        selfieimg: picture,
      });
      if (
        response.data.StatusCode === 200 &&
        response.data.result.length !== 0
      ) {
        localStorage.setItem("showModal", true)
        dispatch(sponsorDiscount({ apply: false, photoID: [] }));
        setIsLoading(false);
        dispatch(packageDetails(""));
        dispatch(fetchSelfieImageProduct(response.data.result));
        localStorage.setItem(
          "awsImageExpiry",
          response.data.result.awsImageExpary
        );
        snack.enqueueSnackbar({
          variant: "success",
          message: response.data.Message,
        });
        dispatch(clearCart());
        setTimeout(() => {
          navigate("/searchbyselfie");
        }, 1000);
      } else {
        setIsLoading(false);

        snack.enqueueSnackbar({
          variant: "warning",
          message: response.data.Message,
        });
      }
    } catch {
      setIsLoading(false);

      snack.enqueueSnackbar({
        variant: "warning",
        message: "Invalid",
      });
    }
  };

  return (
    <Box>
      <div style={{ display: isLoading ? "flex" : "none" }} className="modal">
        <div className="modal-content">
          <div className="loader"></div>
          <div className="modal-text">Processing...</div>
        </div>
      </div>
      <>
        <Box>
          <Box sx={ImageUploadFormStyle.imguploadIconContainer}>
            <img src={IconHelper.ImageUploadIcon} alt="camera icon" />
          </Box>
          <Typography
            sx={{
              color: ColorHelper.BlackColor,
              fontFamily: FontHelper.fRegular,
              fontSize: 18,
              textAlign: "center",
            }}
          >
            {ConstantHelper.DropImageHereTitle}
            <Typography
              sx={{
                color: ColorHelper.YellowColor2,
                fontFamily: FontHelper.fMedium,
                fontSize: 16,
                textAlign: "center",
              }}
            >
              {ConstantHelper.WarningForImageUpload}
            </Typography>
            {picture == "" ? (
              <Webcam
                height={400}
                ref={webcamRef}
                width={400}
                screenshotFormat="image/jpeg"
                mirrored={true}
                imageSmoothing={true}
              />
            ) : (
              <img src={picture} style={{}} />
            )}
            {picture !== "" ? (
              <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
                <Button
                  sx={{
                    backgroundColor: "#450a76",
                    color: "#e9eef3",
                    alignItems: "center",
                    marginTop: "12px",
                    "&:hover": {
                      backgroundColor: "#000000",
                      color: "#fff",
                    },
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    setPicture("");
                  }}
                >
                  <CameraOutlinedIcon />
                  Retake
                </Button>
                <Button
                  sx={{
                    backgroundColor: "#450a76",
                    color: "#e9eef3",
                    alignItems: "center",
                    marginTop: "12px",
                    "&:hover": {
                      backgroundColor: "#000000",
                      color: "#fff",
                    },
                  }}
                  onClick={handleImageUpload}
                >
                  <UploadIcon />
                  Upload
                </Button>
                {/* <a download="FILENAME.jpeg" href={picture}>Download</a> */}
              </Box>
            ) : (
              <Button
                sx={{
                  backgroundColor: "#450a76",
                  color: "#e9eef3",
                  alignItems: "center",
                  marginTop: "12px",
                  "&:hover": {
                    backgroundColor: "#000000",
                    color: "#fff",
                  },
                }}
                onClick={(e) => {
                  e.preventDefault();
                  capture();
                }}
              >
                <CameraOutlinedIcon />
                Capture
              </Button>
            )}
          </Typography>
        </Box>
      </>
    </Box>
  );
};

export default ImageUploadForm;
