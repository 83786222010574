import React, { useEffect } from "react";

import { styled } from "@mui/material/styles";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FolderIcon from "@mui/icons-material/Folder";
import { ListItem } from "@mui/material";
import ColorHelper from "../../Helpers/ColorHelper";
import IconHelper from "../../Helpers/IconHelper";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetData } from "../../redux/shoppingActions";

import axios from "axios";
import { useState } from "react";
import { ApiManager } from "../../ApiClient";

function generate(element) {
  return [0, 1, 2].map((value) =>
    React.cloneElement(element, {
      key: value,
    })
  );
}



const Demo = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: "8px",
}));

const SimpleMiniDropDown = ({ UserMenuData }) => {

  const dispatch = useDispatch();


  const [dense, setDense] = React.useState(false);
  

  const [secondary, setSecondary] = React.useState(false);

  useEffect(() => {
    window.scrollTo(0, 100);
    window.scrollTo(0, 0);


    // const updatedDropDown = UserMenuData.filter((element) => {
    //   if (userInfo.isOrganiser && element.Title == "Event Dashboard") {
    //     return false;
    //   }
    //   if (userInfo.isPartner && element.Title == "Show Revenue") {
    //     return false;
    //   }
    //   return true;
    // }
    // );
    // console.log("updatedDropDown", updatedDropDown)


    // if (!localStorage.getItem("isOrganiser")) {
    //   const result = UserMenuData.filter(
    //     (element) => element.Title != "Event Dashboard"
    //   );
    //   // console.log("---result--0-", result);
    //   setUpdateUserMenuData(result);
    // }

    // if (!localStorage.getItem("isPartner")) {
    //   const result = UserMenuData.filter(
    //     (element) => element.Title != "Show Revenue"
    //   );
    //   // console.log("---result--1-", result);

    //   setUpdateUserMenuData(result);
    // }
  }, []);

  const navigate = useNavigate();

  const handleLogout = (title) => {
    if (title == "Log out") {
      localStorage.removeItem("Name");
      localStorage.removeItem("isSignedIn");
      localStorage.removeItem("ID");
      localStorage.removeItem("gst");
      localStorage.removeItem("isSignedIn");
      localStorage.removeItem("eventID");
      localStorage.removeItem("cartPrice");
      localStorage.removeItem("Selected Event");
      localStorage.removeItem("Print");
      localStorage.removeItem("Photo Print");
      localStorage.removeItem("Recent Event");
      localStorage.removeItem("currPage");
      dispatch(resetData());
      navigate("/");
      window.location.reload();
    } else if (title == "Orders History") {
      navigate("/order-history");
    } else if (title == "Show Revenue") {
      navigate("/show_revenue");
    } else if (title == "Event Dashboard") {
      navigate("/organization_dashboard");
    } else {
      navigate("/change-password");
    }
  };

  return (
    <Grid
      container
      sx={{
        color: "#6B7280",
        position: "absolute",
        right: { xs: -10, md: 0 },
        top: { xs: 70, md: 100 },
        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
        zIndex: 5,
        width: "230px",
        borderRadius: "8px",
        overflow: "hidden",
        "li > div": {
          minWidth: "40px",
        },
      }}
    >
      <Grid item xs={12} md={12}>
        <Demo>
          <List dense={dense} sx={{ py: 0 }}>
            {UserMenuData.map((item, index) => (
              <ListItem
                onClick={() => {
                  handleLogout(item.Title);
                }}
                sx={{
                  borderBottom: `1px solid ${ColorHelper.BlackColor}`,
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: ColorHelper.PrimaryColor,
                    span: {
                      color: ColorHelper.WhiteColor,
                    },
                    svg: {
                      fill: ColorHelper.WhiteColor,
                    },
                  },
                }}
                className="user-menu-list"
                key={index}
              >
                <ListItemIcon>{item.Icon}</ListItemIcon>
                <ListItemText
                  primary={item.Title}
                  secondary={secondary ? "Secondary text" : null}
                  sx={{ color: ColorHelper.BlackColor }}
                />
              </ListItem>
            ))}
          </List>
        </Demo>
      </Grid>
    </Grid>
  );
};

export default SimpleMiniDropDown;
