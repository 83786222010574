import React from 'react'
import ColorHelper from '../../Helpers/ColorHelper'

const PrintIcon = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.25 6H13.5V2.25H4.5V6H3.75C2.505 6 1.5 7.005 1.5 8.25V12.75H4.5V15.75H13.5V12.75H16.5V8.25C16.5 7.005 15.495 6 14.25 6ZM6 3.75H12V6H6V3.75ZM12 14.25H6V11.25H12V14.25ZM13.5 11.25V9.75H4.5V11.25H3V8.25C3 7.8375 3.3375 7.5 3.75 7.5H14.25C14.6625 7.5 15 7.8375 15 8.25V11.25H13.5Z" fill={ColorHelper.BlueColor} />
            <path d="M13.5 9.375C13.6989 9.375 13.8897 9.29598 14.0303 9.15533C14.171 9.01468 14.25 8.82391 14.25 8.625C14.25 8.42609 14.171 8.23532 14.0303 8.09467C13.8897 7.95402 13.6989 7.875 13.5 7.875C13.3011 7.875 13.1103 7.95402 12.9697 8.09467C12.829 8.23532 12.75 8.42609 12.75 8.625C12.75 8.82391 12.829 9.01468 12.9697 9.15533C13.1103 9.29598 13.3011 9.375 13.5 9.375Z" fill={ColorHelper.WhiteColor} />
        </svg>
    )
}

export default PrintIcon