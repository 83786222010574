import ColorHelper from "../../Helpers/ColorHelper";
import FontHelper from "../../Helpers/FontHelper";

const ProductCardStyle = {
  ModalBtn1: {
    fontFamily: FontHelper.fBold,
    fontSize: 12,
    color: ColorHelper.WhiteColor,
    backgroundColor: "#b91880",
    px: "15px",
    py: "10px",
    height: 40,
    borderRadius: 0,
    textTransform: "capitalize",
    width: { xs: "160px", md: "199px" },
    borderRadius: "3px",
    marginRight: "5px",
    mb: 0,
    "&:hover": {
      backgroundColor: ColorHelper.NavyBlue,
    },
  },
  ModalBtn2: {
    fontFamily: FontHelper.fBold,
    fontSize: 12,
    color: ColorHelper.BlueColor,
    backgroundColor: ColorHelper.LightPurple,
    px: "15px",
    py: "10px",
    height: 40,
    borderRadius: 1,
    textTransform: "capitalize",
    width: "100%",
    marginRight: "5px",
    mb: 0,
    "&:hover": {
      backgroundColor: ColorHelper.LightPurple,
    },
  },
  ModalBtn3: {
    fontFamily: FontHelper.fBold,
    fontSize: 12,
    color: ColorHelper.WhiteColor,
    backgroundColor: "#22C55E",
    px: "10px",
    py: "10px",
    height: 40,
    borderRadius: 1,
    textTransform: "capitalize",
    width: "100%",
    mt: { xs: 0, md: 0 },
    "&:hover": {
      backgroundColor: ColorHelper.NavyBlue,
    },
  },
  ModalBtn4: {
    fontFamily: FontHelper.fBold,
    fontSize: 12,
    border: "1px solid black",
    color: ColorHelper.BlackColor,
    backgroundColor: ColorHelper.WhiteColor,
    px: "20px",
    py: "10px",
    height: 40,
    borderRadius: "3px",
    textTransform: "capitalize",
    width: "100%",
    marginRight: "5px",
    mb: 0,
    "&:hover": {
      backgroundColor: ColorHelper.NavyBlue,
      color: ColorHelper.WhiteColor,
    },
  },
  ModalBtn5: {
    fontFamily: FontHelper.fBold,
    fontSize: 12,
    border: "1px solid black",
    color: ColorHelper.WhiteColor,
    backgroundColor: "#3F0E77",
    px: "20px",
    py: "10px",
    height: 40,
    borderRadius: "3px",
    textTransform: "capitalize",
    width: "100%",
    mx: "10px",
    marginRight: "30px",
    mb: 0,
    "&:hover": {
      backgroundColor: ColorHelper.NavyBlue,
    },
  },
};

export default ProductCardStyle;
