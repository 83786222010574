import React from "react";

const WarningIcon = () => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.7975 2.75H6.2025L2.25 6.7025V12.2975L6.2025 16.25H11.7975L15.75 12.2975V6.7025L11.7975 2.75ZM14.25 11.675L11.175 14.75H6.825L3.75 11.675V7.325L6.825 4.25H11.175L14.25 7.325V11.675Z"
        fill="#F59E0B"
      />
      <path
        d="M9 13.25C9.19891 13.25 9.38968 13.171 9.53033 13.0303C9.67098 12.8897 9.75 12.6989 9.75 12.5C9.75 12.3011 9.67098 12.1103 9.53033 11.9697C9.38968 11.829 9.19891 11.75 9 11.75C8.80109 11.75 8.61032 11.829 8.46967 11.9697C8.32902 12.1103 8.25 12.3011 8.25 12.5C8.25 12.6989 8.32902 12.8897 8.46967 13.0303C8.61032 13.171 8.80109 13.25 9 13.25Z"
        fill="#F59E0B"
      />
      <path d="M8.25 5.75H9.75V11H8.25V5.75Z" fill="#F59E0B" />
    </svg>
  );
};

export default WarningIcon;
