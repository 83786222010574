import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState, useRef } from "react";
import ColorHelper from "../../Helpers/ColorHelper";
import FontHelper from "../../Helpers/FontHelper";
import ImageHelper from "../../Helpers/ImageHelper";
import FaqStyle from "./FaqStyle";
import PremiumPackageModal from "../Modals/PackagesModal/PremiumPackageModal";
const SingleQA = ({ item }) => {
  const [show, setShow] = useState(false);
  const [Id, setId] = useState(0);
  const [question, setQestion] = useState("");
  const [ans, setans] = useState("");
  const ref = useRef(null);

  const handleClick = () => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Box
      sx={{
        ...FaqStyle.question,
        ...(show && { border: `1px solid ${ColorHelper.LightGrey3}` }),
      }}
    >
      <Grid container sx={FaqStyle.addcontent} id="addcontent">
        <Grid
          xs={10.3}
          sm={11.7}
          lg={11.6}
          sx={{ backgroundColor: ColorHelper.LightGrey4 }}
        >
          <Box
            onClick={(e) => {
              setShow((prev) => !prev);
              setId(item.id);
              setQestion(item.question);
              setans(item.answer);
              handleClick();
            }}
            underline="hover"
            sx={{ ...FaqStyle.link, ...(show && { pb: 2 }) }}
          >
            Q{item.id}: {item.question}
          </Box>
        </Grid>
        <Grid
          xs={1}
          sm={0.3}
          lg={0.4}
          sx={{ backgroundColor: ColorHelper.LightGrey4 }}
        >
          <Box
            onClick={(e) => {
              setShow((prev) => !prev);
              setId(item.id);
              setQestion(item.question);
              setans(item.answer);
              handleClick();
            }}
            sx={{
              cursor: "pointer",
              backgroundColor: ColorHelper.LightGrey4,
            }}
          >
            {show != true ? (
              <img src={ImageHelper.add} />
            ) : (
              <img src={ImageHelper.sub} />
            )}
          </Box>
        </Grid>
      </Grid>
      {/* ref={ref} */}
      <Box sx={FaqStyle.answer}>
        {show && item.id == Id ? (
          <Box sx={FaqStyle.answerContent}>
            <Grid container>
              <Grid xs={12} md={11}>
                <Typography
                  sx={{
                    fontFamily: FontHelper.fRegular,
                    // color: ColorHelper.LightGrey7,
                    color:"#31353d",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: "16px",
                    py: { xs: 1, md: 2 },
                    width: "100%",
                    // borderBottom: `1px solid ${ColorHelper.LightGrey3}`,
                  }}
                >
                  {ans}
                  {item.id === 20 && <PremiumPackageModal />}
                </Typography>
              </Grid>
              <Grid md={2} xs={2}></Grid>
            </Grid>
          </Box>
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );
};

export default SingleQA;
