import * as React from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  addToCart,
  addAllPrintToCart,
  removeFromCart,
  clearCart,
} from "../../redux/shoppingActions";
import { useDispatch, useSelector } from "react-redux";
import ColorHelper from "../../Helpers/ColorHelper";
import FontHelper from "../../Helpers/FontHelper";
import IconHelper from "../../Helpers/IconHelper";
import { Box, Grid } from "@mui/material";
import Modal from "@mui/material/Modal";
import { useState } from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import CancelIcon from "@mui/icons-material/Cancel";
import Masonry from "react-masonry-css";
import "./productstyle.css";
import ImageHelper from "../../Helpers/ImageHelper";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PrintIcon from "@mui/icons-material/Print";
import Carousel from "react-multi-carousel";
import SingleProductCard from "./SingleProductCard";
//masonary
// import Masonry from 'react-masonry-css'
import "./ProductStyles.css";
import PlusCirclular from "../../Assets/Icons/PlusCirclular";
import SingleProduct from "./Productimgsingle";
import ProductCardStyle from "./ProductCardStyle";
import SinglePrintProduct from "./SinglePrintProduct";
import "./ProductCard1.css";
import SingleCarousalProduct from "./SingleCarousalProduct";
import PopUpPrint from "./PopUpPrint";
import { useNavigate } from "react-router-dom";
import ProductMasonry from "./ProductMasonry";

export default function ProductCard({
  photo,
  stateDetails,
  cartDetails,
  type,
  setReportedPhoto,
  reportPics,
  loadPage,
  setLoadPage,
}) {
  // const state = useSelector((state))
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [AddToCartText, setAddtoCartText] = useState("Add To Cart");
  const [AddToCollageText, setAddtoCollageText] = useState("Collage");
  const cartInfo = useSelector((state) => state.shop.cartInfo);
  const [carousalArray, setCarousalArray] = useState(photo);
  const [open, setOpen] = useState(false);
  const [click, setClick] = useState(false);

  const [orderDetailsID, setorderDetailsID] = useState();
  const [imageAthlete, setImageAthlete] = useState();
  const config = useSelector((state) => state.config.masterConfig.result);
  const products = useSelector((state) => state.shop.products);
  const printItems =
    type == "unlabeled" ? products.products : products.printProductInfo;

  // const handleAddtoCart = (id, image, product, type, price) => {
  //   const cartItems = {
  //     id: id,
  //     image: image,
  //     category: "image",
  //     bibNumber: stateDetails.bibNumber,
  //     type: type,
  //     videopath: "",
  //   };
  //   product == undefined && price == undefined
  //     ? dispatch(addToCart(cartDetails, cartItems))
  //     : dispatch(
  //         addToCart(cartDetails, {
  //           ...cartItems,
  //           product: product,
  //           price: price,
  //         })
  //       );
  // };
  // const handleAllPrintToCart = (printItems, id) => {
  //   // alert('Hi');
  //   if (PrintAddAllToCartText == "Add All To Cart") {
  //     dispatch(addAllPrintToCart(printItems, stateDetails.bibNumber, id));
  //     setPrintAddAlltoCartText("Added");
  //   } else {
  //     dispatch(clearCart());
  //     setPrintAddAlltoCartText("Add All To Cart");
  //   }
  // };

  const breakpointColumnsObj = {
    default: type === "unlabeled" ? 5 : 3,
    1100: 3,
    700: 2,
    500: 1,
  };

  // const images = [
  //   {
  //     id: 0,
  //     download_url: ImageHelper.PrintsImage01,
  //     name: "Fridge Magnet (4x6 inches)",
  //     type: "Magnet",
  //     price: 739,
  //     gstPrice: "₹872",
  //   },
  //   {
  //     id: 1,
  //     download_url: ImageHelper.PrintsImage05,
  //     name: "Frame (10x10 inches)",
  //     type: "Frame",
  //     price: 319,
  //     gstPrice: "₹376",
  //   },
  //   {
  //     id: 2,
  //     download_url: ImageHelper.PrintsImage03,
  //     name: "Coffee Mugs",
  //     type: "Coffee Mug",
  //     price: 259,
  //     gstPrice: "₹306",
  //   },
  //   {
  //     id: 3,
  //     download_url: ImageHelper.PrintsImage04,
  //     name: "Wall Poster(13x10 inches)",
  //     type: "Marathon Poster",
  //     price: 350,
  //     gstPrice: "₹413",
  //   },
  // ];

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "350px", md: "1000px" },
    "@media screen and (min-width: 601px) and (max-width: 899px)": {
      width: "550px",
    },
    marginTop: { xs: "150px", md: 0 },
    bgcolor: "background.paper",
    flexWrap: "wrap",
    // border: "2px solid #000",
    boxShadow: 24,
    p: { xs: 2, sm: 3, md: 4 },
    borderRadius: "10px",
  };

  //order print modal

  const handleOpen = (image, id) => {
    // e.preventDefault();

    setImageAthlete(image);
    setorderDetailsID(id);

    setOpen(true);
  };
  const handleClose = (e) => {
    e.preventDefault();
    setOpen(false);
  };

  // const handleModalOpen = (id, image) => {
  //   setOpen(true);
  // };

  //on click picture modal

  const handlePicOpen = (e, index) => {
    e.preventDefault();
    var tempArray = photo.slice(index, photo.length);
    var temp2 = photo.slice(0, index);
    tempArray = tempArray.concat(...temp2);
    setCarousalArray(tempArray);
    setClick(true);
  };
  const handlePicClose = (e) => {
    setClick(false);
    setLoadPage(true);
    navigate("/products");
  };

  //onclick picture child modal

  const [childOpen, setChildOpen] = useState(false);

  const handleChildOpen = (e) => {
    e.preventDefault();
    setChildOpen(true);
  };
  const handleChildClose = (e) => {
    e.preventDefault();
    setChildOpen(false);
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1619 },
      items: 1,
      slidesToSlide: 1,
      // partialVisibilityGutter: 50,
      // slidesToSlide: 3,
    },
    laptop: {
      breakpoint: { max: 1619, min: 1024 },
      items: 1,
      slidesToSlide: 1,
      arrows: true,
      // partialVisibilityGutter: 50,
      // slidesToSlide: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 640 },
      items: 1,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 639, min: 0 },
      items: 1,
      slidesToSlide: 1,
      arrows: false,
    },
  };

  const carouselParams = {
    additionalTransfrom: 0,
    autoPlay: false,
    autoPlaySpeed: 2000,
    centerMode: false,
    className: "",
    containerClass: "carousel-container",
    dotListClass: "",
    draggable: true,
    focusOnSelect: false,
    infinite: true,
    itemClass: "",
    keyBoardControl: true,
    minimumTouchDrag: 80,
    customTransition: "all",
    transitionDuration: 100,
    renderButtonGroupOutside: true,
    renderDotsOutside: false,
    responsive: responsive,
    showDots: false,
    sliderClass: "",
  };

  const [cart, setCart] = useState("Add to cart");

  return (
    <>
      {/* <Sample /> */}
      <ProductMasonry
        photo={photo}
        breakpointColumnsObj={breakpointColumnsObj}
        stateDetails={stateDetails}
        cartDetails={cartDetails}
        type={type}
        handleClose={handleClose}
        handleOpen={handleOpen}
        handlePicOpen={handlePicOpen}
        reportPics={reportPics}
        setReportedPhoto={setReportedPhoto}
      />

      {/* order print modal  below*/}
      {!config.DISABLEPRINTS.includes(products.eventId) && (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
          stateDetails={stateDetails}
        >
          <Box sx={{ ...style }} className="modalHeight">
            <Box className="muimodalfirst">
              <Typography
                sx={{
                  fontFamily: FontHelper.fMedium,
                  fontSize: "16px",
                }}
              >
                BIB No:{" "}
                <span
                  style={{
                    color: "blue",
                    fontFamily: FontHelper.fBold,
                    fontSize: "16px",
                  }}
                >
                  {stateDetails.bibNumber}
                </span>
              </Typography>
              <Typography
                sx={{ fontFamily: FontHelper.fBold, fontSize: "16px" }}
              >
                {stateDetails.eventName}
              </Typography>
              <Button onClick={handleClose}>
                <CancelIcon color="error" />
              </Button>
            </Box>
            <Grid container columns={{ xs: 12, md: 12 }}>
              {printItems?.map((dataimg) => {
                return (
                  <SinglePrintProduct
                    dataimg={dataimg}
                    orderDetailsID={orderDetailsID}
                    imageAthlete={imageAthlete}
                    stateDetails={stateDetails}
                    cartDetails={cartDetails}
                  />
                );
              })}
            </Grid>
            <Grid container spacing={2} className="buttonsmodal">
              {/* <Grid xs={6} style={{ flexBasis: "0% !important" }}>
                <Button
                  disableripple={false}
                  onClick={() => {
                    handleAllPrintToCart(printItems, orderDetailsID);
                  }}
                  btnText="PRINT"
                  sx={ProductCardStyle.ModalBtn1}
                  startIcon={<AddShoppingCartIcon />}
                >
                  {PrintAddAllToCartText}
                  <span style={{ marginLeft: "0.3rem" }}></span>
                </Button>
              </Grid> */}
              <Grid xs={6}>
                <Button
                  onClick={handleClose}
                  btnText="PRINT"
                  sx={ProductCardStyle.ModalBtn1}
                >
                  Proceed
                  <span style={{ marginLeft: "0.3rem" }}></span>
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      )}

      {/* onpickclickmodal parent */}

      <Modal
        open={click}
        onClose={handlePicClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: "476px", mt: { xs: "5px" } }}>
          <Box className="picmodal">
            <Button
              onClick={handlePicClose}
              sx={{
                right: "60px",
                top: "80px",
                float: "right",
                position: "absolute",
                zIndex: "3",
              }}
            >
              <i className="closeroundnew">
                <CloseRoundedIcon fontSize="20px" />
              </i>
            </Button>
            {/* fcous her */}
            <Carousel {...carouselParams}>
              {carousalArray?.map((valueimg) => {
                return (
                  <SingleCarousalProduct
                    config={config}
                    products={products}
                    valueimg={valueimg}
                    photo={carousalArray}
                    stateDetails={stateDetails}
                    cartDetails={cartDetails}
                    handleChildOpen={handleChildOpen}
                    handleOpen={handleOpen}
                  />
                );
              })}
            </Carousel>
          </Box>
        </Box>
      </Modal>
      {/* on pickclick child modal */}
      <Modal
        open={childOpen}
        onClose={handleChildClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: "447px", mt: { xs: "10px" } }}>
          <Box className="muimodalfirst" sx={{ marginLeft: "10px" }}>
            <Typography
              sx={{
                fontFamily: FontHelper.fMedium,
              }}
            >
              BIB No: <span style={{ color: "blue" }}>TS-6069</span>
            </Typography>

            <Button onClick={handleChildClose}>
              <i className="closeround">
                {" "}
                <CloseRoundedIcon fontSize="25px" />
              </i>
            </Button>
          </Box>
          <Grid container columns={{ xs: 12, md: 12 }}>
            {printItems?.map((dataimg) => {
              return <PopUpPrint dataimg={dataimg} />;
            })}

            <Grid container spacing={2} className="buttonsmodalnew">
              <Grid xs={6} sx={{ paddingRight: "10px" }}>
                <Button btnText="PRINT" sx={ProductCardStyle.ModalBtn5}>
                  Proceed
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}
