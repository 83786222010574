import { Box } from "@mui/system";
import React, { useState, useEffect } from "react";
import FeaturedEvents from "../Components/FeaturedEvents/FeaturedEvents";
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import HeroSection from "../Components/HeroSection/HeroSection";
import Highlights from "../Components/Highlights/Highlights";
import axios from "axios";
import Cookies from "../Components/Cookies/Cookies";
import UpcomingAndPastEvents from "../Components/UpcomingAndPastEvents.js/UpcomingAndPastEvents";
import eventData from "../Components/EventCard/EventData";
import { setConfig } from "../redux/shoppingActions";
import { useDispatch } from "react-redux";
import { ApiManager } from "../ApiClient";
import { useSnackbar } from "notistack";
import { saveUserIsdCode } from "../redux/shoppingActions";

const HomePage = () => {
  const snack = useSnackbar();

  const dispatch = useDispatch();
  const [selectedEvent, setSelectedEvent] = useState();
  const [pastEvents, setPastEvents] = useState();
  const [upcomingEvents, setUpcomingEvents] = useState();
  const [highlights, setHighlights] = useState();
  const [events, setEvents] = useState([]);
  const [eventTheme, setEventTheme] = useState();
  const [eventYear, setEventYear] = useState();
  const [featuredEvents, setFeaturedEvents] = useState();
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [userName, setUserName] = useState("");
  const [cookiesModal, setCookies] = useState(true);
  const [heroImage, setHeroImage] = useState(null);

  const [year, setYear] = useState(null);
  const [allYear, setAllYear] = useState();
  const [isLoading, setIsLoading] = useState(false);

  

  const fetchEvents = async () => {
    setIsLoading(true);

    await axios
      .get(ApiManager.getEventName())
      .then((res) => {
        if (res.data.StatusCode === 200) {
          setIsLoading(false);

          const response = res.data.result;
          const searchableEvents = response.filter(
            (data) => data.issearchable == 1
          );
          localStorage.setItem("Recent Event", res.data.result[0].event_name);
          setEvents(searchableEvents);
        } else {
          setIsLoading(false);

          snack.enqueueSnackbar({
            variant: "error",
            message: res.data.Message,
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const fetchUserIPCountry = () => {
    ApiManager.getUserCountryBasisOfIP()
      .then((res) => {
        if (res.data !== undefined) {
          let data = res.data;
          if (data.country !== undefined) {
            dispatch(saveUserIsdCode(data.country_calling_code))
          }
        }
      })
      .catch((err) => {
        console.log(err);

      });
  };

  const fetchEventThemeDetails = async () => {
    const response = await axios.get(ApiManager.getEventThemeDetails());
    setEventTheme(response.data.result.evtthemes);
    setEventYear(response.data.result.evtyears);
  };

  const fetchEventsWithData = async () => {
    setIsLoading(true);

    const response = await axios.get(ApiManager.homePage());
    if (response.data.StatusCode === 200) {
      setIsLoading(false);

      let arr = Object.keys(response.data.result.past_event);
      setAllYear(arr.reverse());
      setYear(arr[0]);
      let featureEventsYearsArray = Object.keys(
        response.data.result.feature_event
      );
      setFeaturedEvents(response.data.result.feature_event);
      setPastEvents(response.data.result.past_event);
      setUpcomingEvents(response.data.result.upcoming_event);
      setHighlights(response.data.result.highlight_season);
      setHeroImage(response.data.result.hero_image);
    } else {
      setIsLoading(false);

      snack.enqueueSnackbar({
        variant: "error",
        message: response.data.Message,
      });
    }
  };

  const handleClose = () => {
    setCookies(!cookiesModal);
  };

  const fetchConfig = () => {
    dispatch(setConfig());
  };
  useEffect(() => {
    fetchUserIPCountry()
    window.scrollTo(0, 100);
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    localStorage.setItem("currPage", "Cart");
    document.title = "Sports photography service in India";
    fetchConfig();
    if (localStorage.getItem("Cookies") == "true") {
      setCookies(false);
    }
    localStorage.setItem("currPage", "labeled");

    fetchEvents();
    fetchEventsWithData();
    fetchEventThemeDetails();
    setIsSignedIn(localStorage.getItem("isSignedIn"));
    if (localStorage.getItem("isSignedIn") == "yes") {
      setUserName(localStorage.getItem("Name"));
    } else {
      setUserName("Sign In");
    }
  }, [year]);

  return (
    <Box>
      <div style={{ display: isLoading ? "flex" : "none" }} className="modal">
        <div className="modal-content" >
          <div className="loader"></div>
          <div className="modal-text">Processing...</div>
        </div>
      </div>
      <>
        <Box>
          {cookiesModal && <Cookies open={cookiesModal} close={handleClose} />}
          <Header isSignedIn={isSignedIn} userName={userName} />
          {eventData !== undefined && eventYear !== undefined && (
            <HeroSection
              selectedEvent={selectedEvent}
              setSelectedEvent={setSelectedEvent}
              events={events}
              HeroImage={heroImage}
              eventTheme={eventTheme}
              eventYear={eventYear}
            />
          )}

          {highlights !== undefined && <Highlights highlights={highlights} />}
          {pastEvents !== undefined && (
            <UpcomingAndPastEvents
              pastEvents={pastEvents}
              upcomingEvents={upcomingEvents}
              year={year}
              setYear={setYear}
              allYears={allYear}
            />
          )}
          {featuredEvents && <FeaturedEvents featuredEvents={featuredEvents} />}
          <Footer />
        </Box>
      </>
    </Box>
  );
};
export default HomePage;
