import { legacy_createStore, applyMiddleware, compose } from "redux";
import persistedReducer from "./rootReducer";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE || compose;

const store = legacy_createStore(persistedReducer, composeWithDevTools(applyMiddleware(thunk)));

export default store;
