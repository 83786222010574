import React from "react";
import FaqStyle from "./FaqStyle";
import { Box } from "@mui/system";
import FaqData from "./FaqData";
import { Typography } from "@mui/material";
import FontHelper from "../../Helpers/FontHelper";
import ImageHelper from "../../Helpers/ImageHelper";
import ColorHelper from "../../Helpers/ColorHelper";
import Button from "@mui/material/Button";
import SingleQA from "./SingleQA";
import FilledButton from "../Ui-Components/Buttons/FilledButton/FilledButton";
import { useNavigate } from "react-router-dom";

export default function Faqs() {
  const navigate = useNavigate("");

  const handleClick = () => {
    navigate("/contact-us");
  };

  return (
    <>
      <Box sx={FaqStyle.container}>
        <Box sx={FaqStyle.heading}>
          <Typography sx={FaqStyle.text1}>Frequently Asked Question</Typography>
          <Typography sx={FaqStyle.text2}>
            Everything you need to know about the product and billing.
          </Typography>
        </Box>
        <Box sx={FaqStyle.content}>
          {FaqData.map((item) => (
            <SingleQA item={item} />
          ))}
        </Box>

        <Box sx={FaqStyle.heading}>
          <Typography
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={ImageHelper.faq1} style={{ ml: 2 }} />
            <img src={ImageHelper.faq2} />
            <img src={ImageHelper.faq3} style={{ mr: 2 }} />
          </Typography>
          <Typography sx={FaqStyle.text4}>Still have questions?</Typography>
          <Typography sx={FaqStyle.text5}>
            Can’t find the answer you’re looking for? Please chat to our
            friendly team.
          </Typography>
          <FilledButton
            handleClick={handleClick}
            btnText="Get in touch"
            color={ColorHelper.WhiteColor}
            bgColor={ColorHelper.PrimaryColor}
            bordercolor={ColorHelper.WhiteColor}
            sx={{
              ml: -0.05,
              my: 2,
              fontFamily: FontHelper.fBold,
              fontWeight: 500,
              fontSize: 16,
            }}
            cstHover={{
              "&:hover": {
                backgroundColor: ColorHelper.PrimaryColorLight,
                color: ColorHelper.WhiteColor,
              },
            }}
          />
        </Box>
      </Box>
    </>
  );
}
