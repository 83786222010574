import * as actionTypes from './shoppingTypes';
const INITIAL_STATE = {
    masterConfig: []
}
const configReducer = (state = INITIAL_STATE, {type,payload}) => {
    switch(type){
        case actionTypes.SAVE_CONFIG:
            return{
                    ...state,masterConfig:{...payload}
                }
        default:
            return state;
    }
}
export default configReducer;