import ColorHelper from "../../Helpers/ColorHelper";
import FontHelper from "../../Helpers/FontHelper";

const HighlightsStyle = {
  SectionWraper: {
    px: { xs: 0, md: 13 },
    py: { xs: 5, md: 10 },
    backgroundColor: ColorHelper.WhiteColor2,
  },
  innerContainer: {
    px: { xs: 0, md: 5, lg: 7 },
    py: { xs: 5, md: 8 },
    pt: { xs: 5, md: 3 },
    borderRadius: "10px",
    backgroundColor: ColorHelper.WhiteColor,
    position: "relative",
    "#ArrowEdgeLeft": {
      transform: { xs: "translateX(10px)", md: "translateX(-20px)" },
    },
    "#ArrowEdgeRight": {
      transform: { xs: "translateX(-10px)", md: "translateX(20px)" },
    },
    // "> div": {
    //   ml: { xs: 0, md: "-2rem", lg: "-2rem" },
    //   pl: { xs: 0, md: "-10rem", lg: "-10rem" },
    // },
    // ".react-multiple-carousel__arrow--left": {
    //   transform: "translateX(-50%)",
    //   backgroundColor: ColorHelper.PrimaryColor,
    //   left: "43px",
    // },
    // ".react-multiple-carousel__arrow--right": {
    //   transform: "translateX(120%)",
    //   backgroundColor: ColorHelper.PrimaryColor,
    //   right: { xs: "75px", md: "53px" },
    // },
  },
  overlay: {
    width: "100%",
    height: "300px",
    position: "absolute",
    top: "24%",
    left: 0,
    background:
      "linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,212,255,0) 75%, rgba(0,212,255,0) 100%)",
    opacity: 0,
  },
  categoryBlock: {
    // mx: 5,
    height: { xs: "26.9rem", md: "18.2rem" },
    width: { xs: "59%", md: "75%" },
    backgroundRepeat: "no-repeat",
    backgroundSize: { xs: "cover", md: "contain" },
    position: "relative",
    "&:hover #overlay": {
      background: "linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 100%)",
      opacity: 1,
    },
    "&:hover #categoryTitle": {
      display: "block",
    },
    "&:hover #categorytexts": {
      bottom: "0px",
      transition: "all 0.3s ease-in 1s",
    },
    "&:hover #categorytexts": {
      bottom: "10px",
    },
  },
  categorytexts: {
    py: 2,
    position: "absolute",
    bottom: "-150px",
    left: "50%",
    right: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  categorytTitle: {
    fontSize: { xs: 14, md: "0.7vw" },
    fontWeight: 600,
    fontFamily: FontHelper.fSemiBold,
    color: ColorHelper.WhiteColor,
    minWidth: "max-content",
    mb: 2,
  },
  carouselContainer: {},
  HighlightContainer: {
    justifyContent: { xs: "center", md: "unset" },
    maxWidth: "100%",
    ml: { xs: 0, md: 4 },
    mx: 4,
  },
  HighlightImageContainer: {
    width: "100%",
    img: {
      maxWidth: "100%",
      maxHeight: "390px",
      ObjectFit: "cover",
    },
  },
};

export default HighlightsStyle;
