import { Box, Typography, Button, Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import ConstantHelper from "../../../Helpers/ConstantHelper";
import SectionTitle3 from "../../Ui-Components/SectionTitle3/SectionTitle3";
import CartCardComp from "../CartCardComp/CartCardComp";
import DigitalDownloadSectionData from "./DigitalDownloadSectionData";
import CartCardUnlabeledComp from "../CartCardComp/CartCardUnlabeledComp";
import { useSelector, useDispatch } from "react-redux";
import ColorHelper from "../../../Helpers/ColorHelper";
import IconHelper from "../../../Helpers/IconHelper";
import FontHelper from "../../../Helpers/FontHelper";
import Modal from "@mui/material/Modal";
import { sponsorDiscount } from "../../../redux/shoppingActions";
import ImageSlider from "../../Modals/PackagesModal/ImageComponent/ImageSlider";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from '@mui/material/FormControlLabel';
import { useLocation } from "react-router-dom";
const DigitalDownloadsSection = ({
  data,
  updateBill,
  type,
  digital,
  bibNumber,
  orderId,
  sponsorLogo
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const findpath = location.pathname;
  const { sponsorOfferAvailableFlag } = useSelector((state) => state.shop.products);
  const cartInfo = useSelector((state) => state.shop.cartInfo);

  const downloadsCart = cartInfo.filter((item) => {
    if (item.type == "download") {
      return item.category === "image";
    }
  });
  const cartPrice = useSelector((state) => state.shop.cartCheckout);
  const sponsorDetails = cartPrice?.getSponsorOffersDetails;

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "70%", lg: "725px" },
    // height: { xs: "450px", lg: "300px" },
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: { xs: 2, md: "24px" },
    gap: "24px",
    borderRadius: "5px",
    flex: "none",
    order: "0",
    alignSelf: "stretch",
    flexGrow: "0",
  };

  const [openSponorModal, setOpenSponsorModal] = useState(false);
  const handleClose = () => {
    setOpenSponsorModal(false);
    dispatch(sponsorDiscount({ apply: false, photoID: [] }));

  };
  const CartCardDataAddCard = {
    title: ConstantHelper.AddMorePhotos,
    addMore: "/",
    addMoreItems: "/",
  };
  const CartCardDataRemoveCard = {
    title: ConstantHelper.removeBtn,
    addMore: "/",
    addMoreItems: "/",
  };

  const CartCardDataFullPackage = {
    title: ConstantHelper.AddMorePhotos,
    addMore: "/",
    addMoreItems: "/",
  };
  const digitalDownload = data.filter((item) => {
    return item.type === "download";
  });
  const handleClick = () => {
    setTimeout(() => {
      dispatch(sponsorDiscount({ apply: true, photoID: [] }));
      setOpenSponsorModal(false);
    }, 300)

  }

  // useEffect(() => {
  //   if (findpath == "/cart" && sponsorOfferAvailableFlag === true && downloadsCart.length > 0) {
  //     setOpenSponsorModal(true);
  //   }
  // }, []);

  return (
    <Box sx={{ pb: 3 }}>
      {/* {sponsorOfferAvailableFlag &&  ( */}
      {/* { findpath == "/cart"&& sponsorOfferAvailableFlag && (
       
      )} */}
      <SectionTitle3 title={ConstantHelper.DigitalDownloadsTitle} setOpenSponsorModal={setOpenSponsorModal} />
      {(data !== null || data !== undefined) && type !== "unlabeled" ? (
        <CartCardComp
          type={type}
          CartCardData={digitalDownload}
          actionStyle={2}
          CartCardDataRemoveCard={CartCardDataRemoveCard}
          CartCardDataAddCard={CartCardDataAddCard}
          CartCardDataFullPackage={CartCardDataFullPackage}
          updateBill={updateBill}
          digital={digital}
          digitalDownload={digitalDownload}
          orderId={orderId}
          bibNumber={bibNumber}
          sponsorLogo={sponsorLogo}
        />
      ) : (
        <CartCardUnlabeledComp
          type={type}
          CartCardData={data}
          actionStyle={2}
          CartCardDataAddCard={CartCardDataAddCard}
          CartCardDataFullPackage={CartCardDataFullPackage}
          updateBill={updateBill}
          digital={digital}
          digitalDownload={digitalDownload}
          bibNumber={bibNumber}
          orderId={orderId}
        />
      )}
    </Box>
  );
};

export default DigitalDownloadsSection;
