import { borderRadius, width } from "@mui/system";
import ColorHelper from "../../Helpers/ColorHelper";
import FontHelper from "../../Helpers/FontHelper";

const PreorderStyle = {
  //1st grid
  SectionHead: {
    color: "#48197d",
    fontFamily: FontHelper.fSemiBold,
    fontSize: "26px",
  },
  Sectionmain: {
    px: { xs: 2, md: 5 },
    mt: { xs: 3, md: 3 },
  },
  Evename: {
    fontFamily: FontHelper.fBold,
    fontSize: 17,
    fontWeight: "bolder",
    color: "#48197d",
  },
  EventPrice: {
    fontFamily: FontHelper.fBold,
    fontSize: 24,
    fontWeight: 700,
    // fontWeight: "bolder",
    color: "#48197d",
  },
  leftpara: {
    textAlign: "center",
    padding: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  heading: {
    fontFamily: FontHelper.fBold,
    fontSize: 17,
    color: "#333333",
    mt: { xs: 0, md: 3 },
  },
  pricebox: {
    display: "flex !important",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
    fontFamily: FontHelper.fBold,
    fontSize: 14,
  },
  crosstext: {
    fontFamily: FontHelper.fBold,
    fontSize: 14,

    textDecoration: "line-through",
    marginLeft: "13px",
    color: "#656565",
  },
  //2nd grid below
  boxcheckout: {
    border: "1px solid #656565",
    // height: '300px',
    maxWidth: "600px",

    margin: "auto",
    borderRadius: "3px",
    position: "relative",
  },
  guestpart: {
    color: "#ffffff",
    // position: 'absolute',
    padding: "10px",
    backgroundColor: "#48197d",
    border: "1px solid #656565",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: FontHelper.fSemiBold,
    fontSize: 20,
  },

  formlabel: {
    display: "flex",
    width: "fit-content",
    alignItems: "center",
    mt: "5px",
    textAlign: { xs: "left", md: "right" },
    fontFamily: FontHelper.fMedium,
    color: ColorHelper.PrimaryColor,
    fontSize: "14px",
  },
  formerror: {
    width: "fit-content",
    textAlign: { xs: "left", md: "right" },
    fontFamily: FontHelper.fMedium,
    color: ColorHelper.RedColor,
    fontSize: "12px",
  },
  formsize: {
    mt: 0,
    width: "100%",
    "& .MuiInputBase-root": {
      width: "auto",
    },
  },
  siderimg: {
    display: "flex !important",
    flexDirection: "column",
    "#reCaptcha-container": {
      display: "flex",
      justifyContent: { xs: "flex-start", sm: "center" },
      alignItems: "center",
      transform: { xs: "scale(70%) translateX(-10%)", sm: "none" },
      mt: 2,
    },
  },
  formone: {
    display: "flex !important",
    justifyContent: "flex-start",
    flexDirection: { xs: "column", md: "row" },
    flexWrap: "wrap",
    px: { xs: 2, md: 5 },
  },
  contbox: {
    textAlign: "center",
    px: { xs: "10px", md: "30px" },
    py: 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
    gap: { xs: 2, md: 4 },
  },

  listtype: {
    listStyleType: "disc",
    color: "#48197d",
    px: { xs: 6, sm: 6, md: 4, lg: 10 },
    marginTop: "10px",
  },
  Discountlabel: {

  }
};

export default PreorderStyle;
