import React from "react";
import ColorHelper from "../../Helpers/ColorHelper";

const RemoveFromCart = (props) => {
  const { color } = props;
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_429_3447)">
        <path
          d="M1.48133 0.423752L0.423828 1.48125L3.71633 4.77375L5.37383 8.26875L4.36133 10.1063C4.24133 10.3163 4.17383 10.5638 4.17383 10.8263C4.17383 11.6513 4.84883 12.3263 5.67383 12.3263H11.2688L12.3038 13.3613C12.0463 13.5459 11.854 13.8074 11.7545 14.1083C11.655 14.4091 11.6535 14.7338 11.7502 15.0356C11.8469 15.3373 12.0368 15.6006 12.2926 15.7876C12.5484 15.9746 12.857 16.0757 13.1738 16.0763C13.6763 16.0763 14.1188 15.8288 14.3888 15.4463L16.5188 17.5763L17.5763 16.5188L1.48133 0.423752ZM5.67383 10.8263L6.49883 9.32625H8.26883L9.76883 10.8263H5.67383V10.8263ZM15.4238 2.57625H5.76383L7.26383 4.07625H14.1563L12.0863 7.82625H11.0063L12.4613 9.28125C12.8663 9.17625 13.2038 8.91375 13.3988 8.55375L16.0838 3.68625C16.3613 3.19125 15.9938 2.57625 15.4238 2.57625V2.57625ZM5.67383 13.0763C4.84883 13.0763 4.18133 13.7513 4.18133 14.5763C4.18133 15.4013 4.84883 16.0763 5.67383 16.0763C6.49883 16.0763 7.17383 15.4013 7.17383 14.5763C7.17383 13.7513 6.49883 13.0763 5.67383 13.0763Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_429_3447">
          <rect width="18" height="18" fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RemoveFromCart;
