import ColorHelper from "../../Helpers/ColorHelper";
import FontHelper from "../../Helpers/FontHelper";

const FaqStyle = {
  container: {
    backgroundColor: ColorHelper.WhiteColor2,
    py: 4,
    px: { xs: 2, md: 8, lg: 40 },
  },
  questionLine: {
    display: "flex",
    alignContent: "space-between",
    flexDirection: "row",
    // flexWrap: 'nowrap",
    justifyContent: "space-around",
  },
  button: {
    display: "flex",
    alignContent: "space-between",
    flexDirection: "row",
    // flexWrap: 'nowrap",
    justifyContent: "space-around",
  },

  addcontent: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignContent: "center",
    px: { xs: 3, md: 5 },
    justifyContent: "space-evenly",
    backgroundColor: ColorHelper.WhiteColor4,
  },
  answerContent: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    alignContent: "center",
    px: { xs: 3, md: 5 },
    justifyContent: "space-evenly",
    backgroundColor: ColorHelper.WhiteColor2,
    width: "100%",
  },

  heading: {
    display: "flex",
    flexDirection: "column",
    flexwrap: "nowrap",
    alignContent: "space-around",
    justifyContent: "space-around",
    alignItems: "center",
    FontSize: "24px",
    FontFamily: FontHelper.fBold,
    py: { xs: 2, md: 0 },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    alignContent: "flex-start",
    justifyContent: "space-around",
    alignItems: "flex-start",
    FontSize: "24px",
    gap: 2,
    FontFamily: FontHelper.fBold,
    py: { xs: 1, md: 5 },
  },
  /*  addcontent: {
        Display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        alignContent: "center",
        alignItems: "center",
        justifyContent: "space-around"
    }, */

  link: {
    color: ColorHelper.LightGrey6,
    fontFamily: `${FontHelper.fRegular} !important`,
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 600,
    cursor: "pointer",
    backgroundColor: ColorHelper.LightGrey4,
  },

  text1: {
    fontSize: { xs: "26px", md: "30px", lg: "36px" },
    fontFamily: FontHelper.fBold,
    fontStyle: "normal",
    fontWeight: 600,
    textAlign: { xs: "center", md: "center" },
    px: { xs: 3, md: 15 },
  },
  text2: {
    fontSize: { xs: "16px", md: "30px", lg: "20px" },
    color: ColorHelper.DarkGrey1,
    fontFamily: FontHelper.fRegular,
    fontStyle: "normal",
    fontWeight: 400,
    textAlign: { xs: "center", md: "center" },
    px: { xs: 3, md: 15 },
    py: { xs: 0, md: 1 },
  },

  text4: {
    fontSize: "20px",
    fontFamily: FontHelper.fSemiBold,
    fontStyle: "normal",
    fontWeight: 500,
    px: { xs: 3, md: 15 },
    pt: { xs: 1, md: 3 },
  },
  text5: {
    fontSize: "18px",
    color: ColorHelper.DarkGrey1,
    fontFamily: FontHelper.fRegular,
    fontStyle: "normal",
    textAlign: "center",
    fontWeight: 400,
    px: { xs: 3, md: 15 },
    py: { xs: 0, md: 1 },
  },

  question: {
    color: ColorHelper.PrimaryColor,
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "space-around",
    py: { xs: 1, md: 2 },
    width: "100%",
    backgroundColor: ColorHelper.LightGrey4,
    borderRadius: "8px",
  },
  answer: {
    display: "flex",
    flexdirection: "row",
    flexwrap: "nowrap",
    alignContent: "center",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
  },
};

export default FaqStyle;
