import { padding } from "@mui/system";

const orderStyle = {
  mainbx: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    flexWrap: "wrap",
    padding: "20px",
    minHeight: "60vh",
  },
  formsize: {
    width: "70%",
    px: { xs: 0, md: 2 },
    "& .MuiInputBase-root": {
      width: { xs: "200px", md: "500px" },
      ml: { xs: 5, md: 0 },
    },
    ".MuiInputBase-input.Mui-disabled": {
      // WebkitTextFillColor: "#000",
      // color: "#000 !important"
    },
  },
};

export default orderStyle;
