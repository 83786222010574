import { Box } from "@mui/system";
import React, { useEffect } from "react";
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import SectionTitle3 from "../Components/Ui-Components/SectionTitle3/SectionTitle3";
import "./table.css";

import axios from "axios";
import { useState } from "react";
import { ApiManager } from "../ApiClient";
import MyRevenueList from "../Components/OrderHistory/MyRevenueList";
import { Typography } from "@mui/material";
import FontHelper from "../Helpers/FontHelper";

const OrganizerDashboard = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [style, setStyle] = useState("combo-box-demo");
  const [orders, setOrders] = useState([]);
  const [events, setEvents] = useState([]);

  const [updatedData, setUpdatedData] = useState(null);

  // setIsLoading(true);

  useEffect(() => {
    window.scrollTo(0, 100);
    window.scrollTo(0, 0);
    fetchOrgnEvents();
  }, []);

  const fetchOrgnEvents = async () => {
    const response = await axios.post(ApiManager.partnerShare(), {
      userid: localStorage.getItem("email"),
    });

    if (response.data.StatusCode === 200) {
      setIsLoading(false);
      setEvents(response.data.result.eventdetails);
    } else {
      setIsLoading(false);
      console.log("err: ", response.data.Message);
    }
  };

  return (
    <Box>
      <div style={{ display: isLoading ? "flex" : "none" }} className="modal">
        <div className="modal-content">
          <div className="loader"></div>
          <div className="modal-text">Processing...</div>
        </div>
      </div>
      {/* <div style={{maxWidth: "1200px", width: "100%", margin: "20px auto", padding: "0 20px"}}> */}
        {/* <Box> */}
          <>
            <Header />
            <div style={{maxWidth: "1200px", width: "100%", margin: "20px auto", padding: "0 20px"}}>
              <Box>
                <Typography className="evntRegd"
                  sx={{
                    fontFamily: FontHelper.fBold,
                    fontSize: "30px",
                    fontWeight: 700,
                    color: "#3F0E77",
                    py: 1
                    // px: 2,
                    // marginLeft: "80px",
                  }}
                >
                  My Share
                </Typography>
              </Box>
            </div>
            <MyRevenueList events={events} />
            <Footer />
          </>
        {/* </Box> */}
      {/* </div> */}
    </Box>
  );
};

export default OrganizerDashboard;
