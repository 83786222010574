import ImageHelper from "../../Helpers/ImageHelper";
import UltraPremiumPackageModal from "../Modals/PackagesModal/UltraPremiumPackageModal";

const FaqData = [
  {
    id: 1,
    question: "How do I find my photos?",
    answer:
      () => {
        return (
          <div>
            <p style={{ marginBottom: "10px" }}>
              Find your race - Enter all or part of the event name in the search box at the top left of the page.
            </p>
            <p>
              Enter your Bib number in the search box at the top right of the page or Search your photo manually in unlabeled section.
            </p>
          </div>
        )
      }
  },
  {
    id: 2,
    question: "I can’t find my photos",
    answer: () => {
      return (
        <div>
          <p>
            Search your photo manually in unlabeled section.
          </p>
          <div style={{ margin: "10px 0px" }}>
            <p >
              There are several reasons due to which a photo is not searchable. Few examples are:
            </p>
            <ol type="a" style={{ marginLeft: "8%" }}>
              <li>
                BIB is blocked by accessories/ hand.
              </li>
              <li>
                BIB is not pinned correctly and got folded during the race.
              </li>
              <li>
                BIB is not pinned and held in the hand or pinned on the side/ back.
              </li>
            </ol>
          </div>
          <p style={{ marginBottom: "10px" }}>
            (OR)
          </p>
          <p>
            Please drop a note with your Race name, date, location & BIB number, your email ID & mobile number via ‘Contact Us’ or on the offline message box available at the bottom right corner of the page. We shall get back in 48 hours.
          </p>
        </div>
      )
    }

  },
  {
    id: 3,
    question: "You emailed me a link to my photos but there are none",
    answer:
      "Regret the inconvenience. Please contact us with appropriate details (see above)",
  },
  ,
  {
    id: 4,
    question: "How do I order my photo?",
    answer:
      () => {
        return (
          <div>
            <p style={{ marginBottom: "10px" }}>
              Click on the photo you like. You’ll be presented an option ‘Add to cart’, check that option. You can add as many you want to the cart and then, click on ‘Checkout’.
            </p>
            <p>
              Review your order in ‘View Cart’, then click on ‘Pay’ to complete the delivery and payment details.
            </p>
          </div>
        )
      }
  },
  {
    id: 5,
    question: "I want to order more photos from the same event",
    answer:
      "Click on “Your race” to view more of your own images. To view images of a different person, return to the homepage, select the event and enter the person’s BIB number. You may select any other photos from the same event and add them to your shopping cart – even if they are of different competitors.  This is considered as separate order.",
  },
  {
    id: 6,
    question: "I am happy with my order and I am ready to pay",
    answer:
      () => {
        return (
          <div>
            <p>
              Make a final check of the order in your Shopping Cart. Click on “Pay”.
            </p>
            <p style={{ margin: "10px 0 " }}>
              If you include an email address, you will receive an automatic email when payment is successful.
            </p>
            <p>
              Your order details will be confirmed and an Order Number and email ID will appear on the screen. We suggest you print this page or save it on your computer. We recommend you keep a record of the order number.
            </p>
          </div>
        )
      }
  },
  {
    id: 7,
    question: "How do I pay?",
    answer: () => {
      return (
        <div>
          <p>
            We recommend payment by any card with a Visa or Mastercard logo (including Debit Plus and Maestro) as the option with the fastest processing time. Credit Card details may be entered on the Checkout page. This is a secure site. At the end of the transaction you will receive an order number. Keep this for reference in case of an enquiry. If you listed your email address at Checkout, you will receive an automatic email confirming your order number.
          </p>
          <p style={{ margin: "10px 0" }}>
            Bank Transfer/American Express Online and other payment options are available through PayU. If you choose one of these options, follow the prompts you receive.
          </p>
          <p>
            When all details have been entered, click “Submit”.
          </p>
        </div>
      )
    }
  },
  {
    id: 8,
    question: "Why do you want my email address?",
    answer:
      "We send an automatic email to confirm your order and another when it has been fulfilled. We also need your email address if you have ordered digital products for download.",
  },
  {
    id: 9,
    question: "How do I know my order has been accepted?",
    answer: () => {
      return (
        <div>
          <p>
            You order details will be confirmed and an Order Receipt will appear on the screen. You may print the receipt or save it on your computer. We recommend you keep a record of the order number.
          </p>
          <p style={{ margin: "10px 0" }}>
            When your order has been processed online, you will receive a confirmation email that includes the Order Number. Please keep a record of the Order Number until you receive your photos. You will need to quote it in any enquiries.
          </p><p>
            If you included your email address when placing your order, you will receive an automatic email when it is dispatched or ready to download.
          </p>
        </div>
      )
    }
  },
  {
    id: 10,
    question: "When will my order arrive?",
    answer:
      "Digital download images are usually available minutes after receipt of payment. At busy times, downloads may take longer. Please be patient.",
  },
  {
    id: 11,
    question: "Can I include another person’s images in my digital pack?",
    answer:
      "No, at the moment it's not allowed. An order can contain images related to single BIB only. You can write to us listing photoIDs of the photos from unlabeled section for further instructions.",
  },
  {
    id: 12,
    question:
      "Can I include images from unlabeled section after adding some or all images from bib search??",
    answer: "All orders are available for a single BIB number only.",
  },
  {
    id: 13,
    question: "How soon can I download my images?",
    answer:
      "Digital download images are usually available minutes after receipt of payment. At busy times, downloads may take longer. Please be patient.",
  },
  {
    id: 14,
    question: "How do I download my images?",
    answer: () => {
      return (
        <div>
          <p>
            When your order is ready, an automatic email is sent with a link directly to your download page.
          </p>
          <p style={{ margin: "10px 0" }}>
            You can also go to the homepage and click on “Download" on the right side of the site or click on "Download" button
          </p>
          <p>
            Enter your order number and BIB number or email address in the boxes, then click “Continue”.
          </p>
          <p style={{ marginTop: "10px" }}>
            To download images, click “Download” under each image or "Download all". This will save the image to your computer for viewing and printing at leisure. We recommend you save them to a CD for safe-keeping. On a low speed internet connections, download operation might timeout. In such scenarios, you may need to download individual image.
          </p>
        </div>
      )
    }
  },
  {
    id: 15,
    question: " How long do I have for downloading photos?",
    answer: "Photos remain online to download for 30 days.",
  },
  {
    id: 16,
    question:
      "My online downloads say “Expired” and I’ve lost the images from my computer",
    answer:
      "Your order has not been lost. Please “Contact Us”.  Describe the problem encountered, along with your Order number, correct email address and mobile number.  We will get back to you with a solution in 48 hours",
  },
  {
    id: 17,
    question:
      "I placed my order a long time ago and I haven’t received anything.",
    answer:
      "Please “Contact Us” with order number, email address and mobile number, payment date and any other information to help us locate your order.",
  },
  {
    id: 18,
    question: "I placed my order and had no contact at all.",
    answer:
      "We regret the inconvenience.  Please contact us with order number, email address, race name, bibnumber and mobile number.  We will get back to you with a solution in 48 hours.",
  },
  {
    id: 19,
    question: "Will I get photo paper prints?",
    answer:
      "No, for digital download orders, you'll receive only download link. There are options to print your selected photo on an article listed on the site. Hence, Users are requested to enter shipping address. And, Unless explicitly stated photo paper prints are NOT part of the delivery.",
  },
  {
    id: 20,
    question: "Are high resolution photos going to be available after payment?",
    answer:
      "Yes, high resolution photos of size between 2MB and 6MB will be available for download. These photos may have transparent race logo and oneglint logo overlay imprinted in the final downloadable copy, mostly at the bottom of the photo.",
  },
  {
    id: 21,
    question: "How soon can I receive printed articles?",
    answer:
      "Typically, article prints will be delivered within 2 weeks. There may be delays at times if our printing partners are having other orders. Your photos will be corrected for color, cropped for better fitment on the chosen article and passed onto our printing partners. They will schedule the prints and directly ship to your address.",
  },
  {
    id: 22,
    question: "I have pre-booked the raceday photos. How can I download them?",
    answer: () => {
      return (
        <div>
          <p>
            Map your registration reference to your bib here. If you pre-booked at the time of registration, your registration reference acts as pre-booking order number. If you pre-booked on our website, please check your inbox or spam mail to find order number (or) check "Orders History" by logging into the site. On successful submission, you'll receive confirmation notification. You can then login to website and download high resolution raceday photos associated with your bib.
          </p>
          <div style={{ marginTop: "10px" }}>
            <p>Terms and Conditions</p>
            <ul style={{ marginLeft: "8%" }}>
              <li>
                While effort is to ensure all participants are covered, for reasons beyond our control few may get left. Some reasons could be wearing BIB# wrongly, tailing other runners or running in groups etc.
              </li>
              <li>
                Incase if there are no race day photos available despite the bib is worn correctly, we would be limited to compensating the buyer with equivalent value or coupon for the upcoming race.
              </li>
            </ul>
          </div>
        </div>
      )
    }
  },
  {
    id: 23,
    question: "What Do I get in Ultra Premium Package?",
    answer: () => {
      return (
        <div>
          <p>
            A runner always wonders what s/he gets as a final deliverable, before deciding to purchase. Here is the list of various images you will be downloading on successful order booking.
          </p>
          <UltraPremiumPackageModal />
        </div>
      )
    }
  },

];

export default FaqData;
