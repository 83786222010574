export const ADD_TO_CART = "ADD_TO_CART";
export const FETCH_PRODUCTS = "FETCH_PRODUCTS";
export const SET_PRODUCTS = "SET_PRODUCTS";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const UPDATE_CART = "UPDATE_CART";
export const GET_CART_DETAILS = "GET_CART_DETAILS";
export const VIEW_CART = "VIEW_CART";
export const CHECKOUT = "CHECKOUT";
export const SAVE_CONFIG = "SAVE_CONFIG";
export const UNLABELED_PRODUCTS = "UNLABELED_PRODUCTS";
export const RESET = "RESET";
export const CLEAR_CART = "CLEAR_CART";
export const REMOVE_PRINT = "REMOVE PRINT";
export const SAVE_USER = "SAVE_USER";
export const TYPE_OF_PAGE = "TYPE_OF_PAGE";
export const MULTIPLE_BIB_PRODUCT = "MULTIPLE_BIB_PRODUCT";
export const ORDER_INFO = "ORDER_INFO";
export const PACKAGE_INFO = "PACKAGE_INFO";
export const CLEAR_ALL_DIGITAL_PHOTO = "CLEAR_ALL_DIGITAL_PHOTO";
export const SPONSOR_DISCOUNT = "SPONSOR_DISCOUNT";
export const SAVE_USER_ISD_CODE = "SAVE_USER_ISD_CODE"
