// Helpers
import ColorHelper from "../../../Helpers/ColorHelper";

const modalBGcolor = {
  backgroundColor: ColorHelper.WhiteColor,
};

const ImageUploadModalStyle = {
  modalOuter: {
    backgroundColor: ColorHelper.LightBlue,
    p: 3,
    maxWidth: { xs: "100%", sm: "70vw", lg: "60vw" },
    borderRadius: "20px",
  },
  CloseIconContainer: {
    justifyContent: "flex-end",
    pr: 3,
    pt: 2,
    position: "absolute",
    top: 0,
    right: -5,
    "&:hover": {
      backgroundColor: ColorHelper.WhiteColor,
    },
  },
  modalWrapper: {
    minHeight: "max-content",
    position: "relative",
    ...modalBGcolor,
  },
  modalHeader: {
    ...modalBGcolor,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    pl: 2,
    pt: 3,
    pb: 1,
    borderBottom: `1px solid ${ColorHelper.LightGrey1}`,
  },
  ModalTitle: {
    fontSize: "1.2rem",
    fontWeight: 600,
    color: ColorHelper.PrimaryColor,
  },
  modalContent: {
    ...modalBGcolor,
    p: 2,
  },
  main: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minWidth: "fit-content",
    maxWidth: { xs: "100%", sm: "50%", md: "50vw" },
    p: { xs: 0, md: 4 },
  },
  videoContainer: {
    width: { xs: "100%", md: "100%" },
  },
  moalFooter: {
    ...modalBGcolor,
    borderTop: `2px solid ${ColorHelper.lightGrey}`,
    py: 2,
  },
};

export default ImageUploadModalStyle;
