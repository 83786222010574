import { Box, Typography, Card, CardActions } from "@mui/material";
import React, { useState, useEffect } from "react";
import ColorHelper from "../../../Helpers/ColorHelper";
import ConstantHelper from "../../../Helpers/ConstantHelper";
import ImageHelper from "../../../Helpers/ImageHelper";
import SectionTitle3 from "../../Ui-Components/SectionTitle3/SectionTitle3";
import CartCardComp from "../CartCardComp/CartCardComp";
import CollageJourneyMapSectionData from "./CollageJourneyMapSectionData";
import IconHelper from "../../../Helpers/IconHelper";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FontHelper from "../../../Helpers/FontHelper";
import { useNavigate } from "react-router-dom";

const CollageJourneyMapSection = ({ collageCart, type, bibNumber }) => {
  const navigate = useNavigate();
  const CartCardDataAddCard = {
    title: ConstantHelper.AddMoreCollages,
    addMore: "/",
    addMoreItems: "/",
  };
  const [path, setPath] = useState("");
  useEffect(() => {
    const path = window.location.href.split("/").at(-1);
    setPath(path);
  }, [window.location.href]);
  useEffect(() => {
    localStorage.setItem("Orientation", "0");
  }, []);
  const [map, setMap] = useState("Horizontal");

  const HandleAddItemBtn = (url) => {
    type !== "unlabeled"
      ? navigate("/products")
      : navigate("/unlabeled-events");
  };

  const handlePlusBtn = (url) => {
    type !== "unlabeled"
      ? navigate("/products")
      : navigate("/unlabeled-events");
  };
  return (
    <Box sx={{ py: 3, position: "relative" }}>
      <SectionTitle3 title={ConstantHelper.CollageJourneyMapTitle} />
      {path !== "orderDetails" &&
      !path.includes("myorders") &&
      !path.includes("preauthdownload") ? (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              mb: 3,
              pr: { xs: 0, md: 10 },
            }}
          >
            <FormControl
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                width: "auto",
              }}
            >
              <FormLabel
                id="demo-row-radio-buttons-group-label"
                sx={{
                  fontFamily: FontHelper.fRegular,
                  color: `${ColorHelper.DarkGrey2} !important`,
                }}
              >
                Select Orientation : &nbsp;
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                defaultValue={"Horizontal"}
                sx={{
                  "span.MuiFormControlLabel-label": {
                    fontFamily: FontHelper.fMedium,
                    fontSize: "1.1rem",
                    color: ColorHelper.DarkGrey4,
                  },
                }}
              >
                <FormControlLabel
                  defaultChecked
                  value="Horizontal"
                  control={
                    <Radio
                      onClick={() => {
                        setMap("Horizontal");
                        localStorage.setItem("Orientation", JSON.stringify(0));
                      }}
                      value={"Horizontal"}
                      sx={{
                        "&.Mui-checked": {
                          color: ColorHelper.BlueColor,
                        },
                      }}
                    />
                  }
                  label="Horizontal"
                />
                <FormControlLabel
                  value="Vertical"
                  control={
                    <Radio
                      onClick={() => {
                        setMap("Vertical");
                        localStorage.setItem("Orientation", JSON.stringify(1));
                      }}
                      sx={{
                        "&.Mui-checked": {
                          color: ColorHelper.BlueColor,
                        },
                      }}
                    />
                  }
                  label="Vertical"
                />
              </RadioGroup>
            </FormControl>
            <Typography
              sx={{
                fontFamily: FontHelper.fRegular,
                fontSize: "0.9rem",
                color: ColorHelper.YellowColor2,
              }}
            >
              *Minimum 3 photos are mandatory to make collage
            </Typography>
          </Box>

          <Box
            id="CollageLayoutPreview"
            sx={{
              position: { xs: "static", md: "static" },
              right: "-40%",
              bottom: { xs: "0%", lg: "-10vh" },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: { xs: "row", sm: "row", md: "row" },
              pl: { xs: 0, md: "10%" },
              borderLeft: {
                xs: "none",
                md: `1px solid ${ColorHelper.LightGrey4}`,
              },
              borderBottom: {
                xs: `1px solid ${ColorHelper.LightGrey4}`,
                md: "none",
              },
              mb: { xs: 6, md: 0 },
              img: {
                maxWidth: { xs: "100%", md: "16.5vw" },
              },
            }}
          >
            {map == "Horizontal" ? (
              <img src={ImageHelper.HorizontalCollage} />
            ) : (
              <img src={ImageHelper.VerticalCollage} />
            )}
          </Box>
        </>
      ) : (
        ""
      )}

      <Box
        sx={{
          // display: "flex",
          // justifyContent: "flex-start",
          // alignItems: "flex-start",
          // flexDirection: "column",
          // rowGap: 3,
          // px: { xs: 5, sm: 5, lg: 10 },
          columnGap: { xs: 0, sm: 3 },
          rowGap: { xs: 3, sm: 3 },
          display: "grid",
          gridTemplateColumns: "1fr",
        }}
      >
        <CartCardComp
          collageCart={collageCart}
          CartCardData={[]}
          actionStyle={1}
        />
        {path !== "orderDetails" &&
          !path.includes("myorders") &&
          // bibNumber !== "selfiesearch" &&
          !path.includes("preauthdownload") && (
            <Card
              sx={{
                width: { xs: "100%", sm: "40%" },
                maxHeight: "100%",
                p: "20px",
                borderRadius: "5px 5px 0 0",
                textAlign: "center",
                marginRight: "20px",
              }}
              onClick={() => HandleAddItemBtn(CartCardDataAddCard.addMoreItems)}
            >
              <Box
                sx={{
                  borderRadius: "5px",
                  maxWidth: "100%",
                  aspectRatio: "1/1",
                  maxHeight: "100%",
                  objectFit: "cover",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  backgroundColor: ColorHelper.LightGreen,
                }}
              >
                <Box
                  onClick={() => handlePlusBtn(CartCardDataAddCard.addMore)}
                  sx={{
                    cursor: "pointer",
                  }}
                >
                  <IconHelper.PlusCirclular color={ColorHelper.GreenColor} />
                </Box>
                <Typography
                  sx={{
                    fontFamily: FontHelper.fBold,
                    fontSize: 18,
                    color: ColorHelper.BlackColor,
                    mt: 4,
                  }}
                >
                  {CartCardDataAddCard.title}
                </Typography>
              </Box>
              <CardActions sx={{ px: 0, py: 0 }}>
                <Box
                  disableripple={true}
                  onClick={() =>
                    HandleAddItemBtn(CartCardDataAddCard.addMoreItems)
                  }
                  sx={{
                    fontFamily: FontHelper.fBold,
                    fontSize: { xs: 14, lg: 18 },
                    textAlign: "center",
                    color: ColorHelper.WhiteColor,
                    backgroundColor: ColorHelper.GreenColor,
                    width: "100%",
                    px: "15px",
                    py: "10px",
                    borderRadius: "5px",
                    marginLeft: "auto",
                    mt: 2,
                    mb: 0,
                    cursor: "pointer",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    "&:hover": {
                      backgroundColor: ColorHelper.GreenColor,
                    },
                  }}
                >
                  {ConstantHelper.AddMoreCollages}
                </Box>
              </CardActions>
            </Card>
          )}
      </Box>
    </Box>
  );
};

export default CollageJourneyMapSection;
