import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import ColorHelper from "../../../Helpers/ColorHelper";
import FontHelper from "../../../Helpers/FontHelper";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { sponsorDiscount } from "../../../redux/shoppingActions";
import ClearIcon from '@mui/icons-material/Clear';
const SectionTitle3 = (props) => {
  const dispatch = useDispatch()
  const { title, sx, setOpenSponsorModal } = props;
  const location = useLocation()
  const findpath = location.pathname;
  const { appliedDiscount, discountedPhoto } = useSelector((state) => state.shop.sponsorDiscount)
  const cartPrice = useSelector((state) => state.shop.cartCheckout);
  const sponsorDetails = cartPrice?.getSponsorOffersDetails;
  const handleRemoveCoupon = () => {
    dispatch(sponsorDiscount({ apply: false, photoID: [] }))
  }
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginRight: "50px",
          pr: { xs: 0, md: 5, lg: 10 },
          my: 3,
          backgroundColor: ColorHelper.LightPurple,
        }}
      >
        <Typography
          sx={{
            fontFamily: FontHelper.fBold,
            fontSize: "18px",
            padding: "8px ",
            color: ColorHelper.PrimaryColor,
            ...sx,
          }}
        >
          {title}

        </Typography>
        {title == "Digital Downloads" && findpath =="/cart"&&  appliedDiscount && <div style={{  }}>
          <button onClick={handleRemoveCoupon} style={{
            border: "none",
            backgroundColor: "#9a1c27",
            color: "#fff",
            padding: "5px 10px",
            borderRadius: "5px",
            cursor: "pointer",
            float:"right"
          }}>
            {sponsorDetails.coupon_name} <span><ClearIcon style={{ verticalAlign: "middle", fontSize: "18px" }} /></span>
          </button>
        </div>}
        {/* <div style={{ display: "flex", width: "90%", justifyContent: "flex-end", cursor: "pointer" }} onClick={handleRemoveCoupon}>
          {title == "Digital Downloads" && appliedDiscount &&
            <Box sx={{ display: "flex", flexDirection: "row", width: "23%", backgroundColor: "#9a1c27", justifyContent: "center", alignItems: "center", borderRadius: "10px", cursor: "pointer" }}>
              <div>

                <button style={{ border: "none", background: "none", fontSize: "12px", color: "white" }}>
                  Remove {sponsorDetails.coupon_name}
                </button>
              </div>
              <div>
                <ClearIcon sx={{ fontSize: "12px", marginLeft: "4px", marginTop: "2px" }} />
              </div>
            </Box>}
        </div> */}
      </Box>

    </Box>
  );
};

export default SectionTitle3;
