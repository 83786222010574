import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useNavigate } from "react-router-dom";
import ColorHelper from "../../../Helpers/ColorHelper";
import IconHelper from "../../../Helpers/IconHelper";
import ImageHelper from "../../../Helpers/ImageHelper";
import SectionTitleStyle2 from "./SectionTitleStyle2";
import './SectionTitle2.css';
import { useSelector } from "react-redux";

const SectionTitle2 = (props) => {
  const { title, sx1, eventLogo, eventLogoShow, bgColor, type, page } = props;
  const state = useSelector((state) => state.shop.products);
  const navigate = useNavigate();
  return (
    <Box
      sx={{ ...SectionTitleStyle2.sectionHeading, backgroundColor: bgColor }}
    >
      <Box sx={SectionTitleStyle2.SectionHeadingLeftPart}>
        {/* {type == 'unlabeled' ? */}
          {/* <Box onClick={() => navigate("/unlabeled-events")} sx={SectionTitleStyle2.BackBtn}> */}
          <Box onClick={() => navigate(-1)} sx={SectionTitleStyle2.BackBtn}>
            <IconHelper.LeftArrow />
          </Box>
          {/* : <Box onClick={() => page == 'products' ? navigate("/") : localStorage.getItem("currPage") == "UnlabeledCart" ? navigate('/unlabeled-events') : navigate(-1)} sx={SectionTitleStyle2.BackBtn}>
            <IconHelper.LeftArrow />
          </Box>
        } */}
        {title !== undefined ? (
          <Typography sx={{ ...SectionTitleStyle2.title, ...sx1 }}>
            {title}
          </Typography>
        ) : (
          <Typography sx={{ ...SectionTitleStyle2.title, ...sx1 }}>
            No event selected
          </Typography>
        )}
      </Box>
      <Box
        sx={{
          border: `1px solid ${ColorHelper.LightPurple}`,
          width: "100%",
          mx: 3,
        }}
      ></Box>
      {eventLogo !== undefined ? (
        <Box sx={{ width: "190px" }}>
          <img
            src={eventLogo}
            style={{ objectFit: 'contain' }}
            alt="Event Logo"
          />
        </Box>
      ) : eventLogoShow ? (
        <IconHelper.DummyLogoPlaceholder width="50px" />
      ) : null}
    </Box>
  );
};

export default SectionTitle2;
