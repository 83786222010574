import { Box } from "@mui/system";
import React from "react";
import FooterNote from "../Components/FoooterNote/FooterNote";
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import { useLocation } from "react-router-dom";
import HeaderNote from "../Components/HeaderNote/HeaderNote";
import ProductCard from "../Components/PorductCard/ProductCard";
import SummarySection from "../Components/SummarySection/SummarySection";
import SummaryStickySection from "../Components/SummaryStickySection/SummaryStickySection";
import SectionTitle2 from "../Components/Ui-Components/SectionTitle2/SectionTitle2";
import { fetchUnlabeledProducts } from "../redux/shoppingActions";
import ConstantHelper from "../Helpers/ConstantHelper";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Pagination } from "@mui/material";
import ProductCardVideo from "../Components/PorductCard/ProductCardVideo";
import { useDispatch } from "react-redux";
import ColorHelper from "../Helpers/ColorHelper";
import IconHelper from "../Helpers/IconHelper";
import FontHelper from "../Helpers/FontHelper";
import { useNavigate } from "react-router-dom";
import LoaderComp from "../Components/Loader/LoaderComp";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import SummaryStickySectionUnlabeled from "../Components/SummaryStickySection/SummaryStickySectionUnlabeled";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const UnlabeledProductPage = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.shop.products);
  const cartDetails = useSelector((state) => state.shop.cartInfo);
  const [value, setValue] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState();
  const [numberOfPages, setNumberOfPages] = React.useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [products, setCurrentProducts] = useState(state);
  const navigate = useNavigate();
  const [reportPics, setReportPics] = useState(false);

  const theme = useTheme();
  const onMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleChange = (event, newValue) => {
    setIsLoading(true);
    localStorage.setItem("currPage", "Unlabeled");
    dispatch(
      fetchUnlabeledProducts(state.eventName, state.eventID, 50, newValue)
    );
  };

  const location = useLocation();
  const findPath = location.pathname;

  useEffect(() => {
    window.scrollTo(0, 100);
    window.scrollTo(0, 0);
    findPath === "/unlabeled-events" &&
      localStorage.setItem("currPage", "unlabeled-cart");
  }, []);

  useEffect(() => {
    setIsLoading(false);
    // alert("State changed")
    document.title = state.eventName + " | Unlabeled";
    const totalImages = state.total_image_count;
    const totalPages = totalImages / 50;
    setNumberOfPages(Math.round(totalPages));
    setCurrentProducts(state);
  }, [state]);

  return (
    <Box
      sx={{
        "#ImageTabPanel-TZ, #VideoTabPanel-TZ": {
          "> div": {
            px: "0px",
          },
        },
      }}
    >
      <div style={{ display: isLoading ? "flex" : "none" }} className="modal">
        <div className="modal-content">
          <div className="loader"></div>
          <div className="modal-text">Processing...</div>
        </div>
      </div>
      {state.s3images ? (
        <>
          <Header />
          <SectionTitle2
            title={state.eventName}
            eventLogo={state.eventLogo}
            eventLogoShow={true}
            page={"products"}
          />
          <HeaderNote priceInfo={state.priceInfo} />
          <SummarySection
            bibNumber={state.bibNumber}
            imagesCount={state.s3images.length}
            unlabledPage="true"
          />
          <Box
            sx={{
              width: "100%",
              px: { xs: 5, sm: 5, md: 5, lg: 10 },
              "#TabsContainer": {
                width: "min-content",
                margin: { xs: "auto", sm: "unset" },
              },
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              id="TabsContainer"
              sx={{
                color: ColorHelper.WhiteColor,
                borderRadius: "0px 0px 5px 5px",
                ".MuiTabs-indicator": {
                  display: "none",
                },
                ".Mui-selected": {
                  color: ColorHelper.WhiteColor,
                  backgroundColor: ColorHelper.PinkColor,
                  "svg path": {
                    fill: ColorHelper.WhiteColor,
                  },
                  "#PhotoTab, #VideoTab ": {
                    color: ColorHelper.WhiteColor,
                  },
                },
              }}
            >
              <Tab
                sx={{
                  color: ColorHelper.LightGrey8,
                  borderRadius: "0px 0px 0px 5px",
                }}
                label={
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: { xs: 1, md: 2 },
                    }}
                  >
                    <IconHelper.PhotoIcon />
                    <Typography
                      sx={{
                        color: ColorHelper.LightGrey8,
                        fontFamily: FontHelper.fBold,
                        fontSize: { xs: 12, md: 16 },
                      }}
                      id="PhotoTab"
                    >
                      Photos
                    </Typography>
                  </Box>
                }
                {...a11yProps(0)}
              />
            </Tabs>

            <TabPanel value={value} index={0} id="ImageTabPanel-TZ">
              {state?.s3images?.length !== 0 ? (
                <ProductCard
                  photo={products.s3images}
                  stateDetails={state}
                  cartDetails={cartDetails}
                  type={"unlabeled"}
                  reportPics={reportPics}
                />
              ) : (
                <Typography>
                  No Images found for this run. Click{" "}
                  <span
                    style={{ color: "#3F0E77", cursor: "pointer" }}
                    onClick={() => {
                      navigate("/");
                    }}
                  >
                    here
                  </span>{" "}
                  to return to home page..
                </Typography>
              )}
            </TabPanel>
          </Box>
          {numberOfPages > 0 && (
            <Box
              sx={{
                margin: "auto",
                ".MuiPagination-ul": {
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 2,
                  marginLeft: "16px",
                  marginRight: "16px",
                  my: 2,
                  "> li": {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: ColorHelper.PrimaryColor,
                    borderRadius: "50%",
                    aspectRatio: "1/1",
                    button: {
                      opacity: 1,
                    },
                  },
                  "button, div": {
                    color: ColorHelper.WhiteColor,
                  },
                  svg: {
                    fill: ColorHelper.WhiteColor,
                  },
                },
              }}
            >
              <Pagination
                count={numberOfPages}
                color="primary"
                size={onMobile == true ? "small" : "medium"}
                siblingCount={1}
                // showFirstButton
                // siblingCount={0}
                onChange={(e, value) => {
                  handleChange(e, value);
                }}
              />
            </Box>
          )}

          {cartDetails.length !== 0 ? <SummaryStickySectionUnlabeled /> : null}
          <FooterNote />
          <Footer />
        </>
      ) : (
        <LoaderComp />
      )}
    </Box>
  );
};
export default UnlabeledProductPage;
