import React from 'react'
import ColorHelper from '../../Helpers/ColorHelper'

const CartFilledIcon = () => {
    return (
        <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M1.25 1.5V3H2.75L5.45 8.6925L4.4375 10.5225C3.89 11.5275 4.61 12.75 5.75 12.75H14.75V11.25H5.75L6.575 9.75H12.1625C12.725 9.75 13.22 9.4425 13.475 8.9775L16.16 4.11C16.2232 3.99632 16.2556 3.8681 16.2541 3.73804C16.2525 3.60798 16.217 3.48058 16.1511 3.36845C16.0852 3.25631 15.9911 3.16334 15.8783 3.09871C15.7654 3.03409 15.6376 3.00006 15.5075 3H4.4075L3.7025 1.5H1.25ZM5.75 13.5C4.925 13.5 4.2575 14.175 4.2575 15C4.2575 15.825 4.925 16.5 5.75 16.5C6.575 16.5 7.25 15.825 7.25 15C7.25 14.175 6.575 13.5 5.75 13.5ZM11.7575 15C11.7575 14.175 12.425 13.5 13.25 13.5C14.075 13.5 14.75 14.175 14.75 15C14.75 15.825 14.075 16.5 13.25 16.5C12.425 16.5 11.7575 15.825 11.7575 15Z" fill={ColorHelper.WhiteColor} />
        </svg>

    )
}

export default CartFilledIcon