import { useState } from "react";

import {
  nameValidator,
  mobileNoValidator,
  emailValidator,
  genderValidator,
  shippingAddressValidator,
  pinValidator,
} from "../Validator.js";

const touchErrors = errors => {
  return Object.entries(errors).reduce((acc, [field, fieldError]) => {
    acc[field] = {
      ...fieldError,
      dirty: true,
    };
    return acc;
  }, {});
};

export const useLoginFormValidator = form => {
  const [errors, setErrors] = useState({
    name: {
      dirty: false,
      error: false,
      message: "",
    },
    isd_code: {
      dirty: false,
      error: false,
      message: "",
    },
    phone_number: {
      dirty: false,
      error: false,
      message: "",
    },
    email: {
      dirty: false,
      error: false,
      message: "",
    },
    gender: {
      dirty: false,
      error: false,
      message: "",
    },
    shipping_address: {
      dirty: false,
      error: false,
      message: "",
    },
    pincode: {
      dirty: false,
      error: false,
      message: "",
    },
  });

  const validateForm = ({ form, field, errors, forceTouchErrors = false }) => {
    let isValid = true;

    // Create a deep copy of the errors
    let nextErrors = JSON.parse(JSON.stringify(errors));

    // Force validate all the fields
    if (forceTouchErrors) {
      nextErrors = touchErrors(errors);
    }

    const { name, phone_number, email, gender, shipping_address, pincode } = form;

    if (nextErrors.name.dirty && (field ? field === "name" : true)) {
      const nameMessage = nameValidator(name, form);
      nextErrors.name.error = !!nameMessage;
      nextErrors.name.message = nameMessage;
      if (!!nameMessage) isValid = false;
    }


    if (nextErrors.phone_number.dirty && (field ? field === "phone_number" : true)) {
      const mobileNoMessage = mobileNoValidator(phone_number, form);
      nextErrors.phone_number.error = !!mobileNoMessage;
      nextErrors.phone_number.message = mobileNoMessage;
      if (!!mobileNoMessage) isValid = false;
    }

    if (nextErrors.email.dirty && (field ? field === "email" : true)) {
      const emailMessage = emailValidator(email, form);
      nextErrors.email.error = !!emailMessage;
      nextErrors.email.message = emailMessage;
      if (!!emailMessage) isValid = false;
    }

    if (nextErrors.gender.dirty && (field ? field === "gender" : true)) {
      const genderMessage = genderValidator(gender, form);
      nextErrors.gender.error = !!genderMessage;
      nextErrors.gender.message = genderMessage;
      if (!!genderMessage) isValid = false;
    }

    if (nextErrors.shipping_address.dirty && (field ? field === "shipping_address" : true)) {
      const shippingAddressMessage = shippingAddressValidator(shipping_address, form);
      nextErrors.shipping_address.error = !!shippingAddressMessage;
      nextErrors.shipping_address.message = shippingAddressMessage;
      if (!!shippingAddressMessage) isValid = false;
    }

    if (nextErrors.pincode.dirty && (field ? field === "pincode" : true)) {
      const pinCodeMessage = pinValidator(pincode, form);
      nextErrors.pincode.error = !!pinCodeMessage;
      nextErrors.pincode.message = pinCodeMessage;
      if (!!pinCodeMessage) isValid = false;
    }

    setErrors(nextErrors);

    return {
      isValid,
      errors: nextErrors,
    };
  };

  const onBlurField = e => {
    const field = e.target.name;
    const fieldError = errors[field];
    if (fieldError.dirty) return;

    const updatedErrors = {
      ...errors,
      [field]: {
        ...errors[field],
        dirty: true,
      },
    };

    validateForm({ form, field, errors: updatedErrors });
  };

  return {
    validateForm,
    onBlurField,
    errors,
  };
};
