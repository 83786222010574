import ImageHelper from "../../Helpers/ImageHelper";

const eventData = [{
    eventLogo:ImageHelper.EventLogo1,
    eventName:'Vedanta Delhi Half Marathon 2022',
    date: '16-Oct-2022',
},{
    eventLogo:ImageHelper.EventLogo1,
    eventName:'Vedanta Delhi Half Marathon 2022',
    date: '16-Oct-2022'
},
{
    eventLogo:ImageHelper.EventLogo1,
    eventName:'Vedanta Delhi Half Marathon 2022',
    date: '16-Oct-2022'
},
{
    eventLogo:ImageHelper.EventLogo1,
    eventName:'Vedanta Delhi Half Marathon 2022',
    date: '16-Oct-2022'
},
{
    eventLogo:ImageHelper.EventLogo1,
    eventName:'Vedanta Delhi Half Marathon 2022',
    date: '16-Oct-2022'
},
{
    eventLogo:ImageHelper.EventLogo1,
    eventName:'Vedanta Delhi Half Marathon 2022',
    date: '16-Oct-2022'
},
{
    eventLogo:ImageHelper.EventLogo1,
    eventName:'Vedanta Delhi Half Marathon 2022',
    date: '16-Oct-2022'
},
{
    eventLogo:ImageHelper.EventLogo1,
    eventName:'Vedanta Delhi Half Marathon 2022',
    date: '16-Oct-2022'
},
// {
//     eventLogo:ImageHelper.EventLogo1,
//     eventName:'Vedanta Delhi Half Marathon 2022',
//     date: '16-Oct-2022'
// }
]

export default eventData;
