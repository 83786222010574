import * as React from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  addToCart,
  addAllPrintToCart,
  removeFromCart,
  clearCart,
} from "../../redux/shoppingActions";
import { useDispatch, useSelector } from "react-redux";
import ColorHelper from "../../Helpers/ColorHelper";
import FontHelper from "../../Helpers/FontHelper";
import IconHelper from "../../Helpers/IconHelper";
import { Box, Grid } from "@mui/material";
import Modal from "@mui/material/Modal";
import { useState } from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import CancelIcon from "@mui/icons-material/Cancel";
import Masonry from "react-masonry-css";
import "./productstyle.css";
import ImageHelper from "../../Helpers/ImageHelper";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PrintIcon from "@mui/icons-material/Print";
import Carousel from "react-multi-carousel";
import SingleProductCard from "./SingleProductCard";
//masonary
// import Masonry from 'react-masonry-css'
import "./ProductStyles.css";
import PlusCirclular from "../../Assets/Icons/PlusCirclular";
import SingleProduct from "./Productimgsingle";
import ProductCardStyle from "./ProductCardStyle";
import SinglePrintProduct from "./SinglePrintProduct";
import "./ProductCard1.css";
import SingleCarousalProduct from "./SingleCarousalProduct";
import PopUpPrint from "./PopUpPrint";
import { useNavigate } from "react-router-dom";

export default function ProductMasonry({
  photo,
  breakpointColumnsObj,
  stateDetails,
  cartDetails,
  type,
  handleClose,
  handleOpen,
  handlePicOpen,
  reportPics,
  setReportedPhoto,
}) {
  return (
    <>
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {photo?.map((item, index) => (
          <SingleProduct
            index={index}
            photo={photo}
            stateDetails={stateDetails}
            cartDetails={cartDetails}
            item={item}
            typeOfProd={type}
            handleOpen={handleOpen}
            handleClose={handleClose}
            handlePicOpen={handlePicOpen}
            reportPics={reportPics}
            setReportedPhoto={setReportedPhoto}
            // handleReport={handleReport}
          />
        ))}
      </Masonry>
    </>
  );
}
