import React from 'react'
import ColorHelper from '../../Helpers/ColorHelper'

const SearchIcon = () => {
    return (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_311_4241)">
                <path d="M16.1455 14.5833H15.3226L15.0309 14.3021C16.2809 12.8438 16.9268 10.8542 16.5726 8.73959C16.083 5.84376 13.6663 3.53126 10.7497 3.17709C6.34342 2.63543 2.63509 6.34376 3.17676 10.75C3.53093 13.6667 5.84343 16.0833 8.73926 16.5729C10.8538 16.9271 12.8434 16.2813 14.3018 15.0313L14.583 15.3229V16.1458L19.0101 20.5729C19.4372 21 20.1351 21 20.5622 20.5729C20.9893 20.1458 20.9893 19.4479 20.5622 19.0208L16.1455 14.5833ZM9.89551 14.5833C7.30176 14.5833 5.20801 12.4896 5.20801 9.89584C5.20801 7.30209 7.30176 5.20834 9.89551 5.20834C12.4893 5.20834 14.583 7.30209 14.583 9.89584C14.583 12.4896 12.4893 14.5833 9.89551 14.5833Z" fill={ColorHelper.BlackColor} />
            </g>
            <defs>
                <clipPath id="clip0_311_4241">
                    <rect width="25" height="25" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default SearchIcon