import { Box } from "@mui/system";
import React, { useState, useEffect } from "react";
import CustomNavbar from "../Navbar/CustomNavbar";

const Header = (props) => {
  // Sticky Menu Area
  // const {isSignedIn,userName} = props;

  const isSignedIn = localStorage.getItem("isSignedIn");
  const userName = localStorage.getItem("Name");

  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });

  /* Method that will fix header after a specific scrollable */
  const isSticky = (e) => {
    const header = document.querySelector(".header-section");
    const scrollTop = window.scrollY;
    scrollTop >= 250
      ? header.classList.add("is-sticky")
      : header.classList.remove("is-sticky");
  };

  return (
    <Box
      className="header-section d-none d-xl-block"
      sx={{
        minWidth: "100%",
      }}
    >
      <CustomNavbar
        isSignedIn={isSignedIn}
        userName={userName}
        //cartInfo={props.currentCart}
        bib={props.bib}
      />
    </Box>
  );
};

export default Header;
