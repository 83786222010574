import React from "react";

const VideoIcon = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 3.162C2 2.79667 2.29667 2.5 2.662 2.5H13.338C13.7033 2.5 14 2.79667 14 3.162V13.838C13.9998 14.0135 13.93 14.1818 13.8059 14.3059C13.6818 14.43 13.5135 14.4998 13.338 14.5H2.662C2.48648 14.4998 2.3182 14.43 2.19409 14.3059C2.06998 14.1818 2.00018 14.0135 2 13.838V3.162ZM3.33333 3.83333V13.1667H12.6667V3.83333H3.33333ZM7.08133 6.11L10.334 8.278C10.3706 8.30234 10.4006 8.33535 10.4213 8.37408C10.4421 8.41281 10.4529 8.45606 10.4529 8.5C10.4529 8.54394 10.4421 8.58719 10.4213 8.62592C10.4006 8.66465 10.3706 8.69766 10.334 8.722L7.08067 10.89C7.04055 10.9166 6.99399 10.9318 6.94591 10.9341C6.89784 10.9364 6.85005 10.9256 6.80761 10.9029C6.76516 10.8802 6.72965 10.8465 6.70484 10.8052C6.68003 10.764 6.66684 10.7168 6.66667 10.6687V6.33133C6.66676 6.2831 6.67993 6.23579 6.70477 6.19445C6.72962 6.15311 6.76521 6.11929 6.80776 6.09657C6.85032 6.07386 6.89823 6.06312 6.9464 6.06548C6.99458 6.06784 7.04121 6.08323 7.08133 6.11Z"
        fill="#344054"
      />
    </svg>
  );
};

export default VideoIcon;
