import ColorHelper from "../../Helpers/ColorHelper";
import FontHelper from "../../Helpers/FontHelper";

const ContactStyle = {
  main: {
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    justifyContent: "center",
    alignItem: "center",
    px: { xs: 0, sm: 4, md: 4, lg: 10 },
    py: { xs: 0, sm: 5, md: 8, lg: 10 },
    "#formComp > div": {
      px: 0,
    },
  },
  labeltxt: {
    fontFamily: FontHelper.fRegular,
    color: ColorHelper.LightGrey8,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    py: { xs: 0, md: 0.5 },
  },
  form: {
    display: "flex",
    flexDirection: "column",
    flexwrap: "nowrap",
    alignContent: "space-around",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    height: "100%",
    pt: { xs: 4, md: 0 },
    px: { xs: 3, md: 0 },
  },
  formcard: {
    // px: { xs: 3, md: 15 },
  },
  text1: {
    fontSize: { xs: "26px", md: "36px" },
    fontFamily: FontHelper.fBold,
    fontStyle: "normal",
    fontWeight: 600,
    // px: { xs: 3, md: 15 },
  },
  text2: {
    fontSize: { xs: "16px", md: "20px" },
    color: ColorHelper.DarkGrey1,
    fontFamily: FontHelper.fRegular,
    fontStyle: "normal",
    fontWeight: 400,
    // px: { xs: 3, md: 15 },
    py: { xs: 0, md: 1 },
  },
  termstxt: {
    fontSize: "16px",
    color: ColorHelper.DarkGrey1,
    fontFamily: FontHelper.fRegular,
    fontStyle: "normal",
    fontWeight: 400,
    // px: { xs: 0, md: 0 },
    py: { xs: 0, md: 1 },
  },
  submitbtn: {
    fontSize: "20px",
    color: ColorHelper.DarkGrey1,
    fontFamily: FontHelper.fRegular,
    fontStyle: "normal",
    fontWeight: 400,
    width: "100%",
    py: { xs: 0, md: 1 },
    mt: { xs: 1, md: 2 },
  },
  imgContainer: {
    // maxWidth: '50%'
    width: { xs: "100%", lg: "unset" },
    px: { Xs: 0, sm: 2, lg: 4 },
    //height: {xs}
    img: {
      width: { xs: "100%", sm: "100%", lg: "auto" },
      height: { xs: "100%", sm: "100%", lg: "unset" },
    },
  },
};

export default ContactStyle;
