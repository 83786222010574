const PrivacyPolicyData = [
    {
      title: "Data You Provide",
      description: [
        {
          para: "When you register an account, subscribe to the Application, make an online payment, participate in a survey, subscribe to the Application free or paid plan, email us or in any other way submit your Personal Information to us, oneglint may collect, process and store the following data: your first and last name, your email, your phone number and postal address. We do not store your card details on any of our internal resources / databases, but transfer them instantly to the Payment Gateway Industry Data Security Standard compliant service provider for further processing. When receiving an online payment, we collect shipping and billing address, your card details: number, cardholder name, card number, expiry date and the Card Security Code (CSC) or the Card Verification Value (CVV). You will know when we gather your data through the oneglint website or Application, as you will provide your data on one of the Site’s web pages and perform an action that will authorize it to be sent to us. We will keep record of your purchases, any marketing-, sales- and support-related communication and replies, general Application usage statistics in order to provide you with the high level Application and support services.",
        },
      ],
    },
    {
      title: "Data We Collect Automatically",
      description: [
        {
          para: "oneglint may collect information through our Site and Application. The data we gather from your device: it’s type, IP address, the browser/ mobile app you are using, OS (operating system), as well as the referral site from which you entered the oneglint website. We may also monitor your online behavior, including the time of your visit, your online activity at the website including the URL clickstream through the oneglint website, the pages you visited, the time you spent viewing them, and the frequency of your visits. We gather this data automatically through the commonly used data-gathering technologies, like cookies and web beacons in order to understand how Customers and website visitors browse the Site and use the Application. This data helps us manage the Site and Application, analyze general online behavior and usage trends, and provide targeted advertisements. We also collect demographic information about our Application user and Site visitor base that helps us tailor both the Site’s content and the Application functionality in order to meet your expectations and requirements. When you browse the Site or use the Application, we collect Personal and Navigational Information along with the unique User IDs. In addition, we aggregate extra data that refers to the user flow, such as the start and the end of a web session, page views, purchase and checkout. We may associate this data with the information we store within the analytics software to Personal Information that you submitted to us. We do this to enhance our Application and Site content and UX, as well as use this data to improve the Application functionality, our marketing communication and analytics.",
        },
      ],
    },
    {
      title: "Facebook and Google Sign-in Authentication",
      description: [
        {
          para: 'You may log into our Site and Application using either Facebook or Google Sign-in authentication system. When you use either Facebook or Google Sign-in authentication, you give us access to your full name and email address. This data is stored by us in compliance with the Privacy Policy.',
        },
        {
          para: "oneglint and its officers, directors, employees, subsidiaries, affiliates, licensors and representatives, will not be responsible or liable for any damages of any kind including, without limitation, lost business or profits, direct, indirect, incidental, consequential, compensatory, exemplary, special or punitive damages that may result from your access to or use of either this Web site or the photographs DVDs, CDs or video clips.",
        },
      ],
    },
    {
      title: "Usage of reCaptcha",
      description: [
        {
          para: `oneglint uses reCaptcha version 2 (checkbox) to protect the Site and the Application from spam and abuse. reCaptcha version 2 is embedded on the oneglint email sign up, sign-in, guest checkout website pages. So, when a user signs up or signs in using an email or uses guest checkout, Google captures the necessary information in order to differentiate a human being from a bot. That is why, in this case the Google Privacy Policy and Terms and Conditions apply.`,
        },
      ],
    },
    {
      title: "Personal Information",
      description: [
        {
          para: "Under ‘Personal Information’ we understand any data that you of your own free will provide us with, such as your first and last name, phone number, your email address, company name, your posting and billing address, as well as any other information that refers to you or your workplace (business). We also collect Client ID that is assigned to your browser, and User ID that is associated with the use of the Application and your personal account. Personal Information also refers to other information that is available on the internet, such as from Facebook, LinkedIn, Twitter and Google, and includes any other publicly available data that you previously agreed to share and that is available from other service providers.",
        },
      ],
    },
    {
      title: "Navigational Information",
      description: [
        {
          para: "‘Navigational Information’ refers to your computer and your visits to the Site and Application such as your IP address, geographical location, browser type, referral source, length of visit and pages viewed. For more information, please visit the Navigational Information web page.",
        },
      ],
    },
    {
      title: "Sensitive Information",
      description: [
        {
          para: "oneglint reserve the right to make changes to this Web site, this Agreement, and the other information contained in this Web site at any time and without notice. Please refer to these User Terms when you visit the site as they may change from time to time.",
        },
      ],
    },
  ];
  
  export default PrivacyPolicyData;
  