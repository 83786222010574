import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import ImageHelper from '../../Helpers/ImageHelper';
import SingleProduct from '../PorductCard/Productimgsingle';



const Error404 = () => {

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        p: 4,
    }


    return (
        <div>
            <Header />
            <Box sx={{ position: 'relative', height: "300px" }}>
                <Box sx={style}>
                    <Typography variant="h5" component="h2" >
                        <img src={ImageHelper.erimage} alt='err' />
                        Error 404:<br />the server cannot find the requested resource.
                    </Typography>
                </Box>
            </Box>
            <Footer />

        </div>
    )
}

export default Error404;