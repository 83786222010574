import {
  Grid,
  Button,
  Typography,
  Divider,
  TextField,
  CardContent,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Footer from "../Footer/Footer";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Header from "../Header/Header";
import { Box } from "@mui/system";
import FontHelper from "../../Helpers/FontHelper";
import ConstantHelper from "../../Helpers/ConstantHelper";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import ContactStyle from "../Contact/ContactStyle";
import ColorHelper from "../../Helpers/ColorHelper";
import { Savings } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import axios from "axios";
import FilledButton from "../Ui-Components/Buttons/FilledButton/FilledButton";

import { ApiManager } from "../../ApiClient";
import DatePicker from "react-datepicker";
import PreorderStyle from "../PreOrder/Preorderstyle";
// import "react-datepicker/dist/react-datepicker.css";

import {
  mobileNoValidator,
  emailValidator,
  accountNoValidator,
  ifscCodeValidator,
  panCradValidator,
  firstNameValidator,
  lastNameValidator,
  nickNameValidator,
  accountNameValidator,
  bankNameValidator,
  branchNameValidator,
  accountTypeValidator,
} from "../Orderdetails/UserOrderDetails/Validator";

//Phone No. validation
const ifscRegEx = /^[A-Za-z]{4}\d{7}$/;
const accountNoRegEx = /^[0-9]{9,18}$/;
const panCardRegEx = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;

const EventRegistrationForm = () => {
  const snack = useSnackbar();
  let navigate = useNavigate();

  const [events, setEvents] = useState([]);
  const [eventSelectionDate, setEventSelectionDate] = useState([]);
  const [eventsFromDate, setEventsFromDate] = useState("");
  const [eventsToDate, setEventsToDate] = useState("");
  const [isVerified, setVerified] = useState(false);

  const [partnerId, setpartnerId] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [style, setStyle] = useState("combo-box-demo");
  const [selectedDate, setselectedDate] = useState([]);
  const [isButtonDisabled, setButtonDisabled] = useState(false);

  const [value, setValue] = React.useState("");
  const [isEdit, setisEdit] = useState("0");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState();

  const [emailId, setEmailId] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [nickName, setNickName] = useState("");
  const [accountName, setAccountName] = useState("");
  const [accountNo, setAccountNo] = useState("");
  const [bankName, setBankName] = useState("");
  const [branch, setBranch] = useState("");
  const [ifsc, setIfsc] = useState("");
  const [panNo, setPanNo] = useState("");
  const [message, setMessage] = useState("");
  const [checked, setChecked] = React.useState(false);
  const [checkedFromDate, setCheckedFromDate] = React.useState(false);
  const [checkedToDate, setCheckedToDate] = React.useState(false);
  const [startDate, setStartDate] = useState(new Date());

  const [isVerifiedEmailId, setVerifiedEmailId] = useState(false);
  const [isVerifiedFirstName, setVerifiedFirstName] = useState(false);
  const [isVerifiedLastName, setVerifiedLastName] = useState(false);
  const [isVerifiedPhoneNo, setVerifiedPhoneNo] = useState(false);
  const [isVerifiedNickName, setVerifiedNickName] = useState(false);
  const [isVerifiedAccountName, setVerifiedAccountName] = useState(false);
  const [isVerifiedAccountNo, setVerifiedAccountNo] = useState(false);
  const [isVerifiedAccountType, setVerifiedAccountType] = useState(false);
  const [isVerifiedBankName, setVerifiedBankName] = useState(false);
  const [isVerifiedBranch, setVerifiedBranch] = useState(false);
  const [isVerifiedIfsc, setVerifiedIfsc] = useState(false);
  const [isVerifiedPanNo, setVerifiedPanNo] = useState(false);
  const [isVerifiedFromDate, setVerifiedFromDate] = useState(false);
  const [isVerifiedToDate, setVerifiedToDate] = useState(false);
  const [isVerifiedTermsNCond, setVerifiedTermsNCond] = useState(false);
  const [isVerifiedNickNameDisabled, setisVerifiedNickNameDisabled] =
    useState(false);

  const accountTypeChange = (event) => {
    setValue(event.target.value);
  };

  // const removeDateFromArray = (eventsFromDate) => {
  //   const data = eventSelectionDate.map((data) => data === eventsFromDate)
  //   .filter((value) => value === true).length;
  // };

  const handleChangeFromDate = (date) => {
    if (!checkedFromDate) {
      eventSelectionDate.push(eventsFromDate);
    } else {
      const result = eventSelectionDate.filter((ele) => ele != date);
      setEventSelectionDate(result);
    }
    setCheckedFromDate(!checkedFromDate);
    setVerifiedFromDate(!checkedFromDate);
  };

  const handleChangeToDate = (date) => {
    if (!checkedToDate) {
      eventSelectionDate.push(eventsToDate);
    } else {
      const result = eventSelectionDate.filter((ele) => ele != date);
      setEventSelectionDate(result);
    }
    setCheckedToDate(!checkedToDate);
    setVerifiedToDate(!checkedToDate);
  };

  const handleChange = () => {
    setChecked(!checked);
    setVerifiedTermsNCond(!checked);
  };

  const headers = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(() => {
    document.title = "Event Registration";
    window.scrollTo(0, 100);
    window.scrollTo(0, 0);
    getEventDetails();
  }, []);

  const clearAllFieldBlank = () => {
    setEmailId("");
    setFirstName("");
    setLastName("");
    setPhoneNo("");
    setNickName("");
    setAccountName("");
    setAccountNo("");
    setValue("");
    setBankName("");
    setBranch("");
    setIfsc("");
    setPanNo("");
    setMessage("");
    setChecked("");
    setStartDate("");
  };

  const onSubmit = async () => {
    const inputDate = new Date(startDate);
    const year = inputDate.getFullYear();
    const month = String(inputDate.getMonth() + 1).padStart(2, "0"); // Month is zero-based
    const day = String(inputDate.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;

    if (!accountName) {
      setVerifiedAccountName("Bank name is required");
    } else if (accountName.length < 3) {
      setVerifiedAccountName("Bank name must have a minimum 3 characters");
    }
    setVerifiedAccountName(true);

    if (
      isVerifiedEmailId === true &&
      isVerifiedFirstName === true &&
      isVerifiedLastName === true &&
      isVerifiedPhoneNo === true &&
      isVerifiedNickName === true &&
      isVerifiedAccountName === true &&
      isVerifiedAccountNo === true &&
      isVerifiedBankName === true &&
      isVerifiedBranch === true &&
      isVerifiedPanNo === true &&
      isVerifiedTermsNCond === true &&
      isVerifiedIfsc === true
    ) {
      setIsLoading(true);

      await axios
        .post(
          ApiManager.registerPartner(),
          {
            eventId: selectedEvent,
            event_id: selectedEvent,
            emailId: emailId,
            form_type: isEdit,
            isterms: checked ? "1" : "0",
            bankifsc: ifsc,
            pannum: panNo,
            availabledate: eventSelectionDate,
            partnerremarks: message,
            nickname: nickName,
            firstname: firstName,
            lastname: lastName,
            mobilenumber: phoneNo,
            bankaccname: accountName,
            bankaccnum: accountNo,
            bankacctype: value === Savings ? 1 : 2,
            bankname: bankName,
            bankbranch: branch,
          },
          headers
        )
        .then((res) => {
          if (res.data.StatusCode === 200) {
            setIsLoading(false);

            if (res.data.HasError === true) {
              snack.enqueueSnackbar({
                variant: "warning",
                message: res.data.Message,
              });
            } else {
              snack.enqueueSnackbar({
                variant: "success",
                message: res.data.Message,
              });
              setTimeout(() => {
                localStorage.getItem("currPage") == "NotLoggedIn"
                  ? window.location.reload()
                  : navigate("/organization_dashboard");
              }, 1000);
            }
          } else {
            setIsLoading(false);
            snack.enqueueSnackbar({
              variant: "warning",
              message: res.data.Message,
            });
          }
        })
        .catch((err) => {
          setIsLoading(false);
          snack.enqueueSnackbar({
            variant: "warning",
            message: "Error",
          });
        });
      clearAllFieldBlank();
    }
  };

  const getEventDetails = async () => {
    setIsLoading(true);

    await axios
      .post(ApiManager.registerPartner())
      .then((res) => {
        if (res.data.StatusCode === 200) {
          setIsLoading(false);

          const response = res.data.result.eventslist;
          const searchableEvents = response.filter(
            (data) => data.issearchable == 1
          );
          setEvents(searchableEvents);
        } else {
          setIsLoading(false);

          snack.enqueueSnackbar({
            variant: "error",
            message: res.data.Message,
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const verifyEmail = async (emailId) => {
    if (!emailId) {
      setVerifiedEmailId("Email is required");
    } else if (!new RegExp(/\S+@\S+\.\S+/).test(emailId)) {
      setVerifiedEmailId("Incorrect email format");
    } else {
      setVerifiedEmailId(true);

      setIsLoading(true);
      await axios
        .post(ApiManager.registerPartner(), {
          eventId: localStorage.getItem("Partner Selected Event Id"),
          partnerid: emailId,
        })
        .then((res) => {
          if (res.data.StatusCode === 200) {
            setIsLoading(false);
            const str = res.data.Message;

            const first = str.split(" ")[0];

            // console.log("-----firstWords-----", first);
            if (first === "Welcome") {
              setisEdit("0");
              alert(res.data.Message);
            } else {
              setisEdit("1");
            }

            const response = res.data.result;
            setpartnerId(response.partners.partnerid);
            setEmailId(response.partners.partnerid);
            setVerifiedEmailId(true);

            setFirstName(response.partners.firstname);
            setVerifiedFirstName(true);

            setLastName(response.partners.lastname);
            setVerifiedLastName(true);

            setPhoneNo(response.partners.mobile);
            setVerifiedPhoneNo(true);

            setNickName(response.partners.partnername);
            setVerifiedNickName(true);

            setAccountName(response.partners.bankaccname);
            setVerifiedAccountName(true);

            setAccountNo(response.partners.bankaccnum);
            setVerifiedAccountNo(true);

            if (response.partners.bankacctype === 1) {
              setValue("Savings");
            } else {
              setValue("Current");
            }
            setVerifiedAccountType(true);

            setBankName(response.partners.bankname);
            setVerifiedBankName(true);

            setBranch(response.partners.bankbranch);
            setVerifiedBranch(true);

            setIfsc(response.partners.bankifsc);
            setVerifiedIfsc(true);

            setPanNo(response.partners.pannum);
            setVerifiedPanNo(true);

            if (response.partners.isterms === 1) {
              setChecked(true);
            } else {
              setChecked(false);
            }

            setNickName(response.partners.nickname);
            if (response.partners.nickname.length > 0) {
              setisVerifiedNickNameDisabled(true);
            }

            setMessage(response.partners.remarks);

            setVerifiedTermsNCond(true);
            let date = response.partners.datesavailable;

            // date = date.substring(1);
            // date = date.slice(0, -1);

            const dateParts = date.split("-");
            let year = dateParts[0];
            let month = dateParts[1];
            let day = dateParts[2];

            let timeStamp = Date.parse(day + " " + month + " " + year);
            let datee = new Date(year + "-" + month + "-" + day);

            setStartDate(datee);
          } else {
            setIsLoading(false);
            snack.enqueueSnackbar({
              variant: "error",
              message: res.data.Message,
            });
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  };

  return (
    <Box>
      <div style={{ display: isLoading ? "flex" : "none" }} className="modal">
        <div className="modal-content">
          <div className="loader"></div>
          <div className="modal-text">Processing...</div>
        </div>
      </div>

      <>
        <Header />

        <Box gridColumn="span 6" marginTop="12px">
          <Typography className="evntRegd"
            sx={{
              fontFamily: FontHelper.fBold,
              fontSize: "30px",
              fontWeight: 700,
              color: "#3F0E77",
              px: 2,
              marginLeft: "80px",
            }}
          >
            Event Registration
          </Typography>

          <Divider variant="middle" sx={{}} />

          <div style={{ maxWidth: "600px", width: "100%", margin: "20px auto", padding: "20px" }}>
            <>
              <Grid container sx={{ mx: 0 }}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{
                    my: 1,
                  }}
                >
                  <Autocomplete
                    onChange={(events, newValue) => {
                      setSelectedEvent(newValue.event_id);

                      setEventsFromDate(newValue.event_from_date);
                      setEventsToDate(newValue.event_to_date);

                      {
                        newValue == null
                          ? setStyle("")
                          : setStyle("afterSubmit");
                      }
                      localStorage.setItem(
                        "Partner Selected Event",
                        newValue.event_id + " - " + newValue.event_name
                      );
                      localStorage.setItem(
                        "Partner Selected Event Id",
                        newValue.event_id
                      );
                      let eventsFromDate = newValue.event_from_date;
                      let eventsToDate = newValue.event_to_date;
                      let eventLocation = newValue.event_location;

                      // console.log("eventsFromDate-----", eventsFromDate);
                      // console.log("eventsToDate-----", eventsToDate);
                      // console.log("eventLocation-----", eventLocation);

                      alert(
                        "Event is scheduled from " +
                          eventsFromDate +
                          " to " +
                          eventsToDate +
                          " at " +
                          eventLocation
                      );
                    }}
                    onInputChange={(events, newInputValue) => {
                      setInputValue(newInputValue);
                    }}
                    options={events}
                    getOptionLabel={(option) =>
                      option.event_name
                        ? option.event_id + " - " + option.event_name
                        : ""
                    }
                    freeSolo
                    size="small"
                    renderInput={(params) => (
                      <TextField
                        sx={{ fontFamily: FontHelper.fRegular }}
                        {...params}
                        placeholder={ConstantHelper.SerachFieldTitle}
                        inputProps={{
                          ...params.inputProps,
                          maxLength: 2,
                          onKeyPress: (event) => {
                            const charCode = event.which || event.keyCode;
                            const charStr = String.fromCharCode(charCode);
                            if (!/[A-Za-z]/.test(charStr)) {
                              event.preventDefault();
                            }
                          },
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} sx={{ my: 1 }}>
                  <TextField
                    sx={{ width: "100%" }}
                    label="Email"
                    variant="outlined"
                    value={emailId}
                    fullWidth
                    required
                    onChange={(e) => {
                      setVerifiedEmailId(emailValidator(e.target.value));
                      setEmailId(e.target.value);
                      verifyEmail(e.target.value);
                    }}
                  />
                  {isVerifiedEmailId !== "" && (
                    <Typography sx={PreorderStyle.formerror}>
                      {isVerifiedEmailId}
                    </Typography>
                  )}
                </Grid>

                <Grid
                  className="GridpaddingRight"
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  sx={{ my: 1, pr: 2 }}
                >
                  <TextField
                    label="First Name"
                    variant="outlined"
                    fullWidth
                    value={firstName}
                    required
                    autoFocus
                    onChange={(e) => {
                      setFirstName(e.target.value);
                      setVerifiedFirstName(firstNameValidator(e.target.value));
                    }}
                  />
                  {isVerifiedFirstName !== "" && (
                    <Typography sx={PreorderStyle.formerror}>
                      {isVerifiedFirstName}
                    </Typography>
                  )}
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={6} sx={{ my: 1 }}>
                  <TextField
                    label="Last Name"
                    variant="outlined"
                    fullWidth
                    value={lastName}
                    required
                    autoFocus
                    onChange={(e) => {
                      setLastName(e.target.value);
                      setVerifiedLastName(lastNameValidator(e.target.value));
                    }}
                  />
                  {isVerifiedLastName !== "" && (
                    <Typography sx={PreorderStyle.formerror}>
                      {isVerifiedLastName}
                    </Typography>
                  )}
                </Grid>

                <Grid
                  className="GridpaddingRight"
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  sx={{ my: 1, pr: 2 }}
                >
                  <TextField
                    label="Phone Number"
                    variant="outlined"
                    fullWidth
                    value={phoneNo}
                    required
                    autoComplete="Phone Number"
                    autoFocus
                    onChange={(e) => {
                      setPhoneNo(e.target.value);
                      setVerifiedPhoneNo(mobileNoValidator(e.target.value));
                    }}
                  />
                  {isVerifiedPhoneNo !== "" && (
                    <Typography sx={PreorderStyle.formerror}>
                      {isVerifiedPhoneNo}
                    </Typography>
                  )}
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={6} sx={{ my: 1 }}>
                  <TextField
                    disabled={isVerifiedNickNameDisabled}
                    label="Nick Name"
                    variant="outlined"
                    fullWidth
                    name="nickName"
                    value={nickName}
                    required
                    autoComplete="Nick Name"
                    autoFocus
                    onChange={(e) => {
                      setNickName(e.target.value);
                      setVerifiedNickName(nickNameValidator(e.target.value));
                    }}
                  />
                  {isVerifiedNickName !== "" && (
                    <Typography sx={PreorderStyle.formerror}>
                      {isVerifiedNickName}
                    </Typography>
                  )}
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} sx={{ my: 1 }}>
                  <TextField
                    label="Account Name"
                    variant="outlined"
                    fullWidth
                    name="accountName"
                    value={accountName}
                    required
                    autoComplete="Account Name"
                    autoFocus
                    onChange={(e) => {
                      setAccountName(e.target.value);
                      setVerifiedAccountName(
                        accountNameValidator(e.target.value)
                      );
                    }}
                  />
                  {isVerifiedAccountName !== "" && (
                    <Typography sx={PreorderStyle.formerror}>
                      {isVerifiedAccountName}
                    </Typography>
                  )}
                </Grid>

                <Grid
                  className="GridpaddingRight"
                  item
                  xs={12}
                  sm={6}
                  md={12}
                  lg={6}
                  sx={{ my: 1, pr: 2 }}
                >
                  <TextField
                    label="Account Number"
                    variant="outlined"
                    fullWidth
                    name="accountNumber"
                    value={accountNo}
                    required
                    autoComplete="Account Number"
                    autoFocus
                    onChange={(e) => {
                      setAccountNo(e.target.value);
                      setVerifiedAccountNo(accountNoValidator(e.target.value));
                    }}
                  />
                  {isVerifiedAccountNo !== "" && (
                    <Typography sx={PreorderStyle.formerror}>
                      {isVerifiedAccountNo}
                    </Typography>
                  )}
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  sx={{ my: 1 }}
                  className="datepicker_custom"
                >
                  <select
                    className="w-100"
                    value={value}
                    onChange={accountTypeChange}
                    style={{
                      width: "100%",
                      padding: "7px 10px",
                      border: "1px solid #ccc",
                      background: "none",
                      borderRadius: "3px",
                    }}
                  >
                    <option value="savings">Savings</option>
                    <option value="current">Current</option>
                  </select>

                  {isVerifiedAccountType !== "" && (
                    <Typography sx={PreorderStyle.formerror}>
                      {isVerifiedAccountType}
                    </Typography>
                  )}
                </Grid>

                <Grid
                  className="GridpaddingRight"
                  item
                  xs={12}
                  sm={6}
                  md={12}
                  lg={6}
                  sx={{ my: 1, pr: 2 }}
                >
                  <TextField
                    label="Bank Name"
                    variant="outlined"
                    fullWidth
                    name="bankName"
                    value={bankName}
                    required
                    autoComplete="Savings"
                    autoFocus
                    onChange={(e) => {
                      setBankName(e.target.value);
                      setVerifiedBankName(bankNameValidator(e.target.value));
                    }}
                  />
                  {isVerifiedBankName !== "" && (
                    <Typography sx={PreorderStyle.formerror}>
                      {isVerifiedBankName}
                    </Typography>
                  )}
                </Grid>

                <Grid
                  className="GridpaddingRight"
                  item
                  xs={12}
                  sm={6}
                  md={12}
                  lg={6}
                  sx={{ my: 1 }}
                >
                  <TextField
                    label="Branch"
                    variant="outlined"
                    fullWidth
                    name="branch"
                    value={branch}
                    required
                    autoComplete="Branch"
                    autoFocus
                    onChange={(e) => {
                      setBranch(e.target.value);
                      setVerifiedBranch(branchNameValidator(e.target.value));
                    }}
                  />
                  {isVerifiedBranch !== "" && (
                    <Typography sx={PreorderStyle.formerror}>
                      {isVerifiedBranch}
                    </Typography>
                  )}
                </Grid>

                <Grid
                  className="GridpaddingRight"
                  item
                  xs={12}
                  sm={6}
                  md={12}
                  lg={6}
                  sx={{ my: 1, pr: 2 }}
                >
                  <TextField
                    label="IFSC code"
                    variant="outlined"
                    fullWidth
                    name="ifsc"
                    value={ifsc}
                    required
                    autoComplete="IFSC code"
                    autoFocus
                    onChange={(e) => {
                      setIfsc(e.target.value);
                      setVerifiedIfsc(ifscCodeValidator(e.target.value));
                    }}
                  />
                  {isVerifiedIfsc !== "" && (
                    <Typography sx={PreorderStyle.formerror}>
                      {isVerifiedIfsc}
                    </Typography>
                  )}
                </Grid>

                <Grid item xs={12} sm={6} md={12} lg={6} sx={{ my: 1 }}>
                  <TextField
                    label="PAN No"
                    variant="outlined"
                    fullWidth
                    name="panNo"
                    value={panNo}
                    required
                    autoComplete="PAN No"
                    autoFocus
                    onChange={(e) => {
                      setPanNo(e.target.value);
                      setVerifiedPanNo(panCradValidator(e.target.value));
                    }}
                  />
                  {isVerifiedPanNo !== "" && (
                    <Typography sx={PreorderStyle.formerror}>
                      {isVerifiedPanNo}
                    </Typography>
                  )}
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  sx={{ my: 1 }}
                  className="datepicker_custom"
                >
                  {eventsFromDate ? (
                    <FormControlLabel
                      control={<Checkbox />}
                      label={eventsFromDate}
                      checked={checkedFromDate}
                      // onChange={handleChangeFromDate}
                      onChange={() => {
                        handleChangeFromDate(eventsFromDate);
                      }}
                    />
                  ) : (
                    ""
                  )}

                  {eventsToDate ? (
                    <FormControlLabel
                      control={<Checkbox />}
                      label={eventsToDate}
                      checked={checkedToDate}
                      // onChange={handleChangeToDate}
                      onChange={() => {
                        handleChangeToDate(eventsToDate);
                      }}
                    />
                  ) : (
                    ""
                  )}
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} sx={{ my: 1 }}>
                  <textarea
                    variant="outlined"
                    placeholder="Enter Camera and Availability details"
                    fullWidth
                    name="message"
                    multiline={true}
                    rows="4"
                    cols="40"
                    value={message}
                    style={{
                      padding: "10px",
                      maxWidth: "100%",
                      boxSizing: "border-box",
                      width: "100%",
                    }}
                    required
                    autoComplete="Remarks"
                    onChange={(e) => {
                      setMessage(e.target.value);
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} sx={{ my: 1 }}>
                  <Typography sx={{ fontSize: "22px", fontWeight: "bold" }}>
                    Terms of service
                  </Typography>
                  <div className="termCondition">
                    <p>
                      Read all of the below carefully & accept in toto. We have
                      taken all care to protect Photographers effort & skill,
                      while listing the below.
                    </p>
                    <ol className="EventRegdOl">
                      <li>
                        oneglint is the brand name owned by oneglint Media
                        Solutions Private Limited
                      </li>
                      <li>
                        By accepting the assignment, you would abide by the fair
                        practice policies of oneglint & Race Organisers
                      </li>
                      <li>
                        Privacy of participants has to be respected & this
                        photography is to cover the runners in action with no
                        demeaning or in compromising positions. Make sure yours
                        clicks are respectable & acceptable in all respects
                      </li>
                      <li>
                        You have to ensure you're stationed in the area
                        allocated & click as many pictures as possible, to
                        maximise your earning.
                      </li>
                      <li>
                        You would click pictures in a setting that's apt for
                        your camera, which would give an output in JPG of size
                        3-4MB per picture
                      </li>
                      <li>
                        While the need is to click participants with focus on
                        BIB#, you are advised to click the candid moments
                        happening around your area of allocation
                      </li>
                      <li>
                        Ensure you use the best possible ISO settings in your
                        camera while clicking as there is no Post Processing
                        involved
                      </li>
                      <li>
                        oneglint will have all rights to sell the Photographs
                        clicked by the Photographers during the event, to the
                        participants through its website with permission from
                        Race Organisers. Photographers would share the revenue
                        earned as per agreed terms.
                      </li>
                      <li>
                        oneglint is creating a platform for Photography
                        community & would respect your contribution, your skills
                        & right of usage. However due to limitations, we would
                        request you to refrain from sharing the photographs
                        taken during this event for 30 days, from the date of
                        the event in any social media
                      </li>
                      <li>
                        oneglint would make sure, post close of primary sale
                        window, transfer the agreed proceedings to your bank
                        account shared with us during registration. Kindly make
                        sure you provide correct details. Any wrong or missing
                        information may delay the payment process
                      </li>
                      <li>
                        Sale price is based on various costs that would incur in
                        creating this facility & oneglint has arrived at an
                        optimal share of the proceedings as compensation to the
                        photographers.
                      </li>
                      <li>
                        oneglint in all respects would make sure all the
                        collected data is mapped correctly to the photographer.
                        However due to unforeseen situations, if any jumbling
                        happens, oneglint would try to make sure to take
                        correction actions, as may be viable & will be responded
                        to
                      </li>
                      <li>
                        While oneglint is making all arrangements to maximise
                        the sale of the photographs, in case of any eventuality
                        like technical or operation issues beyond the control
                        arises, we would be in no position to compensate any
                        loss that may arise due to this
                      </li>
                      <li>
                        All Photographers should make sure they leave the copy
                        of Photographs taken during the event at the respective
                        desk, before leaving the venue
                      </li>
                      <li>
                        Incase of any disputes, oneglint's decision would be
                        final
                      </li>
                    </ol>
                  </div>
                </Grid>

                <FormControlLabel
                  required
                  control={<Checkbox />}
                  label="I Agree with Terms of Services"
                  checked={checked}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={12} lg={6} sx={{ my: 1 }}>
                <Button
                  sx={{
                    height: "50px",
                    width: "100%",
                    alignItems: "center",
                    flexDirection: "column",
                    backgroundColor: "#3f0e77",
                    color: "#FFFFFF",
                    marginBottom: "20px",
                    "&:hover": {
                      backgroundColor: ColorHelper.WhiteColor,
                      color: ColorHelper.PrimaryColor,
                      border: `1px solid ${ColorHelper.PrimaryColor}`,
                    },
                  }}
                  onClick={onSubmit}
                >
                  Submit
                </Button>
              </Grid>
            </>
          </div>
        </Box>
        <Footer />
      </>
    </Box>
  );
};

export default EventRegistrationForm;
