// Helper
import ConstantHelper from "../../Helpers/ConstantHelper";

const NavLinkData = [
  {
    menuTitle: ConstantHelper.MenuName1,
    menuLink: "/home",
    subMenu: [],
  },
  {
    menuTitle: ConstantHelper.MenuName2,
    menuLink: "/events",
    subMenu: [],
  },
  {
    menuTitle: ConstantHelper.MenuName3,
    menuLink: "/faqs",
    subMenu: [],
  },
  {
    menuTitle: ConstantHelper.MenuName4,
    menuLink: "/contact-us",
    subMenu: [],
  },
  // {
  //   menuTitle: ConstantHelper.MenuName5,
  //   menuLink: "/registration",
  //   subMenu: [],
  // },
];

export default NavLinkData;
