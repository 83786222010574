import ImageHelper from "../../../Helpers/ImageHelper";

const DigitalDownloadSectionData = [
    {
        id: 0,
        image: ImageHelper.ProductPageImage01,
    },
    {
        id: 1,
        image: ImageHelper.ProductPageImage02,
    },
    {
        id: 2,
        image: ImageHelper.ProductPageImage03,
    },
    {
        id: 3,
        image: ImageHelper.ProductPageImage01,
    },
    {
        id: 4,
        image: ImageHelper.ProductPageImage02,
    },
    {
        id: 5,
        image: ImageHelper.ProductPageImage03,
    },
    {
        id: 6,
        image: ImageHelper.ProductPageImage01,
    },
    {
        id: 7,
        image: ImageHelper.ProductPageImage02,
    },
    {
        id: 8,
        image: ImageHelper.ProductPageImage03,
    },
    {
        id: 9,
        image: ImageHelper.ProductPageImage01,
    },
    {
        id: 10,
        image: ImageHelper.ProductTotal
    }
];

export default DigitalDownloadSectionData;