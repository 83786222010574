import ImageHelper from "../../Helpers/ImageHelper";

const ProductCardData = [
    {
        id: 'pc01',
        img: ImageHelper.ProductPageImage08,
        AddToCart: "/",
        OrderPrint: "/",
        Collage: "/",
    },
    {
        id: 'pc02',
        img: ImageHelper.ProductPageImage02,
        AddToCart: "/",
        OrderPrint: "/",
        Collage: "/",
    },
    {
        id: 'pc03',
        img: ImageHelper.ProductPageImage03,
        AddToCart: "/",
        OrderPrint: "/",
        Collage: "/",
    },
    {
        id: 'pc04',
        img: ImageHelper.ProductPageImage04,
        AddToCart: "/",
        OrderPrint: "/",
        Collage: "/",
    },
    {
        id: 'pc05',
        img: ImageHelper.ProductPageImage05,
        AddToCart: "/",
        OrderPrint: "/",
        Collage: "/",
    },
    {
        id: 'pc06',
        img: ImageHelper.ProductPageImage06,
        AddToCart: "/",
        OrderPrint: "/",
        Collage: "/",
    },
    {
        id: 'pc07',
        img: ImageHelper.ProductPageImage07,
        AddToCart: "/",
        OrderPrint: "/",
        Collage: "/",
    },
    {
        id: 'pc08',
        img: ImageHelper.ProductPageImage08,
        AddToCart: "/",
        OrderPrint: "/",
        Collage: "/",
    },
    {
        id: 'pc09',
        img: ImageHelper.ProductPageImage09,
        AddToCart: "/",
        OrderPrint: "/",
        Collage: "/",
    },
    {
        id: 'pc10',
        img: ImageHelper.ProductPageImage10,
        AddToCart: "/",
        OrderPrint: "/",
        Collage: "/",
    },
    {
        id: 'pc11',
        img: ImageHelper.ProductPageImage11,
        AddToCart: "/",
        OrderPrint: "/",
        Collage: "/",
    },
    {
        id: 'pc12',
        img: ImageHelper.ProductPageImage12,
        AddToCart: "/",
        OrderPrint: "/",
        Collage: "/",
    },
];

export default ProductCardData;