import { Box } from "@mui/system";
import React, { useState, useEffect } from "react";
import ContactForm from "../Components/Contact/Contact";
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import axios from "axios";
import { ApiManager } from "../ApiClient";

const Contact = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [events, setEvents] = useState();
  const [featuredEvents, setFeaturedEvents] = useState();
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [userName, setUserName] = useState("");
  const [countryPhoneCode, setCountryPhoneCode] = useState();
  // var listOfEvents=[]

  useEffect(() => {
    document.title = "Contact Us";
    window.scrollTo(0, 100);
    window.scrollTo(0, 0);
  }, []);

  const fetchEvents = async () => {
    setIsLoading(true);

    await axios
      .get(ApiManager.getEventName())
      .then((res) => {
        setIsLoading(false);

        setCountryPhoneCode(res.data.country_codes);
        setEvents(res.data.result);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };
  const fetchEventsWithData = async () => {
    setIsLoading(true);
    const response = await axios.get(ApiManager.homePage());
    if (response.data.StatusCode === 200) {
      setIsLoading(false);

      setFeaturedEvents(response.data.result.feature_event);
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchEvents();
    fetchEventsWithData();
    setIsSignedIn(localStorage.getItem("isSignedIn"));
    if (localStorage.getItem("isSignedIn") == "yes") {
      setUserName(localStorage.getItem("Name"));
    } else {
      setUserName("Sign In!");
    }
  }, []);

  return (
    <Box>
      <div style={{ display: isLoading ? "flex" : "none" }} className="modal">
        <div className="modal-content">
          <div className="loader"></div>
          <div className="modal-text">Processing...</div>
        </div>
      </div>
      <>
        <Box>
          <Header isSignedIn={isSignedIn} userName={userName} />
          <ContactForm countryPhoneCode={countryPhoneCode} />
          <Footer />
        </Box>
      </>
    </Box>
  );
};
export default Contact;
