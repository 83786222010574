import ColorHelper from "../../Helpers/ColorHelper";
import FontHelper from "../../Helpers/FontHelper";

const TNCHeaderStyle = {
  SectionWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px 0px 8px",
    gap: "4px",
    boxSizing: "border-box",
    borderBottom: "1px solid #E4E7EC",
  },
  Title: {
    fontFamily: FontHelper.fSemiBold,
    color: ColorHelper.LightGrey8,
    fontWeight: "700",
    fontSize: { xs: 26, md: 36 },
    lineHeight: "44px",
    flex: "none",
    order: "0",
    alignSelf: "stretch",
    flexGrow: "0",
  },
  Caption: {
    fontFamily: FontHelper.fRegular,
    color: ColorHelper.LightGrey7,
    fontWeight: "400",
    fontSize: { xs: 14, md: 16 },
    lineHeight: { xs: "19px", md: "30px" },
    flex: "none",
    order: "1",
    alignSelf: "stretch",
    flexGrow: "0",
  },
};

export default TNCHeaderStyle;
