import { Box } from "@mui/system";
import React, { useEffect } from "react";
import FooterNote from "../Components/FoooterNote/FooterNote";
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import OrderStatus from "../Components/CartSections/OrderStatus/OrderStatus";

const OrderSucess = () => {
 
  return (
    <Box>
      <Header />
      <OrderStatus />
      <FooterNote />
      <Footer />
    </Box>
  );
};
export default OrderSucess;
