import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import { Box, Button, Grid, Typography } from "@mui/material";
import ImageHelper from "../../../Helpers/ImageHelper";
import FontHelper from "../../../Helpers/FontHelper";
import ColorHelper from "../../../Helpers/ColorHelper";
import IconHelper from "../../../Helpers/IconHelper";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ImageSlider from "./ImageComponent/ImageSlider";
import { SliderData } from "./ImageComponent/SiderData";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "80%", lg: "825px" },
  height: { lg: "500px" },
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  padding: { xs: 2, md: "24px" },
  gap: "24px",
  borderRadius: "5px",
  flex: "none",
  order: "0",
  alignSelf: "stretch",
  flexGrow: "0",
};
const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1619 },
    items: 1,
    slidesToSlide: 1,
    // partialVisibilityGutter: 50,
    // slidesToSlide: 3,
  },
  laptop: {
    breakpoint: { max: 1619, min: 1024 },
    items: 1,
    slidesToSlide: 1,
    arrows: true,
    // partialVisibilityGutter: 50,
    // slidesToSlide: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 640 },
    items: 1,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 639, min: 0 },
    items: 1,
    slidesToSlide: 1,
    arrows: false,
  },
};

const carouselParams = {
  additionalTransfrom: 0,
  autoPlay: true,
  autoPlaySpeed: 1000,
  centerMode: false,
  className: "",
  containerClass: "carousel-container",
  // customButtonGroup: <SliderCustomBtns />,
  dotListClass: "",
  draggable: true,
  focusOnSelect: false,
  infinite: true,
  itemClass: "",
  keyBoardControl: true,
  minimumTouchDrag: 80,
  customTransition: "all",
  transitionDuration: 100,
  renderButtonGroupOutside: true,
  renderDotsOutside: false,
  responsive: responsive,
  showDots: false,
  sliderClass: "",
  //   partialVisible: true
  // slidesToSlide: 1,
};

const PremiumPackageModal = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1619 },
      items: 1,
      slidesToSlide: 1,
      // partialVisibilityGutter: 50,
      // slidesToSlide: 3,
    },
    laptop: {
      breakpoint: { max: 1619, min: 1024 },
      items: 1,
      slidesToSlide: 1,
      arrows: true,
      // partialVisibilityGutter: 50,
      // slidesToSlide: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 640 },
      items: 1,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 639, min: 0 },
      items: 1,
      slidesToSlide: 1,
      arrows: false,
    },
  };

  const modalSliderData = [
    {
      title: "High Resolution Images",
      discription1: "Here is a sample high resolution image with overlay.",
      discription2:
        "The high resolution images will come with an overlay approved by Run organisers. Typically, the overlay will be at the bottom of the image. Sometimes, it may be on the top portion of image, mostly with Organiser logo and other logos as approved by Organiser.",
      image: ImageHelper.pacakgeModal1,
    },
    // {
    //   title: "High Resolution Images",
    //   discription1: "Here is a sample high resolution image with overlay.",
    //   discription2:
    //     "The high resolution images will come with an overlay approved by Run organisers. Typically, the overlay will be at the bottom of the image. Sometimes, it may be on the top portion of image, mostly with Organiser logo and other logos as approved by Organiser.",
    //   image: ImageHelper.pacakgeModal2,
    // },
    // {
    //   title: "High Resolution Images",
    //   discription1: "Here is a sample high resolution image with overlay.",
    //   discription2:
    //     "The high resolution images will come with an overlay approved by Run organisers. Typically, the overlay will be at the bottom of the image. Sometimes, it may be on the top portion of image, mostly with Organiser logo and other logos as approved by Organiser.",
    //   image: ImageHelper.pacakgeModal3,
    // },
  ];

  return (
    <div>
      <Button
        onClick={handleOpen}
        style={{ marginLeft: "-9px", textTransform: "none" }}
      >
        Click Here
      </Button>
      <span
        style={{
          fontFamily: FontHelper.fMedium,
          fontSize: 12,
          color: ColorHelper.DarkGrey4,
        }}
      >
        to view the sample high resolution photos
      </span>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            padding: "24px",
            gap: "8px",
            borderRadius: "10px",
            flex: "none",
            order: "0",
            flexGrow: "0",
          }}
        >
          <Box sx={style}>
            <Box
              style={{
                boxSizing: "border-box",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "flex-start",
                padding: { xs: 0, md: "0px 0px 8px 16px" },
                gap: { xs: "6px", md: "282px" },
                borderBottom: "1px solid #E4E7EC",
                flex: "none",
                order: "0",
                alignSelf: "stretch",
                flexGrow: "0",
              }}
            >
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                sx={{
                  fontFamily: FontHelper.fRegular,
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: { xs: 14, md: "20px" },
                  lineHeight: "24px",
                  color: ColorHelper.LightGrey6,
                  flex: "none",
                  order: "0",
                  flexGrow: "0",
                }}
              >
                What Do I get in Premium Package?
              </Typography>
              <Box
                onClick={handleClose}
                style={{
                  cursor: "pointer",
                }}
              >
                <IconHelper.CloseIcon
                  color={ColorHelper.RedColor}
                  bgcolor={ColorHelper.RedColor}
                />
              </Box>
            </Box>

            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "0px 18px",
                gap: "30px",
                flex: "none",
                order: "1",
                alignSelf: "stretch",
                flexGrow: "0",
              }}
            >
              <Grid
                container
                id="ModalContainer"
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: "0px",
                  gap: { xs: "20px", md: "60px" },
                  flex: "none",
                  order: "0",
                  flexGrow: "1",
                  flexWrap: { xs: "wrap", md: "nowrap" },
                }}
              >
                <Grid
                  item
                  xs={12}
                  md={8}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    padding: "0px",
                    gap: "8px",
                    flex: "none",
                    order: { xs: 2, md: 0 },
                    flexGrow: "1",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      padding: "0px",
                      gap: "4px",
                      flex: "none",
                      order: "0",
                      alignSelf: "stretch",
                      flexGrow: "0",
                    }}
                  >
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      sx={{
                        fontFamily: FontHelper.fRegular,
                        fontStyle: "normal",
                        fontWeight: "600",
                        fontSize: "20px",
                        lineHeight: "24px",
                        color: ColorHelper.LightGrey6,
                        flex: "none",
                        order: "0",
                        flexGrow: "0",
                      }}
                    >
                      High Resolution Images
                    </Typography>
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      sx={{
                        fontFamily: FontHelper.fRegular,
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "16px",
                        lineHeight: "19px",
                        color: "ColorHelper.DarkGrey7",
                        flex: "none",
                        order: "1",
                        alignSelf: "stretch",
                        flexGrow: "0",
                      }}
                    >
                      Here is a sample high resolution image with overlay.
                    </Typography>
                  </Box>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    sx={{
                      fontFamily: FontHelper.fRegular,
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "17px",
                      color: ColorHelper.LightGrey7,
                      flex: "none",
                      order: "1",
                      alignSelf: "stretch",
                      flexGrow: "0",
                    }}
                  >
                    The high resolution images will come with an overlay
                    approved by Run organisers. Typically, the overlay will be
                    at the bottom of the image. Sometimes, it may be on the top
                    portion of image, mostly with Organiser logo and other logos
                    as approved by Organiser.
                  </Typography>
                </Grid>
                {/* <Carousel {...carouselParams}> */}
                <Grid
                  item
                  xs={12}
                  md={4}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* <img src={ImageHelper.pacakgeModal1} alt="pacakgeModal" /> */}
                  <ImageSlider />
                </Grid>
                {/* </Carousel> */}
              </Grid>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default PremiumPackageModal;
