const EventListData = [
    {
        name: "NMDC Hyderabad Marathon 2022",
        label: "NMDC-Hyderabad-Marathon-2022",
    },
    {
        name: "TCS World 10K Bengaluru 2022",
        label: "TCS-World-10K-Bengaluru-2022",
    },
    {
        name: "Run for Girl Child 2022",
        label: "Run-for-Girl-Child-2022",
    },
    {
        name: "Club Run 2022",
        label: "Club-Run-2022",
    },
    { 
        name: "NMDC Hyderabad Marathon 2022",
        label: "NMDC-Hyderabad-Marathon-2022",
    },
    { 
        name: "NMDC Hyderabad Marathon 2022",
        label: "NMDC-Hyderabad-Marathon-2022",
    },
];

export default EventListData;