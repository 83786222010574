import { Box } from "@mui/system";
import React, { useState, useEffect } from "react";
import {
  Button,
  Container,
  Modal,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
} from "@mui/material";
import eventData from "../EventCard/EventData";
import EventCard from "../EventCard/EventCard";
import FontHelper from "../../Helpers/FontHelper";
import styled from "styled-components";
import ColorHelper from "../../Helpers/ColorHelper";
import FeatureEventsStyle from "../FeaturedEvents/FeatureEventsStyle";
import { reverse } from "lodash";
import { ApiManager } from "../../ApiClient";

const UpcomingAndPastEvents = (props) => {
  const { pastEvents, price, cutPrice, year, setYear, allYears } = props;

  const CustomEventButton = styled(Button)({
    backgroundColor: "#7F56D9",
  });
  useEffect(() => {}, []);

  const [selectedOption, setSelectedOption] = useState(null);

  const [yeardata, setYeardata] = useState([]);

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  useEffect(() => {
    if (pastEvents) {
      const arraydaya = reverse(Object.keys(pastEvents));
      setYeardata(arraydaya);
      setSelectedOption(arraydaya[0]);
    }
  }, []);
  return (
    <>
      <Box sx={{ backgroundColor: "#F2F4F7" }}>
        <Box
          sx={{
            marginLeft: { xs: "1.5rem", md: "5.5rem", lg: "5.5rem" },
            marginRight: { xs: "1.5rem", md: "5.5rem", lg: "5.5rem" },
            fontFamily: FontHelper.fBold,
            display: "flex",
          }}
        >
          <Typography
            variant="h6"
            sx={{ fontFamily: FontHelper.fBold, paddingTop: "12px" }}
          >
            Upcoming Events
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            flexWrap: "wrap",
            gap: { xs: 2, md: 3 },
            marginTop: "4px",
            mx: { xs: 2, md: 3, lg: 10 },
            borderTop: `1px solid ${ColorHelper.DarkGrey6}`,
            borderBottom: `1px solid ${ColorHelper.DarkGrey6}`,
            py: 5,
          }}
        >
          {props.upcomingEvents?.length > 0 &&
            props.upcomingEvents?.map((data, index) => (
              <EventCard
                image={ApiManager.url() + `${data.event_logo}`}
                eventName={data.event_name}
                date={data.event_from_date}
                type={"upcoming"}
                eventId={data.event_id}
                preAllowed={data.preorder_allowed}
              />
            ))}
          {props.upcomingEvents?.length === 0 && (
            <Grid
              container
              sx={[
                FeatureEventsStyle.CorouselInner,
                {
                  width: "auto",
                  marginLeft: "0px",
                  marginRight: "0px",
                  textAlign: "center",
                  margin: "0 auto",
                },
              ]}
            >
              No Upcoming Events Found
            </Grid>
          )}
        </Box>
      </Box>

      <Box sx={{ backgroundColor: "#F2F4F7" }}>
        <Box
          sx={{
            marginLeft: { xs: "1.5rem", md: "5.5rem", lg: "5.5rem" },
            marginRight: { xs: "1.5rem", md: "5.5rem", lg: "5.5rem" },
            fontFamily: FontHelper.fBold,
            display: "flex",
          }}
        >
          <Typography
            variant="h6"
            sx={{ fontFamily: FontHelper.fBold, paddingTop: "12px" }}
          >
            Past Events
          </Typography>

          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <Select value={selectedOption} onChange={handleChange}>
              <MenuItem disabled value="Year">
                Year
              </MenuItem>
              {yeardata &&
                yeardata.map((year) => {
                  return <MenuItem value={year}>{year}</MenuItem>;
                })}
            </Select>
          </FormControl>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            flexWrap: "wrap",
            gap: { xs: 2, md: 3 },
            marginTop: "4px",
            mx: { xs: 2, md: 3, lg: 10 },
            borderTop: `1px solid ${ColorHelper.DarkGrey6}`,
            borderBottom: `1px solid ${ColorHelper.DarkGrey6}`,
            py: 5,
          }}
        >
          {pastEvents &&
            pastEvents[selectedOption] &&
            pastEvents[selectedOption].map((item) => {
              if (item.event_logo && item.event_name && item.event_to_date) {
                return (
                  <EventCard
                    key={item.event_id}
                    // image={`https://test.photos.oneglint.com/${item.event_logo}`}
                    image={ApiManager.url() + `${item.event_logo}`}
                    eventName={item.event_name}
                    date={item.event_to_date}
                    preAllowed={item.preorder_allowed}
                  />
                );
              }
              return null;
            })}
        </Box>
      </Box>
    </>
  );
};

export default UpcomingAndPastEvents;
