import { Box, Typography } from "@mui/material";
import React from "react";
import ColorHelper from "../../Helpers/ColorHelper";
import FontHelper from "../../Helpers/FontHelper";
import IconHelper from "../../Helpers/IconHelper";

const HeroBottomStripe = () => {
  return (
    <Box
      sx={{
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: { xs: "flex-start", md: "center" },
        background: "rgba(255, 255, 255, 0.1)",
        width: "100%",
        zIndex: 1,
        bottom: 0,
        padding: { xs: "8px 16px", md: "20px 80px" },
      }}
    >
      <IconHelper.InfoIcon />
      <Typography
        sx={{
          fontFamily: FontHelper.fMedium,
          color: ColorHelper.WhiteColor,
          fontSize: { xs: 16, md: 16 },
          textAlign: { xs: "center", md: "left" },
          pl: "8px",
        }}
      >
       Tata Steel Kolkata 25K 2022 Finisher's videos will be available soon.
      </Typography>
    </Box>
  );
};

export default HeroBottomStripe;
