import CartIcon from "../Assets/Icons/CartIcon";
import UserLoginIcon from "../Assets/Icons/UserLoginIcon";
import UserPlus from "../Assets/Icons/UserPlus";
import LeftArrow from "../Assets/Icons/LeftArrow";
import CartPlusIcon from "../Assets/Icons/CartPlusIcon";
import PrintIcon from "../Assets/Icons/PrintIcon";
import CartFilledIcon from "../Assets/Icons/CartFilledIcon";
import RemoveFromCart from "../Assets/Icons/RemoveFromCart";
import PlusCirclular from "../Assets/Icons/PlusCirclular";
import PaymentCard from "../Assets/Icons/PaymentCard";
import CheckMarkIcon from "../Assets/Icons/CheckMarkIcon";
import CloseIcon from "../Assets/Icons/CloseIcon";
import DotIcon from "../Assets/Icons/DotIcon.svg";
import CameraIcon from "../Assets/Icons/CameraIcon";
import SearchIcon from "../Assets/Icons/SearchIcon";
import ImageUploadIcon from "../Assets/Icons/ImageUploadIcon.svg";
import ArrowIcon from "../Assets/Icons/ArrowIcon";
import Facebook from "../Assets/Icons/Facebook.svg";
import GitHub from "../Assets/Icons/GitHub.svg";
import LinkedIn from "../Assets/Icons/LinkedIn.svg";
import Twitter from "../Assets/Icons/Twitter.svg";
import Social_icon from "../Assets/Icons/Social_icon.svg";
import Logo from "../Assets/Logos/Logo.svg";
import ArrowEdge from "../Assets/Icons/ArrowEdge";
import InfoIcon from "../Assets/Icons/InfoIcon";
import HistoryIcon from "../Assets/Icons/HistoryIcon";
import KeyIcon from "../Assets/Icons/KeyIcon";
import LogoutIcon from "../Assets/Icons/LogoutIcon";
import WarningIcon from "../Assets/Icons/WarningIcon";
import PhotoIcon from "../Assets/Icons/PhotoIcon";
import VideoIcon from "../Assets/Icons/VideoIcon";
import UnlabledIcon from "../Assets/Icons/UnlabledIcon";
import ReturnArrow from "../Assets/Icons/ReturnArrow";
import CloseIcon2 from "../Assets/Icons/CloseIcon2";
import Instagram from "../Assets/Icons/Instagram";
import Youtube from "../Assets/Icons/Youtube";
import InstantDownloadCheck from "../Assets/Icons/InstantDownloadCheck.svg";
// import SackDollar from "../Assets/Icons/SackDollarSolid.svg";
import SackDollarIcon from "../Assets/Icons/SackDollarIcon";
import ChartLineIcon from "../Assets/Icons/ChartLineIcon";
import ChartLineSolid from "../Assets/Icons/ChartLineSolid.svg";
import { ReactComponent as Aatc } from "../Assets/Icons/Aatc.svg";
//PlaceHolders
import DummyLogoPlaceholder from "../Assets/Icons/DummyLogoPlaceholder";
import RedWarningIcon from "../Assets/Icons/RedWarningIcon";

import chevronDownIcon from "../Assets/Icons/chevronDownIcon";

const IconHelper = {
  //social
  Facebook,
  Instagram,
  Youtube,
  GitHub,
  LinkedIn,
  Twitter,
  Social_icon,
  // Testimonials
  CartIcon,
  UserLoginIcon,
  UserPlus,
  LeftArrow,
  CartPlusIcon,
  PrintIcon,
  CartFilledIcon,
  RemoveFromCart,
  PlusCirclular,
  PaymentCard,
  CheckMarkIcon,
  CloseIcon,
  DotIcon,
  CameraIcon,
  SearchIcon,
  ImageUploadIcon,
  ArrowIcon,
  Logo,
  Aatc,
  ArrowEdge,
  InfoIcon,
  HistoryIcon,
  KeyIcon,
  LogoutIcon,
  RedWarningIcon,
  // SackDollar,
  SackDollarIcon,
  ChartLineIcon,
  // ChartLineSolid,
  //Placeholder
  DummyLogoPlaceholder,
  WarningIcon,
  PhotoIcon,
  VideoIcon,
  UnlabledIcon,
  ReturnArrow,
  CloseIcon2,
  chevronDownIcon,

  InstantDownloadCheck,
};

export default IconHelper;
