import React from "react";

const DummyLogoPlaceholder = ({ width }) => {
  return (
    <div style={{ width: width }}>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 512 512"
      >
        <g>
          <g>
            <rect y="0.001" width="235.016" height="50.361" />
          </g>
        </g>
        <g>
          <g>
            <rect y="92.328" width="235.016" height="50.36" />
          </g>
        </g>
        <g>
          <g>
            <polygon points="0,184.656 0,235.016 71.344,235.016 163.672,235.016 235.016,235.016 235.016,184.656 		" />
          </g>
        </g>
        <g>
          <g>
            <rect x="461.638" y="0.001" width="50.361" height="235.016" />
          </g>
        </g>
        <g>
          <g>
            <rect x="369.31" y="0.001" width="50.36" height="235.016" />
          </g>
        </g>
        <g>
          <g>
            <polygon points="276.983,0.001 276.983,71.345 276.983,163.672 276.983,235.016 327.343,235.016 327.343,0.001 		" />
          </g>
        </g>
        <g>
          <g>
            <rect x="276.983" y="461.639" width="235.016" height="50.361" />
          </g>
        </g>
        <g>
          <g>
            <rect x="276.983" y="369.311" width="235.016" height="50.36" />
          </g>
        </g>
        <g>
          <g>
            <polygon points="440.656,276.984 348.327,276.984 276.983,276.984 276.983,327.344 512,327.344 512,276.984 		" />
          </g>
        </g>
        <g>
          <g>
            <rect y="276.984" width="50.361" height="235.016" />
          </g>
        </g>
        <g>
          <g>
            <rect x="92.328" y="276.984" width="50.36" height="235.016" />
          </g>
        </g>
        <g>
          <g>
            <polygon points="184.655,276.984 184.655,511.999 235.016,511.999 235.016,440.655 235.016,348.328 235.016,276.984 		" />
          </g>
        </g>
      </svg>
    </div>
  );
};

export default DummyLogoPlaceholder;
