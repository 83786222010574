import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import MenuItem from "@mui/material/MenuItem";
import { styled, alpha } from "@mui/material/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useLocation, useNavigate } from "react-router-dom";
// Custom
import CustomNavbarStyle from "./CustomNavbarStyle";

// Helpers
import ColorHelper from "../../Helpers/ColorHelper";
import FontHelper from "../../Helpers/FontHelper";
import ImageHelper from "../../Helpers/ImageHelper";

// Data
import NavLinkData from "../Navbar/NavLinkData";
import FilledButton from "../Ui-Components/Buttons/FilledButton/FilledButton";
import BorderedButton from "../Ui-Components/Buttons/BorderedButton/BorderedButton";
import ConstantHelper from "../../Helpers/ConstantHelper";
import IconHelper from "../../Helpers/IconHelper";
import { useSelector } from "react-redux";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import SimpleMiniDropDown from "../SimpleMiniDropDown/SimpleMiniDropDown";
import SummaryStickySection from "../SummaryStickySection/SummaryStickySection";
import React, { useState, useEffect } from "react";

const StyledMenu = styled((props) => (

  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));
const UserMenuData = [
  {
    Title: "Orders History",
    Icon: <IconHelper.HistoryIcon />,
    Link: "/order-history",
  },
  {
    Title: "Change Password",
    Icon: <IconHelper.KeyIcon />,
    Link: "/",
  },

  {
    Title: "Show Revenue",
    Icon: <IconHelper.SackDollarIcon />,
    Link: "/",
  },

  {
    Title: "Event Dashboard",
    Icon: <IconHelper.ChartLineIcon />,
    Link: "/",
  },

  {
    Title: "Log out",
    Icon: <IconHelper.LogoutIcon />,
    Link: "/",
  },
];

const CustomNavbar = ({ isSignedIn, userName, bib }) => {
  const userInfo = useSelector((state) => state.user.user)
  const [dropDownMenu, setDropDownMenu] = useState(UserMenuData)
  const bibId = localStorage.getItem("bibId");
  const [isClicked, setClicked] = useState(false);

  const theme = useTheme();
  const location = useLocation();

  const aboveMobile = useMediaQuery(theme.breakpoints.up("sm"));

  const [SimpleMiniDropDownState, setSimpleMiniDropDownState] = useState(false);

  const closeHandle = () => {
    setSimpleMiniDropDownState(!SimpleMiniDropDownState);
  };

  const cartInfo = useSelector((state) => state.shop.cartInfo);

  const [anchorElNav, setAnchorElNav] = React.useState(null); // for HambergerMenu
  const [anchorEl, setAnchorEl] = React.useState(null); // for Submenu
  const navigate = useNavigate();
  useEffect(() => {

    // window.scrollTo(0, 0);
    const result = UserMenuData.filter((ele) => {
      if (!userInfo.isOrganiser && ele.Title == "Event Dashboard") {
        return false;
      }
      if (!userInfo.isPartner && ele.Title == "Show Revenue") {
        return false;
      }
      return true
    })
    setDropDownMenu(result)
  }, []);

  const navigateAndHandle = () => { };

  // HambergerMenu Functions
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event?.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  // Submenu Functions
  const open = Boolean(anchorEl);
  const handleClick = (datalink, event) => {
    if (datalink == "/events") {
      window.open("https://oneglint.com/");
    } else {
      navigate(`${datalink}`);
      if (datalink == "/home") {
        window.location.reload();
      }
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const goHome = () => {
    navigate("/");
    window.location.reload();
  };

  const handleCoursePage = (url) => {
    navigate(`${url}`);
  };

  const handleCourseLandingPage = (url) => {
    navigate(`${url}`);
  };

  const [currentPath, setCurrentPath] = useState("");

  useEffect(() => {
    const paths = window.location.pathname.split("/");
    if (paths[1] !== "") {
      setCurrentPath(paths[1]);
    }
  }, [window.location.pathname]);

  const getMainMenuLinks = () =>
    NavLinkData.map((menuData,index) => (
      <Box sx={{ width: { xs: "100vw", md: "auto" } }} key={index}>
        <MenuItem
          key={menuData.menuTitle}
          disableRipple
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          variant="contained"
          disableelevation="true"
          onClick={() => handleClick(menuData.menuLink)}
          endicon={<KeyboardArrowDownIcon />}
          sx={{
            ...CustomNavbarStyle.liOfMenu,
            borderBottom: {
              xs: `1px solid ${ColorHelper.LightGrey4}`,
              md: "none",
            },
          }}
        >
          <Typography
            textAlign="center"
            className={`oneGlint-link-title
            ${`/${currentPath}` === menuData?.menuLink
                ? "oneGlint-active-link-title"
                : ""
              }
           `}
            sx={{
              fontFamily: FontHelper.fMedium,
              fontSize: 16,
              pl: 0,
              ml: 0,
              color: ColorHelper.DarkGrey4,
              ...(aboveMobile && {
                color: ColorHelper.DarkGrey3,
              }),
              "&:hover": {
                color: ColorHelper.PrimaryColor,
                fontWeight: "bold",
              },
              ...(aboveMobile && {
                "&:hover": {
                  color: ColorHelper.WhiteColor,
                  fontWeight: "medium",
                },
              }),
            }}
          >
            {menuData.menuTitle}
          </Typography>
          {/* {GetSubMenus()} */}
        </MenuItem>
      </Box>
    ));

  const NavBtnsComp = () => (
    <Box
      sx={{
        textAlign: { xs: "center", md: "unset" },
        width: { xs: "100%", md: "unset" },
        py: { xs: 2, md: 0 },
        // backgroundColor: ColorHelper.PrimaryColor,
        // boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
        zIndex: 3,
      }}
    >
      <BorderedButton
        btnText={
          cartInfo == undefined
            ? ConstantHelper.NavbarBnt1
            : ConstantHelper.NavbarBnt1 + `(${cartInfo.length})`
          // : ConstantHelper.NavbarBnt1 + `0`
        }
        color={ColorHelper.PrimaryColor}
        bgColor={ColorHelper.WhiteColor}
        bordercolor={ColorHelper.WhiteColor}
        startIcon={<IconHelper.CartIcon />}
        handleClick={() => {
          bibId === "unlabeled"
            ? navigate("/unlabeled-cart")
            : navigate("/cart");
        }}
        sx={{
          fontFamily: FontHelper.fSemiBold,
          fontWeight: 500,
          fontSize: { xs: 12, sm: 12, md: 16 },
          px: "19px",
          height: { xs: "auto", md: "50px" },
          border: { xs: "none", sm: `2px solid ${ColorHelper.WhiteColor}` },
        }}
      />
      <BorderedButton KeyboardArrowUpIcon
        handleClick={() => {
          setClicked(!isClicked);
          isSignedIn ? closeHandle() : navigate("/sign-in");
        }}
        btnText={isSignedIn ? userName : ConstantHelper.NavbarBnt2}
        color={ColorHelper.PrimaryColor}
        bgColor={ColorHelper.WhiteColor}
        startIcon={<IconHelper.UserLoginIcon />}
        endIcon={isClicked ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        sx={{
          position: "relative",
          fontFamily: FontHelper.fSemiBold,
          fontWeight: 500,
          fontSize: { xs: 12, md: 16 },
          height: { xs: "40px", sm: "50px" },

          px: "19px",
          fontWeight: 600,
        }}
        cstHover={{
          "&:hover": {
            backgroundColor: ColorHelper.PrimaryColorLight,
            color: ColorHelper.WhiteColor,
          },
        }}
      />
      {isSignedIn && SimpleMiniDropDownState && (
        <SimpleMiniDropDown onClose={closeHandle} UserMenuData={dropDownMenu} className="mobile_view" />
      )}
    </Box>
  );

  const NavBtnsCart = () => (
    <Box
      sx={{
        textAlign: { xs: "center", md: "unset" },
        width: { xs: "100%", md: "unset" },
        py: { xs: 2, md: 0 },
        // backgroundColor: ColorHelper.PrimaryColor,
        // boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
        zIndex: 3,
        "@media screen and (width: 768px)": {
          display: "none",
        },
      }}
    >
      <BorderedButton
        btnText={
          cartInfo == undefined
            ? ConstantHelper.NavbarBnt1
            : ConstantHelper.NavbarBnt1 + `(${cartInfo.length})`
          // : ConstantHelper.NavbarBnt1 + `0`
        }
        color={ColorHelper.WhiteColor}
        bordercolor={ColorHelper.WhiteColor}
        startIcon={<IconHelper.CartIcon />}
        handleClick={() => navigate("/cart")}
        sx={{
          fontFamily: FontHelper.fSemiBold,
          fontWeight: 500,
          fontSize: { xs: 12, md: 16 },
          px: "19px",
          height: { xs: "40px", sm: "50px" },
          border: { xs: "1px", sm: `2px solid ${ColorHelper.WhiteColor}` },
        }}
      />
      {isSignedIn && SimpleMiniDropDownState && (
        <SimpleMiniDropDown close={closeHandle} UserMenuData={dropDownMenu} className="web_view" />
      )}
    </Box>
  );

  const GetSubMenus = () => (
    <StyledMenu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
    >
      {NavLinkData[2].subMenu.map((subMenuData) => (
        <MenuItem
          onClick={() => navigate(`${subMenuData.submenuLink}`)}
          disableripple={true}
          sx={CustomNavbarStyle.MenuItem}
          key={subMenuData.subMenuId}
        >
          {subMenuData.submenunIcon}
          <Box
            sx={{ ...CustomNavbarStyle.SubMenuText }}
            className={subMenuData.subMenuClass}
          >
            <Typography sx={CustomNavbarStyle.subMenuTitle}>
              {subMenuData.submenuTitle}
            </Typography>
            <Typography sx={CustomNavbarStyle.subMenuCaption}>
              {subMenuData.submenuTitle}
            </Typography>
          </Box>
        </MenuItem>
      ))}
    </StyledMenu>
  );

  return (
    <AppBar position="static" sx={CustomNavbarStyle.AppBarStyle}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={CustomNavbarStyle.logoContainer} onClick={() => goHome()}>
            <img src={ImageHelper.Logo} alt="logo" />
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "flex", md: "none" },
              justifyContent: "flex-end ",
            }}
          >
            {/* <Box> {NavBtnsCart()}</Box> */}
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
              sx={{
                svg: {
                  width: "1.5em",
                  height: "1.5em",
                },
              }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
                transform: "translateY(2%)",
                ul: {
                  py: 0,
                },
              }}
            >
              {getMainMenuLinks()}
              {NavBtnsComp()}
            </Menu>
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "`flex" },
              justifyContent: "flex-end",
              alignItems: "center",
              // maxWidth: "50%",
              marginLeft: "auto",
            }}
          >
            {getMainMenuLinks()}
            {NavBtnsComp()}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default CustomNavbar;
