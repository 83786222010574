const NotesStyle = {
    NotesContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        py: 1,
    },
}


export default NotesStyle;