import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { CookiesProvider } from "react-cookie";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { Provider } from "react-redux";
import store from "./redux/store";
import { GoogleOAuthProvider } from "@react-oauth/google";

const root = ReactDOM.createRoot(document.getElementById("root"));
let persistor = persistStore(store);

root.render(
  <GoogleOAuthProvider clientId="690748914038-18tiske9npr33skj1jvpmi4s4fb33ara.apps.googleusercontent.com">
    <React.StrictMode className="App">
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <CookiesProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </CookiesProvider>
      </PersistGate>
    </Provider>
    </React.StrictMode>
  </GoogleOAuthProvider>
);
