import ConstantHelper from "../../../Helpers/ConstantHelper";
import ImageHelper from "../../../Helpers/ImageHelper";

const CollageJourneyMapSectionData = [
    {
        id: 0,
        title: "Photo 1",
        image: ImageHelper.ProductPageImage07,
    },
    {
        id: 1,
        title: "Photo 2",
        image: ImageHelper.ProductPageImage08,
    },
    {
        id: 2,
        title: "Photo 3",
        image: ImageHelper.ProductPageImage09,
    },
];

export default CollageJourneyMapSectionData;