import * as React from "react";
import Button from "@mui/material/Button";
import { Box } from "@mui/system";

export default function BorderedButton(props) {
  const {
    color,
    id,
    bgColor,
    bordercolor,
    beforeColor,
    btnText,
    startIcon,
    endIcon,
    fullWidth,
    sx,
    handleClick,
  } = props;
  return (
    <Button
      id={id}
      fullWidth={fullWidth}
      variant="outlined"
      onClick={handleClick}
      sx={{
        border: "2px solid",
        borderColor: bordercolor,
        color: color,
        backgroundColor: bgColor,
        mx: 1,
        px: { xs: 2, md: 4 },
        py: { xs: 0.7, md: 1.5 },
        fontWeight: 500,
        fontSize: { xs: 12, md: 16 },
        borderRadius: "6px",
        minWidth: "max-content",
        height: "fit-content",
        textTransform: "unset",
        ...sx,
        "&:hover": {
          // backgroundColor: color,
          color: bgColor,
          border: "2px solid",
          borderColor: bgColor,
        },
        "&:hover svg path": {
          fill: bgColor,
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {startIcon ? (
          <Box
            sx={{
              mt: 1,
              svg: {
                path: {
                  fill: color,
                  color: color,
                },
              },
            }}
          >
            {startIcon}
          </Box>
        ) : null}

        {btnText ? (
          <Box
            sx={{
              ...(startIcon && { ml: "0.2rem" }),
              ...(endIcon && { mr: "0.2rem" }),
            }}
          >
            {btnText}
          </Box>
        ) : null}

        {endIcon ? (
          <Box
            sx={{
              mt: 1,
              svg: {
                path: {
                  fill: color,
                  color: color,
                },
              },
            }}
          >
            {endIcon}
          </Box>
        ) : null}
      </Box>
    </Button>
  );
}
