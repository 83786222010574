import { Box } from "@mui/system";
import React from "react";
import FooterNote from "../Components/FoooterNote/FooterNote";
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import { useLocation, useNavigate } from "react-router-dom";
import HeaderNote from "../Components/HeaderNote/HeaderNote";
import ProductCard from "../Components/PorductCard/ProductCard";
import SummarySection from "../Components/SummarySection/SummarySection";
import SummaryStickySection from "../Components/SummaryStickySection/SummaryStickySection";
import SectionTitle2 from "../Components/Ui-Components/SectionTitle2/SectionTitle2";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ProductCardVideo from "../Components/PorductCard/ProductCardVideo";
import FontHelper from "../Helpers/FontHelper";
import ColorHelper from "../Helpers/ColorHelper";
import IconHelper from "../Helpers/IconHelper";
import FilledButton from "../Components/Ui-Components/Buttons/FilledButton/FilledButton";
import { clearCart } from "../redux/shoppingActions";
import LoaderComp from "../Components/Loader/LoaderComp";
import { FaCommentsDollar } from "react-icons/fa";
import PrivacyPolicyModal from "../Components/Modals/PrivacyPolicyModal/PrivacyPolicyModal";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

 
  const state = useSelector((state) => state);
  useEffect(() => {
    document.addEventListener("contextmenu", (e) => {
      e.preventDefault();
    });
    window.scrollTo(0, 100);
    window.scrollTo(0, 0);
    localStorage.setItem("currPage", "labeled");
  }, []);

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ProductPage = () => {
  const location = useLocation();
  
  const state = useSelector((state) => state.shop.products);

  const cartDetails = useSelector((state) => state.shop?.cartInfo);
  const [reportedPhoto, setReportedPhoto] = useState([]);
  const navigate = useNavigate();

  const [reportPics, setReportPics] = useState(false);

  const reportPicsHandle = () => {
    setReportPics(!reportPics);
  };

  const [value, setValue] = React.useState(0);
  const [loadPage, setLoadPage] = React.useState(false);
  const [privacyVerified , setPrivacyVerified] = useState(false);

  const dispatch = useDispatch();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    document.title = state.eventName + " | Select Photos";
  }, []);

  // useEffect(() => {
  //   if (data.thresholdPhotoCount) {
  //     console.log("thresholdData-----", data.thresholdPhotoCount);
  //   }
  // }, [data.thresholdPhotoCount]);

  return (
    <Box
      sx={{
        "#ImageTabPanel-TZ, #VideoTabPanel-TZ": {
          "> div": {
            px: "0px",
          },
        },
      }}
    >
      {state.photo ? (
        <>
          <Header />
          <SectionTitle2
            title={state.eventName}
            eventLogo={state.eventLogo}
            eventLogoShow={true}
            page={"products"}
          />
           <PrivacyPolicyModal privacyPolicy={state?.participantExists[0]} productInfo={state}/>  
          <HeaderNote priceInfo={state.priceInfo} />
          <SummarySection
            bibNumber={state.bibNumber}
            cartDetails={cartDetails}
            ReportBtn={true}
            unlabledPage="false"
            reportPics={reportPics}
            setReportedPhoto={setReportedPhoto}
            reportPicsHandle={reportPicsHandle}
            reportedPhoto={reportedPhoto}
            imagesCount={state.photo.length}
          />
          {!(state?.isVerified !== true && state?.isPrivacyCheckEnabled === true) ?<Box
            sx={{
              width: "100%",
              px: { xs: 5, sm: 5, md: 5, lg: 10 },
              "#TabsContainer": {
                width: "min-content",
                margin: { xs: "auto", sm: "unset" },
              },
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              id="TabsContainer"
              sx={{
                color: ColorHelper.WhiteColor,
                borderRadius: "0px 0px 5px 5px",
                ".MuiTabs-indicator": {
                  display: "none",
                },
                ".Mui-selected": {
                  color: ColorHelper.WhiteColor,
                  backgroundColor: ColorHelper.PinkColor,
                  "svg path": {
                    fill: ColorHelper.WhiteColor,
                  },
                  "#PhotoTab, #VideoTab ": {
                    color: ColorHelper.WhiteColor,
                  },
                },
              }}
            >
              <Tab
                sx={{
                  color: ColorHelper.LightGrey8,
                  borderRadius: "0px 0px 0px 5px",
                }}
                label={
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: { xs: 1, md: 2 },
                    }}
                  >
                    <IconHelper.PhotoIcon />
                    <Typography
                      sx={{
                        color: ColorHelper.LightGrey8,
                        fontFamily: FontHelper.fBold,
                        fontSize: { xs: 12, md: 16 },
                      }}
                      id="PhotoTab"
                    >
                      Photos
                    </Typography>
                  </Box>
                }
                {...a11yProps(0)}
              />
              {state.video?.length !== 0 ? (
                <Tab
                  sx={{
                    color: ColorHelper.LightGrey8,
                    borderRadius: "0px 0px 5px 0px",
                  }}
                  label={
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 2,
                      }}
                    >
                      <IconHelper.VideoIcon />
                      <Typography
                        sx={{
                          color: ColorHelper.LightGrey8,
                          fontFamily: FontHelper.fBold,
                          fontSize: { xs: 12, md: 16 },
                        }}
                        id="VideoTab"
                      >
                        Videos
                      </Typography>
                    </Box>
                  }
                  {...a11yProps(1)}
                />
              ) : null}
            </Tabs>
            {new Date().getTime() >
            new Date(localStorage?.getItem("awsImageExpiry")).getTime() ? (
              <Box
                sx={{
                  border: "1px solid #F5EAEA",
                  borderRadius: "10px",
                  px: "20px",
                  py: "50px",
                  display: "grid",
                  placeItems: "center",
                  my: "50px",
                  fontWeight: "bold",
                }}
              >
                <span style={{}}>
                  Oops !!..Your search session is expired please serach again
                  get the valid images
                </span>
                <FilledButton
                  handleClick={() => {
                    localStorage?.removeItem("awsImageExpiry");
                    navigate("/home");
                    dispatch(clearCart());
                  }}
                  btnText={"Go back"}
                  color={ColorHelper.PrimaryColor}
                  bgColor={ColorHelper.WhiteColor}
                  bordercolor={ColorHelper.WhiteColor}
                  sx={{
                    position: "relative",
                    fontFamily: FontHelper.fSemiBold,
                    fontWeight: 500,
                    fontSize: { xs: 12, md: 16 },
                    height: { xs: "40px", sm: "50px" },
                    px: "19px",
                    fontWeight: 600,
                    my: "20px",
                  }}
                  cstHover={{
                    "&:hover": {
                      backgroundColor: ColorHelper.PrimaryColorLight,
                      color: ColorHelper.WhiteColor,
                    },
                  }}
                />
              </Box>
            ) : (
              <>
                <TabPanel value={value} index={0} id="ImageTabPanel-TZ">
                  <ProductCard
                    photo={state.photo}
                    stateDetails={state}
                    cartDetails={cartDetails}
                    reportPics={reportPics}
                    setReportedPhoto={setReportedPhoto}
                    loadPage={loadPage}
                    setLoadPage={setLoadPage}
                  />
                </TabPanel>
                <TabPanel value={value} index={1} id="VideoTabPanel-TZ">
                  <ProductCardVideo
                    photo={state.video}
                    stateDetails={state}
                    cartDetails={cartDetails}
                    reportPics={reportPics}
                  />
                </TabPanel>
              </>
            )}
          </Box>: <Box sx={{height:"30%"}}></Box>
          }
          {cartDetails?.length !== 0 ? <SummaryStickySection /> : null}
          <FooterNote />
          <Footer />{" "}
        </>
      ) : (
        <LoaderComp />
      )}
    </Box>
  );
};

export default ProductPage;
