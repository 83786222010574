import {
  Typography,
  Button,
  Grid,
  TextField,
  List,
  Link,
  ListItem,

} from "@mui/material";
import CountryCodeData from "../../Helpers/CountryCodeData";
import { Box } from "@mui/system";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import React from "react";
import FontHelper from "../../Helpers/FontHelper";
import ColorHelper from "../../Helpers/ColorHelper";
import PreorderStyle from "./Preorderstyle";
import PaymentIcon from "@mui/icons-material/Payment";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import RecaptchaConfigs from "../../Helpers/RecaptchaConfigs";
import ReCAPTCHA from "react-google-recaptcha";
import { Scale } from "@mui/icons-material";
import ConstantHelper from "../../Helpers/ConstantHelper";
import { ApiManager } from "../../ApiClient";
import { useSnackbar } from "notistack";

import {
  mobileNoValidator,
  emailValidator,
  nameValidator,
  registrationRefValidation,
} from "../Orderdetails/UserOrderDetails/Validator";

const Preorderfile = ({
  image,
  eventName,
  date,
  fromDate,
  toDate,
  eventId,
  price,
  cutPrice,
}) => {
  const snack = useSnackbar();

  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [registrationRef, setRegistrationRef] = useState("");

  const [checkoutInfo, setCheckoutInfo] = useState({});
  const [isVerifiedName, setVerifiedName] = useState(false);
  const [isVerifiedEmail, setVerifiedEmail] = useState(false);
  const [isVerifiedNumber, setVerifiedNumber] = useState(false);
  const [isValidRegistrationRef, setValidRegistrationRef] = useState(false);
  const [isVerified, setVerified] = useState(false);
  const [isdCode, setIsdCode] = useState("+91");
  const [filteredCountryCode, setFilteredCountrycode] = useState([]);
  const [openDropDown, setOpenDropDown] = useState(false);

  // const [couponVisible, setCouponVisible] = useState(false);
  // const [coupon, setCoupon] = useState(null);
  // const [couponAppliedPrice, setCouponAppliedPrice] = useState();
  const recaptchaRef = React.useRef();

  const handleOnChangeRecaptcha = () => {
    setVerified(true);
  };
  const redoCaptcha = () => {
    this.reset();
    this.execute();
  };
  // const handleApplyCoupon = () => {
  //   setCouponAppliedPrice(899);
  // }

  const handleSubmit = async () => {
    setVerifiedName(nameValidator(name));
    setVerifiedEmail(emailValidator(email));
    setVerifiedNumber(mobileNoValidator(number));
    // setValidRegistrationRef(registrationRefValidation(registrationRef));
    const recaptchaValue = recaptchaRef.current.getValue();



    let createPreorder = {
      //newUserFlg: localStorage.getItem("isSignedIn") ? "" : 1,
      newUserFlg: "1",
      userID: checkoutInfo?.length > 0 ? checkoutInfo.id : "",
      name: name,
      email: email,
      phone_number: number,
      isdCode: isdCode,
      photo_count: "1",
      instruction: "prebook order",
      eventID: eventId,
      bibNumber: "prebook",
      amount: price,
      eventName: eventName,
      registration_ref: "NA",
      gCaptchToken: recaptchaValue,
      secretKey: RecaptchaConfigs.Secretkey,
    };

    if (
      isVerifiedName === true &&
      isVerifiedEmail === true &&
      isVerifiedNumber === true
      // && isValidRegistrationRef === true
    ) {
      const response = await axios.post(ApiManager.preOrder(), {
        ...createPreorder,
      });

      if (response.data.StatusCode === 200) {
        let paymentPayload = {
          amount: response.data.result.amount,
          firstname: response.data.result.name,
          email: response.data.result.email,
          phone: response.data.result.phone,
          productinfo: response.data.result.productinfo,
          txnid: response.data.result.txnid,
          furl: ApiManager.fURL(),
          surl: ApiManager.orderSuccess(),
          hash: response.data.result.hash,
          key: response.data.result.key,
          paymentUrl: response.data.result.PAYU_API_URL,
        };
        // console.log("---preOrder paymentPayload---",paymentPayload);       
        navigate("/order-confirmation", { state: paymentPayload });
      } else {
        snack.enqueueSnackbar({
          variant: "error",
          message: response.data.Message,
        });
      }
    }
  };
  const userIsdCode = useSelector((state) => state.user.isdCode);
  useEffect(() => {
    setIsdCode(userIsdCode);
    const userId = localStorage.getItem("ID");
    // userId && checkoutUserInfo(userId);
  }, []);
  const toggleDropDown = () => {
    setOpenDropDown(true);
  }
  const handleFilter = (e) => {
    setIsdCode(e.target.value)
    const result = CountryCodeData.filter((ele) => (ele.code.includes(e.target.value) || ele.name.toLowerCase().includes(e.target.value.toLowerCase())));
    setFilteredCountrycode(result);
  }
  const handleClickOutside = (event) => {
    if (!event.target.closest(".dropdown")) {
      setOpenDropDown(false);
    }
  };
  const handleSelectIsdCode = (code) => {
    setIsdCode(code);
    setOpenDropDown(false);
  }

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  const checkoutUserInfo = async (userId) => {
    axios
      .post(ApiManager.checkoutUserInfo(), {
        userID: userId,
      })
      .then((response) => {
        if (response.data.StatusCode === 200) {
          setCheckoutInfo(response.data.result);
        }
      })
      .catch((err) => {
        //  console.log("Error", err);
      });
  };

  return (
    <>
      <Box
        sx={{
          minHeight: { xs: "100%", md: "61vh", lg: "100%" },
        }}
      >
        <Box sx={PreorderStyle.Sectionmain}>
          <Typography sx={PreorderStyle.SectionHead}>
            Pre-Order Booking
          </Typography>
          <hr />
        </Box>

        <Grid
          container
          sx={PreorderStyle.Sectionmain}
          columns={{ xs: 12, md: 12 }}
        >
          <Grid item xs={12} md={5} sx={PreorderStyle.leftpara}>
            <img
              src={image}
              alt="img"
              style={{ height: "80px", marginBottom: "42px" }}
            />
            <Typography sx={PreorderStyle.Evename}>
              {eventName}
              <br />
              from {fromDate} to {toDate}
            </Typography>
            <Typography sx={PreorderStyle.heading}>
              Pre-Order Price<small>(with GST): </small>{" "}
            </Typography>
            <Box sx={PreorderStyle.pricebox}>
              <Typography sx={PreorderStyle.EventPrice}>₹{price}</Typography>
              <Typography sx={PreorderStyle.crosstext}>₹{cutPrice}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={7}>
            <Box sx={PreorderStyle.boxcheckout}>
              <Typography sx={PreorderStyle.guestpart}>
                Guest Checkout
              </Typography>

              <Box sx={PreorderStyle.siderimg}>
                <Box sx={PreorderStyle.formone}>
                  <Typography sx={PreorderStyle.formlabel}>Name</Typography>
                  <TextField
                    sx={PreorderStyle.formsize}
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    name="name"
                    autoComplete="name"
                    placeholder="Enter Name"
                    autoFocus
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                      setVerifiedName(nameValidator(e.target.value));
                    }}
                  />
                  {isVerifiedName !== "" && (
                    <Typography sx={PreorderStyle.formerror}>
                      {isVerifiedName}
                    </Typography>
                  )}
                </Box>
                <Box sx={PreorderStyle.formone}>
                  <Typography sx={PreorderStyle.formlabel}>
                    E-mail Address
                  </Typography>
                  <TextField
                    sx={PreorderStyle.formsize}
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    name="email"
                    autoComplete="email"
                    type="text"
                    placeholder="Enter email address"
                    value={email}
                    //onChange={(e) => checkEmail(e)} //7978872249
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setVerifiedEmail(emailValidator(e.target.value));
                    }}
                  />
                  {isVerifiedEmail !== true && (
                    <Typography sx={PreorderStyle.formerror}>
                      {isVerifiedEmail}
                    </Typography>
                  )}
                </Box>
                <Box sx={PreorderStyle.formone}>
                  <Typography sx={PreorderStyle.formlabel}>
                    Phone Number
                  </Typography>
                  <Grid container spacing={1} sx={{ alignItems: "baseline" }}>
                    <Grid xs={3} sm={3} lg={3} sx={{ paddingLeft: "0.5rem" }}>
                      <div class="dropdown">
                        <TextField
                          // id="myInput"
                          sx={PreorderStyle.formsize}
                          margin="normal"
                          required
                          fullWidth
                          name="ISD"
                          autoComplete="ISD"
                          value={isdCode}
                          onClick={() => toggleDropDown()}
                          onChange={(e) => handleFilter(e)}
                          placeholder="ISD"
                        // sx={{ width: "100%" }}
                        />
                        {openDropDown &&
                          <div class="dropdown-content">
                            {
                              filteredCountryCode.length === 0 ?
                                CountryCodeData.map((ele) => {
                                  return (
                                    <div class="card" onClick={() => handleSelectIsdCode(ele.code)}>{ele.code} - {ele.name}</div>
                                  )
                                })
                                :
                                filteredCountryCode.map((ele) => {
                                  return (
                                    <div class="card" onClick={() => handleSelectIsdCode(ele.code)}>{ele.code} - {ele.name}</div>
                                  )
                                })
                            }
                          </div>}
                      </div>
                    </Grid>
                    <Grid item xs={9} sm={9} lg={9}>
                      <TextField
                        sx={PreorderStyle.formsize}
                        margin="normal"
                        required
                        fullWidth
                        id="number"
                        name="number"
                        autoComplete="number"
                        placeholder="Enter mobile number"
                        value={number}
                        onChange={
                          (e) => {
                            setNumber(e.target.value);
                            setVerifiedNumber(mobileNoValidator(e.target.value));
                          }
                          //setNumber(e.target.value);
                        }
                      />
                    </Grid>
                  </Grid>
                  {isVerifiedNumber !== true && (
                    <Typography sx={PreorderStyle.formerror}>
                      {isVerifiedNumber}
                    </Typography>
                  )}
                </Box>

                {/* <Box sx={PreorderStyle.formone}>
                  <Typography sx={PreorderStyle.formlabel}>
                    Registration Ref#
                  </Typography>
                  <TextField
                    sx={PreorderStyle.formsize}
                    margin="normal"
                    required
                    fullWidth
                    id="registration"
                    name="registration"
                    autoComplete="registration"
                    placeholder="Enter event application id/ registration id"
                    value={registrationRef}
                    onChange={
                      (e) => {
                        setRegistrationRef(e.target.value);
                        setValidRegistrationRef(
                          registrationRefValidation(e.target.value)
                        );
                      }
                      //setNumber(e.target.value);
                    }
                  />
                  {isValidRegistrationRef !== true && (
                    <Typography sx={PreorderStyle.formerror}>
                      {isValidRegistrationRef}
                    </Typography>
                  )}
                </Box> */}

                <Box sx={PreorderStyle.formone}>
                  <ReCAPTCHA
                    sitekey={RecaptchaConfigs.Sitekey}
                    onExpired={redoCaptcha}
                    onChange={handleOnChangeRecaptcha}
                    ref={recaptchaRef}
                  />
                </Box>
                <Box sx={PreorderStyle.contbox}>
                  <Button
                    disableripple={true}
                    onClick={() => {
                      navigate("/");
                    }}
                    btnText="PRINT"
                    sx={{
                      fontFamily: FontHelper.fBold,
                      fontSize: "14px",
                      color: ColorHelper.BlackColor,
                      backgroundColor: ColorHelper.WhiteColor,
                      height: 40,
                      borderRadius: "3px",
                      textTransform: "capitalize",
                      width: "100px",
                      // marginRight: "10px",
                      padding: "10px",
                      border: "1px solid",
                      "&:hover": {
                        backgroundColor: ColorHelper.NavyBlue,
                        color: ColorHelper.WhiteColor,
                      },
                    }}
                    startIcon={
                      <ArrowBackIosIcon
                        sx={{
                          marginRight: "-10px !important",
                          fontSize: "14px",
                        }}
                      />
                    }
                  >
                    Back
                  </Button>
                  <Button
                    disableripple={true}
                    onClick={handleSubmit}
                    btnText="PRINT"
                    //disabled={!verified}
                    disabled={!isVerified}
                    sx={{
                      fontFamily: FontHelper.fBold,
                      fontSize: 14,
                      padding: "10px",
                      height: 40,
                      borderRadius: "3px",
                      textTransform: "capitalize",
                      width: "100px",
                      // marginLeft: "10px",
                      mb: 0,
                      border: "1px solid black",
                      color: ColorHelper.WhiteColor,
                      backgroundColor: ColorHelper.PrimaryColor,
                      "&:hover": {
                        backgroundColor: ColorHelper.WhiteColor,
                        color: ColorHelper.PrimaryColor,
                        border: `1px solid ${ColorHelper.PrimaryColor}`,
                      },
                      " &.Mui-disabled": {
                        color: "#a6a6a6 !important",
                        background: "#e0e0e0 !important",
                        border: "0px",
                      },
                    }}
                    startIcon={<PaymentIcon />}
                  // onSubmit={submitData}
                  >
                    Pay
                  </Button>
                </Box>
              </Box>
            </Box>
          </Grid>

          <ul style={PreorderStyle.listtype}>
            <li style={{ fontWeight: "700" }}>Note</li>
            <ul style={{ paddingLeft: "25px", margin: "7px 0" }}>
              <li style={{ margin: "4px 0" }}>{ConstantHelper.Note1}</li>
              <li style={{ margin: "4px 0" }}>{ConstantHelper.Note2}</li>
              <li>{ConstantHelper.Note3}</li>
            </ul>
          </ul>
          <ul style={PreorderStyle.listtype}>
            <li style={{ fontWeight: "700" }}>Terms and Conditions</li>
            <ul style={{ paddingLeft: "25px", margin: "7px 0" }}>
              <li style={{ margin: "4px 0" }}>{ConstantHelper.Terms1}</li>
              <li>{ConstantHelper.Terms2}</li>
            </ul>
          </ul>

        </Grid>
        {/* {/ <Typography sx={PreorderStyle.settlink}><Link sx={PreorderStyle.Evename} href="#">Settings</Link></Typography> /} */}
      </Box>
    </>
  );
};

export default Preorderfile;
