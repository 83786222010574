import { Box, Card, CardActions, Typography } from "@mui/material";
import React from "react";
import ConstantHelper from "../../../Helpers/ConstantHelper";
import SectionTitle3 from "../../Ui-Components/SectionTitle3/SectionTitle3";
import CartCardComp from "../CartCardComp/CartCardComp";
import PrintsSectionData from "./PrintsSectionData";
import CartCardUnlabeledComp from "../CartCardComp/CartCardUnlabeledComp";
import ColorHelper from "../../../Helpers/ColorHelper";
import FontHelper from "../../../Helpers/FontHelper";
import { useLocation, useNavigate } from "react-router-dom";
import IconHelper from "../../../Helpers/IconHelper";

const PrintsSection = ({
  data,
  updateBill,
  type,
  print,
  print_instructions,
  bibNumber,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const findpath = location.pathname;

  const collageCart = data.filter((item) => {
    return item.type === "collage";
  });

  const CartCardDataAddCard = {
    title: ConstantHelper.AddMorePrints,
    addMore: "/",
    addMoreItems: "/",
  };
  const HandleAddItemBtn = (url) => {
    navigate("/products");
  };

  const handlePlusBtn = (url) => {
    navigate("/products");
  };

  return (
    <Box sx={{ py: 3 }}>
      <SectionTitle3 title={ConstantHelper.PrintsTitle} />
      {print_instructions && (
        <Typography
          sx={{
            fontFamily: FontHelper.fMedium,
            fontSize: "16px",

            width: "100%",
            marginBottom: "12px",
            color: ColorHelper.LightGrey6,
          }}
        >
          {" "}
          Print instructions:
          <span
            style={{
              fontFamily: FontHelper.fMedium,
              fontSize: "16px",
              paddingLeft: "4px",
              color: ColorHelper.LightGrey6,
            }}
          >
            {print_instructions}
          </span>
        </Typography>
      )}
      <Box
        sx={{
          columnGap: { xs: 0, sm: 3 },
          rowGap: { xs: 3, sm: 3 },
          display: "grid",
          gridTemplateColumns: "1fr",
        }}
      >
        {type !== "unlabeled" ? (
          <CartCardComp
            type={type}
            CartCardData={data}
            actionStyle={1}
            print={print}
            CartCardDataAddCard={CartCardDataAddCard}
            // CartCardDataFullPackage={CartCardDataFullPackage}
            updateBill={updateBill}
            collageCart={collageCart}
          />
        ) : (
          <CartCardUnlabeledComp
            type={type}
            CartCardData={data}
            actionStyle={1}
            CartCardDataAddCard={CartCardDataAddCard}
            print={print}
            updateBill={updateBill}
            collageCart={collageCart}
          />
        )}
      </Box>
      <Box style={{ display: "flex" }}>
        {CartCardDataAddCard &&
          findpath === "/cart" &&
          print == "print" &&
          // bibNumber !== "selfiesearch" && 
          (
            <Card
              sx={{
                width: { xs: "100%", sm: "40%" },
                maxHeight: "100%",
                p: "20px",
                borderRadius: "5px 5px 0 0",
                textAlign: "center",
                marginRight: "20px",
              }}
              onClick={() => HandleAddItemBtn(CartCardDataAddCard.addMoreItems)}
            >
              <Box
                sx={{
                  borderRadius: "5px",
                  maxWidth: "100%",
                  aspectRatio: "1/1",
                  maxHeight: "100%",
                  objectFit: "cover",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  backgroundColor: ColorHelper.LightGreen,
                }}
              >
                <Box
                  onClick={() => handlePlusBtn(CartCardDataAddCard.addMore)}
                  sx={{
                    cursor: "pointer",
                  }}
                >
                  <IconHelper.PlusCirclular color={ColorHelper.GreenColor} />
                </Box>
                <Typography
                  sx={{
                    fontFamily: FontHelper.fBold,
                    fontSize: 18,
                    color: ColorHelper.BlackColor,
                    mt: 4,
                  }}
                >
                  {CartCardDataAddCard.title}
                </Typography>
              </Box>
              <CardActions sx={{ px: 0, py: 0 }}>
                <Box
                  disableripple={true}
                  onClick={() =>
                    HandleAddItemBtn(CartCardDataAddCard.addMoreItems)
                  }
                  sx={{
                    fontFamily: FontHelper.fBold,
                    fontSize: { xs: 14, lg: 18 },
                    textAlign: "center",
                    color: ColorHelper.WhiteColor,
                    backgroundColor: ColorHelper.GreenColor,
                    width: "100%",
                    px: "15px",
                    py: "10px",
                    borderRadius: "5px",
                    marginLeft: "auto",
                    mt: 2,
                    mb: 0,
                    cursor: "pointer",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    "&:hover": {
                      backgroundColor: ColorHelper.GreenColor,
                    },
                  }}
                >
                  {ConstantHelper.AddMorePrints}
                </Box>
              </CardActions>
            </Card>
          )}
      </Box>
    </Box>
  );
};

export default PrintsSection;
