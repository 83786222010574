import { Box, Typography } from "@mui/material";
import React from "react";
import TNCData from "./TNCData";
import TNCOverviewStyle from "./TNCOverviewStyle";

const TNCOverview = () => {
  return (
    <Box sx={TNCOverviewStyle.SectionWrapper}>
      <Typography sx={TNCOverviewStyle.Title}>General Terms</Typography>
      <Typography sx={TNCOverviewStyle.Description}>
        THE FOLLOWING TERMS AND CONDITIONS CONSTITUTE A LEGAL AGREEMENT (THE
        "AGREEMENT") BETWEEN YOU and ONEGLINT MEDIA SOLUTIONS PVT LTD (aka
        oneglint.com or oneglint, here after). PLEASE READ ALL OF THE FOLLOWING
        TERMS AND CONDITIONS. BY USING THE ONEGLINT.COM WEB SITE, YOU INDICATE
        YOUR ACCEPTANCE OF THESE TERMS AND CONDITIONS. IF YOU DO NOT AGREE TO BE
        BOUND BY THESE TERMS AND CONDITIONS, PLEASE EXIT OUR WEB SITE.
      </Typography>

      {TNCData.map((item, index) => (
        <>
          <Typography sx={TNCOverviewStyle.InnerTitle}>
            {index + 1}. {item.title}
          </Typography>
          {item.description.map((DescData) => (
            <Typography sx={TNCOverviewStyle.InnnerDescription}>
              {DescData.para}
            </Typography>
          ))}
        </>
      ))}
    </Box>
  );
};

export default TNCOverview;
