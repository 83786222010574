import { Box } from "@mui/material";
import React from "react";
import ConstantHelper from "../../../Helpers/ConstantHelper";
import SectionTitle3 from "../../Ui-Components/SectionTitle3/SectionTitle3";
import CartCardComp from "../CartCardComp/CartCardComp";
// import DigitalDownloadSectionData from "./DigitalDownloadSectionData";
import CartCardUnlabeledComp from "../CartCardComp/CartCardUnlabeledComp";

const VideoDownloadSection = ({ data, updateBill, type, digital, video }) => {
  const CartCardDataAddCard = {
    title: ConstantHelper.AddMorePhotos,
    addMore: "/",
    addMoreItems: "/",
  };

  const CartCardDataFullPackage = {
    title: ConstantHelper.AddMorePhotos,
    addMore: "/",
    addMoreItems: "/",
  };

  return (
    <Box sx={{ pb: 3 }}>
      <SectionTitle3 title={ConstantHelper.Videos} />
      {(data !== null || data !== undefined) && type !== "unlabeled" ? (
        <CartCardComp
          type={type}
          CartCardData={data}
          actionStyle={2}
          CartCardDataAddCard={CartCardDataAddCard}
          CartCardDataFullPackage={CartCardDataFullPackage}
          updateBill={updateBill}
          digital={digital}
          video={video}
        />
      ) : (
        <CartCardUnlabeledComp
          type={type}
          CartCardData={data}
          actionStyle={2}
          CartCardDataAddCard={CartCardDataAddCard}
          CartCardDataFullPackage={CartCardDataFullPackage}
          updateBill={updateBill}
          digital={digital}
        />
      )}
    </Box>
  );
};

export default VideoDownloadSection;
