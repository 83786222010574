import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import ColorHelper from "../../Helpers/ColorHelper";
import ConstantHelper from "../../Helpers/ConstantHelper";
import FontHelper from "../../Helpers/FontHelper";
import IconHelper from "../../Helpers/IconHelper";
import ImageHelper from "../../Helpers/ImageHelper";
import BorderedButton from "../Ui-Components/Buttons/BorderedButton/BorderedButton";
import FilledButton from "../Ui-Components/Buttons/FilledButton/FilledButton";
import SectionTitle2 from "../Ui-Components/SectionTitle2/SectionTitle2";
import { useNavigate } from "react-router-dom";


const EmptyOrder = () => {
  const navigate = useNavigate();
  return (
    <Box>
     
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          py: { xs: 2, sm: 5 },
          px: { xs: 2, sm: 15 },
        }}
      >
        {/* <img src={ImageHelper.emptyCart} alt="Empty Cart" /> */}
        <Typography
          sx={{
            fontSize: { xs: 26, md: 36 },
            color: ColorHelper.BlackColor,
            fontFamily: FontHelper.fSemiBold,
            lineHeight: "44px",
          }}
        >
          No Purchase History
        </Typography>
        <Typography
          sx={{
            fontSize: { xs: 14, md: 16 },
            color: ColorHelper.DarkGrey2,
            fontFamily: FontHelper.fRegular,
            pt: { xs: "8px", md: "16px" },
            lineHeight: "19.2px",
          }}
        >
         Check back after Your next Purchase!
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
          rowGap: 2,
          py: 3,
        }}
      >
        <FilledButton
          btnText={ConstantHelper.ReturnToHomepage}
          color={ColorHelper.WhiteColor}
          bgColor={ColorHelper.PinkColor}
          startIcon={<IconHelper.ReturnArrow />}
          handleClick={() => navigate("/")}
        />
        {localStorage.getItem("isSignedIn") !== "true" ? (
          <BorderedButton
            btnText={ConstantHelper.LoginForOrderHistory}
            color={ColorHelper.BlackColor}
            bgColor={ColorHelper.WhiteColor}
            bordercolor={ColorHelper.BlackColor}
            startIcon={<IconHelper.CartIcon />}
            handleClick={() => navigate("/sign-in")}
          />
        ) : null}
      </Box>
    </Box>
  );
};

export default EmptyOrder ;