import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import ColorHelper from "../../Helpers/ColorHelper";
import ConstantHelper from "../../Helpers/ConstantHelper";
import FontHelper from "../../Helpers/FontHelper";
import IconHelper from "../../Helpers/IconHelper";
import { useNavigate, useLocation } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const HeaderNote = ({ priceInfo }) => {
  let location = useLocation()
  let navigate = useNavigate();

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          minHeight: "auto",
        }}
      >

        <Box
          sx={{
            fontFamily: FontHelper.fBold,
            padding: { xs: "28px 50px", lg: "8px 80px" },
            width: "100%",
            overflow: "hidden",
            whiteSpace: { xs: "wrap", md: "nowrap" },
            height: "33px",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            backgroundColor: ColorHelper.LightPurple,
            color: ColorHelper.BlueColor,
            fontFamily: FontHelper.fRegular,
          }}
        >
          {location.pathname === "/orderDetails" &&
            <ArrowBackIcon style={{ paddingRight: "5px", color: "black", cursor: "pointer" }} onClick={() => { navigate(-1) }} />
          }
          {priceInfo}

        </Box>

      </Box>
    </>
  );
};

export default HeaderNote;
