import { Box, Typography } from "@mui/material";
import React from "react";
import ColorHelper from "../../../Helpers/ColorHelper";
import ConstantHelper from "../../../Helpers/ConstantHelper";
import FontHelper from "../../../Helpers/FontHelper";
import IconHelper from "../../../Helpers/IconHelper";
import NotesData from "./NotesData";
import NotesStyle from "./NotesStyle";

const Notes = () => {
  return (
    <Box sx={{ mt: 2, backgroundColor: ColorHelper.LightGreen, px: 5, py: 4 }}>
      <Typography
        sx={{
          fontFamily: FontHelper.fBold,
          fontSize: 16,
          color: ColorHelper.DarkGrey4,
        }}
      >
        {ConstantHelper.NotesTitle}
      </Typography>
      {NotesData.map((item,index) => (
        <Box sx={NotesStyle.NotesContainer} key={index}>
          <IconHelper.CheckMarkIcon />
          <Typography
            sx={{
              pl: 2,
              fontFamily: FontHelper.fRegular,
              fontSize: 14,
              color: ColorHelper.DarkGrey4,
            }}
          >
            {item.note}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default Notes;
