import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { useSelector } from 'react-redux'
import ColorHelper from '../../Helpers/ColorHelper'
import ConstantHelper from '../../Helpers/ConstantHelper'
import FontHelper from '../../Helpers/FontHelper'

const FooterNote = () => {
    const note = useSelector((state) => state?.shop?.products?.purchase_notification);
    if (note === "" || note === undefined) {
        <>
        </>
    } else {
        return (
            <Box>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', minHeight: 'auto' }}>
                    <marquee
                        behavior='scroll'
                        scrollamount='15'
                        width='50%'
                        direction='left'
                        height='50px'

                        style={{
                            fontFamily: FontHelper.fBold,
                            padding: '8px 80px',
                            width: '100%',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            height: '33px',
                            display: 'flex', justifyContent: 'flex-start', alignItems: 'center',
                            backgroundColor: ColorHelper.LightYellow,
                            color: ColorHelper.YellowColor,
                        }}>
                        {note}
                    </marquee>
                </Box>
            </Box>
        )
    }
}

export default FooterNote;
