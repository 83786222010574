import { Button, Typography, Divider } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import ColorHelper from "../../Helpers/ColorHelper";
import ConstantHelper from "../../Helpers/ConstantHelper";
import FontHelper from "../../Helpers/FontHelper";
import IconHelper from "../../Helpers/IconHelper";
// import LoaderComp from "../Loader/LoaderComp";

import {
  addAllToCart,
  clearCart,
  fetchProducts,
} from "../../redux/shoppingActions";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import DownloadIcon from "@mui/icons-material/Download";
import { useEffect } from "react";
import axios from "axios";
import { ApiManager } from "../../ApiClient";
import { useSnackbar } from "notistack";
import Loading from "../../Components/Loader/Loading";
import "../../App.css";
const SummarySection = ({
  imagesCount,
  orderId,
  bibNumber,
  ReportBtn,
  unlabledPage,
  type,
  price,
  count,
  urls,
  reportPics,
  reportPicsHandle,
  reportedPhoto,
  setReportedPhoto,
  disabled,
  logo,
}) => {
  const dispatch = useDispatch();
  const [addAllToCartText, setAddAllToCart] = React.useState("Add all to cart");
  const cartInfo = useSelector((state) => state.shop.cartInfo);
  const [isLoading, setIsLoading] = useState(false);
  // data to display
  // const [loadedData, setLoadedData] = useState();
  const filteredCart = cartInfo.filter((item) => {
    if (item.type === "download") {
      return item.category !== "image";
    }
    return item;
  });
  const products = useSelector((state) => state.shop.products);
  // const [loaderEnabled, setLoaderEnabled] = React.useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const findpath = location.pathname;
  const config = useSelector((state) => state.config.masterConfig.result);
  const collage = cartInfo?.filter((items) => items.type == "collage");
  const print = cartInfo?.filter((items) => items.type == "print");
  const images = cartInfo?.filter(
    (items) => items.type === "download" && items.category === "image"
  );
  const videos = cartInfo?.filter(
    (items) => items.type === "download" && items.category === "video"
  );

  let isPending = "false";
  if (findpath === "/myorders" || findpath === "/preauthdownload") {
    // console.log("type---if--", isPending, " ", findpath);
    isPending = "false";
  } else {
    // console.log("type---else--", isPending, " ", findpath);
    isPending = localStorage?.getItem("isPending");
  }
  let isSignedIn = localStorage?.getItem("isSignedIn");
  const snack = useSnackbar();
  // console.log("type-----", isPending, " ", findpath);

  const handleAllToCart = () => {
    if (
      new Date().getTime() >
      new Date(localStorage?.getItem("awsImageExpiry")).getTime()
    ) {
      navigate("/home");
    } else {
      if (addAllToCartText == "Add all to cart") {
        dispatch(addAllToCart(products.photo, bibNumber, filteredCart));
        setAddAllToCart("EMPTY CART");
      } else {
        dispatch(clearCart());
        setAddAllToCart("Add all to cart");
      }
    }
  };

  const downloadPackage = async () => {
    // setLoaderEnabled(true);
    setIsLoading(true);
    const response = await axios.post(ApiManager.downloadOrderZip(), {
      orderID: orderId,
      bibNumber: bibNumber,
    });
    if (response.data.StatusCode == 200) {
      // setLoaderEnabled(false);
      setIsLoading(false);

      window.location.href = response.data.result;
    }
  };

  const checkFullPackage = () => {
    const downloads = cartInfo?.filter((items) => items.type == "download");
    if (products?.photo?.length == downloads?.length) {
      setAddAllToCart("EMPTY CART");
    }
  };

  const hidePhotoHandler = async (type) => {
    let response = {};
    if (reportedPhoto.length > 0) {
      response = await axios.post(ApiManager.recordFeedbackOnImage(), {
        usrfeedback: type === "hide" ? 1 : 2,
        bibNumber: bibNumber,
        photoids: reportedPhoto.join(),
      });
    } else {
      snack.enqueueSnackbar({
        variant: "warning",
        message: "Select atleast one photo.",
      });
    }

    if (response?.status === 200) {
      dispatch(
        fetchProducts(localStorage.getItem("Selected Event"), bibNumber)
      );
      reportPicsHandle();
      setReportedPhoto([]);
    }
  };

  useEffect(() => {
    checkFullPackage();
  }, []);

  return (
    <Box>
      <div style={{ display: isLoading ? "flex" : "none" }} className="modal">
        <div className="modal-content">
          <div className="loader"></div>
          <div className="modal-text">Please Wait...</div>
        </div>
      </div>
      <>
        <Box
          sx={{
            px: { xs: 5, md: 5, lg: 10 },
            backgroundColor: ColorHelper.WhiteColor2,
            // pb: { xs: 1, md: 1, lg: 1 },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: {
                xs: "column",
                sm: "row",
                xxs: "row",
                md: "row",
                lg: "row",
              },
              alignItems: "center",
              minHeight: "auto",
              pt: 3,
              pb: 0.5,

              borderBottom: `1px solid ${ColorHelper.borderColor}`,
            }}
          >
            <Typography
              sx={{
                fontFamily: FontHelper.fBold,
                overflow: "hidden",
                whiteSpace: "wrap",
                height: "auto",
                display: "flex",
                justifyContent: "flex-start",
                width: "100%",
                alignItems: "center",
                color: ColorHelper.BlueColor,
                mb: { xs: 2, md: 0 },
                pr: { xs: 0, sm: 3 },
                ...(unlabledPage == "true" && { pb: 2 }),
              }}
            >
              {logo ? (
                <img
                  src={logo}
                  alt="event_logo"
                  style={{ width: "66px", paddingRight: "6px" }}
                />
              ) : (
                ""
              )}
              {type !== "orderDetails" ? (
                <span
                  style={{
                    color: ColorHelper.BlackColor,
                    fontFamily: FontHelper.fMedium,
                  }}
                >
                  BIB No. :
                  <span
                    style={{
                      color: ColorHelper.BlueColor,
                      fontFamily: FontHelper.fBold,
                      marginLeft: "5px",
                    }}
                  >
                    {bibNumber}
                  </span>
                  <span
                    style={{
                      marginLeft: "5px",
                      marginRight: "5px",
                      color: ColorHelper.LightYellow2,
                    }}
                  >
                    |
                  </span>
                  {findpath !== "/cart" &&
                    findpath !== "/unlabeled-cart" &&
                    findpath !== "/unlabeled-events" ? (
                    <span
                      span
                      style={{
                        color: ColorHelper.BlueColor,
                        fontFamily: FontHelper.fBold,
                        margin: "0 4px",
                        fontSize: 16,
                      }}
                    >
                      {`Congratulations.There are ${imagesCount} photos in this section.`}
                    </span>
                  ) : (
                    findpath === "/unlabeled-events" && (
                      <span
                        span
                        style={{
                          color: ColorHelper.BlueColor,
                          fontFamily: FontHelper.fBold,
                          margin: "0 4px",
                          fontSize: 16,
                        }}
                      >
                        {`There are ${imagesCount} photos in this section.`}
                      </span>
                    )
                  )}
                  {images?.length !== 0 &&
                    (findpath === "/cart" ||
                      findpath === "/unlabeled-cart") && (
                      <>
                        <span
                          style={{
                            color: ColorHelper.BlueColor,
                            fontFamily: FontHelper.fBold,
                            marginLeft: 4,
                            marginRight: 4,
                          }}
                        >
                          {images?.length}{" "}
                        </span>{" "}
                        {ConstantHelper.products1}{" "}
                      </>
                    )}
                  {videos?.length !== 0 &&
                    (findpath === "/cart" ||
                      findpath === "/unlabeled-cart") && (
                      <>
                        <span
                          style={{
                            color: ColorHelper.BlueColor,
                            fontFamily: FontHelper.fBold,
                            marginLeft: 4,
                            marginRight: 4,
                          }}
                        >
                          {videos?.length}{" "}
                        </span>{" "}
                        {ConstantHelper.products5}{" "}
                      </>
                    )}
                  {!config.DISABLEPRINTS.includes(products.eventId) &&
                    print?.length !== 0 &&
                    (findpath === "/cart" ||
                      findpath === "/unlabeled-cart") && (
                      <>
                        <span
                          style={{
                            color: ColorHelper.BlueColor,
                            fontFamily: FontHelper.fBold,
                            marginLeft: 4,
                            marginRight: 4,
                          }}
                        >
                          {" "}
                          {print?.length}{" "}
                        </span>{" "}
                        {ConstantHelper.products2}
                      </>
                    )}
                  {collage?.length !== 0 &&
                    (findpath === "/cart" ||
                      findpath === "/unlabeled-cart") && (
                      <>
                        <span
                          style={{
                            color: ColorHelper.BlueColor,
                            fontFamily: FontHelper.fBold,
                            marginLeft: 4,
                            marginRight: 4,
                          }}
                        >
                          {" "}
                          {collage?.length}{" "}
                        </span>{" "}
                        {ConstantHelper.products4}
                      </>
                    )}
                </span>
              ) : (
                <span
                  style={{
                    color: ColorHelper.BlackColor,
                    fontFamily: FontHelper.fMedium,
                  }}
                >
                  BIB No. :
                  <span
                    style={{
                      color: ColorHelper.BlueColor,
                      fontFamily: FontHelper.fBold,
                      marginLeft: "5px",
                    }}
                  >
                    {bibNumber}
                  </span>
                  <span
                    style={{
                      marginLeft: "5px",
                      marginRight: "5px",
                      color: ColorHelper.LightYellow2,
                    }}
                  >
                    |
                  </span>
                  <span style={{ color: "#667085", marginRight: 4 }}>
                    {"Order ID:"}
                  </span>
                  <span style={{ color: "#667085", marginRight: 4 }}>
                    {orderId}
                  </span>
                  <span
                    style={{
                      marginLeft: "5px",
                      marginRight: "5px",
                      color: ColorHelper.LightYellow2,
                    }}
                  >
                    |
                  </span>
                  <span style={{ color: "#667085", marginRight: 4 }}>
                    {"Order Total:"}
                  </span>
                  <span style={{ color: "#667085", marginRight: 4 }}>
                    ₹{price}
                  </span>
                </span>
              )}
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexWrap: "wrap",
                gap: 3.5,
              }}
            >
              {ReportBtn && reportPics == false && (
                // localStorage.getItem("isSignedIn") &&
                // localStorage.getItem("ID") &&
                <Box
                  sx={{
                    color: ColorHelper.YellowColor2,
                    fontSize: 16,
                    fontFamily: FontHelper.fMedium,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 1,
                    cursor: "pointer",
                    "&:hover": {
                      color: ColorHelper.PrimaryColor,
                      path: {
                        fill: ColorHelper.PrimaryColor,
                      },
                    },
                  }}
                  onClick={() =>
                    isSignedIn
                      ? reportPicsHandle()
                      : alert("you must log in to perform this action")
                  }
                >
                  <IconHelper.WarningIcon /> Report picture(s){" "}
                </Box>
              )}

              {ReportBtn && reportPics == true && (
                <Box
                  sx={{
                    color: ColorHelper.YellowColor2,
                    fontSize: 16,
                    fontFamily: FontHelper.fMedium,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 1,
                    cursor: "pointer",
                    path: {
                      fill: ColorHelper.YellowColor,
                    },
                    "&:hover": {
                      color: ColorHelper.PrimaryColor,
                      path: {
                        fill: ColorHelper.PrimaryColor,
                      },
                    },
                  }}
                  onClick={
                    () =>
                      isSignedIn
                        ? reportPicsHandle()
                        : alert("you must log in to perform this action")
                    //
                  }
                >
                  <IconHelper.LeftArrow />
                  Back
                </Box>
              )}
              {(findpath == "/orderDetails" ||
                findpath == "/myorders" ||
                findpath == "/preauthdownload") &&
                isPending === "false" &&
                count !== 0 && (
                  <Button
                    onClick={downloadPackage}
                    disableripple={true}
                    sx={{
                      fontSize: 16,
                      backgroundColor: "#12B76A",
                      px: { xs: 2, md: 2 },
                      py: { xs: 1.5, md: 1.5 },
                      borderRadius: "3px",
                      marginLeft: "auto",
                      minWidth: "max-content",
                      mb: 0,
                      color: ColorHelper.WhiteColor,
                      backgroundColor: ColorHelper.PrimaryColor,
                      "&:hover": {
                        backgroundColor: ColorHelper.WhiteColor,
                        color: ColorHelper.PrimaryColor,
                        border:`1px solid ${ColorHelper.PrimaryColor}`,
                      },
                    }}
                  >
                    <DownloadIcon />
                    <span
                      style={{ paddingLeft: "10px" }}
                    >{`Download Full Package(${count})`}</span>
                  </Button>
                )}
              {(unlabledPage === "true" || type == "orderDetails"
                ? null
                : reportPics == false) &&
                (findpath === "/products" || findpath === "/searchbyselfie") ? (
                <Button
                  onClick={handleAllToCart}
                  disableripple={true}
                  sx={{
                    fontFamily: FontHelper.fBold,
                    fontSize: 16,
                    color: ColorHelper.WhiteColor,
                    backgroundColor: ColorHelper.IntenseBlue,
                    px: { xs: 2, md: 2 },
                    py: { xs: 1.5, md: 1.5 },
                    borderRadius: 0,
                    marginLeft: "auto",
                    minWidth: "max-content",
                    mb: 0,
                    "&:hover": {
                      backgroundColor: ColorHelper.PrimaryColor,
                    },
                  }}
                >
                  {new Date().getTime() >
                    new Date(
                      localStorage?.getItem("awsImageExpiry")
                    ).getTime() ? (
                    <></>
                  ) : (
                    <IconHelper.CartPlusIcon />
                  )}
                  <span style={{ paddingLeft: "10px" }}>
                    {new Date().getTime() >
                      new Date(localStorage?.getItem("awsImageExpiry")).getTime()
                      ? "Home"
                      : addAllToCartText}
                  </span>
                </Button>
              ) : findpath === "/products" ? (
                <div style={{ display: "flex", columnGap: 8 }}>
                  <Button
                    onClick={() => {
                      hidePhotoHandler("hide");
                    }}
                    disableripple={true}
                    // disabled={disabled}
                    sx={{
                      fontFamily: FontHelper.fBold,
                      fontSize: 16,
                      color: ColorHelper.ErrorText,
                      backgroundColor: ColorHelper.ErrorBg,
                      textTransform: "none",
                      px: { xs: 3, md: 3 },
                      py: { xs: 1, md: 1 },
                      borderRadius: 0,
                      marginLeft: "auto",
                      minWidth: "max-content",
                      borderRadius: 999,
                      mb: 0,
                      "&:hover": {
                        backgroundColor: ColorHelper.ErrorText,
                        color: ColorHelper.ErrorBg,
                      },
                    }}
                  >
                    <span>Hide</span>
                  </Button>
                  <Button
                    onClick={() => {
                      hidePhotoHandler("notMe");
                    }}
                    disableripple={true}
                    // disabled={disabled}
                    sx={{
                      fontFamily: FontHelper.fBold,
                      fontSize: 16,
                      color: ColorHelper.ErrorText,
                      backgroundColor: ColorHelper.ErrorBg,
                      textTransform: "none",
                      px: { xs: 3, md: 3 },
                      py: { xs: 1, md: 1 },
                      borderRadius: 0,
                      marginLeft: "auto",
                      minWidth: "max-content",
                      borderRadius: 999,
                      mb: 0,
                      "&:hover": {
                        backgroundColor: ColorHelper.ErrorText,
                        color: ColorHelper.ErrorBg,
                      },
                    }}
                  >
                    <span>Not Me</span>
                  </Button>
                </div>
              ) : (
                ""
              )}
            </Box>
          </Box>
          {/* {loadedData && <p>{loadedData}</p>} */}
        </Box>
      </>
    </Box>
  );
};

export default SummarySection;
