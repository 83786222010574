import { Box } from "@mui/system";
import React, { useEffect } from "react";
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import SectionTitle3 from "../Components/Ui-Components/SectionTitle3/SectionTitle3";
import "./table.css";
import { useDispatch, useSelector } from "react-redux";
import {
  addAllToCart,
  addAllPrintToCart,
  orderInfo,
  packageDetails,
  fetchProducts,
} from "../redux/shoppingActions";
import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApiManager } from "../ApiClient";
import OrganizerDashboardList from "../Components/OrderHistory/OrganizerDashboardList";
import { Typography } from "@mui/material";
import FontHelper from "../Helpers/FontHelper";

// export const getArray = (length) => {
//   let traversy = [];
//   for (let i = 0; i < length; i++) {
//     traversy.push(i);
//   }
//   return traversy;
// };

const OrganizerDashboard = () => {
  // const { selectedEvent, setSelectedEvent, events } = props;
  const [inputValue, setInputValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [style, setStyle] = useState("combo-box-demo");

  const [selectValue, setSelectValue] = useState("");
  const [open, setOpen] = React.useState(false);

  const [orders, setOrders] = useState([]);
  const [events, setEvents] = useState([]);

  const [updatedData, setUpdatedData] = useState(null);

  // setIsLoading(true);

  // const handleChange = (event) => {
  //   setSelectValue(event.target.value);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  // const handleOpen = () => {
  //   setOpen(true);
  // };

  useEffect(() => {
    window.scrollTo(0, 100);
    window.scrollTo(0, 0);
    fetchOrgnEvents();
  }, []);

  const fetchOrgnEvents = async () => {
    const response = await axios.post(ApiManager.orgnDashboard(), {
      email: localStorage.getItem("email"),
    });

    if (response.data.StatusCode === 200) {
      setIsLoading(false);
      setEvents(response.data.result.eventslist);
    } else {
      setIsLoading(false);

      console.log("err: ", response.data.Message);
    }
  };

  return (
    <Box>
      <div style={{ display: isLoading ? "flex" : "none" }} className="modal">
        <div className="modal-content">
          <div className="loader"></div>
          <div className="modal-text">Processing...</div>
        </div>
      </div>
      <>
        <Box>
          <>
            <Header />
            <div className="fullWidthContainer" style={{ maxWidth: "1200px", width: "100%", margin: "20px auto", padding: "0 20px" }}>
              <Typography className="evntRegd"
                sx={{
                  fontFamily: FontHelper.fBold,
                  fontSize: "30px",
                  fontWeight: 700,
                  color: "#3F0E77",
                  // px: 2,
                  py: 1,
                  // marginLeft: "80px",
                }}
              >Event Dashboard</Typography>

            </div>
            {/* <SectionTitle3 title="Event Dashboard" /> */}
            <OrganizerDashboardList
              events={updatedData ? updatedData : events}
            />
            <Footer />
          </>
        </Box>
      </>
    </Box>
  );
};

export default OrganizerDashboard;
