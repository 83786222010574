import { Box, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import FontHelper from "../../Helpers/FontHelper";
import ColorHelper from "../../Helpers/ColorHelper";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  addToCart,
  removeFromCart,
  removePrintModal,
  removePrint,
} from "../../redux/shoppingActions";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import "./ProductStyles.css";
import AddToCartAddedBtn from "./ProductCardComps/AddToCartAddedBtn";

const SinglePrintProduct = ({
  dataimg,
  orderDetailsID,
  imageAthlete,
  stateDetails,
  cartDetails,
}) => {
  const dispatch = useDispatch();
  const [AddToCartText, setAddtoCartText] = useState("Add To Cart");

  const cartInfo = useSelector((state) => state.shop.cartInfo);
  const handleAddtoCart = (id, s3, image, product, type, price, item) => {
    const cartItems = {
      id: id,
      s3: s3,
      image: image,
      imageAthlete: imageAthlete,
      category: "image", //print
      bibNumber: stateDetails.bibNumber,
      type: "print", //image
      videopath: "",
      // loc:0
    };
    if (AddToCartText == "Add To Cart") {
      setAddtoCartText("Added");
      product == undefined && price == undefined
        ? dispatch(addToCart(cartDetails, cartItems))
        : dispatch(
            addToCart(cartDetails, {
              ...cartItems,
              product: product,
              price: price,
              dataimg: dataimg.id,
            })
          );
    } else {
      setAddtoCartText("Add To Cart");
      dispatch(removePrintModal(id, cartInfo, product));
    }
  };

  const CardBtnStyle = {
    fontFamily: FontHelper.fBold,
    fontSize: 12,
    color: ColorHelper.WhiteColor,
    backgroundColor: " #3f0e77",
    px: { xs: "0px", md: "15px" },
    py: { xs: "0px", md: "10px" },
    height: { xs: "22px", md: "auto" },
    borderRadius: 0,
    textTransform: "capitalize",
    width: "100%",
    minWidth: "auto",
    mb: 0,
    display: "flex",
    justifycontent: "center",
    alignItems: "center",
    flexWrap: "wrap",
    "&:hover": {
      backgroundColor: "#22c55e",
      borderRadius: "3px",
    },
    "> span": {
      mx: { xs: 0, lg: 0.3 },
    },
  };

  const checkInCart = (dataimg) => {
    const ids = cartInfo.filter((items) => items.id && items.type == "print");
    const printIDs = ids.map((items) => items.id + items.product);

    if (printIDs.includes(orderDetailsID + dataimg.name)) {
      setAddtoCartText("Added");
    }
  };

  React.useEffect(() => {
    checkInCart(dataimg);
  }, [cartInfo]);

  return (
    <Grid item xs={6} md={3} key={dataimg.id}>
      <Box className="cardmodal">
        <img
          className="cardimgboxone"
          src={dataimg.image}
          alt={dataimg.prod_id}
        />
        <Typography
          className="smallname"
          sx={{
            fontSize: "0.9rem",
            fontFamily: FontHelper.fRegular,
            color: ColorHelper.LightGrey5,
          }}
        >
          {dataimg.name}
        </Typography>
        <Box
          sx={{
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography
            className="pricename"
            sx={{
              fontFamily: FontHelper.fBold,
              fontSize: "1.2rem",
              color: ColorHelper.BlackColor,
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            Price: ₹{dataimg.price}{" "}
          </Typography>
          <Typography
            className="smallname"
            style={{
              fontFamily: FontHelper.fMedium,
              fontSize: "12px",
            }}
          >
            <span
              style={{
                fontFamily: FontHelper.fMedium,
                fontSize: "12px",
                color: ColorHelper.PinkColor,
              }}
            >
              ₹{dataimg.pricewthgst}
            </span>
            &nbsp;inc.GST
          </Typography>
        </Box>
        {/*  <Button
          // disableripple={false}
          onClick={() => {
            handleAddtoCart(
              orderDetailsID,
              imageAthlete,
              dataimg.name,
              "print",
              dataimg.price
            );
          }}
          className="CartButton"
          btnText="PRINT"
          sx={{
            fontFamily: FontHelper.fBold,
            fontSize: 12,
            color: ColorHelper.WhiteColor,
            backgroundColor: "#b91880",
            px: "15px",
            py: "10px",
            height: 40,
            borderRadius: 0,
            textTransform: "capitalize",
            width: "100%",
            borderRadius: "3px",
            marginRight: "5px",
            mb: 0,
            "&:hover": {
              backgroundColor: ColorHelper.NavyBlue,
            },
          }}
          startIcon={
            addToCartText == "Added" ? <CheckCircleIcon /> : <AddCircleIcon />
          }
        >
          {addToCartText}
        </Button> */}
        <AddToCartAddedBtn
          AddToCartText={AddToCartText}
          CardBtnStyle={CardBtnStyle}
          handleCartBtn={() => {
            handleAddtoCart(
              orderDetailsID,
              dataimg.s3,
              dataimg.image,
              dataimg.name,
              "print",
              dataimg.price
            );
            // id, s3, image, product, type, price, item
          }}
        />
        {/* <button>sas</button> */}
      </Box>
    </Grid>
  );
};
export default SinglePrintProduct;
