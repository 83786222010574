import ColorHelper from "../../../Helpers/ColorHelper";
import FontHelper from "../../../Helpers/FontHelper";

const SectionTitleStyle = {
    sectionHeading : {
        textAlign: 'center',
        mt: 5,
        px: {xs: 5, md: 15},
    },
    title : {
        color: ColorHelper.PrimaryColor,
        fontFamily: FontHelper.fBold,
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '22px',
        lineHeight: '24px',
        mb: 5,
    }
}

export default SectionTitleStyle;