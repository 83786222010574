import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import ColorHelper from "../../Helpers/ColorHelper";
import FontHelper from "../../Helpers/FontHelper";
import PrivacyHeaderStyle from "./PrivacyHeaderStyle";

const PrivacyHeader = () => {
  return (
    <Box sx={PrivacyHeaderStyle.SectionWrapper}>
      <Typography sx={PrivacyHeaderStyle.Title}>Privacy Policy</Typography>
      <Typography sx={PrivacyHeaderStyle.Caption}>
        Effective: 15 July, 2021
      </Typography>
    </Box>
  );
};

export default PrivacyHeader;
