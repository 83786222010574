import React, { useState, useEffect } from "react";
import { Box, Button, Dialog, Slide, Typography } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";

// Helpers
import IconHelper from "../../../Helpers/IconHelper";

// Custom Styles
// import ShowBanner3dStyle from "../../ShowBanner3dStyle";
import ImageUploadModalStyle from "./ImageUploadModalStyle";
// import PreOrderDownloadForm from "./PreOrderDownloadForm";
import ConstantHelper from "../../../Helpers/ConstantHelper";
import ColorHelper from "../../../Helpers/ColorHelper";
import ImageUploadForm from "./ImageUploadForm";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement,
  },
  ref: React.Ref<unknown>
) {
  return <Slide ref={ref} {...props} />;
});

export default function ImageUploadModal(props) {
  const closeDialog = () => {
    props?.onClose();
  };

  return (
    <div style={{ position: "relative" , width:"200px" }}>
      <Dialog
        TransitionComponent={Transition}
        open={props?.open}
        onClose={closeDialog}
      >
        <Box sx={ImageUploadModalStyle.modalOuter}>
          <Box sx={ImageUploadModalStyle.modalWrapper}>
            <Button
              onClick={closeDialog}
              sx={ImageUploadModalStyle.CloseIconContainer}
              className="cf-close"
            >
              <IconHelper.CloseIcon
                color={ColorHelper.RedColor}
                bgcolor={ColorHelper.LightRedColor}
              />
            </Button>
            <Box sx={ImageUploadModalStyle.modalContent}>
              <Box sx={ImageUploadModalStyle.main}>
                <ImageUploadForm  closeDialog={closeDialog}/>
              </Box>
            </Box>
          </Box>
        </Box>
      </Dialog>
    </div>
  );
}
