import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import FooterStyle from "./FooterStyle";
import IconHelper from "../../Helpers/IconHelper";
import { useNavigate } from "react-router-dom";
import ColorHelper from "../../Helpers/ColorHelper";
// import { Instagram, YouTube } from "@mui/icons-material";

const Footer = () => {
  const navigate = useNavigate();

  return (
    <>
      <Box sx={FooterStyle.SectionWrapper1}>
        <Box sx={FooterStyle.Footerupperpart}>
          <Box sx={FooterStyle.Copyright1}>About Us</Box>
          <Box sx={FooterStyle.Copyright2}>
            <p>
              Oneglint is India's fastest growing and leading Photography
              platform.
              <br />
              Our offerings to the organisers of participative sports is
              un-parallel in the industry. <br />
              We aspire to bring "Joy to the participants"
              {/* Oneglint is the India's fastest growing and <br /> leading events
              sports photography platform <br /> since 2016. */}
            </p>
          </Box>
          <Box sx={FooterStyle.Copyright3}>
            <Box
              sx={FooterStyle.footerLinks}
              onClick={() => {
                navigate("/");
              }}
            >
              Home
            </Box>
            <Box
              sx={FooterStyle.footerLinks}
              onClick={() => {
                window.open("https://oneglint.com/");
              }}
            >
              General Events
            </Box>
            <Box
              sx={FooterStyle.footerLinks}
              onClick={() => {
                navigate("/faqs");
              }}
            >
              FAQs
            </Box>
            <Box
              sx={FooterStyle.footerLinks}
              onClick={() => {
                navigate("/contact-us");
              }}
            >
              Contact Us
            </Box>
          </Box>
        </Box>

        <Box sx={FooterStyle.imagefooter}>
          <Box onClick={() => window.open("")} style={FooterStyle.Link}>
            <Box>
              <img src={IconHelper.Logo} alt="BanrdsLogo" />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          px: {
            xs: 0,
            sm: 7,
            lg: 15,
            backgroundColor: ColorHelper.PrimaryColor,
          },
        }}
      >
        <Box
          sx={{
            borderBottom: `1px solid ${ColorHelper.WhiteColor3}`,
            width: "100%",
            height: "auto",
          }}
        ></Box>
      </Box>

      {/* <Box sx={FooterStyle.SectionWrapperuderline} /> */}

      <Box sx={FooterStyle.SectionWrapper}>
        <Box sx={FooterStyle.socialIconsContainer}>
          <Box sx={FooterStyle.Copyright}>
            © 2016-2023 Oneglint Media Solutions Pvt Ltd. All Rights Reserved.
          </Box>
        </Box>
        <Box sx={FooterStyle.socialIconsContainer}>
          <Box
            sx={FooterStyle.FooterIcons}
            onClick={() => navigate("/terms-and-conditions")}
          >
            Terms And Conditions
          </Box>

          <Box
            sx={FooterStyle.FooterIcons}
            onClick={() => {
              navigate("/privacypolicy");
            }}
          >
            Privacy Policy
          </Box>
        </Box>
        <Box
          sx={{
            ...FooterStyle.socialIconsContainer,
            margin: "auto",
            mt: { xs: 3, sm: 3, md: 3, lg: 0 },
          }}
        >
          <Box
            onClick={() => window.open("https://www.twitter.com/oneglint")}
            style={FooterStyle.Link}
          >
            <Box sx={FooterStyle.FooterIcons}>
              <img src={IconHelper.Twitter} alt="BanrdsLogo" />
            </Box>
          </Box>
          <Box
            onClick={() => window.open("https://www.youtube.com/oneglint")}
            style={FooterStyle.Link}
          >
            <Box sx={FooterStyle.FooterIcons}>
              {/* <img src={IconHelper.Youtube} alt="BanrdsLogo" /> */}
              <IconHelper.Youtube />
            </Box>
          </Box>
          <Box
            onClick={() => window.open("https://www.facebook.com/oneglint")}
            style={FooterStyle.Link}
          >
            <Box sx={FooterStyle.FooterIcons}>
              <img src={IconHelper.Facebook} alt="BanrdsLogo" />
            </Box>
          </Box>
          <Box
            onClick={() => window.open("https://instagram.com/oneglint/")}
            style={FooterStyle.Link}
          >
            <Box sx={FooterStyle.FooterIcons}>
              {/* <img src={IconHelper.Instagram} alt="BanrdsLogo" /> */}
              <IconHelper.Instagram />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Footer;
