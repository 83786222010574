import ImageHelper from "../../Helpers/ImageHelper";

const HighlightsData = [
   {
    id: 0,
    title: "NMDC Hydrabad Marathon 2022",
    img: ImageHelper.HighlightImage,
    Button: "/"
   },
   {
    id: 1,
    title: "NMDC Hydrabad Marathon 2022",
    img: ImageHelper.HighlightImage,
    Button: "/"
   },
   {
    id: 2,
    title: "NMDC Hydrabad Marathon 2022",
    img: ImageHelper.HighlightImage,
    Button: "/"
   },
   {
    id: 3,
    title: "NMDC Hydrabad Marathon 2022",
    img: ImageHelper.HighlightImage,
    Button: "/"
   },
   {
    id: 4,
    title: "NMDC Hydrabad Marathon 2022",
    img: ImageHelper.HighlightImage,
    Button: "/"
   },
]

export default HighlightsData;