import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import ColorHelper from "../../../Helpers/ColorHelper";
import ConstantHelper from "../../../Helpers/ConstantHelper";
import FontHelper from "../../../Helpers/FontHelper";
import IconHelper from "../../../Helpers/IconHelper";
import ImageHelper from "../../../Helpers/ImageHelper";
import BorderedButton from "../../Ui-Components/Buttons/BorderedButton/BorderedButton";
import FilledButton from "../../Ui-Components/Buttons/FilledButton/FilledButton";
import SectionTitle2 from "../../Ui-Components/SectionTitle2/SectionTitle2";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { clearCart, orderInfo } from "../../../redux/shoppingActions";

const OrderStatus = (props) => {
  const { title, description, status } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cartDetails = useSelector((state) => state.shop.cartDetails);

  const goToOrderDetails = (orderId) => {
    dispatch(orderInfo(cartDetails.bibNumber, orderId, ""));

    navigate("/orderDetails", {
      state: {
        bibId: cartDetails.bibNumber,
        payment_id: orderId,
        // moveFrom: "Order Success",
      },
    });
  };

  React.useEffect(() => {
    dispatch(clearCart());
  }, []);

  return (
    <Box>
      <SectionTitle2
        title="Order Status"
        eventLogoShow={false}
        bgColor={ColorHelper.LightPurple2}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          py: { xs: 2, sm: 5 },
          px: { xs: 2, sm: 15 },
        }}
      >
        <img
          src={
            ImageHelper.SucessStatus
            // : ImageHelper.ErrorStatus
          }
          alt="StatusSuccess"
        />
        <Typography
          sx={{
            fontSize: { xs: 26, md: 36 },
            color: ColorHelper.BlackColor,
            fontFamily: FontHelper.fSemiBold,
            lineHeight: "44px",
          }}
        >
          {"Thank You. Your order has been placed successfully!"}
        </Typography>
        <Typography
          sx={{
            fontSize: { xs: 14, md: 16 },
            color: ColorHelper.DarkGrey2,
            fontFamily: FontHelper.fRegular,
            pt: { xs: "8px", md: "16px" },
            lineHeight: "19.2px",
          }}
        >
          Order Confirmation Notification with download Link has been mailed to
          your Email-Id "{localStorage.getItem("email")}".
        </Typography>
        <Typography
          sx={{
            fontSize: { xs: 14, md: 16 },
            color: ColorHelper.DarkGrey2,
            fontFamily: FontHelper.fRegular,
            pt: { xs: "8px", md: "16px" },
            lineHeight: "19.2px",
          }}
        >
          {"Your order ID is "}
          {localStorage.getItem("isSignedIn") == "true" ? (
            <span
              onClick={() => {
                goToOrderDetails(localStorage.getItem("txnid"));
              }}
              style={{
                cursor: "pointer",
                textdecoration: "underline",
                color: "blue",
              }}
            >
              {localStorage.getItem("txnid")}.
            </span>
          ) : (
            <span>
              {localStorage.getItem("txnid")}. Please login to view your order
              history
            </span>
          )}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
          rowGap: 2,
        }}
      >
        <FilledButton
          btnText={ConstantHelper.ReturnToHomepage}
          color={ColorHelper.WhiteColor}
          bgColor={ColorHelper.PinkColor}
          startIcon={<IconHelper.ReturnArrow />}
          handleClick={() => navigate("/")}
          sx={{
            "> div> div": {
              mt: "4px",
            },
          }}
        />
        {localStorage.getItem("isSignedIn") !== "true" ? (
          <BorderedButton
            btnText={ConstantHelper.LoginForOrderHistory}
            color={ColorHelper.BlackColor}
            bgColor={ColorHelper.WhiteColor}
            bordercolor={ColorHelper.BlackColor}
            startIcon={<IconHelper.CartIcon />}
            handleClick={() => navigate("/sign-in")}
          />
        ) : null}
      </Box>
    </Box>
  );
};

export default OrderStatus;
