import React, { useState } from "react";
import { Box } from "@mui/system";
import { Typography, Pagination, MenuItem, Select } from "@mui/material";
import {
  Paper,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Divider,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import ConstantHelper from "../../Helpers/ConstantHelper";
import Autocomplete from "@mui/material/Autocomplete";
import { useSnackbar } from "notistack";
import axios from "axios";
import ColorHelper from "../../Helpers/ColorHelper";
import Button from "@mui/material/Button";
import FontHelper from "../../Helpers/FontHelper";
import "../../Pages/table.css";
import { useSelector } from "react-redux";
import { ApiManager } from "../../ApiClient";

const OrganizerDashboardList = ({ events }) => {
  const snack = useSnackbar();
  const [orders, setOrders] = useState([]);

  const [style, setStyle] = useState("combo-box-demo");
  const [inputValue, setInputValue] = useState("");

  const [eventsTotalBib, setEventsTotalBib] = useState("");

  const [eventsTotalImage, setEventsTotalImage] = useState("");
  const [eventsTotalUnImage, setEventsTotalUnImage] = useState("");

  const [eventsToDate, setEventsToDate] = useState("");
  const [selectedEvent, setSelectedEvent] = useState();

  const [filterData, setFilterData] = useState([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const state = useSelector((state) => state.shop);
  const [updateOrders, setUpdateOrders] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async () => {
    setIsLoading(true);

    await axios
      .post(ApiManager.partnerShare(), {
        eventId: selectedEvent,
        userid: localStorage.getItem("email"),
      })
      .then((res) => {
        if (res.data.StatusCode === 200) {
          setIsLoading(false);

          if (res.data.HasError === true) {
            snack.enqueueSnackbar({
              variant: "warning",
              message: res.data.Message,
            });
          } else {
            setOrders(res.data.result.usr_revenue);
            setUpdateOrders(res.data.result.usr_revenue);

            snack.enqueueSnackbar({
              variant: "success",
              message: res.data.Message,
            });
          }
        } else {
          setIsLoading(false);
          snack.enqueueSnackbar({
            variant: "warning",
            message: res.data.Message,
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        snack.enqueueSnackbar({
          variant: "warning",
          message: "Error",
        });
      });
  };

  const filteredOrder = (search) => {
    const results = orders.filter((item) => {
      return (
        item.price == search ||
        item.totalorders == search ||
        item.totalimages == search ||
        item.userimages == search ||
        item.usr_share == search
      );
    });

    if (results.length > 0) {
      setUpdateOrders(results);
    } else {
      setUpdateOrders(orders);
    }
  };

  return (
    <Box>
      <div style={{ display: isLoading ? "flex" : "none" }} className="modal">
        <div className="modal-content">
          <div className="loader"></div>
          <div className="modal-text">Processing...</div>
        </div>
      </div>

      <div className="fullWidthContainer"
          style={{ maxWidth: "1200px", width: "100%", margin: "0px auto", padding: "0 20px" }}>
        <Box>
          <Grid  container spacing={2}>
            <Grid
              item
              lg={10}
              md={10}
              sm={9}
              xs={9}
              sx={{
                fontFamily: FontHelper.fBold,
                fontSize: "30px",
                fontWeight: 700,
                color: "#3F0E77",
                marginBottom: "10px",
                // px: 2,
                // paddingTop: "20px",
                // marginLeft: "80px",
                // marginRight: "80px",
                // marginTop: "12px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Autocomplete
                sx={{
                  width: "100%",
                }}
                onChange={(events, newValue) => {
                  setSelectedEvent(newValue.event_id);
                  // console.log("selected event ------", selectedEvent);
                  {
                    newValue == null ? setStyle("") : setStyle("afterSubmit");
                  }

                  localStorage.setItem(
                    "EventName",
                    newValue.event_id + " - " + newValue.event_name
                  );
                  localStorage.setItem("EventId", newValue.event_id);
                }}
                onInputChange={(events, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                options={events}
                getOptionLabel={(option) =>
                  option.event_name
                    ? option.event_id + " - " + option.event_name
                    : ""
                }
                freeSolo
                size="small"
                renderInput={(params) => (
                  <TextField
                    sx={{ fontFamily: FontHelper.fRegular }}
                    {...params}
                    placeholder={ConstantHelper.SerachFieldTitle}
                    inputProps={{
                      ...params.inputProps,
                      maxLength: 2,
                      onKeyPress: (event) => {
                        const charCode = event.which || event.keyCode;
                        const charStr = String.fromCharCode(charCode);
                        if (!/[A-Za-z]/.test(charStr)) {
                          event.preventDefault();
                        }
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item lg={2} md={2} sm={3} xs={3} >
                <Button
                  sx={{
                    height: "38px",
                    height: { lg: "38px", md: "38px", sm: "35px", xs: "32px" },
                    width: "100%",
                    alignItems: "center",
                    flexDirection: "column",
                    backgroundColor: "#3f0e77",
                    color: "#FFFFFF",
                    "&:hover": { backgroundColor: "#fff", border: "1px solid #3f0e77", color: "#3f0e77" }
                  }}
                  onClick={onSubmit}
                >
                  GET DATA
                </Button>
            </Grid>
          </Grid>

          <Grid  container spacing={2}>
            <Grid item lg={12} md={12} sm={12}>

              <Paper
                component="form"
                sx={{
                  width: "30%",
                  width: { lg: "30%", md: "30%", sm: "100%", xs: "100%" },
                  mb: 1,
                  border: "1px solid #ccc",
                  boxShadow: "none"
                }}
              >
                <IconButton sx={{ p: "0 10px" }} aria-label="menu">
                  <SearchIcon />
                </IconButton>
                <InputBase
                  sx={{ ml: 1, flex: 1, fontSize: "12px", py: 1 }}
                  onChange={(e) => {
                    filteredOrder(e.target?.value);
                  }}
                  placeholder="Search here"
                  inputProps={{ "aria-label": "search google maps" }}
                />
              </Paper>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  // px: { xs: 4, md: 10 },
                  // py: 1,
                }}
              >
                <TableContainer component={Paper}>
                  <Table sx={{ }} aria-label="simple table" className="custom_table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">
                          Order Value{" "}
                          <SwapVertIcon
                            sx={{
                              fontSize: "12px",
                              ":hover": { cursor: "pointer" },
                              color: "#3F0E77",
                            }}
                          />
                        </TableCell>

                        <TableCell align="left">
                          Total Order{" "}
                          <SwapVertIcon
                            sx={{
                              fontSize: "12px",
                              color: "#3F0E77",
                              ":hover": { cursor: "pointer" },
                            }}
                          />
                        </TableCell>

                        <TableCell align="left">
                          Total Images{" "}
                          <SwapVertIcon
                            sx={{
                              fontSize: "12px",
                              color: "#3F0E77",
                              ":hover": { cursor: "pointer" },
                            }}
                          />
                        </TableCell>

                        <TableCell align="left">
                          My pics sold{" "}
                          <SwapVertIcon
                            sx={{
                              fontSize: "12px",
                              color: "#3F0E77",
                              ":hover": { cursor: "pointer" },
                            }}
                          />
                        </TableCell>

                        <TableCell align="left">
                          My Share{" "}
                          <SwapVertIcon
                            sx={{
                              fontSize: "12px",
                              color: "#3F0E77",
                              ":hover": { cursor: "pointer" },
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    {updateOrders && updateOrders.length > 0 ? (
                      <TableBody>
                        {updateOrders
                          ?.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((row, index) => (
                            <TableRow
                              key={row.index}
                              sx={{
                                "&:last-child td, &:last-child th": { border: 0 },
                              }}
                            >
                              <TableCell component="th" scope="row" align="left">
                                {row.price}
                              </TableCell>

                              <TableCell sx={{ cursor: "pointer" }} align="left">
                                {row.totalorders}
                              </TableCell>

                              <TableCell
                                sx={{
                                  cursor: "pointer",
                                  textDecoration: "underline",
                                  "&:hover": {
                                    color: ColorHelper.PinkColor,
                                  },
                                }}
                                align="left"
                              >
                                {row.totalimages}
                              </TableCell>

                              <TableCell sx={{ cursor: "pointer" }} align="left">
                                {row.userimages}
                              </TableCell>

                              <TableCell align="left">{row.usr_share}</TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    ) : (
                      <TableBody>
                        <TableCell colSpan={10} align="center">
                          No matching records found
                        </TableCell>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Box>

              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "flex-start",
                  px: { xs: 4, md: 10 },
                  py: 2,
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "flex-start",
                    px: { xs: 4, md: 10 },
                    py: 2,
                  }}
                >
                  {updateOrders.length > rowsPerPage && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        ml: "60px",
                        mb: "15px",
                      }}
                    >
                      <Pagination
                        count={Math.ceil(updateOrders?.length / rowsPerPage)}
                        page={page + 1}
                        color={"primary"}
                        onChange={(event, value) => {
                          setPage(value - 1);
                        }}
                      />
                    </Box>
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        </div>
      {/* <Footer /> */}
    </Box>
  );
};
export default OrganizerDashboardList;
