import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, } from "@mui/material";
import { Modal } from "@mui/material";
import { sponsorDiscount } from "../../../redux/shoppingActions";
import ImageSlider from "../../Modals/PackagesModal/ImageComponent/ImageSlider";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from '@mui/material/FormControlLabel';
import IconHelper from "../../../Helpers/IconHelper";
import FontHelper from "../../../Helpers/FontHelper";
import ColorHelper from "../../../Helpers/ColorHelper";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
const SponsorDiscountModal = ({ sponsorOfferAvailableFlag }) => {
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: { xs: "70%", lg: "725px" },
        // height: { xs: "450px", lg: "300px" },
        bgcolor: "background.paper",
        boxShadow: 24,
        p: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        padding: { xs: 2, md: "24px" },
        gap: "24px",
        borderRadius: "5px",
        flex: "none",
        order: "0",
        alignSelf: "stretch",
        flexGrow: "0",
    };
    const dispatch = useDispatch();
    const location = useLocation();
    const findpath = location.pathname;
    const [openSponorModal, setOpenSponsorModal] = useState(false);


    const cartInfo = useSelector((state) => state.shop.cartInfo);
    const downloadsCart = cartInfo.filter((item) => {
        if (item.type == "download") {
            return item.category === "image";
        }
    });
    const { getSponsorOffersDetails, sponsordetailflag } = useSelector((state) => state.shop.cartCheckout);


    const handleClose = () => {
        setOpenSponsorModal(false);
        dispatch(sponsorDiscount({ apply: false, photoID: [] }));
        localStorage.setItem("showModal", false)
    };
    const handleClick = () => {
        localStorage.setItem("showModal", false)
        dispatch(sponsorDiscount({ apply: true, photoID: [] }));
        setTimeout(() => {
            setOpenSponsorModal(false);
        }, 300)
    }
    const showModal = localStorage.getItem("showModal");
    // useEffect(() => {
    //     if (getSponsorOffersDetails?.discount_amount) {
    //         setIsLoading(false);
    //     }
    // }, [getSponsorOffersDetails])

    useEffect(() => {

        if (findpath == "/cart" && downloadsCart.length !== 0 && getSponsorOffersDetails && sponsorOfferAvailableFlag && showModal === "true") {
            // if (false) {
            setOpenSponsorModal(true);
        } else {
            setOpenSponsorModal(false);
        }
    }, [showModal, getSponsorOffersDetails, sponsordetailflag]);

    return (
        <Box>

            {
                sponsordetailflag && sponsorOfferAvailableFlag && findpath == "/cart" &&
                <Box
                    // behavior='scroll'
                    // scrollamount='5'
                    // width='30%'
                    // direction='left'
                    // height='50px'
                    sx={{
                        fontFamily: FontHelper.fBold,
                        fontFamily: FontHelper.fBold,
                        padding: { xs: "28px 50px", lg: "8px 80px" },
                        width: "100%",
                        overflow: "hidden",
                        whiteSpace: { xs: "wrap", md: "nowrap" },
                        height: "33px",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        backgroundColor: ColorHelper.LightYellow,
                        color: ColorHelper.YellowColor,
                    }}>

                    Special sponsor offer {getSponsorOffersDetails?.sponsor_name} {getSponsorOffersDetails?.discount_amount} Off on all digital downloads.
                    {downloadsCart.length !== 0 && <button onClick={() => { setOpenSponsorModal(true) }}
                        style={{
                            marginLeft: "10px",
                            backgroundColor: "#7e4d14",
                            padding: "3px",
                            borderRadius: "5px",
                            border: "none",
                            color: "#fff",
                            cursor: "pointer"
                        }}>
                        Apply</button>}
                </Box>
            }
            <Modal
                open={openSponorModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box>
                    <Box sx={style}>
                        <Box
                            style={{
                                boxSizing: "border-box",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "flex-start",
                                padding: { xs: 0, md: "0px 0px 8px 16px" },
                                gap: { xs: "6px", md: "282px" },
                                borderBottom: "1px solid #E4E7EC",
                                flex: "none",
                                order: "0",
                                alignSelf: "stretch",
                                flexGrow: "0",
                            }}
                        >
                            <Typography
                                id="modal-modal-title"
                                variant="h6"
                                component="h2"
                                sx={{
                                    fontFamily: FontHelper.fRegular,
                                    fontStyle: "normal",
                                    fontWeight: "600",
                                    fontSize: { xs: 14, md: "20px" },
                                    lineHeight: "24px",
                                    color: ColorHelper.LightGrey6,
                                    flex: "none",
                                    order: "0",
                                    flexGrow: "0",
                                }}
                            >
                                {getSponsorOffersDetails?.sponsor_name}
                            </Typography>
                            <Box
                                onClick={handleClose}
                                style={{
                                    cursor: "pointer",
                                }}
                            >
                                <IconHelper.CloseIcon
                                    color={ColorHelper.RedColor}
                                    bgcolor={ColorHelper.RedColor}
                                />
                            </Box>
                        </Box>
                        <Box
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                padding: "0px 18px",
                                gap: "30px",
                                flex: "none",
                                order: "1",
                                alignSelf: "stretch",
                                flexGrow: "0",
                            }}
                        >
                            <Grid
                                container
                                id="ModalContainer"
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    padding: "0px",
                                    gap: { xs: "20px" },
                                    flex: "none",
                                    order: "0",
                                    flexGrow: "1",
                                    flexWrap: { xs: "wrap", md: "nowrap" },
                                }}
                            >
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    // md={8}
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "flex-start",
                                        padding: "0px",
                                        gap: "8px",
                                        flex: "none",
                                        order: { xs: 2, md: 0 },
                                        flexGrow: "1",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "flex-start",
                                            padding: "0px",
                                            gap: "4px",
                                            flex: "none",
                                            order: "0",
                                            alignSelf: "stretch",
                                            flexGrow: "0",
                                        }}
                                    >
                                        <Typography
                                            id="modal-modal-title"
                                            variant="h6"
                                            component="h2"
                                            sx={{
                                                fontFamily: FontHelper.fRegular,
                                                fontStyle: "normal",
                                                fontWeight: "600",
                                                fontSize: "20px",
                                                lineHeight: "24px",
                                                color: ColorHelper.LightGrey6,
                                                flex: "none",
                                                order: "0",
                                                flexGrow: "0",
                                            }}
                                        >
                                            {getSponsorOffersDetails?.description}
                                        </Typography>
                                        <Typography
                                            id="modal-modal-title"
                                            variant="h6"
                                            component="h2"
                                            sx={{
                                                fontFamily: FontHelper.fRegular,
                                                fontStyle: "normal",
                                                fontWeight: "500",
                                                fontSize: "16px",
                                                lineHeight: "19px",
                                                color: "ColorHelper.DarkGrey7",
                                                flex: "none",
                                                order: "1",
                                                alignSelf: "stretch",
                                                flexGrow: "0",
                                            }}
                                        >
                                            Your are elegible for this Offer. Select an image for <span style={{ fontWeight: "bold" }}>
                                                <CurrencyRupeeIcon style={{ fontSize: "14px" }} />{getSponsorOffersDetails?.discount_amount} </span>
                                            Discount at the time of Order CheckOut
                                        </Typography>
                                    </Box>
                                    <FormControlLabel control={<Checkbox />} label="Plese Check to Avail this offer" onChange={handleClick} />
                                </Grid>

                                {/* <Grid
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <ImageSlider />
                  </Grid> */}
                            </Grid>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </Box>
    )
}

export default SponsorDiscountModal;