import React from 'react'
import ColorHelper from '../../Helpers/ColorHelper'

const CartIcon = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.4096 9.75C12.9721 9.75 13.4671 9.4425 13.7221 8.9775L16.4071 4.11C16.4703 3.99632 16.5027 3.8681 16.5011 3.73804C16.4996 3.60798 16.4641 3.48058 16.3982 3.36845C16.3323 3.25631 16.2382 3.16334 16.1253 3.09871C16.0124 3.03409 15.8846 3.00006 15.7546 3H4.65457L3.94957 1.5H1.49707V3H2.99707L5.69707 8.6925L4.68457 10.5225C4.13707 11.5275 4.85707 12.75 5.99707 12.75H14.9971V11.25H5.99707L6.82207 9.75H12.4096ZM5.36707 4.5H14.4796L12.4096 8.25H7.14457L5.36707 4.5ZM5.99707 13.5C5.17207 13.5 4.50457 14.175 4.50457 15C4.50457 15.825 5.17207 16.5 5.99707 16.5C6.82207 16.5 7.49707 15.825 7.49707 15C7.49707 14.175 6.82207 13.5 5.99707 13.5ZM13.4971 13.5C12.6721 13.5 12.0046 14.175 12.0046 15C12.0046 15.825 12.6721 16.5 13.4971 16.5C14.3221 16.5 14.9971 15.825 14.9971 15C14.9971 14.175 14.3221 13.5 13.4971 13.5Z" fill={ColorHelper.SecondaryColor} />
        </svg>
    )
}

export default CartIcon