import React, { useState,useEffect } from "react";
import { SliderData } from "./SiderData";
import { FaArrowAltCircleRight, FaArrowAltCircleLeft } from "react-icons/fa";
import './imageModal.css';

const delay = 2500;

const ImageSlider = () => {

    const [index, setIndex] = useState(0);
    const timeoutRef = React.useRef(null);
  
    function resetTimeout() {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    }
    useEffect(() => {
        resetTimeout();
        timeoutRef.current = setTimeout(
          () =>
            setIndex((prevIndex) =>
              prevIndex === SliderData.length - 1 ? 0 : prevIndex + 1
            ),
          delay
        );
    
        return () => {
          resetTimeout();
        };
      }, [index]);

  return (
    <div className="slideshow">
    <div
      className="slideshowSlider"
      style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
    >
      {SliderData.map((data, index) => (
        <div
          className="slide"
          key={index}
        //   style={{ backgroundColor }}
        >
    <img className="sliderImage w-100" src={data.image} style={{width:"100%"}}  />
        </div>
      ))}
    </div>

    {/* <div className="slideshowDots">
      {colors.map((_, idx) => (
        <div
          key={idx}
          className={`slideshowDot${index === idx ? " active" : ""}`}
          onClick={() => {
            setIndex(idx);
          }}
        ></div>
      ))}
    </div> */}
  </div>
  );
};

export default ImageSlider;
