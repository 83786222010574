import React from 'react'
import ColorHelper from '../../Helpers/ColorHelper'

const UserLoginIcon = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 4.5C9.825 4.5 10.5 5.175 10.5 6C10.5 6.825 9.825 7.5 9 7.5C8.175 7.5 7.5 6.825 7.5 6C7.5 5.175 8.175 4.5 9 4.5ZM9 12C11.025 12 13.35 12.9675 13.5 13.5H4.5C4.6725 12.96 6.9825 12 9 12ZM9 3C7.3425 3 6 4.3425 6 6C6 7.6575 7.3425 9 9 9C10.6575 9 12 7.6575 12 6C12 4.3425 10.6575 3 9 3ZM9 10.5C6.9975 10.5 3 11.505 3 13.5V15H15V13.5C15 11.505 11.0025 10.5 9 10.5Z" fill={ColorHelper.SecondaryColor} />
        </svg>
    )
}

export default UserLoginIcon