import React from 'react'
import ColorHelper from '../../Helpers/ColorHelper'

const PlusCirclular = (props) => {
    const { color } = props;
    return (
        <svg width="47" height="46" viewBox="0 0 47 46" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="23.5" cy="23" r="23" fill={color} />
            <path fillRule="evenodd" clipRule="evenodd" d="M21.5 25V34H25.5V25L34.5 25V21L25.5 21V12H21.5V21L12.5 21V25L21.5 25Z" fill={ColorHelper.WhiteColor} />
        </svg>
    )
}

export default PlusCirclular