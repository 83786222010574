import axios from "axios";
import { ApiManager } from "../ApiClient";
import * as actionTypes from "./shoppingTypes";

export const setConfig = () => async (dispatch) => {
  try {
    const response = await axios.get(ApiManager.masterConfiguration());
    localStorage.setItem("Config", JSON.stringify(response.data.result));
    dispatch({ type: actionTypes.SAVE_CONFIG, payload: response.data });
  } catch {
    let err = {
      message: "Failed to retrieve master config",
    };
    dispatch({ type: actionTypes.SAVE_CONFIG, payload: err });
  }
};

export const saveUser = (userData) => {
  // console.log("userInfo", userData)
  return { type: actionTypes.SAVE_USER, payload: userData };

};

export const saveUserIsdCode = (code) => (dispatch) => {
  dispatch({
    type: actionTypes.SAVE_USER_ISD_CODE,
    payload: code
  })
}

export const fetchMultiBibProducts = (payload) => (dispatch) => { };

export const typeOfPage = (payload) => (dispatch) => {
  dispatch({ type: actionTypes.TYPE_OF_PAGE, payload });
};

export const fetchUnlabeledProducts =
  (eventName, eventID, perpageshow, pageNo) => async (dispatch) => {
    try {
      const response = await axios.post(
        ApiManager.searchUnlabledEvent(),
        // `https://test.photos.oneglint.com/api/searchUnlabeledEvent`,
        {
          eventName: eventName,
          eventID: eventID,
          perpageshow: perpageshow,
          pageNo: pageNo,
        }
      );
      localStorage.setItem("eventID", eventID);
      localStorage.setItem("isUnlabled", "true");
      dispatch({
        type: actionTypes.FETCH_PRODUCTS,
        payload: response.data.result,
      });
      return response;
    } catch { }
  };

export const getUnlabeledCartDetails = (cartDetails) => async (dispatch) => {
  try {
    const response = await axios.post(
      ApiManager.unlabledCartDetails(),
      // `https://test.photos.oneglint.com/api/unlabledCartDetails`,
      {
        ...cartDetails,
      }
    );
    localStorage.setItem("gst", response.data.result.gstInfo[0].gst_amount);
    dispatch({ type: actionTypes.CHECKOUT, payload: response.data.result });
  } catch {
    // console.log("Failed to fetch data!");
  }
};

export const resetData = () => async (dispatch) => {
  dispatch({ type: actionTypes.RESET });
};

export const fetchProducts = (eventName, BIBValue, eventId, userId, orderId) => async (dispatch) => {
  const session_token = localStorage.getItem("session_token");
  try {
    const response = await axios.post(ApiManager.searchEventByBib(), {
      eventName: eventName,
      bibNumber: BIBValue,
      eventID: eventId,
      userID: userId,
      sessionToken: session_token || "",
      orderId: orderId || ""

    });
    localStorage.setItem("isUnlabled", "false");
    localStorage.setItem(
      "awsImageExpiry",
      response?.data?.result?.awsImageExpary
    );
    dispatch({
      type: actionTypes.FETCH_PRODUCTS,
      payload: response.data.result,
    });
    return response;
  } catch {
    // console.log("Failed to fetch data!");
  }
};
export const fetchSelfieImageProduct = (result) => async (dispatch) => {
  dispatch({
    type: actionTypes.FETCH_PRODUCTS,
    payload: result,
  });
};

export const getCartDetails = (cartDetails) => async (dispatch) => {
  try {
    const response = await axios.post(ApiManager.cartDetails(), {
      ...cartDetails,
    });
    localStorage.setItem("gst", response.data.result.gstInfo[0].gst_amount);
    dispatch({ type: actionTypes.CHECKOUT, payload: response.data.result });
    return;
  } catch { }
};

export const clearCart = () => (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_CART });
};

export const setProducts = (products) => (dispatch) => {
  return {
    type: actionTypes.SET_PRODUCTS,
    payload: products,
  };
};

export const addToCart = (items, product) => (dispatch) => {
  let length = items.length;
  if (items.length == 0) {
    let cartInfo = [];
    cartInfo = [{ ...product, seq: 1 }];
    dispatch({
      type: actionTypes.ADD_TO_CART,
      payload: {
        cartInfo,
      },
    });
  } else {
    let cartInfo = items;
    // cartInfo.forEach((item) => {
    // item.seq+=1
    // if(item.id==product.id){
    // alreadyExists=true;
    // item.seq+=1
    // }
    // })
    // if(alreadyExists==false){
    // item.seq+=1
    cartInfo = [...cartInfo, { ...product, seq: items[length - 1].seq + 1 }];
    // }
    dispatch({
      type: actionTypes.ADD_TO_CART,
      payload: {
        cartInfo,
      },
    });
  }
};

export const addAllToCart = (product, bibNumber, cart) => (dispatch) => {
  let seq = 0;
  let cartInfo = [...cart];
  // const cartInfo = product.map((items)=>(items.id))
  const tempCart = product.map((items) => {
    if (items.category !== "video")
      return {
        ...items,
        bibNumber: bibNumber,
        seq: (seq += 1),
        videopath: "false",
        type: "download",
        category: "image",
      };
    return {
      ...items,
      seq: (seq += 1),
    };
  });

  for (let i = 0; i < tempCart.length; i++) {
    let flag = true;

    for (let j = 0; j < cart.length; j++) {
      if (
        tempCart[i].id === cart[j].id &&
        tempCart[i].type === "download" &&
        cart[j].type === "download"
      ) {
        flag = false;
      }
    }
    if (flag) {
      cartInfo.push(tempCart[i]);
    }
  }

  dispatch({
    type: actionTypes.ADD_TO_CART,
    payload: {
      cartInfo,
    },
  });
};

export const addAllPrintToCart = (product, bibNumber, id) => (dispatch) => {
  // console.log("addAllPrintToCart", { product, bibNumber, id });
  let seq = 0;
  let cartInfo = product.map((items) => {
    if (items.type === "print")
      return {
        ...items,
        bibNumber: bibNumber,
        seq: (seq += 1),
        videopath: "false",
        type: "print",
        category: "image",
      };
    else if (items.type.includes("collage")) {
      return {
        ...items,
        bibNumber: bibNumber,
        status: "Added",
        seq: (seq += 1),
      };
    } else if (items.category === "video") {
      return {
        id: items.imageid,
        image: items.s3,
        category: items.category,
        bibNumber: bibNumber,
        type: items.type,
        videopath: items.s3,
        seq: seq + 1,
      };
    }
    return {
      id: items.imageid,
      image: items.image,
      s3: items.s3,
      loc: 0,
      bibNumber: bibNumber,
      seq: (seq += 1),
      videopath: "false",
      type: items.type,
      category: items.category,
    };
  });
  // }
  // console.log("addAllPrintToCart", cartInfo);
  dispatch({
    type: actionTypes.ADD_TO_CART,
    payload: {
      cartInfo,
    },
  });
};

// export const addAllPrintToCart = (product, bibNumber, id) => (dispatch) => {
//   let seq = 0;
//   const cartInfo = product.map((items) => ({
//     ...items,
//     bibNumber: bibNumber,
//     product: items.name,
//     price: items.pricewthgst,
//     seq: (seq += 1),
//     videopath: "false",
//     type: "print",
//     category: "image",
//     id: id,
//   }));
//   dispatch({
//     type: actionTypes.ADD_TO_CART,
//     payload: {
//       cartInfo,
//     },
//   });
// };

export const removePrint = (itemID, product, itemName, seq) => (dispatch) => {
  var i = 0;
  var cartInfo = [];
  cartInfo = product.filter((items) => items.seq !== seq);
  dispatch({
    type: actionTypes.REMOVE_PRINT,
    payload: {
      cartInfo,
      itemID,
    },
  });
};

export const removePrintModal =
  (itemID, product, itemName, seq) => (dispatch) => {
    var i = 0;
    const printCart = product.filter((items) => items.type == "print");
    var cartInfo = [];
    var filteredCart = [];
    for (i = 0; i < product.length; i++) {
      if (
        product[i].product == itemName &&
        product[i].id == itemID &&
        product[i].type == "print"
      ) {
        filteredCart.push(product[i]);
      } else {
        // cartInfo=[{...product[i]}]
        cartInfo.push(product[i]);
      }
    }
    dispatch({
      type: actionTypes.REMOVE_PRINT,
      payload: {
        cartInfo,
      },
    });
  };

export const removeFromCartDigital = (itemID, product, seq) => (dispatch) => {
  const cartInfo = product.filter((items) => seq !== items.seq);
  dispatch({
    type: actionTypes.REMOVE_FROM_CART,
    payload: {
      cartInfo,
    },
  });
};

export const removeFromCartCollage = (itemID, product, seq) => (dispatch) => {
  const cartInfo = product.filter((items) => seq !== items.seq);
  dispatch({
    type: actionTypes.REMOVE_FROM_CART,
    payload: {
      cartInfo,
    },
  });
};

export const removeVideoFromCart = (itemID, product, seq) => (dispatch) => {
  const cartInfo = product.filter((items) => seq !== items.seq);
  dispatch({
    type: actionTypes.REMOVE_FROM_CART,
    payload: {
      cartInfo,
    },
  });
};

export const removeFromCart = (itemID, product, type) => (dispatch) => {
  const cartInfo = product.filter((items) => itemID !== items.id);

  dispatch({
    type: actionTypes.REMOVE_FROM_CART,
    payload: {
      cartInfo,
    },
  });
};

export const removeCartCollage = (itemID, product) => (dispatch) => {
  var i = 0;
  var cartInfo = [];
  var filteredCart = [];
  for (i = 0; i < product.length; i++) {
    if (product[i].id == itemID && product[i].type == "collage") {
      filteredCart.push(product[i]);
    } else {
      cartInfo.push(product[i]);
    }
  }
  dispatch({
    type: actionTypes.REMOVE_FROM_CART,
    payload: {
      cartInfo,
    },
  });
};

export const removeCartDownload = (itemID, product) => (dispatch) => {
  var i = 0;
  var cartInfo = [];
  var filteredCart = [];
  for (i = 0; i < product.length; i++) {
    if (product[i].id == itemID && product[i].type == "download") {
      filteredCart.push(product[i]);
    } else {
      cartInfo.push(product[i]);
    }
  }
  dispatch({
    type: actionTypes.REMOVE_FROM_CART,
    payload: {
      cartInfo,
    },
  });
};

export const updateCart = (itemID, value) => {
  return {
    type: actionTypes.UPDATE_CART,
    payload: {
      id: itemID,
      value: value,
    },
  };
};

export const viewCart = (itemID) => {
  return {
    type: actionTypes.VIEW_CART,
    payload: {
      id: itemID,
    },
  };
};

export const reset = () => {
  return {
    type: actionTypes.RESET,
  };
};
export const orderInfo = (bibId, orderId, eventID) => {
  return {
    type: actionTypes.ORDER_INFO,
    payload: { bibNumber: bibId, orderId, eventID },
  };
};
export const packageDetails = (value) => {
  return {
    type: actionTypes.PACKAGE_INFO,
    payload: value,
  };
};
export const clearDigitalPhoto = (cartInfo) => {
  const filteredCart = cartInfo.filter((item) => {
    if (item.type === "download") {
      return item.category !== "image";
    }
    return item;
  });
  return {
    type: actionTypes.CLEAR_ALL_DIGITAL_PHOTO,
    payload: filteredCart,
  };
};
export const sponsorDiscount = (info, data, action) => {
  let photoArr;
  if (action == "add") {
    const photos = [...data, info.photoID]
    photoArr = photos
  }
  else if (action == "remove") {
    const result = data.filter((ele) => ele != info.photoID);
    photoArr = result
  } else {
    photoArr = info.photoID
  }

  return {
    type: actionTypes.SPONSOR_DISCOUNT,
    payload: { ...info, photoID: photoArr }
  }
}
