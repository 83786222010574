import { Button, Card, CardActions, CardMedia, Grid } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect } from "react";
import ApiManager from "../ApiClient/ApiManager";
import BillView from "../Components/BillView/BillView";
import CollageJourneyMapSection from "../Components/CartSections/CollageJourneyMapSection/CollageJourneyMapSection";
import DigitalDownloadsSection from "../Components/CartSections/DigitalDownloadsSection/DigitalDownloadsSection";
import PrintsSection from "../Components/CartSections/PrintsSection/PrintsSection";
import FooterNote from "../Components/FoooterNote/FooterNote";
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import HeaderNote from "../Components/HeaderNote/HeaderNote";
import SummarySection from "../Components/SummarySection/SummarySection";
import SectionTitle2 from "../Components/Ui-Components/SectionTitle2/SectionTitle2";
import ColorHelper from "../Helpers/ColorHelper";
import { useSelector } from "react-redux";
import ConstantHelper from "../Helpers/ConstantHelper";
import axios from "axios";
import { useState } from "react";
import {
  addToCart,
  getCartDetails,
  orderInfo,
  removeFromCart,
} from "../redux/shoppingActions";
import { useDispatch } from "react-redux";
import EmptyCart from "../Components/CartSections/EmptyCart/EmptyCart";
import { useLocation } from "react-router-dom";
import { Pause, PlayCircle } from "@mui/icons-material";
import FontHelper from "../Helpers/FontHelper";
import SectionTitle3 from "../Components/Ui-Components/SectionTitle3/SectionTitle3";
import { isEmpty } from "lodash";
import AddToCartAddedBtn from "../Components/PorductCard/ProductCardComps/AddToCartAddedBtn";
import { useNavigate } from "react-router-dom";
const OrderDetails = () => {
  const { state, search } = useLocation();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);

  const orderref = new URLSearchParams(search).get("orderref");
  const e = new URLSearchParams(search).get("e");
  const lt = new URLSearchParams(search).get("lt");
  const h = new URLSearchParams(search).get("h");

  const navigate = useNavigate();

  const stateDetails = useSelector((state) => state.shop.products);
  const cartDetails = useSelector((state) => state.shop?.cartInfo);
  const cartInfo = useSelector((state) => state.shop.cartInfo);
  const [downloadsCart, setDownloadsCart] = useState();
  let isPending = localStorage?.getItem("isPending");

  const [play, setPlay] = useState(false);
  const vidRef = React.useRef(null);
  const dispatch = useDispatch();
  const handlePlayVideo = () => {
    vidRef.current?.play();
  };
  const handlePuaseVideo = () => {
    vidRef.current?.pause();
  };

  const updateBill = (updatedCart) => {};

  const [AddToCartText, setAddtoCartText] = useState("Add To Cart");

  // const CardBtnStyle = {
  //   fontFamily: FontHelper.fBold,
  //   fontSize: 12,
  //   color: ColorHelper.WhiteColor,
  //   backgroundColor: " #3f0e77",
  //   px: { xs: "0px", md: "15px" },
  //   py: { xs: "0px", md: "10px" },
  //   height: { xs: "40px", md: "auto" },
  //   borderRadius: 0,
  //   textTransform: "capitalize",
  //   width: "100%",
  //   minWidth: "auto",
  //   mb: 0,
  //   display: "flex",
  //   justifycontent: "center",
  //   alignItems: "center",
  //   flexWrap: "wrap",
  //   "&:hover": {
  //     backgroundColor: "#22c55e",
  //     borderRadius: "3px",
  //   },
  //   "> span": {
  //     mx: { xs: 0, lg: 0.3 },
  //   },
  // };

  // const handleAddtoCart = (id, image, product, type, price) => {
  //   const cartItems = {
  //     id: id,
  //     image: image,
  //     category: "video", //video
  //     bibNumber: stateDetails.bibNumber,
  //     type: "download", //download
  //     videopath: image,
  //   };
  //   product == undefined &&
  //     price == undefined &&
  //     dispatch(addToCart(cartDetails, cartItems));
  //   setAddtoCartText("Added");

  //   if (AddToCartText == "Added") {
  //     dispatch(removeFromCart(id, cartDetails));
  //     setAddtoCartText("Add To Cart");
  //   }
  // };

  useEffect(() => {
    fetchOrderDetails();
  }, []);

  const fetchOrderDetails = async () => {
    setIsLoading(true);

    const response = await axios.get(
      ApiManager.preAuthDownload() +
        `?orderref=${orderref}&e=${e}&lt=${lt}&h=${h}`
    );
    if (response.data.StatusCode === 200) {
      setIsLoading(false);

      dispatch(
        orderInfo(
          response.data.result.bib_no,
          response.data.result.order_ref,
          response.data.result.eventID
        )
      );
      setDownloadsCart(response.data.result);
    } else {
      setIsLoading(false);
    }
  };

  const downloadFile = (url) => {
    window.location.href = url;
  };

  return (
    <Box>
      <div style={{ display: isLoading ? "flex" : "none" }} className="modal">
        <div className="modal-content">
          <div className="loader"></div>
          <div className="modal-text">Processing...</div>
        </div>
      </div>
      <>
        <Box>
          <Header bib={121} />
          <>
            <SectionTitle2
              title={downloadsCart?.eventName}
              eventLogo={downloadsCart?.eventLogo}
              eventLogoShow={true}
              bgColor={ColorHelper.WhiteColo4}
            />
            <HeaderNote priceInfo={"Order Details"} />

            {downloadsCart && (
              <SummarySection
                orderId={downloadsCart?.order_ref}
                urls={downloadsCart?.photo}
                count={downloadsCart?.photo.length}
                bibNumber={downloadsCart?.bib_no}
                price={downloadsCart?.orderTotal_amount}
                logo={downloadsCart?.eventLogo}
                type="orderDetails"
              />
            )}
            <Grid
              container
              sx={{
                backgroundColor: ColorHelper.WhiteColor2,
                pb: 12,
                px: { xs: 4, md: 5, lg: 10 },
              }}
            >
              <Grid item xs={12} md={12}>
                {downloadsCart?.photo?.length > 0 ? (
                  <DigitalDownloadsSection
                    data={downloadsCart?.photo}
                    updateBill={updateBill}
                    type={"orderDetails"}
                    bibNumber={downloadsCart?.bib_no}
                    orderId={downloadsCart?.order_ref}
                  />
                ) : null}
                {downloadsCart?.prints.filter((item) => {
                  return item.type === "print";
                }).length > 0 ? (
                  <PrintsSection
                    data={downloadsCart?.prints.filter((item) => {
                      return item.type === "print";
                    })}
                    print_instructions={downloadsCart?.print_instructions}
                    updateBill={updateBill}
                    type={"orderDetails"}
                  />
                ) : null}
                {downloadsCart?.prints.filter((item) => {
                  return item.type.includes("collage");
                }).length > 0 && (
                  <CollageJourneyMapSection
                    collageCart={downloadsCart?.prints.filter((item) => {
                      return item.type.includes("collage");
                    })}
                    collage="collage"
                  />
                )}
                {!isEmpty(downloadsCart?.video) && (
                  <SectionTitle3 title={ConstantHelper.Videos} />
                )}
                {downloadsCart?.video?.length > 0 &&
                  downloadsCart?.video?.map((item) => (
                    <Card
                      sx={{
                        width: "22%",
                        "@media screen and (min-width: 320px) and (max-width: 430px)":
                          {
                            width: "100%",
                          },
                        "@media screen and (width: 820px)": {
                          width: "47%",
                        },
                        "@media screen and (width: 768px)": {
                          width: "47%",
                        },
                        p: "20px",
                        pb: "5px",
                        // height: "25%",
                        //   m: 2,
                        borderRadius: "5px 5px 0 0",
                        border: `1px solid ${ColorHelper.LightGrey4}`,
                        position: "relative",
                        overflow: "visible",
                        alignSelf: "flex-start",
                      }}
                      key={item.imageid}
                    >
                      <CardMedia
                        component={"video"}
                        alt="Please refresh the page or remove this image"
                        height="auto"
                        ref={vidRef}
                        sx={{
                          borderRadius: "3px",
                          maxWidth: "100%",
                          aspectRatio: "1/1",
                          maxHeight: "100%",
                          objectFit: "cover",
                        }}
                        src={item?.s3}
                      />

                      <CardActions sx={{ px: 0 }}>
                        {/* {isPending === "true" ? (
                        <AddToCartAddedBtn
                          AddToCartText={AddToCartText}
                          CardBtnStyle={CardBtnStyle}
                          handleCartBtn={() => {
                            handleAddtoCart(
                              item.imageid,
                              item.s3,
                              undefined,
                              "download",
                              undefined
                            );
                          }}
                        />
                      ) : ( */}
                        <Button
                          onClick={() => {
                            downloadFile(item?.s3);
                          }}
                          disableripple={true}
                          sx={{
                            backgroundColor: "#12B76A",
                            fontFamily: FontHelper.fBold,
                            fontSize: 12,
                            color: ColorHelper.WhiteColor,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            px: "15px",
                            py: "10px",
                            borderRadius: 0,
                            // marginLeft: "auto",
                            mb: 0,
                            "&:hover": { backgroundColor: "#12B76A" },
                          }}
                        >
                          <span
                            style={{
                              fontFamily: FontHelper.fBold,
                              fontSize: 13,
                            }}
                          >
                            {" "}
                            {"Download"}{" "}
                          </span>
                        </Button>
                        {/* )} */}
                      </CardActions>
                    </Card>
                  ))}
              </Grid>
            </Grid>
          </>
          <FooterNote />
          <Footer />
        </Box>
      </>
    </Box>
  );
};
export default OrderDetails;
