import React from "react";

const UnlabledIcon = () => {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.1925 4.19247L2 2.99997L3.08 1.96497L15.8075 14.6925L14.75 15.75L13.25 14.25H4.25C3.425 14.25 2.75 13.575 2.75 12.75V5.24997C2.75 4.83747 2.9225 4.46247 3.1925 4.19247ZM13.7225 4.37997L17 8.99997L15.02 11.7975L6.995 3.74997H12.5C13.0025 3.74997 13.4525 3.99747 13.7225 4.37997Z"
        fill="#F9F5FF"
      />
    </svg>
  );
};

export default UnlabledIcon;
