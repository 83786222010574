import React, { useRef, useState } from "react";
import {
  Grid,
  CardContent,
  MenuItem,
  InputLabel,
  Select,
  CardActions,
  Button,
  FormControl,
  Typography,
} from "@mui/material";

// import { Formik, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "formik-material-ui";
import FilledButton from "../../../Components/Ui-Components/Buttons/FilledButton/FilledButton";
import ConstantHelper from "../../../Helpers/ConstantHelper";
import ColorHelper from "../../../Helpers/ColorHelper";

import { useNavigate } from "react-router-dom";

import CountryCodeData from "../../../Helpers/CountryCodeData";
import { Box } from "@mui/system";

import { Theme, useTheme } from "@mui/material/styles";
import { SelectChangeEvent } from "@mui/material/Select";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useSelector } from "react-redux";
import FontHelper from "../../../Helpers/FontHelper";
import { useLoginFormValidator } from "./hooks/useRegisterForm";
import { useEffect } from "react";
import "./userOrderDetailsForm.css";
import { useSnackbar } from "notistack";

import axios from "axios";
import { FormCheck, FormText } from "react-bootstrap";
import { ApiManager } from "../../../ApiClient";
import {
  nameValidator,
  mobileNoValidator,
  emailValidator,
  genderValidator,
  shippingAddressValidator,
  pinValidator,
} from "./Validator";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 80,
    },
  },
};

const options = [
  { label: "Website Development", value: "website-development" },
  { label: "Software Development", value: "software-development" },
  {
    label: "Mobile Application Development",
    value: "mobile-application-development",
  },
  { label: "Digital Marketing", value: "digital-marketing" },
  { label: "Careers", value: "careers" },
];

const UserOrderForm = ({ price, printInstruction }) => {
  const navigate = useNavigate();
  const snack = useSnackbar();
  const selectedValue = useSelector((state) => state.shop.packageDetails);
  const [isVerifiedName, setVerifiedName] = useState(false);
  const [isVerifiedEmail, setVerifiedEmail] = useState(false);
  const [isVerifiedNumber, setVerifiedNumber] = useState(false);
  const [isVerifiedGender, setVerifiedGender] = useState(false);
  const [isVerifiedShoppingAddress, setVerifiedShoppingAddress] = useState(false);
  const [isVerifiedPinCode, setVerifiedPinCode] = useState(false);

  const [checkoutInfo, setCheckoutInfo] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector((state) => state.user.user);


  const userIsdCode = useSelector((state) => state.user.isdCode);
  const state = useSelector((state) => state);
  const cartPrice = useSelector((state) => state.shop.cartCheckout);
  let cartInfo = useSelector((state) => state.shop.cartInfo);
  const { appliedDiscount, discountedPhoto } = useSelector(
    (state) => state.shop.sponsorDiscount
  );
  const Orientation = localStorage.getItem("Orientation");
  const digitalDownload = cartInfo.filter((item) => item.type === "download" && item.category === "image")
  const printsCart = cartInfo.filter((item) => item.type == "print");
  const collageCart = cartInfo.reduce((items, item) => {
    if (item.type.includes("collage")) {
      items.push({ ...item, type: "collage" + Orientation });
    }
    return items;
  }, [])

  // console.log("cartInfo---", cartInfo);
  // console.log("state---", state);

  const productInfo = useSelector((state) => state.shop.products);
  // console.log("productInfo---", productInfo?.bibNumber);

  const cartVideo = cartInfo.filter((item) => {
    return item.category === "video";
  });
  const isSignedIn = localStorage.getItem("isSignedIn");
  const [filteredCountryCode, setFilteredCountrycode] = useState([]);
  const [openDropDown, setOpenDropDown] = useState(false);

  const [form, setForm] = React.useState(
    isSignedIn
      ? {
        name: user.firstName?.trim() + " " + user.lastName?.trim(),
        email: user.email?.trim(),
        phone_number: user.primaryPhone?.trim(),
        gender: user.gender,
        shipping_address: user.address?.trim(),
        pincode: user.pincode?.trim(),
        countryCode: "+91"
      }
      : {
        name: "",
        email: "",
        phone_number: "",
        gender: "",
        shipping_address: "",
        pincode: "",
        countryCode: "+91"
      }
  );

  const { errors, validateForm, onBlurField } = useLoginFormValidator(form);

  const formlabels = {
    marginBottom: "-0.8rem",
    fontFamily: FontHelper.fBold,
    fontstyle: "normal",
    fontsize: "12px",
    lineheight: "18px",
    color: ColorHelper.PrimaryColor,
    flex: "none",
    order: 0,
    textAlign: "left",
    alignself: "stretch",
    flexgrow: 0,
    mt: 3,
  };

  const Gender = [{ name: "Male" }, { name: "Female" }, { name: "Other" }];

  const checkFormDetails = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    const { isValid } = validateForm({ form, errors, forceTouchErrors: true });
    if (isValid) return;

    let updatedCart = [];
    let totalprice = null;
    if (
      selectedValue === "a" && digitalDownload.length >= productInfo.threshold_photo_count &&
      (productInfo?.bibNumber != "unlabeled" &&
        productInfo?.bibNumber != "selfiesearch")
    ) {
      updatedCart = [
        {
          id: "b_premium",
          image: productInfo?.photo?.length,
          type: "download",
          category: "pack",
          bibNumber: cartPrice.bibNumber,
          // price: totalprice,
          price: productInfo?.bundlePriceInfo?.Premium_Pack,
        },
        ...cartVideo,
        ...printsCart,
        ...collageCart
      ];
      totalprice = price;
    } else if (

      selectedValue === "b" && digitalDownload.length >= productInfo.threshold_photo_count &&
      (productInfo?.bibNumber != "unlabeled" &&
        productInfo?.bibNumber != "selfiesearch")
    ) {

      updatedCart = [
        {
          id: "b_ultraprem",
          image: productInfo?.photo?.length,
          type: "download",
          category: "pack",
          bibNumber: cartPrice.bibNumber,
          // price: totalprice,
          price: productInfo?.ultra_premium_pack,
        },
        ...cartVideo,
        ...printsCart,
        ...collageCart
      ];
      totalprice = price;
    } else {
      totalprice = cartPrice.total_amount;
      updatedCart = cartInfo.map((item) => {
        if (item.type === "collage") {
          return { ...item, type: "collage" + Orientation };
        }
        return item;
      });
    }

    var createOrder = {
      userID: user.id,
      name: form.name,
      email: form.email,
      phone_number: form.phone_number,
      gender: form.gender,
      shipping_address: form.shipping_address,
      pincode: form.pincode,
      isdCode: form.countryCode,
      print_instruction: printInstruction,
      eventID: cartPrice.eventId,
      bibNumber: cartPrice.bibNumber,
      photoID: discountedPhoto.length !== 0 ? discountedPhoto : "",
      sponsorofferID: discountedPhoto.length !== 0 ? cartPrice.getSponsorOffersDetails.id : "",
      amount: totalprice,
      cartInfo: updatedCart,
    };

    if (
      (form.name && isVerifiedName === true) &&
      (form.email && isVerifiedEmail === true) &&
      (form.phone_number && isVerifiedNumber === true) &&
      (form.gender && isVerifiedGender === true) &&
      (form.shipping_address && isVerifiedShoppingAddress === true) &&
      (form.pincode && isVerifiedPinCode === true)
    ) {
      try {
        const response = await axios.post(ApiManager.createOrder(), {
          ...createOrder,
        });

        if (response.data.StatusCode == 200) {
          if (response.data.result.amount === 0) {
            localStorage?.setItem("txnid", response.data.result.txnid);
            setIsLoading(false);
            navigate("/ordersuccess");
          } else {
            var paymentPayload = {
              amount: response.data.result.amount,
              firstname: response.data.result.name,
              email: response.data.result.email,
              phone: response.data.result.phone,
              productinfo: response.data.result.productinfo,
              txnid: response.data.result.txnid,
              furl: ApiManager.fURL(),
              surl: ApiManager.orderSuccess(),
              hash: response.data.result.hash,
              key: response.data.result.key,
              paymentUrl: response.data.result.PAYU_API_URL,
            };
            setIsLoading(false);

            navigate("/order-confirmation", { state: paymentPayload });
          }
        } else {
          setIsLoading(false);
          snack.enqueueSnackbar({
            variant: "error",
            message: response.data.Message,
          });
        }
      } catch (error) {
        setIsLoading(false);
        snack.enqueueSnackbar({
          variant: "error",
          message: "Something went wrong while creating order",
        });
      }
    }
    else {
      // console.log("else block")
      setIsLoading(false);
      setVerifiedName(nameValidator(form.name))
      setVerifiedNumber(mobileNoValidator(form.phone_number))
      setVerifiedEmail(emailValidator(form.email))
      setVerifiedGender(genderValidator(form.gender))
      setVerifiedShoppingAddress(shippingAddressValidator(form.shipping_address))
      setVerifiedPinCode(pinValidator(form.pincode))
    }
  };


  useEffect(() => {
    setForm({
      ...form,
      countryCode: userIsdCode
    })
    setVerifiedName(nameValidator(user.firstName + " " + user.lastName))
    setVerifiedNumber(mobileNoValidator(user.primaryPhone))
    setVerifiedEmail(emailValidator(user.email))
    setVerifiedGender(genderValidator(user.gender))
    setVerifiedShoppingAddress(shippingAddressValidator(user.address))
    setVerifiedPinCode(pinValidator(user.pincode))

    // const userId = localStorage.getItem("ID");
    // if (userId) {
    //   axios
    //     .post(ApiManager.checkoutUserInfo(), {
    //       userID: userId
    //     })
    //     .then((response) => {
    //       if (response.data.StatusCode === 200) {
    //         setCheckoutInfo(response.data.result);
    //       }
    //     })
    //     .catch((err) => {
    //       console.log("Error", err);
    //     });
    // }
  }, []);

  const toggleDropDown = () => {
    setOpenDropDown(true);
  }
  const handleFilter = (e) => {
    setForm({
      ...form,
      countryCode: e.target.value
    })
    const result = CountryCodeData.filter((ele) => (ele.code.includes(e.target.value) || ele.name.toLowerCase().includes(e.target.value.toLowerCase())));
    setFilteredCountrycode(result);
  }
  const handleClickOutside = (event) => {
    if (!event.target.closest(".dropdown")) {
      setOpenDropDown(false);
    }
  };
  const handleSelectIsdCode = (code) => {
    setForm({
      ...form,
      countryCode: code
    })
    setOpenDropDown(false);
  }

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Form>
      <div style={{ display: isLoading ? "flex" : "none" }} className="modal">
        <div className="modal-content">
          <div className="loader"></div>
          <div className="modal-text">Processing...</div>
        </div>
      </div>
      <CardContent>
        <Grid item container spacing={1} justify="center" sx={{ mt: -5 }}>
          <Grid item xs={12} sm={6} md={6}>
            <Typography sx={formlabels}>Name</Typography>
            <Typography sx={{ mt: 2 }} className="tasksInput">
              <input
                variant="outlined"
                onChange={(e) => {
                  setForm({
                    ...form,
                    name: e.target.value,
                  });
                  setVerifiedName(nameValidator(e.target.value));
                }}
                onBlur={onBlurField}
                fullWidth
                name="name"
                value={form.name}
                className="inputField"
                size="small"
                placeholder="Name"
                style={{
                  padding: "10px"
                }}
              />
            </Typography>
            {isVerifiedName !== true && (
              <Typography sx={{ color: "#e11d48" }}>
                {isVerifiedName}
              </Typography>
            )}

          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography sx={formlabels}>Email Address</Typography>
            <Typography sx={{ mt: 2 }} className="tasksInput">
              <input
                variant="outlined"
                fullWidth
                name="email"
                onChange={(e) => {
                  setForm({
                    ...form,
                    email: e.target.value,
                  });

                  setVerifiedEmail(emailValidator(e.target.value));
                }}
                onBlur={onBlurField}
                value={form.email}
                size="small"
                placeholder="Email Address"
                className="inputField"
                style={{
                  padding: "10px"
                }}
              />
            </Typography>
            {isVerifiedEmail !== true && (
              <Typography sx={{ color: "#e11d48" }}>
                {isVerifiedEmail}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={6} spacing={1}>
            <Typography sx={formlabels}>Mobile Number</Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "baseline",
              }}
            >
              <Grid item xs={3} sm={3} md={3}>
                <div class="dropdown">
                  <input
                    className="inputField"
                    name="isd_code"
                    variant="outlined"
                    fullWidth
                    value={form.countryCode}
                    onBlur={onBlurField}
                    onClick={() => toggleDropDown()}
                    onChange={(e) => handleFilter(e)}
                    placeholder="ISD"
                    style={{
                      border: "1px solid #c4c4c4",
                      borderRadius: "5px",
                      padding: "10px"
                    }}
                  />
                  {openDropDown &&
                    <div class="dropdown-content">
                      {
                        filteredCountryCode.length === 0 ?
                          CountryCodeData.map((ele) => {
                            return (
                              <div class="card" onClick={() => handleSelectIsdCode(ele.code)}>{ele.code} - {ele.name}</div>
                            )
                          })
                          :
                          filteredCountryCode.map((ele) => {
                            return (
                              <div class="card" onClick={() => handleSelectIsdCode(ele.code)}>{ele.code} - {ele.name}</div>
                            )
                          })
                      }
                    </div>}
                </div>
              </Grid>

              <Grid item xs={9} sm={9} md={9} sx={{ marginLeft: "7px" }}>
                <Typography
                  sx={{ mt: 2, maxWidth: "100%" }}
                  className="tasksInput"
                >
                  <input
                    variant="outlined"
                    fullWidth
                    name="phone_number"
                    placeholder="Mobile Number"
                    onChange={(e) => {
                      setForm({
                        ...form,
                        phone_number: e.target.value,
                      });
                      setVerifiedNumber(
                        mobileNoValidator(e.target.value)
                      );
                    }}
                    onBlur={onBlurField}
                    value={form.phone_number}
                    className="inputField"
                    type="PhoneNo"
                    size="small"
                    style={{
                      border: "1px solid #c4c4c4",
                      borderRadius: "5px",
                      padding: "10px"
                    }}
                  />
                </Typography>
                {isVerifiedNumber !== true && (
                  <Typography sx={{ color: "#e11d48" }}>
                    {isVerifiedNumber}
                  </Typography>
                )}
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography sx={formlabels}>Gender</Typography>
            <Select
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
              value={form.gender}
              name="gender"
              placeholder="Gender"
              onChange={(e) => {
                setForm({
                  ...form,
                  gender: e.target.value,
                });
                setVerifiedGender(genderValidator(e.target.value));
              }}
              onBlur={onBlurField}
              sx={{ width: "100%", mt: 2 }}
              size="small"
              className="inputField"
            >
              {Gender.map((item) => (
                <MenuItem value={item.name} key={item.name}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
            {/* {errors.gender.dirty && errors.gender.error ? (
              <p className="formFieldErrorMessage">{errors.gender.message}</p>
            ) : null} */}
            {isVerifiedGender !== true && (
              <Typography sx={{ color: "#e11d48" }}>
                {isVerifiedGender}
              </Typography>
            )}
          </Grid>

          <Grid item xs={12} sm={6} md={12}>
            <Typography sx={formlabels}>Shipping Address</Typography>
            <Typography sx={{ mt: 2 }} className="tasksInput">
              {" "}
              <textarea
                variant="outlined"
                fullWidth
                onChange={(e) => {
                  setForm({
                    ...form,
                    shipping_address: e.target.value,
                  });
                  setVerifiedShoppingAddress(
                    shippingAddressValidator(e.target.value)
                  );
                }}
                onBlur={onBlurField}
                name="shipping_address"
                placeholder="City name is sufficient if you are purchasing only digital images"
                value={form.shipping_address}
                type="ShippingAddress"
                multiline={true}
                size="small"
                rows={3}
                className="textareaField"
              />
            </Typography>
            {/* {errors.shipping_address.dirty && errors.shipping_address.error ? (
              <p className="formFieldErrorMessage">
                {errors.shipping_address.message}
              </p>
            ) : null} */}
            {isVerifiedShoppingAddress !== true && (
              <Typography sx={{ color: "#e11d48" }}>
                {isVerifiedShoppingAddress}
              </Typography>
            )}
          </Grid>
          <Grid item xs={10} sm={10} md={10}>
            <Typography sx={formlabels}>Pin Code</Typography>
            <Typography
              sx={{
                mt: 2,
                display: "flex",
                justifyContent: "flex-start",
              }}
              className="tasksInput"
            >
              <input
                variant="outlined"
                onChange={(e) => {
                  setForm({
                    ...form,
                    pincode: e.target.value,
                  });
                  setVerifiedPinCode(pinValidator(e.target.value));
                }}
                onBlur={onBlurField}
                // fullWidth
                name="pincode"
                placeholder="Pin Code"
                value={form.pincode}
                type="text"
                component={TextField}
                size="small"
                className="inputField"
                style={{
                  padding: "10px"
                }}
              />
            </Typography>
            {/* {errors.pincode.dirty && errors.pincode.error ? (
              <p className="formFieldErrorMessage">{errors.pincode.message}</p>
            ) : null} */}
            {isVerifiedPinCode !== true && (
              <Typography sx={{ color: "#e11d48" }}>
                {isVerifiedPinCode}
              </Typography>
            )}
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <FilledButton
          handleClick={checkFormDetails}
          type="submit"
          value="submit"
          btnText={ConstantHelper.submitBtn}
          color={ColorHelper.WhiteColor}
          bgColor={ColorHelper.PrimaryColor}
          sx={{
            ml: "0px",
            width: { xs: "100%", lg: "auto" },
          }}
        />
      </CardActions>
    </Form>
  );
};
export default UserOrderForm;
