import { Box, Typography } from "@mui/material";
import React from "react";
import PrivacyPolicyData from "./PrivacyPolicyData";
import PrivacyOverviewStyle from "./PrivacyOverviewStyle";

const PrivacyOverview = () => {
  return (
    <Box sx={PrivacyOverviewStyle.SectionWrapper}>
      <Typography sx={PrivacyOverviewStyle.Title}>Introduction</Typography>
      <Typography sx={PrivacyOverviewStyle.Description}>
      oneglint Media Solutions Pvt Ltd, India (“oneglint”, “us”, “our”, or “we”), focuses on assuring that the photos.oneglint.com website (“Site”) and the oneglint application (the “Application”), as well as the general ecosystem, provides a safe environment for both website visitors and application users (“Customer”, “You”). We need to collect, process and store some Personal and Navigational information in order to provide you with an ability to review the oneglint website content, allow for using oneglint service and subscribe to it, make online payments, submit support requests and product ideas for our consideration.
      We are dedicated to protecting your privacy. This Privacy Policy (herein the “Policy”) applies to the Site and the service, and it administers the ways we collect, process and store data. The Policy has a direct reference to the Terms of Service and applies to it. It also explains how website visitors and customers can exercise their rights to obtain, access, erase, use or rectify their Personal Information that was of their own free will submitted to us.
      When you browse the Site, subscribe/ register on the website, use the service, leave support requests, submit product ideas, fill out a survey, add comments to the blog posts or perform an online payment, you give consent and fully agree to the Policy under which oneglint collects, processes, uses, stores and discloses Personal Information. If you do not agree with the points stated in this Policy, you should not browse the Site, register on the website, use the service, perform an online payment, submit support requests and product ideas, and perform other activities, which may result in supplying us with their Personal and Navigational Information.
      oneglint is not established in the European Union, but it processes and stores Personal Data of EU citizens, who browses the Site or registers to a free or paid service plan, submits support requests, performs online payment, and performs other activities, which may result in supplying us with their Personal and Navigational Information.
      </Typography>

      {PrivacyPolicyData.map((item, index) => (
        <>
          <Typography sx={PrivacyOverviewStyle.InnerTitle}>
            {index + 1}. {item.title}
          </Typography>
          {item.description.map((DescData) => (
            <Typography sx={PrivacyOverviewStyle.InnnerDescription}>
              {DescData.para}
            </Typography>
          ))}
        </>
      ))}
    </Box>
  );
};

export default PrivacyOverview;
