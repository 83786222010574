import ConstantHelper from "../../../Helpers/ConstantHelper";
import ImageHelper from "../../../Helpers/ImageHelper";

const PrintsSectionData = [
    {
        id: 0,
        title: ConstantHelper.CollageMapTitle1,
        image: ImageHelper.PrintsImage01,
    },
    {
        id: 1,
        title: ConstantHelper.CollageMapTitle2,
        image: ImageHelper.PrintsImage02,
    },
    {
        id: 2,
        title: ConstantHelper.CollageMapTitle3,
        image: ImageHelper.PrintsImage03,
    },
    {
        id: 3,
        title: ConstantHelper.CollageMapTitle4,
        image: ImageHelper.PrintsImage04,
    },
    {
        id: 4,
        title: ConstantHelper.CollageMapTitle5,
        image: ImageHelper.PrintsImage05,
    },
];

export default PrintsSectionData;