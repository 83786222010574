export const nameValidator = (name) => {
  // console.log("nameValidator---if---", name);
  if (!name) {
    return "Name is required";
  } else if (name.length < 3) {
    return "Name must have a minimum 2 characters";
  }
  return true;
};

export const nickNameValidator = (nickName) => {
  // console.log("nickNameValidator---if---", nickName);
  if (!nickName) {
    return "Nick name is required";
  } else if (nickName.length < 5 || nickName.length > 9) {
    return "Nick name must have 6 to 8 characters";
  }
  return true;
};

export const firstNameValidator = (firstName) => {
  // console.log("firstNameValidator---if---", firstName);
  if (!firstName) {
    return "First Name is required";
  } else if (firstName.length < 3) {
    return "First name must have a minimum 3 characters";
  }
  return true;
};
export const lastNameValidator = (lastName) => {
  // console.log("lastNameValidator---if---", lastName);
  if (!lastName) {
    return "Last Name is required";
  } else if (lastName.length < 3) {
    return "Last name must have a minimum 3 characters";
  }
  return true;
};

export const accountNameValidator = (name) => {
  // console.log("accountNameValidator---if---", name);

  if (!name) {
    return "Account name is required";
  } else if (name.length < 3) {
    return "Account name must have a minimum 3 characters";
  }
  return true;
};

export const bankNameValidator = (name) => {
  // console.log("bankNameValidator---if---", name);

  if (!name) {
    return "Bank name is required";
  } else if (name.length < 3) {
    return "Bank name must have a minimum 3 characters";
  }
  return true;
};

export const branchNameValidator = (name) => {
  // console.log("branchNameValidator---if---", name);

  if (!name) {
    return "Branch name is required";
  } else if (name.length < 3) {
    return "Branch name must have a minimum 3 characters";
  }
  return true;
};

// export const dateValidator = (name) => {
//   if (!name) {
//     return "Name is required";
//   } else if (name.length < 3) {
//     return "Name must have a minimum 2 characters";
//   }
//   return true;
// };

export const accountTypeValidator = (name) => {
  if (!name) {
    return "Account Type is required";
  } else if (name.length < 6) {
    return "Account Type must have a minimum 6 characters";
  }
  return true;
};

export const mobileNoValidator = (phone_number) => {
  if (!phone_number) {
    return "Mobile number is required";
  } else if (!new RegExp(/^\+?(91)?[789]\d{9}$|^\+?\d{2,3}\d{8,12}$/).test(phone_number)) {
    return "Enter a valid mobile number without 0 prefix";
  } else {
    return true;
  }
};

export const emailValidator = (email) => {
  if (!email) {
    return "Email is required";
  } else if (!new RegExp(/\S+@\S+\.\S+/).test(email)) {
    return "Incorrect email format";
  } else {
    return true;
  }
};

export const accountNoValidator = (email) => {
  if (!email) {
    return "AccountNo is required";
  } else if (!new RegExp(/^[0-9]{9,18}$/).test(email)) {
    return "Incorrect account number format";
  } else {
    return true;
  }
};

export const ifscCodeValidator = (email) => {
  if (!email) {
    return "IFSC code is required";
  } else if (!new RegExp(/^[A-Za-z]{4}\d{7}$/).test(email)) {
    return "Incorrect IFSC Code format";
  } else {
    return true;
  }
};

export const panCradValidator = (email) => {
  if (!email) {
    return "Pan Card is required";
  } else if (
    !new RegExp(/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/).test(email)
  ) {
    return "Incorrect PAN Card format";
  } else {
    return true;
  }
};

export const registrationRefValidation = (regef) => {
  if (!regef) {
    return "Registration Ref is required";
  } else {
    return true;
  }
};

export const genderValidator = (gender) => {
  if (!gender) {
    return "Gender is required";
  }
  return true;
};

export const shippingAddressValidator = (shipping_address) => {
  if (!shipping_address) {
    return "Shipping Address is required";
  } else if (shipping_address.length < 3) {
    return "Address must have a minimum 3 characters";
  }
  return true;
};


export const pinValidator = (pincode) => {
  if (!pincode) {
    return "Pin Code is required";
  } else if (pincode.length < 4) {
    return "Enter a valid pincode";
  }
  return true;
};
