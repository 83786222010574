import ColorHelper from "../../../Helpers/ColorHelper";
import FontHelper from "../../../Helpers/FontHelper";

const PreOrderDownloadFormStyle = {
  CompWrapper: {
    px: { xs: 0, md: 3 },
    pb: { xs: 2, md: 3 },
    maxWidth: 500,
  },
  formTitle: {
    fontFamily: FontHelper.fMedium,
    fontSize: 30,
    fontWeight: 500,
    color: ColorHelper.PrimaryColor,
    pt: 1,
    textAlign: "center",
  },
  fromContentWrapper: {
    paddingTop: "0rem",
    height: "fit-Content",
  },
  btnContainer: {
    width: "100%",
    textAlign: "center",
    px: 0,
  },
  fieldLabel: {
    fontFamily: FontHelper.fMedium,
    fontSize: 16,
    fontWeight: 500,
    color: ColorHelper.PrimaryColor,
    mt: 1,
    mb: 0.5,
  },
  textBox: {
    borderRadius: 0,
    border: `2px solid ${ColorHelper.PrimaryColor}`,
  },
};

export default PreOrderDownloadFormStyle;
