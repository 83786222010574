import { Password } from "@mui/icons-material";

const ConstantHelper = {
  siteName: "One Glint",

  // HOMEPAGE

  // Navbar Links
  MenuName1: "Home",
  MenuName2: "Events",
  MenuName3: "FAQ",
  MenuName4: "Contact Us",
  MenuName5: "Register",

  NavbarBnt1: "My Cart",
  NavbarBnt2: "Sign In",

  //HeroSection
  Herotitle: "Get your Race Day memories",
  HeroCaption1: "If you have pre-booked the raceday photos,",
  HeroCaptionLink1: "click here",
  HeroCaption2: "If you have difficulty searching with your BIB number, browse",
  HeroCaptionLink2: "Unlabeled section",
  HeroCaptionURL1: "/",

  HeroLink1: "click here",
  SerachFieldTitle: "Select your race/event",
  AdvanceSearchTitle: "Advance Search",

  ExploreMore: "Explore More",
  HightlightSectionTitle: "Highlights of the Season",
  FeaturedEventsSectionTitle: "Featured events of 2023",

  // SINGIN PAGE
  useEmailToSignInTitle: "or use your email to sign in:",
  useEmailToSignUpTitle: "or use your email to sign up:",
  signInBtn: "Sign In",
  signUpBtn: "Sign Up",
  submitBtn: "Submit",
  ResetPassword: "Reset Password",
  ForgotPassword: "Forgot Password",
  ForgotPasswordTitle:
    "Forgot your password? Don't worry put your email we will recover it.",
  ChangePassword: "Change Password",
  OldPassword: "Old Password",
  NewPassWord: "New Password",
  ConfirmPassword: "Confirm Password",
  createAnAccount: "Create an account",
  alreadyHaveAccount: "Already have account, sign in here",
  forgotPassword: "I forgot my password",
  SignIn_Google: "Sign in with Google",
  SignIn_Facebook: "Sign in with Facebook",
  SignUp_Google: "Google",
  SignUp_Facebook: "Sign up with Facebook",

  // PRODUCTPAGE
  EventName: "Hydearabd Triathlon 2019",
  HeaderNote:
    "Price: 1 Photo - ₹ 100, For Each Additional Photo - ₹ 50, 5+ Photos - ₹ 500 + GST",
  footerNoteText:
    "Latest Orders: Manishi purchased 14 photo(s) of NMDC Hyderabad Marathon 2022............    Krishna purchased 1 photo(s) of NMDC Hyderabad Marathon 2022............    Krishna purchased 1 photo(s) of NMDC Hyderabad Marathon 2022............    Krishna placed print order............    Krishna placed print order..",
  BIB_No: "TS-6069",
  SelectedText: "You have",
  products1Count: 3,
  products2Count: 4,
  products3Count: 1,
  products1: "Image(s)",
  products2: "Print(s)",
  products3: "Journey Map",
  products4: "Collage(s)",
  products5: "Video(s)",

  AddFullPackageBtn: "Add full package to cart",
  ProceedToCheckout: "Proceed to checkout",

  addToCartBtn: "Add to cart",
  printOrderBtn: "Print Order",
  CollageBtn: "Collage",
  removeBtn: "Remove",
  AddMorePhotos: "Add more Photos",
  AddMorePrints: "Add more Prints",
  AddMoreItem: "Add more item(s)",
  AddMoreCollages: "Add more Collages",

  AddFullPackageAtDiscountedRatesTitle: "Add Full Package at Discounted rates",

  // CART PAGE
  DigitalDownloadsTitle: "Digital Downloads",
  PrintsTitle: "Prints",
  CollageJourneyMapTitle: "Collage/Journey Map",

  CollageMapTitle1: "Maggnet (3x3)",
  CollageMapTitle2: "Fridge Magnet (4x6 inches)",
  CollageMapTitle3: "Coffee Mug",
  CollageMapTitle4: "Wall Poster (13x10 inches)",
  CollageMapTitle5: "Frame (10x10 inches)",

  ProceedToPay: "Proceed to pay",

  GSTtitle: "GST",
  GSTRateofPercent: "18%",

  NotesTitle: "Notes",

  // ORDER HISTORY
  OrderHistoryTitle: "Orders History",

  // Modals

  // Pre-Order MODAL
  preOrderModalTitle: "Pre-Order Generate Download Link",

  DropImageHereTitle: "Capture your image here ",
  BrowseBtn: "browse",
  SupportedFormated: "Supported JPG, PNG (Max Size: 2mb)",
  WarningForImageUpload:
    "For better results, Please click a photo clearly showing your facial features",

  AdvanceSectionTitle:
    "Use below options to search across run editions (OR) runs in a given year.",
  submitBtn: "Submit",

  ReturnToHomepage: "Return to homepage",
  LoginForOrderHistory: "Login for order history",

  Videos: "Videos",
  //pre-order Booking page
  Note1: "Pre-order now and save your Order reference for later. You will need it to link your bib number and download your race album.",
  Note2:
    "If you are a first time user, new user account will be created automatically.",
  Note3:'You can view your order details on the "Orders History" page.',

  Terms1:
    "While effort is to ensure all participants are covered, for reasons beyond our control few may get left. Some reasons could be wearing BIB# wrongly, tailing other runners or running in groups etc.",
  Terms2:
    "Incase if there are no race day photos available despite the bib is worn correctly, we would be limited to compensating the buyer with equivalent value or coupon for the upcoming race.",

  ImageALT:
    "photos, Download Race photos, Finishers medal photos, Finisher video, Finish line photographs, Race photography, Event photography, Candid moments of Race participants",
};

export default ConstantHelper;
