import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, Suspense } from "react";
import BillView from "../Components/BillView/BillView";
import CollageJourneyMapSection from "../Components/CartSections/CollageJourneyMapSection/CollageJourneyMapSection";
import PrintsSection from "../Components/CartSections/PrintsSection/PrintsSection";
import FooterNote from "../Components/FoooterNote/FooterNote";
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import HeaderNote from "../Components/HeaderNote/HeaderNote";
import SummarySection from "../Components/SummarySection/SummarySection";
import SectionTitle2 from "../Components/Ui-Components/SectionTitle2/SectionTitle2";
import ColorHelper from "../Helpers/ColorHelper";
import { useSelector } from "react-redux";
import { useState } from "react";
import { clearCart, getCartDetails,saveUser } from "../redux/shoppingActions";
import { useDispatch } from "react-redux";
import EmptyCart from "../Components/CartSections/EmptyCart/EmptyCart";
import { useLocation, useNavigate } from "react-router-dom";
import VideoDownloadSection from "../Components/CartSections/VideoSection/VideoDownloadSection";
import FilledButton from "../Components/Ui-Components/Buttons/FilledButton/FilledButton";
import FontHelper from "../Helpers/FontHelper";
import { ApiManager } from "../ApiClient";
import axios from "axios";


import SponsorDiscountModal from "../Components/Modals/SponsorDiscount/SponsorDiscountModal";
// import DigitalDownloadsSection from "../Components/CartSections/DigitalDownloadsSection/DigitalDownloadsSection";
const DigitalDownloadsSection = React.lazy(() =>
  import(
    "../Components/CartSections/DigitalDownloadsSection/DigitalDownloadsSection"
  )
);


const CartPage = () => {
  const dispatch = useDispatch();
  const productInfo = useSelector((state) => state.shop.products);
  // console.log("productInfo", productInfo);
  const cartInfo = useSelector((state) => state.shop.cartInfo);
  const cartDetails = useSelector((state) => state.shop.cartDetails);
  const { sponsorOfferAvailableFlag } = useSelector((state) => state.shop.products);
  const [isLoading, setIsLoading] = useState(false);
  const downloadsCart = cartInfo.filter((item) => {
    if (item.type == "download") {
      return item.category === "image";
    }
  });
  const printsCart = cartInfo.filter((item) => item.type == "print");
  const collageCart = cartInfo.filter((item) => item.type.includes("collage"));
  const videoCart = cartInfo.filter((item) => item.category == "video");
  const config = useSelector((state) => state.config.masterConfig.result);
  const packageDetail = useSelector((state) => state.shop.packageDetails);
  let isPending = localStorage?.getItem("isPending");
  const { discountedPhoto } = useSelector(
    (state) => state.shop.sponsorDiscount
  );
  const userIsdCode = useSelector((state)=> state.user.isdCode);

  


  const cartPrice = useSelector((state) => state.shop.cartCheckout);
  const navigate = useNavigate();

  const updateBill = (updatedCart) => { };

  const location = useLocation();
  const findPath = location.pathname;
  useEffect(() => {
    findPath === "/cart" && localStorage.setItem("currPage", "Cart");
  }, []);

  useEffect(() => {
    let updatedCartInfo = [];
    // console.log("packageDetail",packageDetail)
    if (packageDetail === "b" && cartPrice.bibNumber != "selfiesearch") {
      updatedCartInfo = [
        {
          id: "b_ultraprem",
          image: productInfo?.photo.length,
          type: "download",
          category: "pack",
          bibNumber: cartPrice.bibNumber,
          price: productInfo?.ultra_premium_pack,
        },
        ...videoCart,
        ...printsCart,
        ...collageCart
      ];
    } else if (packageDetail === "a" && cartPrice.bibNumber != "selfiesearch") {
      updatedCartInfo = [
        {
          id: "b_premium",
          image: productInfo?.photo.length,
          type: "download",
          category: "pack",
          bibNumber: cartPrice.bibNumber,
          price: productInfo?.bundlePriceInfo?.price,
        },
        ...videoCart,
        ...printsCart,
        ...collageCart
      ];
    }
    const UserId = localStorage.getItem("ID");
    const payload = {
      ...cartDetails,
      sponsorofferID:
        discountedPhoto.length > 0 ? cartPrice.getSponsorOffersDetails.id : "",
      userID: UserId || "",
      cartInfo:
        (packageDetail === "b" || packageDetail === "a") &&
          downloadsCart?.length >= productInfo.threshold_photo_count
          ? updatedCartInfo
          : cartInfo,
    };
    if (location.state?.isPending !== false) {
      setIsLoading(true);
      dispatch(getCartDetails(payload)).then(() => {
        localStorage.setItem("cartPrice ", JSON.stringify(cartPrice))
        setTimeout(() => {
          setIsLoading(false);
        }, 50);
      });
    }
    localStorage.setItem("currPage", "Cart");
  }, [cartInfo, discountedPhoto]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const userId = localStorage.getItem("ID");
    if (userId) {
      axios
        .post(ApiManager.checkoutUserInfo(), {
          userID: localStorage.getItem("ID"),
        })
        .then((response) => {
          dispatch(saveUser(response.data.result))
        })
        .catch((err) => { });
    }
  }, []);


  return (
    <Box>
      <Header />
      <div style={{ display: isLoading ? "flex" : "none" }} className="modal">
        <div className="modal-content">
          <div className="loader"></div>
          <div className="modal-text">Processing...</div>
        </div>
      </div>
      {cartInfo.length > 0 ? (
        <>
          <SectionTitle2
            title={cartPrice.eventName}
            eventLogo={cartPrice.eventLogo}
            eventLogoShow={true}
            bgColor={ColorHelper.WhiteColo4}
          />
          <HeaderNote priceInfo={cartPrice.priceInfo} />
          <SponsorDiscountModal sponsorOfferAvailableFlag={sponsorOfferAvailableFlag} />

          <SummarySection
            bibNumber={cartDetails.bibNumber}
            unlabledPage="true"
          />
          <Grid
            container
            sx={{
              backgroundColor: ColorHelper.WhiteColor2,
              pb: 12,
              px: { xs: 4, md: 5, lg: 10 },
            }}
          >
            {new Date().getTime() >
              new Date(localStorage?.getItem("awsImageExpiry")).getTime() &&
              isPending !== "true" ? (
              <Box
                sx={{
                  border: "1px solid #F5EAEA",
                  borderRadius: "10px",
                  px: "20px",
                  py: "50px",
                  display: "grid",
                  placeItems: "center",
                  my: "50px",
                  fontWeight: "bold",
                  alignSelf: "center",
                }}
              >
                <span style={{}}>
                  Oops !!..Your search session is expired please serach again
                  get the valid images
                </span>
                <FilledButton
                  handleClick={() => {
                    localStorage?.removeItem("awsImageExpiry");
                    navigate("/home");
                    dispatch(clearCart());
                  }}
                  btnText={"Go back"}
                  color={ColorHelper.PrimaryColor}
                  bgColor={ColorHelper.WhiteColor}
                  bordercolor={ColorHelper.WhiteColor}
                  sx={{
                    position: "relative",
                    fontFamily: FontHelper.fSemiBold,
                    fontWeight: 500,
                    fontSize: { xs: 12, md: 16 },
                    height: { xs: "40px", sm: "50px" },
                    px: "19px",
                    fontWeight: 600,
                    my: "20px",
                  }}
                  cstHover={{
                    "&:hover": {
                      backgroundColor: ColorHelper.PrimaryColorLight,
                      color: ColorHelper.WhiteColor,
                    },
                  }}
                />
              </Box>
            ) : (
              <>
                <Grid item xs={12} md={8}>
                  <Suspense fallback={() => { }}>
                    <DigitalDownloadsSection
                      data={downloadsCart}
                      updateBill={updateBill}
                      digital="digital"
                      orderId={cartDetails.orderId}
                      SponsorDiscountModal={SponsorDiscountModal}
                    />
                  </Suspense>

                  {videoCart?.length > 0 && (
                    <VideoDownloadSection
                      data={videoCart}
                      updateBill={updateBill}
                      video="video"
                      orderId={cartDetails.orderId}
                    />
                  )}
                  {!config.DISABLEPRINTS.includes(cartPrice.eventId) && userIsdCode === '+91' && (
                    <PrintsSection
                      data={printsCart}
                      print="print"
                      updateBill={updateBill}
                      orderId={cartDetails.orderId}
                      bibNumber={cartDetails.bibNumber}
                    />
                  )}
                  {collageCart.length > 0 &&
                    !JSON.parse(
                      localStorage.getItem("Config")
                    ).DISABLEPRINTS.includes(cartPrice.eventId) && (
                      <CollageJourneyMapSection
                        collageCart={collageCart}
                        collage="collage"
                        orderId={cartDetails.orderId}
                        bibNumber={cartDetails.bibNumber}
                      />
                    )}
                </Grid>
                <Grid item xs={12} md={4} sx={{ pt: 3 }}>
                  {cartPrice !== undefined ? (
                    <BillView
                      cartInfo={cartInfo}
                      bibnumber={productInfo.bibNumber}
                    />
                  ) : null}
                </Grid>
              </>
            )}
          </Grid>
        </>
      ) : (
        <EmptyCart />
      )}
      <FooterNote />
      <Footer />
    </Box>
  );
};
export default CartPage;
