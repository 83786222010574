import React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ProductCardData from "./ProductCardData";
import {
  addToCart,
  removeFromCart,
  removeCartCollage,
  removeFromCartDigital,
  removeCartDownload,
} from "../../redux/shoppingActions";
import { useDispatch } from "react-redux";
import ColorHelper from "../../Helpers/ColorHelper";
import FontHelper from "../../Helpers/FontHelper";
import IconHelper from "../../Helpers/IconHelper";
import ConstantHelper from "../../Helpers/ConstantHelper";
import { Box } from "@mui/material";
import FilledButton from "../Ui-Components/Buttons/FilledButton/FilledButton";
import { useSelector } from "react-redux";
import Modal from "@mui/material/Modal";
import { useState } from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PrintIcon from "@mui/icons-material/Print";
import Carousel from "react-multi-carousel";
//masonary
import Masonry from "react-masonry-css";
import "./ProductStyles.css";
import PlusCirclular from "../../Assets/Icons/PlusCirclular";
import AddToCartAddedBtn from "./ProductCardComps/AddToCartAddedBtn";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const SingleProduct = ({
  photo,
  index,
  handleOpen,
  stateDetails,
  cartDetails,
  item,
  handleClose,
  handlePicOpen,
  reportPics,
  setReportedPhoto,
  typeOfProd,
  // handleReport
}) => {
  const [addCollageText, setCollageText] = useState("Collage");
  const [AddToCartText, setAddtoCartText] = useState("Add To Cart");
  const cartInfo = useSelector((state) => state.shop.cartInfo);
  const config = useSelector((state) => state.config.masterConfig.result);
  const products = useSelector((state) => state.shop.products);
  const [reportcheck, setReportCheckbox] = useState(false);
  const dispatch = useDispatch();
  const path = useLocation();
  const userIsdCode = useSelector((state) => state.user.isdCode);

  const handleReport = (img) => {
    setReportCheckbox(!reportcheck);

    if (!reportcheck) {
      setReportedPhoto((prev) => {
        prev.push(img);
        return prev;
      });
    } else {
      setReportedPhoto((prev) => {
        const index = prev?.indexOf(item?.id);
        if (index > -1) {
          prev.splice(index, 1);
        }
        return prev;
      });
    }
  };

  const handleAddCollage = (id, s3, image, type) => {
    const collageItems = {
      id: id,
      s3: s3,
      image: image,
      category: "image",
      bibNumber: stateDetails.bibNumber,
      type: type,
      price: 1179,
      status: "Added",
    };
    if (addCollageText == "Collage") {
      setCollageText("Added");
      dispatch(addToCart(cartDetails, collageItems));
    } else {
      setCollageText("Collage");
      dispatch(removeCartCollage(id, cartInfo, "collage"));
    }
  };

  const handleAddtoCart = (id, s3, image, product, type, price) => {
    const cartItems = {
      id: id,
      s3: s3,
      image: image,
      category: "image",
      bibNumber: stateDetails.bibNumber,
      type: type,
      videopath: "",
      status: "Added",
    };
    if (AddToCartText == "Add To Cart") {
      setAddtoCartText("Added");
      product == undefined && price == undefined
        ? dispatch(addToCart(cartDetails, cartItems))
        : dispatch(
          addToCart(cartDetails, {
            ...cartItems,
            product: product,
            price: price,
          })
        );
    } else {
      setAddtoCartText("Add To Cart");
      dispatch(removeCartDownload(id, cartInfo, "download"));
    }
  };

  const breakpointColumnsObj = {
    default: 3,
  };

  const CardBtnStyle = {
    fontFamily: FontHelper.fBold,
    fontSize: 12,
    color: ColorHelper.WhiteColor,
    backgroundColor: " #3f0e77",
    px: { xs: "0px", md: "0px" },
    py: { xs: "0px", md: "10px" },
    height: { xs: "40px", md: "auto" },
    borderRadius: 0,
    textTransform: "capitalize",
    width: "100%",
    minWidth: "auto",
    mb: 0,
    display: "flex",
    justifycontent: "center",
    alignItems: "center",
    flexWrap: "wrap",
    "&:hover": {
      backgroundColor: "#22c55e",
      borderRadius: "3px",
    },
    "> span": {
      mx: { xs: 0, lg: 0.3 },
    },
  };

  const checkInCart = (dataimg) => {
    const ids = cartInfo?.filter(
      (items) => items.id && items.type == "download"
    );
    const downloadIDs = ids?.map((items) => items.id);
    const collages = cartInfo?.filter(
      (items) => items.id && items.type == "collage"
    );
    const collagesID = collages?.map((items) => items.id);
    if (downloadIDs?.includes(dataimg.id)) {
      setAddtoCartText("Added");
    } else {
      setAddtoCartText("Add To Cart");
    }

    if (collagesID.includes(dataimg.id)) {
      setCollageText("Added");
    } else {
      setCollageText("Collage");
    }
  };

  React.useEffect(() => {
    checkInCart(item);
  }, [cartInfo]);

  useEffect(() => {
    if (!reportPics) {
      setReportCheckbox(false);
    }
  }, [reportPics]);

  return (
    <>
      <Box
        className="masonimage"
        key={item.id}
        sx={{
          img: {
            overflow: "hidden",
            transition: "all 0.5s ease 0s",
          },
          "&:hover img": {
            transform: "Scale(1.1)",
            transformOrigin: "bottom",
          },
          "&:hover .sci": {
            display: "grid",
          },
        }}
      >
        <img
          src={item.s3}
          style={{ width: "100%" }}
          alt={item.id}
          onClick={
            reportPics == false
              ? (e) => {
                handlePicOpen(e, index);
              }
              : () => handleReport(item.id)
          }
        />
        {reportPics && (
          <input
            checked={reportcheck}
            type="checkbox"
            className="checkbox"
            onClick={() => handleReport(item.id)}
            style={{ background: "#d40", color: "#fff" }}
          />
        )}
        {reportPics == false ? (
          <Box
            className="sci"
            sx={{
              bottom: { xs: "0px", md: "0px", lg: "0px" },
              py: { xs: 0, sm: 0.5, lg: 1 },
              px: 1,
              columnGap: { xs: 1, sm: 1 },
              rowGap: { xs: 0, sm: 2 },
              display: "none",
              ...(config.DISABLEPRINTS.includes(products.eventId) ||
                config.DISABLEPRINTS.includes(products.eventID)
                ? {
                  gridTemplateColumns: "1fr",
                }
                : path?.pathname == "/unlabeled-events"
                  ? userIsdCode === '+91' ? { gridTemplateColumns: "1fr 1fr" } : { gridTemplateColumns: "1fr" }
                  : userIsdCode === '+91' ? { gridTemplateColumns: "1fr 1fr 1fr" } : { gridTemplateColumns: "1fr 1fr" }),
            }}
          >
            <AddToCartAddedBtn
              AddToCartText={AddToCartText}
              CardBtnStyle={CardBtnStyle}
              handleCartBtn={() =>
                handleAddtoCart(
                  item.id,
                  item.s3,
                  item.image,
                  undefined,
                  "download",
                  undefined
                )
              }
            />

            {/* {!config.DISABLEPRINTS.includes(products.eventId) && path?.pathname != '/unlabeled-events' &&  ( */}
            {config.DISABLEPRINTS.includes(products.eventId) ||
              (!config.DISABLEPRINTS.includes(products.eventID) && userIsdCode === '+91' && (
                <Button
                  disableripple={true}
                  onClick={() => {
                    handleOpen(item.s3, item.id);
                  }}
                  sx={CardBtnStyle}
                  startIcon={<PrintIcon />}
                >
                  <Typography
                    sx={{
                      fontFamily: FontHelper.fBold,
                      fontSize: 12,
                      color: ColorHelper.WhiteColor,
                      display: { xs: "none", lg: "block" },
                    }}
                  >
                    Order prints
                  </Typography>
                </Button>
              ))}
            {config.DISABLEPRINTS.includes(products.eventId) ||
              (!config.DISABLEPRINTS.includes(products.eventID) &&
                path?.pathname != "/unlabeled-events" && (
                  <Button
                    disableripple={true}
                    sx={CardBtnStyle}
                    startIcon={
                      addCollageText == "Added" ? (
                        <CheckCircleOutlineIcon />
                      ) : (
                        <PhotoLibraryIcon />
                      )
                    }
                    onClick={() => {
                      handleAddCollage(item.id, item.s3, item.image, "collage");
                    }}
                  >
                    <Typography
                      sx={{
                        display: { xs: "none", sm: "block" },
                        fontFamily: FontHelper.fBold,
                        fontSize: 12,
                        color: ColorHelper.WhiteColor,
                        display: { xs: "none", lg: "block" },
                      }}
                    >
                      {addCollageText}
                    </Typography>
                  </Button>
                ))}
          </Box>
        ) : null}
      </Box>
    </>
  );
};

export default SingleProduct;
