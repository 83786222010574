import { Box } from "@mui/system";
import React from "react";

const chevronDownIcon = () => {
  return (
    <Box sx={{ px: "5px" }}>
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6 9.5L12 15.5L18 9.5"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </Box>
  );
};

export default chevronDownIcon;
