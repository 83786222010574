import React, { useEffect } from "react";
import { Box } from "@mui/system";
// import emailjs from "emailjs-com"
import ContactStyle from "./ContactStyle";
import ImageHelper from "../../Helpers/ImageHelper";
import { Grid, Typography, CardContent, Button } from "@mui/material";
import { useRef, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import TextField from "@mui/material/TextField";
import ColorHelper from "../../Helpers/ColorHelper";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import ConstantHelper from "../../Helpers/ConstantHelper";
import FontHelper from "../../Helpers/FontHelper";
import FilledButton from "../Ui-Components/Buttons/FilledButton/FilledButton";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import MailIcon from "@mui/icons-material/Mail";
import CountryCodeData from "../../Helpers/CountryCodeData";
import ReCAPTCHA from "react-google-recaptcha";
import RecaptchaConfigs from "../../Helpers/RecaptchaConfigs";
import axios from "axios";
import { useSnackbar } from "notistack";
import Autocomplete from "@mui/material/Autocomplete";
import { useSelector } from "react-redux";
import { ApiManager } from "../../ApiClient";

export default function Contact({ countryPhoneCode }) {
  const snack = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [isdCode, setIsdCode] = useState("+91");
  const [filteredCountryCode, setFilteredCountrycode] = useState([]);
  const [openDropDown, setOpenDropDown] = useState(false);

  const userIsdCode = useSelector((state) => state.user.isdCode);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 80,
      },
    },
  };

  // const form = useRef();
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [isVerified, setVerified] = useState(false);
  //Data for Form

  //PhoneNo validation
  const numericRegEx = /^\+?(91)?[789]\d{9}$|^\+?\d{2,3}\d{8,12}$/;
  const lengthRegEx = /(?=.{10,})/;

  const validationSchema = yup.object({
    firstName: yup.string()
      .min(3, 'Too Short!')
      .matches(/^[a-zA-Z]+$/, "only alphabets")
      .required("First Name is required"),
    lastName: yup.string()
      .min(3, 'Too Short!')
      .matches(/^[a-zA-Z]+$/, "only alphabets")
      .required("Last Name is required"),
    email: yup.string().email("Invalid email").required("Email is required"),
    phoneNo: yup
      .string()
      .matches(numericRegEx, "Invalid phone number!")
      .matches(lengthRegEx, "Must contain 10 Digits!")
      .required("Phone no is required!"),
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNo: "",
      message: "",
      // phoneID:"",
      bibId: "",
      // termscond:termscond
    },
    enableReinitialize: true,
    validateOnChange: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handleSubmit = async (values) => {
    try {
      const formData = new FormData();
      Object.keys(values).forEach((key) => formData.append(key, values[key]));
      formData.append("isdCode", isdCode);
      setIsLoading(true);

      const response = await axios.post(
        ApiManager.contactFormSubmit(),
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.data.StatusCode === 200 && response.data.Message) {
        setIsLoading(false);
        snack.enqueueSnackbar({
          variant: "success",
          message: response.data.Message,
        });
        formik.resetForm();
      } else {
        setIsLoading(false);
        snack.enqueueSnackbar({
          variant: "warning",
          message: response.data.Message,
        });
      }
    } catch (error) {
      snack.enqueueSnackbar({
        variant: "error",
        message: "Something Went Wrong",
      });
    }
  };

  const handleOnChangeRecaptcha = () => {
    setVerified(true);
  };
  useEffect(() => {
    setIsdCode(userIsdCode)
  }, []);
  const toggleDropDown = () => {
    setOpenDropDown(true);
  }
  const handleFilter = (e) => {
    setIsdCode(e.target.value)
    const result = CountryCodeData.filter((ele) => (ele.code.includes(e.target.value) || ele.name.toLowerCase().includes(e.target.value.toLowerCase())));
    setFilteredCountrycode(result);
  }
  const handleClickOutside = (event) => {
    if (!event.target.closest(".dropdown")) {
      setOpenDropDown(false);
    }
  };
  const handleSelectIsdCode = (code) => {
    setIsdCode(code);
    setOpenDropDown(false);
  }

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  return (
    <Box>
      <div style={{ display: isLoading ? "flex" : "none" }} className="modal">
        <div className="modal-content">
          <div className="loader"></div>
          <div className="modal-text">Processing...</div>
        </div>
      </div>
      <>
        <Grid conatiner sx={ContactStyle.main}>
          <Grid xs={12} sm={12} md={12} lg={6} sx={{ order: { xs: 2, md: 1 } }}>
            <Box sx={ContactStyle.form}>
              <Typography sx={ContactStyle.text1}>Contact Us</Typography>
              <Typography sx={ContactStyle.text2}>
                Our friendly team would love to hear from you.
              </Typography>
              <form id="my-form-id" onSubmit={formik.handleSubmit}>
                <CardContent sx={ContactStyle.formcard}>
                  <Grid container spacing={1} justify="center">
                    <Grid item xs={12} sm={6} md={6}>
                      <Typography sx={ContactStyle.labeltxt}>
                        First Name
                      </Typography>
                      <TextField
                        value={formik.values.firstName}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.firstName &&
                          Boolean(formik.errors.firstName)
                        }
                        helperText={
                          formik.touched.firstName && formik.errors.firstName
                        }
                        variant="outlined"
                        fullWidth
                        name="firstName"
                        placeholder="First Name"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <Typography sx={ContactStyle.labeltxt}>
                        Last Name
                      </Typography>
                      <TextField
                        value={formik.values.lastName}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.lastName &&
                          Boolean(formik.errors.lastName)
                        }
                        helperText={
                          formik.touched.lastName && formik.errors.lastName
                        }
                        variant="outlined"
                        fullWidth
                        name="lastName"
                        placeholder="Last Name"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <Typography sx={ContactStyle.labeltxt}>Email</Typography>
                      <TextField
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.email && Boolean(formik.errors.email)
                        }
                        helperText={formik.touched.email && formik.errors.email}
                        variant="outlined"
                        fullWidth
                        name="email"
                        placeholder="Email"
                      />
                    </Grid>

                    <Grid container spacing={1} sx={{ paddingLeft: "0.6rem" }} >
                      <Grid item xs={12} sm={6} md={6}>
                        <Typography sx={ContactStyle.labeltxt}>Mobile</Typography>
                        <Grid container spacing={1} >
                          <Grid item xs={3} sm={4} md={4}>
                            <div class="dropdown">
                              <TextField
                                id="myInput"
                                variant="outlined"
                                fullWidth
                                value={isdCode}
                                onClick={() => toggleDropDown()}
                                onChange={(e) => handleFilter(e)}
                                placeholder="ISD"
                                sx={{ width: "100%" }} />
                              {openDropDown &&
                                <div class="dropdown-content">
                                  {
                                    filteredCountryCode.length === 0 ?
                                      CountryCodeData.map((ele) => {
                                        return (
                                          <div class="card" onClick={() => handleSelectIsdCode(ele.code)}>{ele.code} - {ele.name}</div>
                                        )
                                      })
                                      :
                                      filteredCountryCode.map((ele) => {
                                        return (
                                          <div class="card" onClick={() => handleSelectIsdCode(ele.code)}>{ele.code} - {ele.name}</div>
                                        )
                                      })
                                  }
                                </div>}
                            </div>

                          </Grid>
                          <Grid item xs={9} sm={8} md={8}>
                            <TextField
                              value={formik.values.phoneNo}
                              onChange={formik.handleChange}
                              error={
                                formik.touched.phoneNo &&
                                Boolean(formik.errors.phoneNo)
                              }
                              helperText={
                                formik.touched.phoneNo && formik.errors.phoneNo
                              }
                              variant="outlined"
                              name="phoneNo"
                              placeholder="Mobile"
                              sx={{
                                width: "100%",
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={12} sm={6} md={6}>
                        <Typography sx={ContactStyle.labeltxt}>BIB Id</Typography>
                        <TextField
                          value={formik.values.bibId}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.bibId && Boolean(formik.errors.bibId)
                          }
                          helperText={formik.touched.bibId && formik.errors.bibId}
                          variant="outlined"
                          fullWidth
                          name="bibId"
                          placeholder="BIB Id"
                        />
                      </Grid>

                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      sx={{ marginBottom: "20px" }}
                    >
                      <Typography sx={ContactStyle.labeltxt}>
                        Message
                      </Typography>
                      <TextField
                        value={formik.values.message}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.message &&
                          Boolean(formik.errors.message)
                        }
                        helperText={
                          formik.touched.message && formik.errors.message
                        }
                        variant="outlined"
                        fullWidth
                        placeholder="Message"
                        name="message"
                        multiline={true}
                        rows={4}
                      />
                    </Grid>
                  </Grid>
                  <ReCAPTCHA
                    sx={ContactStyle.submitbtn}
                    sitekey={RecaptchaConfigs.Sitekey}
                    onChange={handleOnChangeRecaptcha}
                  />
                  <Box sx={ContactStyle.submitbtn}>
                    <FilledButton
                      type="submit"
                      disabled={!isVerified}
                      btnText="Send Message"
                      color={ColorHelper.WhiteColor}
                      bgColor={ColorHelper.PrimaryColor}
                      bordercolor={ColorHelper.WhiteColor}
                      sx={{
                        ml: -0.05,
                        fontFamily: FontHelper.fBold,
                        fontWeight: 500,
                        fontSize: 16,
                        width: "100%",
                      }}
                      cstHover={{
                        "&:hover": {
                          backgroundColor: ColorHelper.PrimaryColorLight,
                          color: ColorHelper.WhiteColor,
                        },
                      }}
                    />
                  </Box>
                </CardContent>
              </form>
              <Box>
                <Typography sx={ContactStyle.text2}>
                  Or you can contact us with
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <a href="https://wa.me/918886666464" target="_blank">
                    <WhatsAppIcon color="success" sx={{ fontSize: 30 }} />
                  </a>
                  <MailIcon
                    sx={{
                      cursor: "pointer",
                      fontSize: 30,
                      color: "navy",
                      margin: "0  10px",
                    }}
                    onClick={() =>
                      window.open(
                        "mailto:support@oneglint.com?subject=Subject&body="
                      )
                    }
                  />{" "}
                  <span
                    style={{
                      color: "blue",
                      textDecoration: "underline",
                      cursor: "pointer",
                      textAlign: "center",
                    }}
                    onClick={() =>
                      window.open(
                        "mailto:support@oneglint.com?subject=Subject&body="
                      )
                    }
                  >
                    {" "}
                    support@oneglint.com
                  </span>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={6}
            sx={{
              order: { xs: 1, md: 2 },
              display: { xs: "none", md: "none", lg: "flex" },
              minWidth: "50%",
            }}
          >
            <Box sx={ContactStyle.imgContainer}>
              <img src={ImageHelper.contact} />
            </Box>
          </Grid>
        </Grid>
      </>
    </Box>
  );
}
