const ColorHelper = {
  PrimaryColor: "#3f0e77",
  PrimaryColorLight: "#592398",
  SecondaryColor: "#FFFFFF",
  LightPurple: "#E1E1FE",
  LightPurple2: "#F4EBFF",
  YellowColor: "#D97706",
  YellowColor2: "#F59E0B",
  LightYellow: "#FEF3C7",
  LightYellow2: "#DDC970",
  IntenseYellow: "#FACC15",
  BlueColor: "#4B4EFC",
  IntenseBlue: "#1D5EFF",
  LightBlue: "#F5F5FF",
  Voiletblue: "#7F56D9",
  NavyBlue: "#01215C",
  GreenColor: "#22C55E",
  LightGreen: "#F0FDF4",
  RedColor: "#EF4444",
  LightRedColor: "#FEE2E2",
  WhiteColor: "#FFFFFF",
  WhiteColor2: "#F2F6FF",
  WhiteColor3: "#E4E7EC",
  WhiteColo4: "#F9FAFB",
  BlackColor: "#000000",
  LightGrey1: "#F0EFEF",
  LightGrey2: "rgba(68, 83, 102, 45%)",
  LightGrey3: "#D1D5DB",
  LightGrey4: "#E5E7EB",
  LightGrey5: "#6B7280",
  LightGrey6: "#101828",
  LightGrey7: "#667085",
  LightGrey8: "#344054",
  LightGrey9: "#98A2B3",
  LightGrey10: "#e4e4e4",
  DarkGrey1: "#4F574B",
  YellowColor3: "#DDC970",
  DarkGrey2: "#323741",
  DarkGrey3: "#9CA3AF",
  DarkGrey4: "#23242A",
  DarkGrey5: "#4B5563",
  DarkGrey6: "#D0D5DD",
  DarkGrey7: "#475467",
  VioletClr1: "#7375FD",
  VioletClr2: "#1E22FB",
  borderColor: "#C3C4FE",
  PinkColor: "#B91880",
  disabledColor: "rgb(200, 200, 200)",
  disabledTextColor: "rgb(134, 135, 136)",
  ErrorBg: "#fee4e2",
  ErrorText: "#d92d20",
};

export default ColorHelper;
